<template lang="pug">
  .config
    div
      h1
        |🛠️開発用データ表示・設定ツール ※デプロイ不可※
      //-
        section
          h2 利用規約バージョン
          form(@submit="updateTermsVersion")
            table
              tr
                th ローカルに保存されたバージョン
                td: input(type="text" v-model="termsVersion.local")
              tr
                th サーバー
                td {{termsVersion.server}}
            input(type="submit" value="保存する")
      section
        h2 ユーザー情報
        p ※validationしないので変なデータを入れないこと
        form(@submit="updateUserInfo")
          table
            tr
              th 表示名 (12文字以下)
              td: input(type="text" minlength="1" maxlength="12" v-model="userInfo.name")
            tr
              th コメント (15文字以下)
              td: input(type="text" minlength="1" maxlength="15" v-model="userInfo.comment")
            tr
              th 生年
              td
                input(type="number" min="1000" max="9999" v-model="userInfo.birthYear")
                | 年
            tr
              th 性別 (1:男性 / 2:女性)
              td
                input(type="radio" name="gender" id="gender1" value="1" v-model="userInfo.gender")
                label(for="gender1") 男性
                input(type="radio" name="gender" id="gender2" value="2" v-model="userInfo.gender")
                label(for="gender2") 女性
            tr
              th 身長
              td
                input(type="number" min="1" max="999" v-model="userInfo.height")
                | cm
            tr
              th 適正体重
              td {{bmi}} kg
            tr
              th 画像
              td {{userInfo.imageUrl}}
          input(type="submit" value="保存する")
      section
        h2 目標体重設定
        p ※validationしないので変なデータを入れないこと
        form(@submit="updateTargetWeight")
          table
            tr
              th 目標体重
              td
                input(type="text" min="1" max="999" v-model="userInfo.targetWeight")
                | kg
            tr
              th 現在の身長
              td
                input(type="text" min="1" max="999" v-model="userInfo.height")
                | cm
            tr
              th 現在の体重
              td
                input(type="text" min="1" max="999" v-model="userInfo.currentWeight")
                | kg
          input(type="submit" value="保存する")
      section
        h2 歩数データ
        ul
          li(v-for="item in stepsData")
            span.time {{item.dateTime | moment}}
            span {{item.steps}} 歩
      section(v-if="healthData.length>0")
        h2 ヘルスデータ
        ul
          li(v-for="item in healthData")
            span.time {{item.dateTime | moment}}
            span {{item.weight}}kg | 
            span {{item.bodyFatPercentage}}% | 
            span(:class="'emotion' + item.breakfast") 朝食
            span(:class="'emotion' + item.nightMeal") 夜食
            span(:class="'emotion' + item.overeating") 過食
            span(:class="'emotion' + item.overdrinking") 過飲
            span(:class="'emotion' + item.sleepness") 眠気
            span(:class="'emotion' + item.feeling") 気分
      section
        h2 個人戦
        table(v-for="item in individualMatches")
          tr
            th タイトル
            td {{item.name}}
          tr
            th 開始日時
            td {{item.displayStartDateTime | moment}}
          tr
            th 終了日時
            td {{item.displayEndDateTime | moment}}
          tr
            th 主催者
            td {{item.isGeneral ? '運営':'テナント'}}
          tr
            th 参加中か？
            td(v-if="item.isRegistered") 参加中
            td(v-else)
              | 未参加
              button(v-on:click="enterIndividualMatch(item.id,item.isGeneral)") 参加する
</template>

<script>
import moment from 'moment-timezone'
import mixinUser from '@/mixins/user.js'

export default {
  mixins: [mixinUser],
  data() {
    return {
      user: null,
      userInfo: {},
      healthData: [],
      stepsData: [],
      individualMatches: [],
      termsVersion: {},
    }
  },
  methods: {
    getUserStatus() {
      this.api(
        'getCurrentUserStatus',
        {
          teamChatModifiedDates: {},
          pointHistoryFromDate: '',
        },
        rv => {
          console.log('getCurrentUserStatus', rv)
          // 参加可能/参加済みの個人戦を表示
          this.individualMatches = rv.individualMatches

          this.userInfo.currentWeight = rv.weight.weight
          this.userInfo.targetWeight = rv.weight.targetWeight
          console.log('walkingProgram', rv.walkingProgram)
        }
      )
    },
    getUserInfo() {
      // カスタムフィールドの参照
      // https://docs.kii.com/ja/guides/cloudsdk/javascript/managing-users/user-attributes/custom-fields/
      this.userInfo = {
        name: this.user.getDisplayName(),
        comment: this.user.get('comment'),
        birthYear: this.user.get('birthYear'),
        imageUrl: this.user.get('imageUrl'),
        height: this.user.get('height'),
        gender: this.user.get('gender'),
      }
    },
    getRank() {
      let monday = moment()
        .day(1)
        .startOf('day') // 1=monday

      this.api(
        'getWalkingProgramRank',
        { timestamp: monday.unix() * 1000, filterbyTenant: false },
        rv => {
          console.log('getWalkingProgramRank', rv)
        }
      )
    },
    updateUserInfo(e) {
      e.preventDefault()

      const identityData = {
        displayName: this.userInfo.name, // ※表示名だけはカスタムフィールドではない
      }
      const customFields = {
        comment: this.userInfo.comment,
        birthYear: 1 * this.userInfo.birthYear,
        height: 1 * this.userInfo.height,
        gender: 1 * this.userInfo.gender,
      }
      this.user
        .update(identityData, null, customFields)
        .then(function(theUser) {
          console.log('updated:', identityData, customFields)
        })
        .catch(function(error) {})
    },
    updateTargetWeight(e) {
      e.preventDefault()

      this.api(
        'registerTargetWeight',
        {
          targetWeight: 1 * this.userInfo.targetWeight,
          currentHeight: 1 * this.userInfo.height,
          currentWeight: 1 * this.userInfo.currentWeight,
        },
        rv => {
          console.log(rv)
        }
      )
    },
    /*
    getTermsVersion() {
      this.termsVersion.local = this.loadLocalTermsVersion()
      this.api('getConfig', {}, rv => {
        console.log('getConfig', rv)
        this.termsVersion.server = rv.termsVersion || 0
      })
    },
    updateTermsVersion(e) {
      e.preventDefault()
      this.saveLocalTermsVersion(this.termsVersion.local)
    },
    */
    // 直近14日間の歩数データを表示
    getStepsData() {
      let args = {
        fromDateTime:
          moment()
            .subtract(14, 'days')
            .unix() * 1000,
        toDateTime: moment().unix() * 1000,
        needsWeeklyAverage: true,
        needsInterview: true,
        needsWeight: true,
        needsBodyFatPercentage: true,
      }
      this.api('getHealthDataByRange', args, rv => {
        this.healthData = rv.healthData
        this.stepsData = rv.stepsData
        console.log('health', rv.healthData)
        console.log('steps', rv.stepsData)
        console.log('weeklyStepsData', rv.weeklyStepsData)
      })
    },
    // 直近7日間の歩数データを登録
    setStepsData() {
      let args = {
        healthData: [],
        osType: 1,
        osVersion: '13.3',
        appVersion: '1.2.3',
        source: 1,
      }
      let steps = 0

      // 7日前から今日までランダムに歩数を記録
      for (let i = 7; i >= 0; i--) {
        let sec = moment()
          .subtract(i, 'days')
          .startOf('day') // 00:00:00:00
          .unix()
        if (i == 3) {
          steps = 0 // 意図的に歯抜けデータをつくる
        } else {
          steps = Math.floor(Math.random() * 6000) + 5000
        }
        args.healthData.push({
          dateTime: sec * 1000, // millisec
          steps,
        })
      }
      this.api('uploadHealthData', args, rv => {
        console.log('stepData uploaded')

        this.api('notifyHealthDataFixed')
      })
    },
    setHealthData() {
      function rand12() {
        return Math.floor(Math.random() * 2) + 1
      }
      for (let i = 7; i >= 0; i--) {
        let sec = moment()
          .subtract(i, 'days')
          .startOf('day') // 00:00:00:00
          .unix()

        let args = {
          dateTime: sec * 1000,
          weight: 60 + Math.floor(Math.random() * 20) / 10,
          bodyFatPercentage: 10 + Math.floor(Math.random() * 100) / 10,
          breakfast: rand12(),
          nightMeal: rand12(),
          overeating: rand12(),
          overdrinking: rand12(),
          sleepness: rand12(),
          feeling: rand12(),
          exercise: rand12(),
          note: '',
        }
        this.api('recordHealthData', args, rv => {
          console.log('healthData uploaded', args, rv)
        })
      }
    },

    // 個人戦に参加する
    enterIndividualMatch(id, isGeneral) {
      this.api(
        'enterIndividualMatch',
        {
          individualMatchID: id,
          isGeneral,
        },
        rv => {
          alert('登録しました')
        }
      )
    },
  },
  computed: {
    bmi: function() {
      const h = this.userInfo.height
      if (!h) return 0
      return Math.round(Math.pow(h / 100, 2) * 22 * 10) / 10
    },
  },
  created() {
    console.log('Config created --------------------')
  },
  mounted() {
    const user = KiiUser.getCurrentUser()
    user.refresh().then(user => {
      this.user = user
      console.log('Config user', this.user)

      //this.getUserStatus()
      this.getRank()
      this.getUserInfo()
      this.setStepsData()
      this.setHealthData()
      this.getStepsData()
      //this.getTermsVersion()
    })
  },
}
</script>
<style scoped lang="scss">
.config {
  font-size: 14px;
}
h1 {
  font-weight: bold;
  font-size: 20px;
  margin: 40px 20px;
}
h2 {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 16px;
  border-bottom: #000 2px solid;
  margin-bottom: 20px;
}
section {
  background: #fff;
  border: #ccc 1px solid;
  margin: 20px;
  padding: 20px 30px;
}
table {
  border-collapse: collapse;
  margin: 12px 0;
  th,
  td {
    border: #999 1px solid;
    padding: 6px 16px;
    text-align: left;
    font-size: inherit;
  }
  th {
    font-weight: bold;
  }
}
button {
  border: #666 1px solid;
  background: #eee;
  padding: 2px 12px;
}
.time {
  color: rgb(22, 120, 158);
  margin-right: 10px;
}

.emotion {
}
.emotion1::after {
  content: '🙂';
}
.emotion2::after {
  content: '🤢';
}
</style>
