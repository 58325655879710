<i18n>
  {
    "ja":{
      "日連続入力中！": "日連続入力中！"
    },
    "en":{
      "日連続入力中！": "days! Inputting continuously"
    }
  }
</i18n>
<template lang="pug">
div(v-if="healthLogInputStatus.continuousDays && healthLogInputStatus.continuousDays >= 2").health-log-input-status-area
  p {{ healthLogInputStatus.continuousDays }}
    span {{$t("日連続入力中！")}}
</template>
<script>
export default {
  name: 'HealthLogInputStatus',
  components: {},
  props: {
    healthLogInputStatus: Object,
  },
  data() {
    return {}
  },
  methods: {},
  created() {},
}
</script>
<style scoped lang="scss">
.health-log-input-status-area {
  background-color: rgb(242, 122, 131);
  border-radius: 8px;
  height: 46px;
  background-image: url('../../assets/images/icon_good_smile.svg');
  background-repeat: no-repeat;
  background-position: center left 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-indent: 52px;
  @include sp-layout {
    width: 100%;
  }
  p {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
  }
  span {
    margin-left: 3px;
    font-size: 14px;
    letter-spacing: normal;
    @include sp-layout {
      font-size: 15px;
    }
  }
}
</style>
