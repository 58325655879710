import store from '../store/index.js'
export default (name, args) => {
  if (!args) args = {}
  args.locale = store.state.locale
  const entry = Kii.serverCodeEntry(name)
  return entry.execute(args).then(params => {
    const { returnedValue } = params[2].getReturnedValue()
    console.log(name, args, returnedValue)
    return returnedValue
  })
}
