<i18n>
{
  "ja":{
    "参加する一覧":"参加する一覧",
    "学ぶ一覧":"学ぶ一覧",
    "コンテンツ":"コンテンツ"
  },
  "en":{
    "参加する一覧":"Events",
    "学ぶ一覧":"Library",
    "コンテンツ":"Article"
  }
}
</i18n>
<template lang="pug">
.SectionContents
  .loading(v-if="!sections || !sections.length")
    img(src="@/assets/images/loading.gif")
  section(v-for="(item, i) in sections")
    //- サブタイトル
    h2(v-if="item.subtitle") {{item.subtitle}}
    //- フリーコメント
    .description(v-if="item.comment" v-html="$options.filters.text2html(item.comment)")

    //- 画像
    ImageViewerWithThumbnail(:images="item.images" :uid="i")

    //- 動画(本番orステージング環境でのみ動作確認)
    .video-content(v-for="video in item.videos")
      video(class="video-js" ref="videoElm" :data-videoId="video.videoID" :poster="video.thumbnailImageUrl")
        source(:src="video.videoUrl" type="application/x-mpegURL")

    //- .pdf添付ファイル
    .attachment(v-if="item.attachments && item.attachments.length")
      .attachment-area.pc
        ul
          li(v-for="file in item.attachments")
            .name {{file.fileName}}
            a.link(:href="file.url" target="_blank" @click="notifyViewReportArticleResource(null, file.id)") {{$t("詳しく見る")}}
      .attachment-area.sp
        ul
          li(v-for="file in item.attachments")
            a.link(:href="file.url" target="_blank" @click="notifyViewReportArticleResource(null, file.id)")
              span.name {{file.fileName}}

    //- 内部リンク
    .reltype-link(v-if="linkButtons[i] && linkButtons[i].to")
      router-link(:to="linkButtons[i].to")
        //- ※customLabelは翻訳されている
        button.middle-button-style.blue-button.w-100(v-if="linkButtons[i].customLabel") {{linkButtons[i].customLabel}} 
        button.middle-button-style.blue-button.w-100(v-else) {{$t(linkButtons[i].label)}}
  
</template>
<script>
import ImageViewerWithThumbnail from '@/components/ImageViewerWithThumbnail'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  components: {
    ImageViewerWithThumbnail,
  },
  props: [
    'sections',
    'libraryId', // 非必須。ライブラリーのみidを渡す
  ],
  data() {
    return {
      isSectionParsed: false,
      linkButtons: [],
      isMovieMounted: false,
      players: [],
    }
  },
  methods: {
    init() {
      if (!this.isMovieMounted) this.mountMovies()
      if (this.sections && !this.isSectionParsed) {
        this.parseSectionData()
      }
    },
    parseSectionData() {
      // propsの変更は推奨されていないのでbuttonだけの配列を新たに作る
      this.linkButtons = this.sections.map((section) => {
        return this.genLinkButtonData(section)
      })
      this.isSectionParsed = true
    },
    genLinkButtonData(section) {
      // sectionType=5: 内部リンク
      if (section.sectionType !== 5) {
        return null
      }
      let label = ''
      let customLabel = ''
      let to = null
      switch (section.relType) {
        case 'Events':
        case 'EventParticipation':
          label = 'イベント'
          to = { name: 'event', params: { id: section.relObjectID } }
          break
        case 'EventList':
          label = '参加する一覧'
          to = { name: 'events' }
          break
        case 'Announcements':
          label = 'お知らせ'
          to = { name: 'announcement', params: { id: section.relObjectID } }
          break
        case 'ReportArticles':
          label = 'コンテンツ'
          to = { name: 'library', params: { id: section.relObjectID } }
          break
        case 'ReportArticleList':
          label = '学ぶ一覧'
          to = { name: 'libraries' }
          break
        case 'TaggedReportArticles':
          label = '学ぶ一覧'
          to = { name: 'libraryTag', params: { id: section.relObjectID } }
          break
        case 'Questionnaire':
          label = 'アンケート'
          to = { name: 'survey', params: { id: section.relObjectID } }
          break
      }
      // カスタムラベルが指定されている場合
      if (section.relLabel) {
        customLabel = section.relLabel
      }
      return { label, customLabel, to }
    },
    mountMovies() {
      console.log('mount movies')
      const options = {
        autoplay: false,
        controls: true,
        fluid: true,
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2.0],
      }
      if (this.$refs.videoElm) {
        this.$refs.videoElm.forEach(elm => {
          const player = videojs(elm, options, function onPlayerReady() {
            console.log('onPlayerReady', this)
          })
          if (this.libraryId) {
            player.on('play', () => {
              const videoId = elm.getAttribute('data-videoId')
              this.notifyViewReportArticleResource(videoId, null)
              console.log('play', videoId)
            })
          }
          this.players.push(player)
        })
        this.isMovieMounted = true
      }
    },
    notifyViewReportArticleResource(videoId, attachmentId) {
      if (this.libraryId) {
        const libraryId = this.libraryId
        this.api(
          'notifyViewReportArticleResource',
          { libraryId, videoId, attachmentId },
          rv => {
            if (!rv || !rv.succeed) {
              console.log(rv, '失敗')
            } else {
              console.log(rv, '成功')
            }
          }
        )
      }
    },
  },
  mounted() {
    console.log('mounted')
    this.init()
  },
  updated() {
    console.log('updated')
    this.init()
  },
  beforeDestroy() {
    if (this.players.length) {
      this.players.forEach(player => {
        player.dispose()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
  padding: 100px;
}
.attachment-area {
  margin: 30px 0 80px;
}
.video-content {
  margin-bottom: 30px;
}
.click-thumbnail-lists {
  margin-bottom: 50px;
}
</style>
