<template lang="pug">
//- イベント一覧ページ
.events
  .common-h1-area
    HeadingBlock(level="1" text="Event")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") 参加する
    p.heading-block-note {{$t("法人契約でご利用の方以外は閲覧できないコンテンツがあります")}}
  .sub-page-padding
    .container
      .events-block
        .events-category-tabs
          ul.tabs-list.events-tabs-list
            li(class="checked tab-select-child")
              .filter-select-block(@click="toggleScheduleTypeSelect()" :class="[ selectScheduleTypeIsOpen ? 'open' : '' ]")
                span(v-if="filterStatus.scheduleType==1") {{$t("開催前")}}
                span(v-if="filterStatus.scheduleType==3") {{$t("本日開催")}}
                span(v-if="filterStatus.scheduleType==2") {{$t("開催済")}}
              .selectBoxOptions(v-show="selectScheduleTypeIsOpen")
                div(@click="changeFilterStatus('scheduleType', 1); toggleScheduleTypeSelect()") {{$t("開催前")}}
                div(@click="changeFilterStatus('scheduleType', 3); toggleScheduleTypeSelect()") {{$t("本日開催")}}
                div(@click="changeFilterStatus('scheduleType', 2); toggleScheduleTypeSelect()") {{$t("開催済")}}
            li(:class="{'checked': filterStatus.onlyFree }")
              input(type="checkbox" id="events-filter-tabs-list-1" @change="changeFilterStatus('onlyFree', $event.target.checked)")
              label(for="events-filter-tabs-list-1") {{$t("無料")}}
            li(:class="{'checked': filterStatus.onlyOnline }")
              input(type="checkbox" id="events-filter-tabs-list-2" @change="changeFilterStatus('onlyOnline', $event.target.checked)")
              label(for="events-filter-tabs-list-2") {{$t("オンライン")}}
            li(:class="{'checked': filterStatus.onlyReservation }")
              input(type="checkbox" id="events-filter-tabs-list-3" @change="changeFilterStatus('onlyReservation', $event.target.checked)")
              label(for="events-filter-tabs-list-3") {{$t("要予約")}}

        .reserved-or-fav(v-if="reservingEvents.length")
          HeadingBlock(level="2" text="予約済み/お気に入り")
          .heading-read-more
            router-link(:to="{name: 'eventsReserved'}") {{$t("もっと見る")}}
          // .reserving-events
          //  Events(:events="reservingEvents")

        template(v-if="filterStatus.scheduleType == 1")
          HeadingBlock(level="2" text="開催予定")

        template(v-if="filterStatus.scheduleType == 2")
          HeadingBlock(level="2" text="開催済")

        template(v-if="filterStatus.scheduleType == 3")
          HeadingBlock(level="2" text="本日開催")

        Events(:events="events")

        .events-readmore-block.center(:class="{ 'hide' : !more }")
          button.middle-button-style.blue-button(@click="readMore") {{$t("もっと見る")}}

</template>

<script>
import { mapState } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'
import Events from '@/components/Events'

export default {
  components: {
    HeadingBlock,
    Events,
  },
  data() {
    return {
      perPage: 9,
      selectScheduleTypeIsOpen: false,
    }
  },
  watch: {
    locale() {
      filterStatusOnChange()
    },
  },
  computed: {
    more() {
      return this.length === this.perPage
    },
    ...mapState('event', { events: 'items' }),
    ...mapState('event', { length: 'length' }),
    ...mapState('event', { filterStatus: 'filterStatus' }),
    ...mapState('reservingEvent', { reservingEvents: 'items' }),
    ...mapState(['locale']),
  },
  methods: {
    changeFilterStatus(key, value) {
      this.$store.commit('event/setFilterStatus', { key, value })
      this.filterStatusOnChange()
    },
    toggleScheduleTypeSelect() {
      this.selectScheduleTypeIsOpen = !this.selectScheduleTypeIsOpen
    },
    filterStatusOnChange() {
      this.$store.commit('event/resetItemsOnly')
      this.getItems()
    },
    getItems() {
      this.$store.dispatch('event/getItems', {
        perPage: this.perPage,
        scheduleType: this.filterStatus.scheduleType,
        onlyFree: this.filterStatus.onlyFree,
        onlyOnline: this.filterStatus.onlyOnline,
        onlyReservation: this.filterStatus.onlyReservation,
      })
    },
    readMore() {
      this.$store.commit('event/incrementPage')
      this.getItems()
    },
  },
  /*
|`offset`|Integer|非必須|ページネーションをする際の開始インデックス（0 origin）<br>指定しない場合は先頭から |
|`perPage`|Integer|非必須|一度に取得する数（指定しないとデフォルト値：20）|
|`scheduleType`|Integer|必須|開催前／開催済の指定<br>`1`:開催前<br>`2`:開催済|
|`onlyFree`|Boolean|非必須|`true`を指定した場合は無料のもののみで絞る<br>指定しない場合は絞りなし|
|`onlyOnline`|Boolean|非必須|`true`を指定した場合はオンラインのもののみで絞る<br>指定しない場合は絞りなし|
|`onlyReservation`|Boolean|非必須|`true`を指定した場合は予約が必要なもののみで絞る<br>指定しない場合は絞りなし|
*/
  created() {
    this.$store.commit('event/resetItemsOnly')
    if (!this.events.length) {
      this.$store.dispatch('event/getItems', {
        perPage: this.perPage,
        scheduleType: 1,
      })
    }
    if (!this.reservingEvents.length) {
      this.$store.dispatch('reservingEvent/getItems', {
        perPage: 3,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.reserved-or-fav{
  margin-bottom: 32px;
}
.events-block {
  .tabs-list > li:first-child:hover {
    opacity: 1;
  }
  .tab-select-child {
    position: relative;
    cursor: pointer;
    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      right: 13px;
      top: 1px;
      bottom: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5.5px 0 5.5px;
      border-color: #ffffff transparent transparent transparent;
      @include sp-layout {
        right: 13px;
      }
    }
  }
}
.events-category-tabs {
  margin-bottom: 55px;
}
.filter-select-block {
  border: none;
}
.filter-select-block:focus {
  outline: none;
}
.filter-select-block,
.filter-select-block option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 28px;
  background-color: #004098;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  @include sp-layout {
    font-size: 13px;
  }
}
.events-block {
  .selectBoxOptions {
    position: absolute;
    width: 140px;
    left: 50%;
    top: 44px;
    transform: translate(-50%, 0);
    z-index: 5;
    background: #fff;
    border: #004098 0px solid;
    border-width: 2px 2px 0;
    color: #004098;
    @include sp-layout {
      width: 118px;
      top: 38px;
    }

    div {
      padding: 4px 8px;
      text-align: center;
      border-bottom: #004098 2px solid;
      cursor: pointer;
    }
    div:hover {
      background: #004098;
      color: #fff;
    }
  }
}

@media (max-width: 580px) {
  .events-block {
    .selectBoxOptions {
      width: 80%;
      top: 35px;
    }
  }
}
</style>
