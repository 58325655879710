<template lang="pug">
  #app(:class="[(this.$i18n.locale === 'ja') ? 'ja' : 'en' ]")
    .warnIE(v-if="$isIE")
      template(v-if="this.$i18n.locale === 'en'") This site is not compatible with Internet Explorer. Please click <a href="microsoft-edge:https://go.andwell.jp">here</a> to change your browser to Microsoft Edge.
      template(v-else) このサイトはInternet Explorerには対応しておりません。<a href="microsoft-edge:https://go.andwell.jp">こちら</a>からブラウザをMicrosoft Edgeに変更してください。
    modal(name="locale-select" :width="640" :height="375" :adaptive="true" :reset="true")
      LocaleSelect
    CommonHeader
    //-
      main
        .maintenance ただいまメンテナンス中です。<br>しばらくしてから再度アクセスしてください。
    main
      router-view(v-if="!needsSignin")
      div(v-else class="message")
        | ログインが必要なコンテンツです。
        span(@click="verifyBeforeSignin") こちら
        |からログインしてください。
    CommonFooter
</template>
<script>
import Vue from 'vue'
import VModal from 'vue-js-modal'
import CommonHeader from '@/components/CommonHeader'
import CommonFooter from '@/components/CommonFooter'
import LocaleSelect from '@/components/LocaleSelect'
import mixinUser from '@/mixins/user.js'
const { META } = require('@/assets/constants.json')

Vue.use(VModal)

export default {
  components: {
    CommonHeader,
    CommonFooter,
    LocaleSelect,
  },
  mixins: [mixinUser],
  computed: {
    needsSignin() {
      return (
        this.$route.meta.requiresAuth && !this.$store.state.user.authenticated
      )
    },
  },
  watch: {
    $route(routeInstance, from) {
      this.createMetaArea(routeInstance)
    },
  },
  created() {
    // 言語設定をstoreからロード、なければブラウザの設定を読む
    this.$store.dispatch('loadLocale')
    let lang =
      this.$store.state.locale ||
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage ||
      'ja'
    lang = lang.substr(0, 2) // en-US, en-GB, ja-JP などに対応
    this.$i18n.locale = lang
    this.$store.dispatch('saveLocale', lang)
  },
  methods: {
    createMetaArea(routeInstance) {
      // title/og:titleを設定
      if (routeInstance.meta.title) {
        const setTitle = routeInstance.meta.title + ' | ' + META.TITLE
        document.title = setTitle
        document
          .querySelector("meta[property='og:title']")
          .setAttribute('content', setTitle)
        document
          .querySelector("meta[property='twitter:title']")
          .setAttribute('content', setTitle)
      } else {
        document.title = META.TITLE
        document
          .querySelector("meta[property='og:title']")
          .setAttribute('content', META.TITLE)
        document
          .querySelector("meta[property='twitter:title']")
          .setAttribute('content', META.TITLE)
      }

      // description/og:descriptionを設定
      if (routeInstance.meta.desc) {
        const setDesc = routeInstance.meta.desc + ' | ' + META.TITLE
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', setDesc)
        document
          .querySelector("meta[property='og:description']")
          .setAttribute('content', setDesc)
        document
          .querySelector("meta[property='twitter:description']")
          .setAttribute('content', setDesc)
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', META.DESCRIPTION)
        document
          .querySelector("meta[property='og:description']")
          .setAttribute('content', META.DESCRIPTION)
        document
          .querySelector("meta[property='twitter:description']")
          .setAttribute('content', META.DESCRIPTION)
      }
    },
  },
  mounted() {
    this.createMetaArea(this.$route)
  },
}
</script>

<style lang="scss" scoped>
.maintenance {
  text-align: center;
  margin: 80px;
}
.message {
  padding: 30vh 0;
  text-align: center;
}
span {
  color: $base-brand-color;
  text-decoration: underline;
  cursor: pointer;
}
.warnIE {
  padding: 10px 12px;
  text-align: center;
  font-size: 14px;
  background: #ebdda0;
  a {
    color: $tags-color-blue;
    text-decoration: underline;
  }
}
</style>
