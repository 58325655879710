<template lang="pug">
//- 「学ぶ」記事ランキング
.libraries
  .tag-h1-area
    .container
      h1._mainttl {{$t("ランキング")}}
  .sub-page-padding
    .container
      ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3.ranking-icon(v-if="rankingData && rankingData.length")
        li.col(v-for="item,index in rankingData" :key="index" :data-ranking="index + 1")
          router-link(:to="{name: 'library', params: {id: item.id}}")
            .column-header-block(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
            .column-footer-block
              ul.common-tags-lists
                li.common-tags-list.common-tags-blue.common-tags-color-bg(v-if="item.relType!='ReportArticles'") {{$t(item.relType)}}
              h2.title {{ item.title }}
  </template>

<script>
import { mapState } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'

export default {
  name: 'libraryRanking',
  components: {
    HeadingBlock,
  },
  data() {
    return {}
  },
  watch: {
    locale() {
      this.load()
    },
  },
  computed: {
    ...mapState('libraryRanking', { rankingData: 'items' }),
    ...mapState(['locale']),
  },
  methods: {
    load() {
      this.$store.dispatch('libraryRanking/getItems')
    },
  },
  created() {
    if (!this.rankingData.length) {
      this.load()
    }
  },
}
</script>

<style scoped lang="scss">
.columns-block-list {
  margin-top: 32px;
  margin-bottom: 30px;
  > li {
    margin-bottom: 40px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include link-hover();
    }
  }
  .column-thumbnail {
    width: 100%;
    display: block;
  }
  .column-header-block {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f8;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }
  .column-footer-block {
    padding-top: 14px;
  }
  .title {
    margin-top: 9px;
    font-weight: bold;
  }
}
</style>
