<template lang="pug">
  .event-sessions
    .event-sessions-by-day(v-for="day in sessionsByDay")
      h3.event-session-date {{ day.date | moment('MMMDo(ddd)') }}
      .event-session(v-for="session in day.sessions" :key="session.id")
        //- ph15～ オフライン/オンラインが混在する場合のみアイコンを表示
        template(v-if="event.providingType === 3")
          .session-offline(v-if="session.providingType === 1") {{$t("オフライン")}}
          .session-online(v-if="session.providingType === 2") {{$t("オンライン")}}
        //- 予約済みなら予約IDを表示する
        .session-reserve-id(v-if="session.participant")
          | {{$t("予約ID")}}：
          span {{ session.participant.reservationID }}
        dl.session-info
          //- 予約なしの場合は開催時間を表示する
          template(v-if="session.reservationType === 0")
            dt {{$t("開催時間")}}
            dd {{ session.startDateTime | moment('HH:mm') }}〜{{ session.endDateTime | moment('HH:mm') }}
          template
            dt {{$t("定員")}}
            dd(v-if="session.capacity") {{ session.capacity }} {{$t("名様")}}
            dd(v-else) {{$t("制限なし")}}
          //- 予約開始前の場合のみ「予約開始」を表示する
          template(v-if="session.reservationStartDateTime && now < session.reservationStartDateTime")
            dt {{$t("予約開始日時")}}
            dd {{ session.reservationStartDateTime | moment('YYYY.MM.DD(ddd) HH:mm') }}
          //- 予約終了
          template(v-if="session.reservationEndDateTime")
            dt {{$t("予約終了日時")}}
            dd {{ session.reservationEndDateTime | moment('YYYY.MM.DD(ddd) HH:mm') }}

        .reserve-button-area(v-if="$store.state.user.authenticated && session.button")
          template(v-if="session.button.url")
            //- 予約が外部サイトの場合
            a._button(:href="session.button.url" target="_blank")
              span {{$t(session.button.label)}}
          template(v-else)
            button._button(v-if="session.button.onClick" :class="session.button.className" @click="session.button.onClick" :value="session.id")
              span._term(v-if="session.button.showTerm") {{ session.startDateTime | moment('HH:mm') }}〜{{ session.endDateTime | moment('HH:mm') }}
              span {{$t(session.button.label)}}
            button._button(v-else :class="session.button.className" :value="session.id")
              span._term(v-if="session.button.showTerm") {{ session.startDateTime | moment('HH:mm') }}〜{{ session.endDateTime | moment('HH:mm') }}
              span {{$t(session.button.label)}}

          transition(name="fade")
            //- Warningテキストエリア
            .reserve-event-status-block(v-show="warningText[session.id]") {{ warningText[session.id] }}
    Questions(v-show="event.questions" ref="questions" :questions="event.questions" @reserve="requestReservation")
</template>
<script>
import moment from 'moment-timezone'
import Questions from '@/components/Event/Questions'

export default {
  components: {
    Questions,
  },
  props: ['event'],
  data() {
    return {
      sessionsByDay: [],
      now: 0,
      warningText: {},
    }
  },
  watch: {
    event() {
      this.init()
    },
  },
  methods: {
    init() {
      this.now = moment().valueOf()
      this.event = this.$props.event
      this.sessionsByDay = this.parseSessions(this.event.sessions)
    },
    // sessionsを日付ごとにまとめる
    parseSessions(sessions) {
      let parsedSessions = []
      const isSameDay = (date1, date2) => {
        return moment(date1).isSame(date2, 'day')
      }
      /* output example
      [
        {
          date:xxxxx, // 当日00:00のmillisec
          sessions:[], // Session型の配列
        },
        {
          date:xxxxxx,
          sessions:[]
        }
        {
          sessions:[] // startとendが同日でないsessionの場合、dateなし
        }
      ]
      */
      sessions.forEach((session) => {
        console.log(session.startDateTime)
        if (
          parsedSessions.length === 0 ||
          !isSameDay(
            parsedSessions[parsedSessions.length - 1].date,
            session.startDateTime
          )
        ) {
          console.log('初めての日')
          // 初めて出てきた日
          if (isSameDay(session.startDateTime, session.endDateTime)) {
            parsedSessions.push({
              date: session.startDateTime,
              sessions: [session],
            })
          } else {
            console.log('1日セッションでない')
            parsedSessions.push({
              sessions: [session],
            })
          }
        } else {
          console.log('初めてじゃない日')
          // 同じ日
          if (isSameDay(session.startDateTime, session.endDateTime)) {
            // この場合parsedSessionsに追加しない
            parsedSessions[parsedSessions.length - 1].sessions.push(session)
          } else {
            console.log('1日セッションでない')
            parsedSessions.push({
              sessions: [session],
            })
          }
        }
        if (session.reservationType !== 0) { // 0:予約なし 1:予約あり 2:外部予約
          session.button = this.switchButtonData(session)
        }
      })
      return parsedSessions
    },
    // メソッド単体テスト用
    test_parseSessions() {
      const sessions = [
        {
          id: 1,
          startDateTime: moment('2021-01-01 09').valueOf(),
          endDateTime: moment('2021-01-01 10').valueOf(),
        },
        {
          id: 2,
          startDateTime: moment('2021-01-01 10').valueOf(),
          endDateTime: moment('2021-01-01 11').valueOf(),
        },
        {
          id: 3,
          startDateTime: moment('2021-01-01 11').valueOf(),
          endDateTime: moment('2021-01-01 12').valueOf(),
        },
        {
          id: 4,
          startDateTime: moment('2021-01-01 12').valueOf(),
          endDateTime: moment('2021-01-01 13').valueOf(),
        },
        {
          id: 5,
          startDateTime: moment('2021-01-02 12').valueOf(),
          endDateTime: moment('2021-01-04 12').valueOf(),
        },
        {
          id: 6,
          startDateTime: moment('2021-01-04 13').valueOf(),
          endDateTime: moment('2021-01-05 13').valueOf(),
        },
        {
          id: 7,
          startDateTime: moment('2021-01-05 13').valueOf(),
          endDateTime: moment('2021-01-05 15').valueOf(),
        },
        {
          id: 8,
          startDateTime: moment('2021-01-05 15').valueOf(),
          endDateTime: moment('2021-01-05 17').valueOf(),
        },
        {
          id: 9,
          startDateTime: moment('2021-01-08 10').valueOf(),
          endDateTime: moment('2021-01-08 15').valueOf(),
        },
        {
          id: 10,
          startDateTime: moment('2021-01-08 15').valueOf(),
          endDateTime: moment('2021-01-08 18').valueOf(),
        },
      ]
      const test = this.parseSessions(sessions)
      console.log(test)
    },
    switchButtonData(session) {
      /*
        ※※labelを変える場合はlang.jsonも変更すること※※
      */
      let button = {}
      const now = this.now

      if (session.endDateTime <= now) {
        // 開催終了後なら開催終了ボタン
        button = {
          className: 'disabled',
          label: '開催終了',
        }
        return button
      }

      if (session.participant) {
        // 予約済みならキャンセルボタン
        button = {
          className: 'cancel',
          showTerm: true,
          label: 'キャンセルする',
          onClick: this.cancelReservation,
        }
        return button
      }

      /*
      Session型 ph15～ ※一部抜粋
      |`providingType`|Integer|必須|提供タイプ<br>`1`:オフライン<br>`2`:オンライン|
      |`reservationType`|Integer|必須|予約タイプ<br>`0`:予約なし<br>`1`:予約あり<br>`2`:外部予約|
      |`isFullyReserved`|Boolean|条件付き必須|外部予約が満席か否か<br>`true`:満席<br>`reservationType: 2`の場合は必須、それ以外はなし|
      |`url`|Boolean|条件付き必須|`reservationType: 2`の場合に外部予約URLが必須で返る|
      */
      if (session.reservationType === 2) {// 2:外部予約
        // 外部予約の場合
        if (session.isFullyReserved) {
          button = {
            className: 'active', // 満席でもactiveかつリンクありの仕様
            showTerm: false,
            label: '満席になりました',
            url: session.url,
          }
        } else {
          button = {
            className: 'active',
            showTerm: false,
            label: '予約は外部サイトへ',
            url: session.url,
          }
        }
        return button
      } else if (
        now < session.reservationStartDateTime ||
        session.reservationEndDateTime <= now
      ) {
        // 予約期間より前か後ならdisabled
        button = {
          className: 'disabled',
          showTerm: true,
          label: '予約する',
        }
        return button
      } else {
        // 予約期間内
        if (session.reservedCount >= session.capacity) {
          // 定員に達している
          button = {
            className: 'disabled',
            showTerm: true,
            label: '満席',
          }
        } else {
          button = {
            className: 'active',
            showTerm: true,
            label: '予約する',
            onClick: this.reserve,
          }
        }
        return button
      }
    },
    reserve(e) {
      console.log('予約処理開始')
      const sessionID = e.target.value
      if (this.event.hasQuestions) {
        // 予約前アンケートを開く
        this.$refs.questions.open(sessionID)
      } else {
        this.requestReservation(sessionID)
      }
    },
    requestReservation(sessionID, answers) {
      this.$emit('showLoading')
      console.log(sessionID, answers)
      let errorText = ''
      this.api(
        'reserveEvent',
        { eventID: this.event.id, sessionID, body: answers },
        (rv) => {
          console.log(rv.reservationID)
          if (rv.succeed) {
            errorText = '予約が完了しました'
            this.$store.commit('event/resetItems')
            this.$store.commit('reservingEvent/resetItems')
          } else {
            const errors = {
              reservationNotAccepted: 'このイベントは現在予約できません',
              alreadyReserved: '予約済です',
              reservationCapacityOver: '定員に達してるため予約できませんでした',
              reservedAnotherSession: '他のセッションに既に予約済です',
            }
            errorText = errors[rv.errorCode] || '予約に失敗しました'
          }
          this.$emit('init') // 親のinit()で再描画する
          this.warningText[sessionID] = this.$i18n.t(errorText)
        }
      )
    },
    cancelReservation(e) {
      this.$emit('showLoading')
      console.log('予約キャンセル処理開始')
      const sessionID = e.target.value
      let errorText = ''
      this.api(
        'cancelEventReservation',
        { eventID: this.event.id, sessionID },
        (rv) => {
          console.log(rv)
          if (rv.succeed) {
            errorText = 'キャンセルを受け付けました'
            this.$store.commit('event/resetItems')
            this.$store.commit('reservingEvent/resetItems')
          } else {
            const errors = {
              cancellationNotAccepted: 'このイベントは現在キャンセルできません',
              noReservation: '予約がないか既にキャンセル済です',
            }
            errorText = errors[rv.errorCode] || 'キャンセルに失敗しました'
          }
          this.$emit('init') // 親のinit()で再描画する
          this.warningText[sessionID] = this.$i18n.t(errorText)
        }
      )
    },
    // recordParticipation(eventID, sessionID) {
    //   //イベント予約用API(テスト用なので後日削除)
    //   this.api('recordParticipation', { eventID, sessionID }, rv => {
    //       console.log(rv)
    //   })
    // }
  },
  mounted() {
    this.init()
    //this.test_parseSessions()

    //- イベント予約用関数
    //- this.recordParticipation('b00ec20d-e929-11eb-ac47-0af363eb5062', '1800')
  },
}
</script>

<style lang="scss" scoped>
/* セッション一覧 */
.event-sessions {
  .event-sessions-by-day {
    margin-bottom: 40px;
  }
  .event-session-date {
    margin: 0 0 16px;
    height: 20px;
    padding-left: 12px;
    border-left: 3px $tags-color-blue solid;
    font-size: 16px;
    color: #222;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .event-session {
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 6px;
    border: $tags-color-blue 1px solid;
    background: #fff;
  }

  .session-offline,
  .session-online {
    height: 36px;
    background-image: url('../../assets/images/event/offline.svg');
    background-size: 36px 36px;
    background-repeat: no-repeat;
    padding-left: 45px;
    margin-bottom: 12px;
    color: $tags-color-blue;
    font-size: 14px;
    font-weight: bold;
    line-height: 34px;
  }
  .session-online {
    background-image: url('../../assets/images/event/online.svg');
  }
  .session-reserve-id {
    border: 1px solid $tags-color-gray;
    padding: 10px 10px;
    font-size: 13px;
    font-weight: 500;
    color: $base-color;
    margin-bottom: 10px;
    border-radius: 4px;
    text-align: center;
    span {
      margin-right: 1em;
    }
  }

  .session-info {
    dt {
      min-width: 5em;
      clear: left;
      float: left;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      color: #999;
    }
    dd {
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.reserve-event-status-block {
  font-size: 14px;
  margin-top: 8px;
  color: $base-color-red;
}
.event-end-block {
  margin-bottom: 25px;
}

.reserve-button-area {
  margin-top: 16px;
  ._button {
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tags-color-blue;
    color: #fff;
    padding: 0 28px;
    border-radius: 24px;
    text-decoration: none;
    @include link-hover();
    &:has(._term) {
      justify-content: space-between;
    }
    &.cancel {
      background-color: #f2f2f7;
      color: $base-color;
    }
    &.disabled {
      background-color: #f2f2f7;
      color: $gray-color;
      transition: none;
      cursor: default;
      opacity: 1;
    }
    span {
      pointer-events: none;
    }
  }
}
</style>
