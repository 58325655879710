<i18n>
{
  "ja":{
    "受け取る設定に変更しました": "受け取る設定に変更しました",
    "受け取らない設定に変更しました": "受け取らない設定に変更しました"
  },
  "en":{
    "受け取る設定に変更しました": "Changed the settings to receive.",
    "受け取らない設定に変更しました": "Change the setting to not accept them."
  }
}
</i18n>

<template lang="pug">
.mailmagazine-select-area
  .btn-close(@click="hideMailMagazineModal")
  .mailmagazine-select-inner
    h2.title {{$t("メールマガジン設定")}}
    template(v-if="status === true && changed")
      div.alert {{$t("受け取る設定に変更しました")}}
    template(v-if="status === false && changed")
      div.alert {{$t("受け取らない設定に変更しました")}}

    ul(class="MailMagazineSelect")
      li
        input(type="radio" name="localeselect" id="Subscribe" v-model="allow" value="true" @change="changeDistributionSetting")
        label(for="Subscribe") {{$t("受け取る")}}
      li
        input(type="radio" name="localeselect" id="Unsubscribe" v-model="allow" value="false" @change="changeDistributionSetting")
        label(for="Unsubscribe") {{$t("受け取らない")}}
    //- button#enter.large-button-style.blue-button(type="button" @click="hideMailMagazineModal") OK
</template>
<script>
import mixinUser from '@/mixins/user.js'

export default {
  name: 'MailMagazineSelect',
  mixins: [mixinUser],
  data() {
    return {
      allow: true,
      status: false,
      changed: false,
    }
  },
  methods: {
    loadDistributionSetting() {
      this.api('getMyProfile', {}, rv => {
        let allow = true
        console.log(rv)
        if (rv.distributionStatus && rv.distributionStatus.email === false) {
          allow = false
        }
        this.allow = allow
      })
    },
    changeDistributionSetting(e) {
      const val = e.target.value === 'true'
      console.log(val)
      this.api(
        'updateDistributionSetting',
        {
          email: val,
        },
        rv => {
          console.log(rv)
          this.changed = true
          this.status = val
        }
      )
      //this.hideMailMagazineModal()
    },
  },
  mounted() {
    this.loadDistributionSetting()
  },
}
</script>

<style scoped lang="scss">
.mailmagazine-select-area {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .mailmagazine-select-inner {
    width: 400px;
    height: 260px;
    max-width: 90%;
  }
  .btn-close {
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background: image-url('icon_batsu.svg') no-repeat center;
    background-size: 45%;
    @include sp-layout {
      width: 45px;
      height: 45px;
    }
  }
  .title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    @include sp-layout {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .note {
    font-size: 13px;
    margin-bottom: 20px;
    @include sp-layout {
      font-size: 12px;
    }
  }
  .MailMagazineSelect {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    > li {
      display: flex;
      align-items: center;
      padding: 0;
    }
    > li + li {
      border-top: 1px solid $tags-color-gray;
    }
    label {
      padding: 8px;
      flex-grow: 1;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.7s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
