<template lang="pug">
  //-  "お知らせ(News)一覧" 取得コンポーネント
  //-  設置例
  //-  AnnouncementBlock(v-if="signedIn" :perPage="3")
  //-  perPageの数値はv-bindしてdata形式で渡すこと
  //-  perPage Number 必須 一度に取得する件数
  div
    ul.announcements-list
      template(v-for="(item, index) in announcements")
        li(v-if="$route.name === 'home' ? index < 5 : true")
          router-link(:to="{name: 'announcement', params: {id: item.id}}")
            .date
              span.important-tag(v-if="item.isImportant") {{$t("重要")}}
              | {{ item.fromDateTime | moment }}
              span.announcement-isread.sp(v-if="item.isRead") {{$t("既読")}}
            .title-line
              p.title {{ item.title }}
            .announcement-isread.pc(v-if="item.isRead") {{$t("既読")}}
    .columns-readmore-block.center(v-if="this.$route.path !== '/'" :class="{ 'hide' : !more }")
      button.middle-button-style.blue-button(@click="readMore") {{$t("もっと見る")}}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AnnouncementBlock',
  props: {
    perPage: {
      type: Number,
      require: true,
    },
  },
  watch: {
    locale() {
      this.$store.dispatch('announcement/getItems', this.perPage)
    },
  },
  computed: {
    more() {
      return this.length === this.perPage
    },
    ...mapState('announcement', { announcements: 'items' }),
    ...mapState('announcement', { length: 'length' }),
    ...mapState(['locale']),
  },
  methods: {
    readMore() {
      this.$store.commit('announcement/incrementPage')
      this.$store.dispatch('announcement/getItems', this.perPage)
    },
  },
  created() {
    if (!this.announcements.length) {
      this.$store.dispatch('announcement/getItems', this.perPage)
    } else if (this.announcements.length < this.perPage) {
      this.$store.commit('announcement/resetItems')
      this.$store.dispatch('announcement/getItems', this.perPage)
    }
  },
}
</script>

<style scoped lang="scss">
.announcements-list {
  padding: 0 140px;
  margin-bottom: 55px;
  @include sp-layout {
    padding: 0;
  }

  > li {
    border-bottom: 1px solid #e6e6e6;

    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      line-height: 1em;
      height: 1.1em;
      overflow: hidden;

      &:hover .title {
        text-decoration: underline;
      }
    }

    a {
      display: flex;
      padding: 20px 0;

      &:hover {
        text-decoration: underline;

        .announcement-isread {
          text-decoration: none;
        }
      }
    }
  }

  > li:last-child {
    border-bottom: none;
  }

  .date {
    margin-right: 60px;
    order: 1;
  }

  .title-line {
    display: flex;
    align-items: center;
    flex-grow: 1;
    order: 2;
  }

  .announcement-isread {
    margin-left: 25px;
    white-space: nowrap;
    color: #004098;
    border: 1px solid #004098;
    border-radius: 3px;
    padding: 1px 8px 2px;
    font-size: 14px;
    order: 3;
  }
  .important-tag {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(236, 53, 126);
    border-radius: 4px;
    padding: 4px 7px;
    margin-right: 12px;
    text-decoration: underline;
    text-decoration-color: #ec357e;
    @include sp-layout {
      font-size: 12px;
      padding: 3px 6px;
    }
  }
}

.top-page-announcements {
  .announcements-list {
    padding: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .announcements-list {
    > li {
      a {
        flex-direction: column;

        .date {
          margin-right: 0;
          margin-bottom: 7px;
          font-size: 13px;
        }

        .title-line {
          font-size: 14px;
        }
      }
    }
  }
  .announcement-isread.sp {
    display: inline !important;
    margin-left: 15px;
    font-size: 12px;
  }
}
</style>
