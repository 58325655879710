<template lang="pug">
//- 友達のプロフィールページ
.account
  .account-bg-image-area(:style="[friendProfile.profile && friendProfile.profile.backgroundImageUrl ? { backgroundImage: 'url(' + friendProfile.profile.backgroundImageUrl + ')'} : {'background-image': 'none'} ]")
  .account-header-data-area
    .container
      h1.hidden {{$t("友達プロフィール")}}
      .account-image(:style="[friendProfile.profile && friendProfile.profile.profileImageUrl ? {'background-image': 'url(' + friendProfile.profile.profileImageUrl + ')'} : {'background-image': 'url(' + defaultThumbnailImage + ')'} ]")
      .account-header-data
        .account-name-wrapper
          .account-name(v-if="friendProfile.profile && friendProfile.profile.nickname") {{ friendProfile.profile.nickname }}
          .account-attribution-list
            ul
              li.attribution.attribution1(v-if="friendProfile.profile && friendProfile.profile.attribution1") {{ friendProfile.profile.attribution1 }}
        .account-comment(v-if="friendProfile.profile && friendProfile.profile.comment") {{ friendProfile.profile.comment }}
      .account-sp-menu.sp
        ul
          li
            router-link(:to="{ name: 'friendProfile'}") {{$t("友達プロフィール")}}

  .sub-page-padding.account-main-content-bg
    .container
      .account-main-content

        .left-col.pc
          ul.account-left-col-lists
            li
              router-link(:to="{ name: 'friendProfile' }") {{$t("友達プロフィール")}}

        .right-col
          .account-profile
            section.content-section.steps-log(v-if="friendProfile.accessTypes && friendProfile.accessTypes.userSteps === 1")
              HeadingBlock(level="2" text="歩数ログ")
              .content-section-inner
                dl.data-list
                  div
                    dt {{$t("今週の順位")}}
                    dd(v-if="friendProfile.userSteps") {{ friendProfile.userSteps.rank && friendProfile.accessTypes.userSteps === 1 ? friendProfile.userSteps.rank : '-' }} / {{ friendProfile.userSteps.userCount && friendProfile.accessTypes.userSteps === 1 ? friendProfile.userSteps.userCount : '-' }} {{$t("位")}}
                    dd(v-else) - / - {{$t("位")}}
                  div
                    dt {{$t("今週の平均歩数")}}
                    dd(v-if="friendProfile.userSteps") {{ friendProfile.userSteps.averageSteps && friendProfile.accessTypes.userSteps.toLocaleString() === 1 ? friendProfile.userSteps.averageSteps : '-' }} {{$t("歩")}}
                    dd(v-else) - {{$t("歩")}}

            section.content-section(v-if="friendProfile.teamMatches && friendProfile.teamMatches.length && friendProfile.accessTypes && friendProfile.accessTypes.teamMatches === 1")
              HeadingBlock(level="2" text="参加しているチーム対抗戦")
              .content-section-inner
                dl.data-list(v-if="friendProfile.teamMatches")
                  template(v-for="(teamMatche, index) in friendProfile.teamMatches")
                    div
                      dt {{$t("チーム対抗戦名")}}
                      dd {{ teamMatche.name ? teamMatche.name : '-' }}
                    div
                      dt {{$t("所属チーム名")}}
                      dd {{ teamMatche.team.name ? teamMatche.team.name : '-' }}
                      dt {{$t("チーム順位")}}
                      dd {{ teamMatche.team.rankHistory ? teamMatche.team.rankHistory.slice(-1)[0] : '-' }} / {{ teamMatche.team.maxRank ? teamMatche.team.maxRank : '-' }} {{$t("位")}}
                    div(v-if="teamMatche.team.league")
                      dt {{$t("所属リーグ")}}
                      dd {{$t("リーグ")}}{{ teamMatche.team.league ? teamMatche.team.league : '-' }}
                      dt {{$t("リーグ内順位")}}
                      dd {{ teamMatche.team.leagueRank ? teamMatche.team.leagueRank : '-' }} / {{ teamMatche.team.maxLeagueRank ? teamMatche.team.maxLeagueRank : '-' }} {{$t("位")}}
                    div
                      dt {{$t("平均歩数")}}
                      dd {{ teamMatche.team.stepsHistory ? teamMatche.team.stepsHistory.slice(-1)[0].toLocaleString() : '-' }} {{$t("歩")}}
</template>

<script>
import moment from 'moment-timezone'
import mixinUser from '@/mixins/user.js'
import HeadingBlock from '@/components/HeadingBlock'

export default {
  mixins: [mixinUser],
  components: {
    HeadingBlock,
  },
  data() {
    return {
      defaultThumbnailImage: require('@/assets/images/icon_user.svg'),
      friendProfile: {},
    }
  },
  methods: {
    getFriendProfile(userID) {
      this.api('getFriendProfile', { userID }, rv => {
        if (!rv || !rv.succeed) {
          this.$router.push({
            path: '/notFound',
          })
        } else {
          this.friendProfile = rv
        }
      })
    },
    display() {},
  },
  mounted() {
    const id = this.$route.params.id
    if (!id) return
    this.getFriendProfile(id)
  },
}
</script>

<style lang="scss">
.account {
  .account-bg-image-area {
    width: 100%;
    height: 240px;
    background-size: cover;
    background-position: center;
    background-color: $base-bg-gray-color;
    @include sp-layout {
      height: 104px;
    }
  }
  .account-header-data {
    padding: 34px 0 34px 200px;
    @include sp-layout {
      padding: 0;
    }
  }
  .account-sp-menu {
    > ul {
      display: flex;
      > li {
        min-width: 55px;
        a {
          display: block;
          padding: 12px 0;
          text-align: center;
          &.router-link-exact-active {
            color: $base-brand-color;
            border-bottom: 2px solid $base-brand-color;
            pointer-events: none;
          }
        }
      }
      > li + li {
        margin-left: 15px;
      }
    }
  }
  .account-image {
    position: absolute;
    margin-top: -30px;
    display: inline-block;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    background-color: $base-bg-color;
    background-position: center;
    background-repeat: no-repeat;
    border: 10px solid $base-bg-color;
    overflow: hidden;
    background-size: cover;
    @include sp-layout {
      position: static;
      height: 64px;
      width: 64px;
      border: 2px solid $base-bg-color;
    }
    &._default {
      background: image-url('main_logo.svg') no-repeat center
        $base-bg-gray-color;
      background-size: 100px;
    }
  }
  .account-name {
    font-size: 24px;
    font-weight: bold;
    @include sp-layout {
      font-size: 16px;
    }
  }
  .account-name-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    @include sp-layout {
      display: block;
    }
  }
  .account-attribution-list {
    margin-left: 25px;
    @include sp-layout {
      margin-left: 0;
    }
    > ul {
      display: flex;
      font-size: 13px;
      color: $gray-color;
    }
    .attribution {
      position: relative;
    }
    .attribution + .attribution {
      margin-left: 16px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -11px;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 5px;
        height: 5px;
        border-top: 1px solid $gray-color;
        border-left: 1px solid $gray-color;
        transform: rotate(135deg);
      }
    }
  }
  .account-comment {
    margin-bottom: 15px;
  }
  .account-main-content-bg {
    background-color: $base-bg-gray-color;
  }
  .account-main-content {
    display: flex;
    justify-content: space-between;
    .left-col {
      width: 320px;
    }
    .right-col {
      width: 760px;
      @include sp-layout {
        width: 100%;
      }
    }
    .content-section {
      background-color: $base-bg-color;
      padding-top: 20px;
      padding-bottom: 40px;
      @include sp-layout {
        padding-top: 11px;
        padding-bottom: 20px;
        font-size: 13px;
      }
      h2 {
        @include sp-layout {
          font-size: 16px;
        }
      }
    }
    .content-section + .content-section {
      margin-top: 32px;
    }
    .data-list {
      > div {
        display: flex;
        justify-content: space-between;
        padding: 18px 0;
        flex-wrap: wrap;
        > dt {
          width: 50%;
          text-align: left;
        }
        > dd {
          width: 50%;
          text-align: right;
        }
        @include sp-layout {
          padding: 16px 0;
        }
      }
      > div + div {
        border-top: 1px solid $tags-color-gray;
      }
    }
    .content-section-inner {
      padding: 0 40px;
      @include sp-layout {
        padding: 0 18px;
      }
    }
  }
  .average-weight-area {
    display: flex;
    margin-top: 17px;
    ._left,
    ._right {
      width: 48%;
      background-color: $base-bg-gray-color;
      border-radius: 6px;
      padding: 32px;
      @include sp-layout {
        padding: 14px;
      }
    }
    ._right {
      margin-left: 4%;
      ._num {
        color: $base-brand-color;
      }
    }
    ._ttl {
      margin-bottom: 6px;
    }
    ._num {
      font-size: 28px;
      font-family: $font-gotham-bold;
      @include sp-layout {
        font-size: 20px;
      }
    }
  }
  .private-setting-info {
    display: flex;
    justify-content: flex-end;
    margin-top: -36px;
    margin-bottom: 25px;
  }
  .account-left-col-lists {
    margin-top: 35px;
    padding-left: 25px;
    li {
      a {
        color: $gray-color;
        cursor: pointer;
        &.router-link-exact-active {
          color: $base-brand-color;
          position: relative;
          pointer-events: none;
          &::after {
            content: '';
            position: absolute;
            left: -16px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $base-brand-color;
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li + li {
      margin-top: 18px;
    }
  }
  .account-setting-list {
    > li {
      position: relative;
      a {
        display: block;
        padding: 18px 0;
        @include link-hover();
      }
      button {
        display: block;
        padding: 18px 0;
        @include link-hover();
        font-size: 100%;
        width: 100%;
        height: 100%;
        text-align: left;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 10px;
        height: 10px;
        border-top: 2px solid $gray-color;
        border-left: 2px solid $gray-color;
        transform: rotate(135deg);
      }
    }
    > li + li {
      border-top: 1px solid $tags-color-gray;
    }
  }
}
.space {
  height: 100px;
  width: 100%;
}
</style>
