<template lang="pug">
.how-to-use
  .common-h1-area
    HeadingBlock(level="1" text="About &well")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") {{$t("&wellについて")}}
  .sub-page-padding
    .container
      article.article-content(v-for="category in howToUseData[locale]" :key="category.id")
        div.h2(:id="category.id")
          h2 {{category.title}}
        AccordionMenu(v-for="item in category.content" :key="item.id" :id="item.id")
          template(slot="title") {{item.h1}}
          template(slot="content")
            .movie(v-if="item.movie")
              video(class="video-js" ref="videoElm" :poster="item.thumbnailImageUrl")
                source(:src="item.movie" type="application/x-mpegURL")
            .image(v-if="item.image && item.color")
              figure(:style="'background:' + item.color" :class="item.color == 'transparent' ? 'p-0' : ''")
                img(:src="require(`@/assets/images/howtouse/${locale}/${item.image}`)" :alt="item.h1")
            .text(:class="{ 'w-100' : !item.image }")
              h3(v-if="item.h2") {{item.h2}}
              div(v-html="item.text")
</template>

<script>
import howToUseData from '@/const/howToUse'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import HeadingBlock from '@/components/HeadingBlock'
import AccordionMenu from '@/components/AccordionMenu'

export default {
  name: 'howToUse',
  components: {
    HeadingBlock,
    AccordionMenu,
  },
  data() {
    return {
      howToUseData: howToUseData,
      player: null,
      players: [],
    }
  },
  methods: {
    mountMovies() {
      const options = {
        autoplay: false,
        controls: true,
        fluid: true,
      }
      if (this.$refs.videoElm) {
        this.$refs.videoElm.forEach(elm => {
          const player = videojs(elm, options, function onPlayerReady() {
            console.log('onPlayerReady', this)
          })
          this.players.push(player)
        })
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === 'en' ? 'en' : 'ja'
    },
  },
  mounted() {
    this.mountMovies()
  },
  beforeDestroy() {
    if (this.players.length) {
      this.players.forEach(player => {
        player.dispose()
      })
    }
  },
}
</script>

<style lang="scss">
.how-to-use {
  .article-content {
    max-width: 800px;
    margin: 0 auto 80px;
    .accordion {
      clear: left;
      margin-bottom: 20px;
    }
    .accordion-content {
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      @include sp-layout {
        flex-direction: column;
      }
    }
    .image {
      flex-shrink: 0;
      text-align: center;
      width: 40%;
      margin-right: 20px;
      @include sp-layout {
        width: 100%;
        margin-right: 0;
      }
      figure {
        padding: 15px;
      }
    }
    .movie {
      width: 100%;
      margin-bottom: 25px;
    }
    .text {
      line-height: 1.5;
      padding-bottom: 0;
      font-size: 14px;
      width: calc(60% - 20px);
      @include sp-layout {
        padding: 15px 15px 0;
        width: 100%;
      }

      ul {
        list-style-type: disc;
        padding-left: 1em;
        margin: 1em 0.5em;
      }
      li {
        line-height: 1.8;
      }
    }
    .movie + .text {
      width: 100%;
    }
    .w-100 {
      width: 100%;
      padding: 15px 15px 0;
    }
    h2 {
      font-size: 16px;
      margin-bottom: 30px;
    }
    // アンカーリンク対策。pc/spのヘッダー高さに応じてpadding等を調整
    .h2::before,
    .accordion::before {
      display: block;
      height: 131px;
      margin-top: -131px;
      content: '';
      @include sp-layout {
        height: 56px;
        margin-top: -56px;
      }
    }
    h3 {
      margin: 10px 0 20px;
      font-size: 16px;
      font-weight: bold;
      @include sp-layout {
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}
</style>
