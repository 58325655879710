<template lang="pug">
.privacy-policy
  .container
    article.article-content(v-if="this.$i18n.locale === 'en'")
      h2 Handling of Personal Information Related to the &amp;well Health Management Support Services(Privacy Policy)

      h3 Article 1 (Definitions)
      div "Handling of Personal Information Related to the &amp;well Health Management Support Services" (hereinafter referred to as "this Document") will apply to the services related to the &amp;well health management support services (hereinafter referred to as "the Services") provided by Mitsui Fudosan Co., Ltd. (hereinafter referred to as "the Company" or "we"). It stipulates the handling of information regarding users of the Services (hereinafter referred to as "Service Users" or "you") and the prescribed smartphone application (hereinafter referred to as "the App"). Please refer to Article 10 for the contact information for Mitsui Fudosan Co., Ltd.

      h3 Article 2 (User information obtained through the Services)
      div In this Document, user information includes the information set forth below:
      ol
        li The following information provided by you or your company
          ol(class="roman")
            li Name
            li Email address
            li Information about the companies, organizations, departments, etc. that you belong to
            li Gender and date of birth
            li Height and weight
            li Health care information such as health checkup results (This does not include details of medical advice or stress check results.)
            li Other information entered by you into the input forms specified by the Company
        li Information provided by other services when you allow linking with them in connection with using the Services
          ol(class="roman")
            li Steps, weight, and body fat ratio
            li Information about body composition
            li Calorie intake and calories burned
            li Other information that you have authorized to be disclosed
        li The following information collected by the Company in connection with your use of the Services
          ol(class="roman")
            li Steps and log information such as the use of content, browsing, bookings, and earning points through the App
            li Booking information such as booking participation in events, etc.
            li Photos, videos, etc. related to your participation in events, etc.

      h3 Article 3 (Purposes of using user information)
      div The Company and its group companies (Mitsui Fudosan Co., Ltd. and the consolidated subsidiaries listed in its securities reports, etc.; hereinafter the same.) will use user information to the extent necessary to achieve the following purposes of use. Moreover, health care information such as health checkup results will not be handled beyond the scope necessary to ensure the Service Users' health.
      ol
        li To operate the Services and the App, and to provide products and services handled by the Company
          div &lt;Examples include the following purposes of use&gt;
          ul
            li Operating &amp;well points
            li Delivering point-exchange products
            li Responding to inquiries from Service Users
        li To introduce products and services related to customers' clothing, food, housing, entertainments, and work as handled by the Company and its group companies, and provide various kinds of information and special offers regarding them
          div &lt;Examples include the following purposes of use&gt;
          ul
            li Providing information about various seminars, campaigns, and events*
            li Delivering behavioral targeting ads (an advertising method based on analyzing information obtained about users' browsing and service use history, etc. to predict their attributes and interests, and customizing featured content accordingly) using advertisement delivery companies*
            li Providing coupons and discounts when services are used
          div
            | *This includes providing information based on customers' attributes and interests as predicted based on analyzing information about their transaction history, etc. obtained by the Company and its group companies.<br>
            | Notification, distribution, and provision of the above will be done via telephone calls, sealed postcards, email newsletters, direct mail, etc.
        li To develop and improve products and services related to customers' clothing, food, housing, entertainment, and work as handled by the Company and its group companies, and conduct market research and other marketing activities, surveys, and analysis to help the Company and its group companies provide better products and services to customers
          div &lt;Examples include the following purposes of use&gt;
          ul
            li Developing new products and services for users
            li Conducting surveys
            li Analyzing usage trends
        li To ensure Service Users' health 
          div &lt;Examples include the following purposes of use&gt;
          ul
            li Providing the App's functions
            li Creating various aggregated analyses and reports for improving users' health via the Services
        li To provide  information (email address, step count data, company/department names, etc.) of Service  Users to their companies under the Service Users' consent
          div &lt;Examples include the following purposes of use&gt;
          ul
            li Providing various aggregated analyses and reports, and analyzing them at Service Users' companies
            li Administrating and supporting the Service User's health at their workplace
        li To provide  information (email address, step count data, company/department names, etc.) of Service Users to their companies under the Service Users' consent
          div &lt;Examples include the following purposes of use&gt;
          ul
            li Providing various aggregated analyses and reports, and analyzing them at Service Users' companies
            li Administrating and supporting the Service User's health at their workplace
        li To provide the service to third parties (including companies that use it) to achieve the purposes of use stated in 1 to 5 above
        li The App connects with Health Connect and allows the App member's information collected through Health Connect to be linked to each function of the App. In this case, the Company shall use the App member's information to which the App is linked from Health Connect, complying with the policy about Health Connect's authority, including the <a href="https://support.google.com/googleplay/android-developer/answer/9888170#ahp" target="_blank">"Requirements for Limited Use"</a> specified by Google Inc. The Health Connect information used in the App and how it is used in the App are as follows:
          ul
            li Data linked by the App: height, weight, steps, body fat ratio
            li Purpose of use in the App: To display height, weight, steps, and body fat ratio on the App, as well as other activity figures calculated based on such information.
          div We will always be aware of the importance of ensuring the security of personal information, and implement information security measures using education and other appropriate means to prevent unauthorized access, loss, destruction, falsification, leaks, etc. In addition to such prevention, we will always correct security measures, as necessary.

      h3 Article 4 (Provision of user information to third parties)
      ol
        li In addition to the following cases, the Company may otherwise provide user information to Mitsui Fudosan group companies within the scope necessary to achieve the purposes of use stated in 1 to 5 above.
          ol(class="roman")
            li When it has obtained the Service Users' consent in advance
            li When it introduces external events to the Service Users and accepts bookings for those events within the Services based on an outsourcing contract signed with an external event organizer (The Company will provide information on your behalf to external events organizers only within the scope necessary for you to participate in them.)
            li When it is required to do so by law
            li When doing so is necessary in order to protect a person's life, body, or property, and it would be difficult to obtain the consent of the Service Users concerned
            li When doing so is particularly necessary in order to improve public health or promote the sound growth of children, and it would be difficult to obtain the consent of the Service Users concerned
            li When doing so is necessary in order to cooperate with any of the following parties in the performance of their legally prescribed duties, and obtaining the consent of the Service Users concerned may interfere with the performance of those duties: a national government agency, a local public organization, or a party either of these outsource to
        li In addition to name and email address, the information provided will also include other items necessary to achieve the relevant purposes of use (Information included in Article 2 (1) (i) to (iv) and (vii).). However, it will be limited to the minimum items necessary. From the user information it obtains, the Company will not provide health care information such as health checkup results to any third parties other than those that it is outsourcing the work to.
          div &lt;Example of personal data that will be provided&gt;
          ul
            li Information provided when using the Services
        li If providing user information to a third party, the Company will do so in writing or by post, telephone, fax, email, an electronic medium, etc., and will give due consideration to security control; and if providing electronic data, it will employ encryption and other necessary measures when delivering it.
        li If you ask us to stop providing your information to third parties, it will stop doing so accordingly. Please ask our contact for inquiries about disclosure, etc. Please be aware if we stop providing your information, it may make some or all of the services unavailable.
        li The Company may aggregate user information, turn it into statistics that the individual Service Users cannot be identified from, then provide it as statistical data to Service Users' companies.

      h3 Article 5 (Joint use of user information)
      div The Company will jointly use user information as follows:
      ol
        li
          | Items of personal data that will be jointly used Service Users' names and email addresses, information about their service provision history, etc. (Information included in Article 2, (1) (i) to (iv) and (vii).)
          | <br>This will not include health care information such as health checkup results from the user information obtained by the Company
          div &lt;Example of personal data that will be jointly used&gt;
          ul
            li Information provided when using the Services
        li Scope of joint users
          div Mitsui Fudosan group companies
        li Purposes of use by joint users
          div The same as the purposes of use stated in 1 to 3 above
        li Party responsible for joint use
          div Mitsui Fudosan Co., Ltd. (<a style="display:inline;" href="https://www.mitsuifudosan.co.jp/english/corporate/about_us/outline/" target="_blank">Company profile</a>)

      h3 Article 6 (Outsourcing user information)
      div The operation, maintenance, etc. of the systems and apps for managing user information received from Service Users will be outsourced as follows:
      ol(class="roman")
        li Outsourced work: Operation and maintenance of the systems and apps for managing user information
        li Outsourced to: Kii Corporation (system maintenance and operation), Up-frontier, Inc. (app maintenance and operation)
        li Supervision of outsourced-to organizations: The Company will enter into an outsourcing contract with each organization it outsources to, stipulate the necessary matters to prevent leaking, etc. of Service Users’ information (such as management of personal information, maintenance of confidentiality, and prohibition of reprovision of user information), and appropriately supervise those organizations. In addition, if it is necessary to achieve the purposes of use, the Company may outsource work to a subcontractor within the scope necessary to perform it, after establishing the necessary and appropriate supervision to ensure that user information will be managed safely. (Examples of such subcontractors are as follows: courier companies that undertake product delivery, companies that undertake outsourced server and content management, and companies that handle payments on behalf of others.)

      h3 Article 7 (Points to keep in mind)
      ol(class="roman")
        li The Services are not medical treatment, and the Company does not guarantee the accuracy, appropriateness, etc. of the health results.
        li If you have any worries or concerns about your health, then regardless of the results about it provided via the Services, please consult an occupational physician, member of occupational health staff, or other kind of physician.
        li We ask that Service Users please consult their companies for information on conducting health checkups.

      h3 Article 8 (Method of involving Service Users)
      div The Services will enable Service Users to suspend the acquisition, modification, deletion, or use of all or part of their information by performing operations themselves or requesting that the action be taken.

      h3 Article 9 (Links to the Privacy Policy, etc.)
      div Please click on the link below to check the latest version of the Company's privacy policy and of this Document. If the Company's privacy policy differs from this Document, then this Document will take precedence.
      ul
        li The Company's Privacy Policy
          div How to check it: Please refer to the following website: <br><a href="https://www.mitsuifudosan.co.jp/english/privacy_policy/" target="_blank">https://www.mitsuifudosan.co.jp/english/privacy_policy/</a>
        li Terms of use of the App and the Services
          div How to check them: Go to "Settings" in the App and check "Terms of Use"
        li This Document
          div How to check it: Go to "Settings" in the App and check "Handling of Personal Information Related to the &amp;well Health Management Support Services (Privacy Policy)"

      h3 Article 10 (Revision, deletion, etc. of personal information)
      ol
        li The Company will respond to requests for revision, deletion, etc., of Service Users' information stored in the App. If you wish to make such a request, please contact us using the information below. The Company will promptly revise a Service User’s personal information held by it within a reasonable scope, and delete such information when it deems that it needs to comply with a request to do so. However, it will only do so after confirming that the request was made by the person concerned.
        li If Service Users can directly revise or delete their personal information via the website by using their ID, password, etc., we ask that they handle such operations themselves.
          div &lt;Contact for inquiries&gt;<br>	Please refer to "Contact Us" in "Settings" in the App.

      h3 Article 11 (Handling of information after the termination of the Services)
      div In the event that the provision of the Services by the Company to Service Users is terminated following the termination of the contractual relationship between the Company and the Service Users' companies, the information obtained by the Company will be handled as follows:
      ol(class="roman")
        li Personal information about the Service Users that the Company obtained from the Service Users' companies will be deleted within three months of the termination of the Services.
        li In the case of (i) above, for the purposes of Article 3 (Purposes of using user information), various information (such as steps) obtained by the Company through provision of the Services may be stored and used in a state by which individuals cannot be identified from it.

      h3 Article 12 (Disclosure of personal information)
      div For inquiries about the disclosure of Service Users' information stored in the App, please refer to "2. Requesting Disclosure” on the following page: <br><a href="https://www.mitsuifudosan.co.jp/english/privacy_policy/contact/" target="_blank">https://www.mitsuifudosan.co.jp/english/privacy_policy/contact/</a>

      h3 Article 13 (Changes)
      div This Document is subject to change.
      ol
        li If an update for the App involves changes or additions to the items of information obtained from the Service Users, changes to the purposes of use, or changes regarding matters such as the provision of information to third parties, we will notify the Service Users before they download it. Furthermore, we will obtain their consent again before or during installation with regard to important matters.
        li If there are any other changes or additions to the items of information obtained from the Service Users, changes to the purposes of use, or changes regarding matters such as the provision of information to third parties, we will notify the Service Users through the message function. Furthermore, we will obtain their consent again before or during installation with regard to important matters.
        li In order to ensure appropriate protection of personal information, this Document will be reviewed on an ongoing basis, taking into account factors such as changes in the environment. If you have any questions or requests regarding the handling of personal information, please contact us using the contact information given in Article 10, and we will respond appropriately.
      div( class="text-right") End of Document
      div( class="revision")
        | Enacted on: April 1, 2019<br>
        | Revised on: April 1, 2020<br>
        | July 26, 2021<br>
        | June 1, 2022<br>
        | April 30,2024<br>
        | June19,2024<br>
        | Mitsui Fudosan Co., Ltd.<br>


    article.article-content(v-else)
      h2 健康経営支援サービス&amp;wellに関する個人情報の取扱いについて<br>（プライバシーポリシー）

      h3 第１条（定義）
      div 「健康経営支援サービス&amp;wellに関する個人情報の取扱いについて」（以下、「本文書」といいます。）は、三井不動産株式会社（以下、「当社」といいます。）が提供する健康経営支援サービス&amp;wellに関するサービス（以下、「本サービス」といいます。）及び所定のスマートフォン用アプリケーション（以下、「本アプリケーション」といいます。）における本サービスの利用者様（以下、「本サービス利用者様」といいます。）の個人情報を含む利用者情報の取扱いを定めたものです。なお、三井不動産株式会社の連絡先は、第１０条をご覧下さい。

      h3 第２条（本サービスにおいて取得する利用者情報）
      div 本文書において、利用者情報とは、以下の各項に定める情報を含みます。
      ol
        li 本サービス利用者様もしくは所属企業様からご提供いただく以下の情報
          ol(class="circle")
            li 氏名
            li メールアドレス
            li 所属企業・団体および所属部門等に関する情報
            li 性別・生年月日
            li 身長・体重
            li 健康診断結果等のヘルスケア情報（保健指導の内容及びストレスチェックの結果は含みません。）
            li その他当社が定める入力フォームに本サービス利用者様が入力する情報
        li 本サービス利用者様が本サービスの利用において、他サービスと連携を許可することにより、当該他サービスからご提供いただく情報
          ol(class="circle")
            li 歩数・体重・体脂肪率
            li 体組成情報
            li 摂取カロリー・消費カロリー
            li その他利用者様が開示を認めた情報
        li 本サービス利用者様の本サービス利用にあたって当社が収集する以下の情報
          ol(class="circle")
            li 歩数およびアプリの利用によるコンテンツの利用・閲覧・予約・ポイント獲得等のログ情報
            li イベント等への参加予約などの予約情報
            li イベント等に参加された利用者様のイベント参加に関する写真・動画等


      h3 第３条（利用者情報の利用目的）
      div 当社および当社のグループ各社（三井不動産株式会社および三井不動産株式会社の有価証券報告書等に記載されている連結子会社とします。以下同じ）は、利用者情報を以下の利用目的の達成に必要な範囲で利用いたします。なお、健康診断結果等のヘルスケア情報については、本サービス利用者様の健康確保に必要な範囲を超えて取り扱うことはございません。
      ol
        li 本サービス及び本アプリケーションの運営ならびに当社が取り扱う商品・サービスのご提供のため
          div ＜例として、以下の利用目的が含まれます＞
          ul
            li &amp;wellポイントの運営
            li ポイント交換商品の発送
            li 本サービス利用者様からのお問い合わせへの対応
        li 当社および当社のグループ各社の取り扱うお客様の衣･食･住･遊･働に関わる商品・サービスのご紹介ならびに各種情報・特典のご提供のため
          div ＜例として、以下の利用目的が含まれます＞
          ul
            li 各種セミナー・キャンペーン・イベントのご案内※
            li 広告配信事業者を利用した行動ターゲティング広告（取得した閲覧履歴やサービス利用履歴等の情報を分析し、利用者様の属性・ご興味関心を推定して出稿内容を変える広告手法）の配信※
            li クーポン・サービス利用時の割引の提供
          div ※当社または当社のグループ各社が取得したお取引履歴等の情報を分析し、お客様の属性・ご興味関心等を推定した上でのご案内を含みます。
          div 上記のご案内・配信・提供は電話または封書葉書等の送付もしくはメールマガジン、ダイレクトメール等により行います。
        li 当社および当社のグループ各社の取り扱うお客様の衣･食･住･遊･働に関わる、商品・サービスの開発・改善、ならびに当社および当社のグループ会社が行うお客様によりよい商品・サービスをご提供するための市場調査などのマーケティング活動・調査・分析のため
          div ＜例として、以下の利用目的が含まれます＞
          ul
            li 新たな利用者様向け商品・サービスの開発
            li アンケートの実施
            li 利用動向分析
        li 本サービス利用者様の健康確保のため
          div ＜例として、以下の利用目的が含まれます＞
          ul
            li 本アプリケーションの各機能の提供
            li 本サービスにて実施する健康増進のための各種集計分析・レポートの作成
        li 利用者様の同意に基づき、利用者様の所属企業様に対し、利用者情報（メールアドレス、歩数データ、企業名/部署名等）を提供するため
          div ＜例として、以下の利用目的が含まれます＞
            ul
              li 所属企業様への各種集計分析・レポートの提供、所属企業様におけるこれらの分析
              li 所属企業様における利用者様の健康管理、支援
        li 上記利用目的１～5の達成にあたり第三者（利用企業様を含みます。）に提供するため
        li 本アプリでは、ヘルスコネクトと接続し、ヘルスコネクトで収集した本アプリ会員の情報を本アプリの各機能に連携させることができます。この場合、当社は、本アプリがヘルスコネクトから連携されるアプリ会員情報を Google 社の定める<a href="https://support.google.com/googleplay/android-developer/answer/9888170#ahp" target="_blank">「限定的な使用の要件」</a>を含め、ヘルスコネクトの権限に関するポリシーに準拠して使用します。本アプリで使用されるヘルスコネクトの情報及び本アプリにおける使用方法は以下の通りです。
          ul
            li 本アプリで連携されるデータ:身長、体重、歩数、体脂肪率、
            li 本アプリにおける利用目的:本アプリ上の身長、体重、歩数、体脂肪率、の表示及びこれらの情報を元に算出されるその他のアクティビティ数値を表示するため。
          div 個人情報の安全性確保の重要性を常に認識し、不正アクセス、紛失、破壊、改ざん、漏えい等が起きないよう、教育その他適切な手段を使用して情報セキュリティ対策を実施し、未然防止を行うと同時に、必要に応じて常にセキュリティ対策の是正を行います。

      h3 第４条（利用者情報の第三者提供）
      ol
        li 当社は、以下のいずれかに該当する場合のほか、上記利用目的１．～５．の達成に必要な範囲で、利用者情報を三井不動産グループ各社に提供することがあります。
          ol(class="circle")
            li 利用者様の所属企業様が上記利用目的5.の目的のために、利用者情報の開示を求めた場合
            li ①の他、本サービス利用者様に事前の同意を得た場合
            li 外部のイベント運営会社との委託契約に基づき、外部のイベントを本サービス利用者様に紹介し当該予約を本サービス内で受け付ける場合（本イベントに参加いただくために必要な範囲に限り、お預かりしている利用者情報をご本人に代わって外部のイベント運営会社に提供します。）
            li 法令に基づく場合
            li 人の生命、身体又は財産保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            li 公衆衛生の向上又は児童健全な育成推進ために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            li 国の機関若しくは地方公共団体又その委託を受けた者が法令定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行支障を及ぼすおそれがあるとき
        li 提供する情報は、氏名、メールアドレスのほか、各利用目的の達成のために必要な項目（第２条（１）①～④および⑦に含まれる情報。）とさせていただきますが、必要最低限の項目に限定することとします。なお、当社が取得する利用者情報の内、健康診断結果等のヘルスケア情報は、業務委託先を除く第三者に提供いたしません。
          div ＜提供する個人データの例＞
          ul
            li 本サービス利用時にご提供いただいた事項
        li 第三者に提供する場合は、書面、郵便物、電話、FAX、電子メール、電子媒体などを用いて、安全管理に十分に配慮して受け渡しを行うものとし、電子データを提供する場合は、暗号化する等必要な措置を講じて受け渡しを行います。
        li ご本人からのお申し出により、第三者への提供を停止いたします。開示等のご相談窓口へお申し出ください。停止によりサービスの一部または全部が受けられなくなる場合がありますので予めご承知おき願います。
        li 当社は、利用者情報を集計し、本サービス利用者様個人を識別することができないように統計化したうえで、統計情報として所属企業様に提供することがございます。


      h3 第５条（利用者情報の共同利用）
      div 弊社は、利用者情報を次のとおり共同利用いたします。
      ol
        li 共同利用する個人データの項目
          div 本サービス利用者様の名前、メールアドレス、サービス提供の履歴に関する情報等（第２条（１）①～④および⑦に含まれる情報。）
          div 当社が取得する利用者情報の内、健康診断結果等のヘルスケア情報は含まれません
          div ＜共同利用する個人データの例＞
            ul 
              li 本サービス利用時にご提供いただいた事項
        li 共同利用する者の範囲
          div 三井不動産グループ各社
        li 共同利用する者の利用目的
          div 上記利用目的１．～５．と同様
        li 共同利用責任者
          div 三井不動産株式会社(<a href="https://www.mitsuifudosan.co.jp/corporate/about_us/outline/" target="_blank">会社概要</a>)

      h3 第６条（利用者情報の委託）
      div 本サービス利用者様からいただいた利用者情報を管理するシステム及びアプリの運用・メンテナンス等については、以下の内容で委託を行います。
      ol(class="circle")
        li 委託の内容：利用者情報の管理するシステム及びアプリの運用・メンテナンス
        li 委託先：Kii株式会社（システム保守・運用）、アップフロンティア株式会社（アプリ保守・運用）
        li 委託先の監督：当会社と委託先の間で業務委託契約を締結し、利用者情報の管理、秘密保持、再提供の禁止等、本サービス利用者様の利用者情報の漏洩等が無いよう必要な事項を取り決めるとともに、適切に監督致します。また、各利用目的の達成に必要な場合には、商品配送を請負う宅配業者、サーバーやコンテンツの管理委託業者、決済を代行している業者などの業務委託会社に対し、利用者情報の安全管理が図られるよう、必要かつ適切な監督を行った上で、当該委託業務を遂行するために必要な範囲で委託する場合があります。

      h3 第７条（留意事項）
      ol(class="circle")
        li 本サービスは医療行為ではなく、健康状態の結果について当社は、医療面での正確性、適切性等を保証するものではありません。
        li 本サービスによる健康状態の結果に関わらず、ご自身の健康状態について不安や懸念がある場合は、産業医や産業保健スタッフ、その他医師等へご相談ください。
        li 健康診断の実施に関する情報は本サービス利用者様の所属企業様にご相談ください。

      h3 第８条（本サービス利用者様関与の方法）
      div 本サービスでは、本サービス利用者様の操作やお申し出により、本サービス利用者様の情報の全部もしくは一部の取得停止、変更、削除、利用の停止をすることができます。

      h3 第９条（個人情報保護方針等へのリンク）
      div 当社の個人情報保護方針および本文書の最新のものは、下記のリンクよりご確認ください。当社の個人情報保護方針と本文書が異なる場合には、本文書が優先するものとします。
      ul
        li 当社の個人情報保護方針
          div 確認方法：次のサイトでご確認ください <a href="https://www.mitsuifudosan.co.jp/privacy_policy/" target="_blank">https://www.mitsuifudosan.co.jp/privacy_policy/</a>
        li 本アプリケーションおよび本サービスの利用規約
          div 確認方法：本アプリケーションの「設定」から「利用規約」をご確認ください
        li 本文書
          div 確認方法：本アプリケーションの「設定」から「健康経営支援サービス&amp;wellに関する個人情報の取扱いについて（プライバシーポリシー）」をご確認ください

      h3 第１０条（個人情報の訂正、削除等）
      ol
        li 本アプリケーションにおける本サービス利用者様の情報について、訂正、削除等のご要望に対応します。ご希望の場合は以下の問合せ先宛に、お問い合わせください。ご本人からの請求であることを確認できた場合に限り、当社が保有している本サービス利用者様の個人情報を合理的範囲内で速やかに訂正し、また当社が当該請求に応じる必要があると判断した場合は当該情報を削除します。
        li ID・パスワードなどを用いて、本サービス利用者様が直接ウェブサイトにて個人情報の訂正・削除ができる場合は、本サービス利用者様にてご対応ください。
          div ＜お問い合わせ窓口＞<br>本アプリケーションの「設定」から「お問い合わせ」をご確認ください。

      h3 第１１条 (本サービス終了後の情報の取扱い)
      div 本サービス利用者の所属企業と当社との契約関係の終了に伴い、当社から本サービス利用者への本サービスの提供が終了する場合、当社が取得した情報を以下のように取り扱います。
      ol(class="circle")
        li 当社が本サービス利用者の所属企業から取得した、本サービス利用者の個人情報は、本サービス提供終了から3か月以内に削除します。
        li 上記①の場合、第３条(利用者情報の利用目的)のため、当社が本サービスの提供を通して取得した各種情報（歩数等の情報）を個人が特定されない状態で保管・利用することがございます。

      h3 第１２条（個人情報の開示）
      div 本アプリケーションにおける本サービス利用者様の情報の開示に関するお問い合わせは、下記のページの「２．開示をお求めの場合」をご覧ください。
      div <a href="https://www.mitsuifudosan.co.jp/privacy_policy/contact/" target="_blank">https://www.mitsuifudosan.co.jp/privacy_policy/contact/</a>

      h3 第１３条（変更）
      div 本文書は改定されることがあります。
      ol
        li 本アプリケーションのバージョンアップに伴って、本サービス利用者様の情報の取得項目の変更や追加、利用目的の変更、第三者提供等について変更がある場合には、ダウンロード前に通知し、重要なものについてはインストール前もしくはインストール時にあらためて同意を取得させていただきます。
        li その他、本サービス利用者様の情報の取得項目の変更や追加、利用目的の変更、第三者提供等について変更がある場合には、メッセージ機能を通じてお知らせすると同時に、重要なものについてはあらためて同意を取得させていただきます。
        li 本文書は、適切な個人情報保護を実施するため、環境の変化等を踏まえ、継続的に見直します。個人情報の取扱いに関するお問い合わせおよびお申出については、適切に対応させていただきますので、第１０条の問い合わせ窓口までお問い合わせ下さい。

      div( class="text-right") 以上

      div( class="revision")
        | 制定日	2019年4月1日<br>
        | 改訂日	2020年4月1日<br>
        | 2021年7月26日<br>
        | 2022年6月1日<br>
        | 2024年4月30日<br>
        | 2024年6月19日<br>
        | 三井不動産株式会社<br>


</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'

export default {
  name: 'privacyPolicy',
  components: {
    HeadingBlock,
  },
}
</script>

<style lang="scss" scoped>
/* (1) (2) (3) ... */
@counter-style decimal-brackets {
  system: numeric;
  symbols: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
  suffix: ')';
  prefix: '(';
}
/* ① ② ③ ... */
@counter-style decimal-circle {
  system: cyclic;
  symbols: '①' '②' '③' '④' '⑤' '⑥' '⑦' '⑧' '⑨' '⑩';
  suffix: '';
  prefix: '';
}
/* (i) (ii) (iii) (iv) ... */
@counter-style roman-brackets {
  system: cyclic;
  symbols: '\2170''\2171''\2172''\2173''\2174''\2175''\2176''\2177''\2178''\2179';
  suffix: ')';
  prefix: '(';
}
.privacy-policy {
  .article-content {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;

    a {
      display: inline;
    }
  }

  ol {
    list-style-type: decimal-brackets;
    padding-left: 1.2em;

    &.roman {
      list-style-type: roman-brackets;
      padding-left: 2em;
    }
    &.circle {
      list-style-type: decimal-circle;
      padding-left: 1em;
    }
    li {
      padding-left: 0.5em;
      margin: 0.3em 0;
    }

    ol {
      padding-left: 3em;
    }
  }
  ul {
    padding-left: 1.5em;
    list-style-type: disc;
  }
  .revision {
    text-align: right;
    margin-top: 6em;
    padding-top: 1em;
    border-top: $gray-color 1px solid;
  }
}
</style>
