<i18n>
{
  "ja": {
    "メールを送信しました": "メールを送信しました"
  },
  "en": {
    "メールを送信しました": "Email has been sent."
  }
}
</i18n>
<template lang="pug">
  .container
    section
      h2 {{$t('メールを送信しました')}}
      .text(v-if="this.$i18n.locale === 'en'") We have sent you a URL to set your password. Please check the contents and set your password.
      .text(v-else) パスワード設定のためのURLをお送りしましたので、内容をご確認のうえ、パスワードの設定をお願いします。
</template>

<script>
export default {
  name: 'SignupComplete',
}
</script>

<style scoped lang="scss">
.text {
  margin-top: 2em;
}
</style>
