<!--
3-パスワード忘れ
-->
<template lang="pug">
  .signin
    .common-h1-area
      HeadingBlock(level="1" text="Forgot Your Password?")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") パスワードをお忘れの場合
    .sub-page-padding
      .container
        section
          .text(v-if="this.$i18n.locale === 'en'") If you've forgotten your password, please reset it and register one again.<br>We'll send a link to the password reset screen to your registered email address.<br>Please enter your registered email address.
          .text(v-else) パスワードをお忘れの場合はパスワードをリセットし、パスワードの再登録を行って下さい。<br>登録しているメールアドレスへパスワードリセット画面へのリンクを送信します。<br>登録メールアドレスを入力して下さい。
          ResetPassword(next="resetPasswordRequested")

</template>
<script>
import HeadingBlock from '@/components/HeadingBlock'
import ResetPassword from '@/components/ResetPassword'
export default {
  components: {
    HeadingBlock,
    ResetPassword,
  },
}
</script>
