import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const libraryRepository = RepositoryFactory.get('library')

const defaultState = () => ({
  items: [],
  length: 0,
  paged: 0,
  tag: [],
})

const state = defaultState()

const getters = {}

const mutations = {
  incrementPage(state) {
    state.paged++
  },
  setItems(state, payload) {
    state.length = payload.length
    state.items = state.items.concat(payload)
  },
  setTag(state, tag) {
    state.tag = tag
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems({ state, commit }, { tagID, genreTagID, sortBy, perPage }) {
    try {
      const { succeed, reportArticles, tag } = await libraryRepository.getTag(
        tagID,
        genreTagID,
        sortBy,
        state.paged * perPage,
        perPage
      )
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', reportArticles)
        commit('setTag', tag)
      }
    } catch (error) {
      //
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
