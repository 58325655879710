<template lang="pug">
  form(@submit.prevent="postForm")
    div.messageArea(v-if="message") {{ message }}
    ul
      li(v-for="item in post.body" :class="`type-${item.type}`") 
        div.body
          div.question-line
            span.head(v-if="item.questionnaireIndex != null") {{item.questionnaireIndex + 1 }}
            span.question(v-if="item.question" v-html="item.question")
            span.required(v-if="requiredLabel(item)") {{ requiredLabel(item) }}

          div.alert(v-if="hasAlart(item.questionnaireIndex)" class="alert") {{ requireMessage }}
          TextInput(v-if="item.type === 'text'" @getData='setData($event)' :item='item')
          Checkbox(v-if="item.type === 'checkbox'" @getData='setData($event)' :item='item')
          Select(v-if="item.type === 'select'" @getData='setData($event)' :item='item')
          SlideImage(v-if="item.type === 'images'" :item="item")
    div.submitArea(v-if="!post.answered")
      button.middle-button-style.blue-button(type='submit') {{ labelSend }}
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const SurveyRepository = RepositoryFactory.get('survey')
import { mapState } from 'vuex'
import { messages } from '@/const/messages'
import { labels } from '@/const/messages'
import TextInput from '@/components/Input/Text'
import Checkbox from '@/components/Input/Checkbox'
import Select from '@/components/Input/Select'
import SlideImage from '@/components/Input/SlideImage'
export default {
  name: 'Form',
  components: {
    TextInput,
    Select,
    Checkbox,
    SlideImage,
  },
  props: {
    post: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      formData: [],
      message: '',
      checkFrom: false,
      sampleSurvey: {
        succeed: true,
        id: '94f50d24-6e93-48f0-b2e7-06e0e7fbe9a3',
        fromDateTime: 1546268400000,
        toDateTime: 1548860400000,
        title: '開始前アンケート',
        isGeneral: true,
        body: [
          {
            type: 'comment',
            question: '生活習慣について',
          },
          {
            type: 'images',
            images: [
              {
                id: '1',
                url:
                  'https://api.andwell.jp/api/x/s.3ee2d20c47a0-6f98-ee11-d3e4-84cccf31',
              },
              {
                id: '2',
                url:
                  'https://api.andwell.jp/api/x/s.3ee2d20c47a0-6f98-ee11-d3e4-84cccf31',
              },
            ],
          },
          {
            questionnaireIndex: 0,
            required: true,
            type: 'select',
            question: '運動はしていますか？',
            choices: ['定期的にしている', '時々している', 'していない'],
            choiceImages: [
              null,
              [
                {
                  id: '5',
                  url:
                    'https://api.andwell.jp/api/x/s.3ee2d20c47a0-6f98-ee11-d3e4-84cccf31',
                },
              ],
              null,
            ],
          },
          {
            questionnaireIndex: 1,
            required: false,
            type: 'checkbox',
            question: '当てはまるものを全て選択して下さい',
            choices: ['飲酒する', '喫煙する', '寝不足である', ''],
          },
        ],
        answered: false,
      },
    }
  },
  computed: {
    filteredQuestionnaire() {
      return this.post.body.filter(
        item => typeof item.questionnaireIndex !== 'undefined'
      )
    },
    fillRequired() {
      const result = this.filteredQuestionnaire.filter(
        (item, index) => this.formData[index] == null && item.required
      )
      return result.length === 0
    },
    labelSend() {
      return labels.form.send.text[this.locale]
    },
    requireMessage() {
      return messages.invalid.required_short.text[this.locale]
    },
    ...mapState({
      locale: state => state.locale,
    }),
  },
  methods: {
    setData(data) {
      this.$set(this.formData, data.index, data.value)
    },
    async postForm() {
      this.checkFrom = true
      if (!this.fillRequired) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.message = messages.invalid.required.text[this.locale]
        return
      }

      try {
        const data = await SurveyRepository.post(
          this.$route.params.id,
          this.post.isGeneral,
          this.formData
        )
        if (!data.succeed) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          this.message = messages.error.unknown.text[this.locale]
          return
        }
        // アンケートの回答済みを更新したいので一覧のキャッシュを削除
        this.$store.dispatch('survey/resetItems')
        await this.$router.push({ name: 'survey-thanks' })
      } catch (error) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.message = messages.error.unknown.text[this.locale]
      }
    },
    hasAlart(index) {
      if (typeof index !== 'undefined') {
        return (
          this.formData[index] == null &&
          this.filteredQuestionnaire[index].required &&
          this.checkFrom
        )
      }
    },
    requiredLabel(item) {
      const label = {
        ja: '*',
        en: '*',
      }
      if (typeof item.required !== 'undefined') {
        return item.required ? label[this.locale] : ''
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
.messageArea {
  padding: 1.6em;
  color: $alert-color-red;
  background: rgba($alert-color-red, 0.1);
  margin: 0 0 2em 0;
  border-radius: 10px;
}
form {
  margin-top: 2em;
  @include sp-layout {
    margin-top: 0;
  }
}
li {
  padding: 1.6em 1.6em;
  display: flex;
  @include sp-layout {
    padding: 1.1em 1.1em;
  }
}
.head {
  font-weight: bold;
  background: #e60012;
  color: #fff;
  width: 30px;
  display: inline-block;
  text-align: center;
  height: 28px;
  border-radius: 3px;
  margin-right: 10px;
  margin-top: 3px;
  line-height: 27px;
  flex-shrink: 0;
}
.question-line {
  display: flex;
  align-items: center;
}
.question {
  font-size: 20px;
  @include sp-layout {
    font-size: 16px;
  }
}
.body {
  width: 100%;
}
.type-comment {
  margin: 17px 0 10px;
  background: $base-bg-gray-color;
}
.type-images {
  padding: 0;
}
.required {
  display: inline-block;
  margin-left: 8px;
  color: #e60012;
  font-size: 24px;
  line-height: 7px;
  height: 5px;
}
.alert {
  color: $alert-color-red;
  font-weight: bold;
  font-size: 0.85em;
  margin: 0.4em 0 0 0;
}
.submitArea {
  margin: 4em 0 0 0;
}
.blue-button {
  display: block;
  margin: 0 auto;
  &:before {
    content: none;
  }
}
</style>
