import Repository from './Repository'

export default {
  getConfig() {
    return Repository('getConfig')
  },
  notifyLoggedIn() {
    return Repository('notifyLoggedIn')
  },
}
