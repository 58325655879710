<template lang="pug">
.account-profile
  modal(name="mailmagazine-select" :width="640" :height="320" :adaptive="true" :reset="true")
    MailMagazineSelect

  section.content-section.basic-info(v-if="userData.profile.personalInformation")
    HeadingBlock(level="2" text="基本情報")
    .content-section-inner
      .private-setting-info
        p.undisclosed {{$t("非公開")}}
      dl.data-list
        div
          dt {{$t("性別")}}
          template(v-if="userData.profile.personalInformation.gender === 1")
            dd {{$t("男")}}
          template(v-else-if="userData.profile.personalInformation.gender === 2")
            dd {{$t("女")}}
          template(v-else)
            dd -
        div
          dt {{$t("生年")}}
          dd {{ userData.profile.personalInformation.birthYear ? userData.profile.personalInformation.birthYear : '-' }}{{$t("年")}}
        div
          dt {{$t("身長")}}
          dd {{userData.profile.personalInformation.height ? userData.profile.personalInformation.height.toFixed(1) : '-' }}cm
      .average-weight-area
        ._left
          p._ttl {{$t("適正体重（BMI22）")}}
          p._num {{ userData.bestWeight ? userData.bestWeight + 'kg' : '-kg' }}
        ._right
          p._ttl {{$t("あなたの目標体重")}}
          p._num {{ userData.profile.personalInformation.targetWeight ? userData.profile.personalInformation.targetWeight.toFixed(1) + 'kg' : '-kg' }}

  section.content-section.steps-log(v-if="userData.profile.userSteps")
    HeadingBlock(level="2" text="歩数ログ")
    .content-section-inner
      .private-setting-info
        template(v-if="userData.profile.accessTypes && userData.profile.accessTypes.userSteps === 0")
          p.undisclosed {{$t("非公開")}}
        template(v-else-if="userData.profile.accessTypes && userData.profile.accessTypes.userSteps === 1")
          p.disclosed {{$t("公開")}}
      dl.data-list
        div
          dt {{$t("今週の順位")}}
          dd {{ userData.profile.userSteps.rank && userData.profile.accessTypes.userSteps === 1 ? userData.profile.userSteps.rank : '-' }} / {{ userData.profile.userSteps.userCount && userData.profile.accessTypes.userSteps === 1 ? userData.profile.userSteps.userCount : '-' }} {{$t("位")}}
        div
          dt {{$t("今週の平均歩数")}}
          dd {{ userData.profile.userSteps.averageSteps && userData.profile.accessTypes.userSteps === 1 ? userData.profile.userSteps.averageSteps.toLocaleString() : '-' }} {{$t("歩")}}

  section.content-section(v-if="userContract && userContract.teamMatchEnabled && userData.profile.teamMatches && userData.profile.teamMatches.length")
    HeadingBlock(level="2" text="参加しているチーム対抗戦")
    .content-section-inner
      .private-setting-info
        template(v-if="userData.profile.accessTypes && userData.profile.accessTypes.teamMatches === 0")
          p.undisclosed {{$t("非公開")}}
        template(v-else-if="userData.profile.accessTypes && userData.profile.accessTypes.teamMatches === 1")
          p.disclosed {{$t("公開")}}
      dl.data-list(v-if="userData.profile.teamMatches")
        template(v-for="(teamMatche, index) in userData.profile.teamMatches")
          div
            dt {{$t("チーム対抗戦名")}}
            pre {{ teamMatche.name ? teamMatche.name : '-' }}
          div
            dt {{$t("所属チーム名")}}
            dd {{ teamMatche.team.name ? teamMatche.team.name : '-' }}
            dt {{$t("チーム順位")}}
            dd {{ teamMatche.team.rankHistory ? teamMatche.team.rankHistory.slice(-1)[0] : '-' }} / {{ teamMatche.team.maxRank ? teamMatche.team.maxRank : '-' }} {{$t("位")}}
          div(v-if="teamMatche.team.league")
            dt {{$t("所属リーグ")}}
            dd {{$t("リーグ")}}{{ teamMatche.team.league ? teamMatche.team.league : '-' }}
            dt {{$t("リーグ内順位")}}
            dd {{ teamMatche.team.leagueRank ? teamMatche.team.leagueRank : '-' }} / {{ teamMatche.team.maxLeagueRank ? teamMatche.team.maxLeagueRank : '-' }} {{$t("位")}}
          div
            dt {{$t("平均歩数")}}
            dd {{ teamMatche.team.stepsHistory ? teamMatche.team.stepsHistory.slice(-1)[0].toLocaleString() : '-' }} {{$t("歩")}}

  section.content-section
    HeadingBlock(level="2" text="設定")
    .content-section-inner
      ul.data-list.account-setting-list
        li(v-if="userContract && userContract.emailDistributionEnabled")
          button(@click="showMailMagazineModal") {{$t("メールマガジン設定")}}
        li
          button(@click="showLocaleSelectModal") 言語設定 Language: {{ $i18n.locale === 'ja' ? $t("日本語"): $t("English") }}
        li
          a(:href="this.$i18n.locale === 'ja' ? 'https://www3.mitsuifudosan.co.jp/enquete/regulation.php?enqueteurl=c3caa245d0f764c1e29085f7a2ecb307' : 'https://www3.mitsuifudosan.co.jp/enquete/regulation.php?enqueteurl=36f461a6037d8beb226113ae7531199b' " target="_blank") {{$t("お問い合わせ")}}
        li
          button(type="button" @click="profileSignout") {{$t("ログアウト")}}
        li(v-if="userContract && userContract.contractType == 5")
          button(@click="deleteLitePlanUser") {{$t("退会")}}

</template>

<script>
import mixinUser from '@/mixins/user.js'
import HeadingBlock from '@/components/HeadingBlock'
import MailMagazineSelect from '@/components/MailMagazineSelect'

export default {
  name: 'AccountProfile',
  mixins: [mixinUser],
  components: {
    HeadingBlock,
    MailMagazineSelect,
  },
  data() {
    return {
      userContract: null,
    }
  },
  props: {
    userData: Object,
  },
  methods: {
    profileSignout() {
      this.$store.dispatch('signout')
      this.$router.replace({ path: '/' })
    },
    async getUserContract() {
      this.userContract = await this.$store.dispatch('getUserContract')
      console.log('Profile Page: getUserContract', this.userContract)
    },
    deleteLitePlanUser() {
      const ja = "退会手続きをすると、全てのサービスがご利用できなくなります。\n本当に退会してよろしいでしょうか？"
      const en = "If you cancel your membership, you will no longer be able to use all services.Are you sure you want to cancel your membership?"
      const text = this.$i18n.locale === 'en' ? en : ja

      if (!confirm(text)) {
        return
      }
      this.api('deleteLitePlanUser', {}, rv => {
        if (rv.succeed) {
          this.profileSignout()
        } else {
          alert('ERROR')
        }
      })
    }
  },
  mounted() {
    this.getUserContract()
  },
}
</script>

<style lang="scss" scoped></style>
