<template lang="pug">
  .container
    section
      .text(v-if="this.$i18n.locale === 'en'") Please enter the required information to create an account.<br> <span class="_pink">※</span> is required
      .text(v-else) アカウント作成に必要な事項を入力してください。<br><span class="_pink">※</span>は必須項目です
      form(v-on:submit.prevent="confirm")
        ._item(v-for="(item, key) in formSettings")
          label(:class="{'required': item.required}") {{$t(item.label)}}
          input(type="text" :required="item.required" :placeholder="$t(item.label)" v-model="accountModel[key]")
        .error(v-if="formError") {{formError}}
        ._action
          button.large-button-style(type="submit") {{$t('確認する')}}

</template>
<script>
import formSettings from './formSettings'

export default {
  name: 'SignupForm',
  props: {
    accountModel: {},
  },
  data() {
    return {
      formSettings: {},
      formError: '',
    }
  },
  methods: {
    confirm() {
      if (!this.isValid()) {
        return
      }
      this.$router.push({ name: 'signup-confirm' })
    },
    isValid() {
      let valid = true
      this.formError = ''

      const input = this.accountModel
      if (input.userEmailAddress !== input.userEmailAddress2) {
        valid = false
        this.formError = this.$i18n.t('メールアドレスが確認用と違います')
      }
      return valid
    },
  },
  mounted() {
    // this.$refs.invitationCode.focus()
    this.formSettings = formSettings
  },
}
</script>
<style scoped lang="scss">
</style>
