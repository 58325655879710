<!--
2-個人メールの登録とパスワードリセットメール送信
(updateEmailAddressWithResetPasswordを使うのはここだけ)
-->
<template lang="pug">
  .signin
    .common-h1-area
      HeadingBlock(level="1" text="Personal Email Registration")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") 個人メール登録
    .sub-page-padding
      .container
        section.requestResetPassword
          .text(v-if="this.$i18n.locale === 'en'") We'll send you a URL for setting a password.<br>Please enter an email address where you can receive emails.
          .text(v-else) パスワードを設定するURLをお送りします。<br>メールが受け取り可能なメールアドレスを入力してください。
          form(v-on:submit.prevent="requestResetPassword")
            div
              input(id="mailaddress" type="text" :placeholder="$t('メールアドレス')" v-model="mailaddressModel")
            .error(v-if="mailErrorText") {{mailErrorText}}
            button.large-button-style.blue-button(type="submit") {{$t("登録")}}

</template>
<script>
import HeadingBlock from '@/components/HeadingBlock'
export default {
  components: {
    HeadingBlock,
  },
  data() {
    return {
      mailaddressModel: '',
      mailErrorText: '',
    }
  },
  methods: {
    requestResetPassword() {
      this.mailErrorText = ''
      const mailAddress = this.mailaddressModel
      if (!mailAddress) {
        this.mailErrorText = this.$i18n.t('メールアドレスが入力されていません')
        return
      }
      this.api(
        'updateEmailAddressWithResetPassword',
        { email: mailAddress },
        rv => {
          if (!rv.succeed) {
            console.log(rv.errorCode)
            this.mailErrorText = this.$i18n.t(
              'テナントユーザーとして登録されていません'
            )
          } else {
            this.mailErrorText = ''
            console.log('requestResetPassword done.')
            this.$router.push({ name: 'registerMailAddressRequested' })
          }
        }
      )
    },
  },
}
</script>
