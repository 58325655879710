const howToUseData = {
  ja: [
    {
      id: 1000,
      title: '「&wellアプリ」とは',
      content: [
        {
          id: 1001,
          color: '#004098',
          h1: '&well（アンドウェル）アプリとは',
          h2: '',
          text: `健康に対しての意識を変え、行動にうつし、健康的な活動を習慣化していただくため、健康に関する様々な情報の提供、イベント・各種プログラムのご案内をするアプリケーションです。<br>
          <br>
          <a href="https://go.andwell.jp/library/ddd8f65c-72a0-11ec-9ce8-0af363eb5062">＞アプリの操作方法について（紹介動画）</a>
※ご自身のPWでログインしてご覧ください。<br>
※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: 'movie1.png',
        },
        {
          id: 1018,
          color: 'transparent',
          h1: '&well Walk イベントとは',
          h2: '',
          text: `「&well Walk イベント」は楽しい仕掛けで大人気！<br>
          <br>
          「Walk チーム対抗戦」や「Walk 個人チャレンジ」に楽しく参加しているうちに、自然と“歩く”運動習慣が身につくようになっています。がんばって歩いた人には賞品も♪<br>
          <br>
          <a href="https://go.andwell.jp/library/c49a4bc5-baf6-11ec-b342-06d158a16ffc">＞「&well Walk イベント」について（紹介動画）</a><br>
          ※ご自身のPWでログインしてご覧ください。<br>
          ※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: '1018.png',
        },
        {
          id: 1002,
          color: 'transparent',
          h1: '&wellウォーク：チーム対抗戦とは',
          h2: '',
          text: `みんなが夢中になれるウォーキングプログラム<br>
          <br>
  歩くことをきっかけに健康に関心を持っていただくことを目的としたプログラムです。<br>
  <ul>
  <li>参加企業の中で7名以上のチームを結成し、チームの平均歩数を競う歩数競争です。</li>
  <li>健康づくりで目指したい、8000歩以上の達成を目指します。</li>
  <li>チャットをしながら同僚と励ましあい、社内のコミュニケーション活性化にも役立ちます。</li>
  <li>上位チームには参加者が楽しめる豪華賞品を進呈します。</li>
  <li>開催：年3回</li>
  </ul>
  <a href="https://go.andwell.jp/library/a27faf13-72b0-11ec-9ce8-0af363eb5062">＞「チーム対抗戦  チーム編成方法」について（紹介動画）</a>
※ご自身のPWでログインしてご覧ください。<br>
※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: 'movie2.png',
        },
        {
          id: 1003,
          color: '#004098',
          h1: '&wellウォーク：個人チャレンジとは',
          h2: '',
          text: `やる気を大事にして健康活動を継続<br>
          <br>
  「歩く」ことが日常に、行動が変わっていくことを目的としてプログラムです。<br>
  <ul>
  <li>生活習慣病予防に良いと言われている、1日8000歩を一人でも多く達成してもらうことを目的としたイベントです。</li>
  <li>イベント期間中の個人の平均歩数が一定歩数以上の方に抽選で豪華賞品をプレゼントします。</li>
  <li>開催：年3回</li>
  </ul>`,
          image: 'walkthrough_illust22@2x.png',
        },
        {
          id: 1004,
          color: 'transparent',
          h1: '&wellフェスタとは',
          h2: '',
          text: `大規模イベントを健康活動のきっかけに<br>
          <br>
  計測会で自身の体を知る、健康に関する新しい知識を得、体験ができるプログラムです。<br>
  <ul>
  <li>同僚を誘って大勢で楽しめるコンテンツが満載の健康イベントです。</li>
  <li>アプリのほか特設サイト等を活用しオンラインで開催します。</li>
  <li>開催：年間3回</li>
  </ul>
  <a target="_blank" href="http://andwell-festa.jp/">http://andwell-festa.jp/</a>`,
          image: '26.jpg',
        },
        {
          id: 1005,
          color: 'transparent',
          h1: '「ウェル知」とは',
          h2: '',
          text: `健康知識を楽しく学びながら行動変容につなげる<br>
          <br>
  1日1問、気軽に取り組めるヘルスリテラシークイズです。<br>
  <ul>
  <li>健康知識にまつわる問題を、食事、運動、睡眠、メンタルヘルス、健康管理の5分野別に出題します。</li>
  <li>職場で話題になるような、驚きと気づきのある問題で楽しみながら健康リテラシーを高めていきます。</li>
  <li>開催：年間3回（各回25問程度）</li>
  </ul>
  <a href="https://go.andwell.jp/library/68e8cee5-e0a7-11eb-a0b5-06d158a16ffc">＞「ウェル知」について（紹介動画）</a>
  ※ご自身のPWでログインしてご覧ください。<br>
  ※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: 'movie5.png',
          /*
          movie:
            'https://movie.andwell.jp/224e4b32-9e51-4f56-a173-996234ad7f6c/movie.m3u8',
          thumbnailImageUrl:
            'https://movie.andwell.jp/224e4b32-9e51-4f56-a173-996234ad7f6c/thumbnail-00001.png',
          */
        },
        {
          id: 1006,
          color: 'transparent',
          h1: 'Weekly プログラムとは',
          h2: '',
          text: `健康知識を定期的に学び健康博士に<br>
          <br>
  週1回、動画で気軽にヘルスリテラシーを習得できるプログラムです。<br>
  <ul>
  <li>食事、運動、睡眠、メンタルメルス、健康管理など様々な分野の専門家による&wellオリジナルプログラムです。</li>
  <li>1動画5分で気軽に見れるオンラインプログラムです。隙間時間でご覧いただけます。</li>
  <li>開催：毎週火・水曜日配信 ※曜日など変更になる可能性がございます。</li>
  </ul>
  <a href="https://go.andwell.jp/library/5971636f-cdc0-11eb-9b97-06d158a16ffc">＞Weekly プログラムについて（紹介動画）</a>
  ※ご自身のPWでログインしてご覧ください。<br>
  ※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: 'movie6.png',
          /*
          movie:
            'https://movie.andwell.jp/115d08b8-09e8-41ca-a8d1-42431ad2d07c/movie.m3u8',
          thumbnailImageUrl:
            'https://movie.andwell.jp/115d08b8-09e8-41ca-a8d1-42431ad2d07c/thumbnail-00001.png',
          */
        },
        {
          id: 1009,
          color: '#AFD1D8',
          h1: '自分ログ・歩数ログとは',
          h2: '',
          text: `日々の生活習慣を可視化して管理<br>
          <br>
  健康づくりを自分自身で行い、習慣化するためのツールです。<br>
  <br>
  ・自分ログ<br>
  毎日、歩行・食事・睡眠・気分のパターン、体重・体脂肪率を記録・可視化することで健康的な生活スタイルを促進します。<br>
  <br>
  ・歩数ログ<br>
  日々の歩数データや燃焼カロリーを記録・管理することができます。`,
          image: 'walkthrough_illust25@2x.png',
        },
        {
          id: 1010,
          color: '#AFD1D8',
          h1: 'ミッションとは',
          h2: '',
          text: `健康活動を楽しく継続<br>
          <br>
  指定されたミッションを達成するとポイントが獲得できます。<br>
  <ul>
  <li>ミッションは毎月設定され、ミッションを1つ達成する毎にポイントが獲得でき、更に&wellスタンプが1つ押されます。</li>
  <li>&wellスタンプを10個集めると、 &wellスタンプコンプリートボーナスとして別途ポイントを獲得することができます。</li>
  <li>多くのミッションを達成して、ポイントをGETしましょう。</li>
  </ul>`,
          image: 'walkthrough_illust24@2x.png',
        },
        {
          id: 1011,
          color: 'transparent',
          h1: '&wellポイントとは（ため方・使い方）',
          h2: '',
          text: `ポイントを貯めて豪華賞品をゲット！<br>
          <br>
  &wellでの健康づくりを、楽しく・モチベーション高く取り組んでいただくための仕組みです。<br>
  <br>
  ・貯める：<br>
  日々の活動を積み重ね、様々なミッションをクリアするとポイントゲット！<br>
  <br>
  ・使い方は2通り！<br>
  1. いつでもポイントを商品と交換できます。<br>
  2. ポイント交換キャンペーン<br>
    - 開催：年3回<br>
    - 豪華賞品をゲットできるチャンス？！<br>
    <br>
    <a href="https://go.andwell.jp/library/32e170ac-c994-11eb-9b97-06d158a16ffc">＞ポイントのため方・使い方について（紹介動画）</a>
※ご自身のPWでログインしてご覧ください。<br>
※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: 'movie11.png',
        },
        {
          id: 1019,
          color: 'transparent',
          h1: '自分の生活習慣を振り返ろう',
          h2: '',
          text: `自分の生活習慣を振り返り、より健康に♪<br>
          <br>
          生活習慣の記録や歩数から「生活習慣レポート」や「ログレポートメール」など生活習慣を振り返るツールをご用意しています。<br>
          あなたの健康管理に、ぜひご活用ください。<br>
          <br>
          <a href="https://go.andwell.jp/library/079bc8dd-b943-11ec-b99b-0af363eb5062">＞「自分の生活習慣を振り返ろう！」について（紹介動画）</a><br>
          ※ご自身のPWでログインしてご覧ください。<br>
          ※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: '1019.png',
        },
        {
          id: 1012,
          color: '#AFD1D8',
          h1: '疾病リスク予測とは',
          h2: '',
          text: `現在の健康診断データから疾病の将来発症リスクを予想<br>
          <br>
  自分自身の健康状態を知り、健康への意識の変化・きっかけづくりになるツールです。<br>
  <ul>
  <li>1年分の健康診断データをもとに、5年後の３疾病（糖尿病・高血圧症・脂質異常症）のリスク（％）を予測します。</li>
  <li>「5年後の発症確率」でリスクを予測</li>
  <li>「改善シミュレーション」で目標体重をアドバイス</li>
  <li>「生活習慣改善のアドバイス」で、あなたに必要なコンテンツをレコメンド</li>
  </ul>`,
          image: 'walkthrough_illust31@2x.png',
        },
        {
          id: 1013,
          color: '#C8B38D',
          h1: '友だち機能とは',
          h2: '',
          text: `仲間がいることでモチベーションを保ち健康習慣を継続<br>
          <br>
  QRコードや「つながるコード」を利用してアプリ内の友だちになることが可能です。<br>
  <br>
  ・友だちになる方法は3つです。<br>
  1. 相手のQRコードを、自分のQRコードリーダーでスキャンする<br>
  2. 自分のQRコードを、相手のQRコードリーダーでスキャンする<br>
  3. 「つながるコード」をSNSやメールを使って相手に送り、ユーザー検索欄に「つながるコード」を入力してもらうと自分が表示されます。<br>
  <ul>
  <li>「友だちになる」ボタンをタップすると「申請中」になります。</li>
  <li>リクエストメニューにリクエストが届くので「承認」ボタンをタップする</li>
  </ul>
  <ul>
  <li>友だちになると、プロフィール情報（所属・ニックネーム）や活動データなどを閲覧でき、チャットを楽しむことができます。</li>
  <li>日々のアクティビティは友だちのタイムラインに表示されます。</li>
  </ul>
  ■友だちの削除方法<br>
  1. リストを長押しする<br>
  2. 削除しますか？ダイヤログを表示<br>
  3. 「はい」タップで友達リストから友達を削除完了`,
          image: 'walkthrough_illust7@2x.png',
        },
        {
          id: 1014,
          color: '#AFD1D8',
          h1: '目標設定とは',
          h2: '',
          text: `ゴールを目指そう。ダイエットチャレンジ！<br>
          <br>
  現在の体重・身長を入力すると、 BMI22を元にした適正な体重を表示することができます。<br>
  <ul>
  <li>適正体重を参考に、自分にあった目標体重を入力し、ダイエットチャレンジを開始しよう！</li>
  <li>入力した目標体重はグラフ上に表示されるので、視覚的に目標体重を確認することができます。</li>
  <li>現在の体重・身長から計算しBMIが18.5未満だった場合、痩せすぎと判断し、ダイエットチャレンジを実施することができません。そのため目標体重を入力することができません。</li>
  <li>なお、現在の体重-2㎏以内（1ヵ月あたり）の減量が理想的な目標体重となります。</li>
  </ul>
  無理のない目標体重を定め、健康的にダイエットを成功させよう！`,
          image: 'walkthrough_illust29@2x.png',
        },
        {
          id: 1015,
          color: 'transparent',
          h1: 'ウェアラブル端末について',
          h2: '',
          text: `&wellアプリは、ウェアラブル端末等と連携できます。<br>
          <br>
          <a href="https://go.andwell.jp/library/25d824d8-eeb7-11eb-a3b7-0af363eb5062">＞ウェアラブル端末やBluetooth体重の連携について（紹介動画）</a>
          ※ご自身のPWでログインしてご覧ください。<br>
          ※紹介動画内のアプリ画面が最新でない場合がございます、予めご了承ください。`,
          image: 'movie15.png',
          /*
          movie:
            'https://movie.andwell.jp/43fc2171-b71e-4e27-bcdd-9c941c08675e/movie.m3u8',
          thumbnailImageUrl:
            'https://movie.andwell.jp/43fc2171-b71e-4e27-bcdd-9c941c08675e/thumbnail-00001.png',
          */
        },
        {
          id: 1016,
          color: '#838383',
          h1: 'InBodyについて',
          h2: '',
          text: `＆wellアプリは、InBodyと連携できます。<br>
          <ul>
  <li>InBodyは、高性能な体組成計で、筋肉・脂肪量を部位別に表示でき、身体の内面のバランスを分析可能です。</li>
  <li>オフィスビル内の特設スペース（=&well Pit）で使用できます。</li>
  <li>&wellアプリと連携すると、体重・体脂肪率・BMI・筋肉量を自動で取得できます。<br>
    取得したデータは自分ログのデータとして表示されます。<br>
    グラフとして表示したいデータをタップで選択することが可能です。<br>
  </li>
  </ul>
  ＜InBody470とデータ連携する方法＞<br>
  <ul>
  <li>初回測定時はインボディ画面上にIDを入力してください。</li>
  <li>「あなたのつながるコード9桁」を手入力するか、QRコードをQRコードリーダーで取得するとIDが入力されます。</li>
  <li>初回利用のみ次画面にて属性情報を手入力してください。（携帯番号・身長・年齢・性別）</li>
  <li>2回目以降は、属性情報を入力する必要はありません。測定前にQRコードをInBodyのQRコードリーダーから読み込んでから測定を開始してください。</li>
  <li>測定後に「データを取得する」ボタンをタップすることで測定データを&wellアプリに連携することが可能です。</li>
  </ul>`,
          image: '22.png',
        },
        {
          id: 1017,
          color: '#838383',
          h1: 'あすけんについて',
          h2: '',
          text: `＆wellは、あすけん（食生活記録・改善アプリ）と連携できます。<br>
          <ul>
  <li>&wellアプリと連携することで、以下のデータを取り組むことができます。<br>
  &体重 ・体脂肪率・摂取エネルギー・消費カロリー<br>
  ※前日の登録分が翌日に反映されます。</li>
  <li>取得したデータは自分ログのデータとして表示されます。</li>
  <li>グラフとして表示したいデータをタップで選択することが可能です。</li>
  </ul>
  ※連携するには、ご利用のスマートフォンにあすけんがインストールされていて、新規会員登録が完了していることが前提となります。<br>
  <br>
  ・あすけんアカウントをお持ちの方<br>
  設定画面→あすけんとデータを連携する画面へ遷移し、「データを連携する」ボタンをタップするとあすけんへのログイン画面が起動するので、お持ちのアカウントでログインしてください。データアクセス許諾画面が表示されるので、取得するデータにチェックを入れて「許可」ボタンをタップしてください。<br>
  <br>
  ・あすけんアカウントをお持ちでない方<br>
  ストアからあすけんをインストールして新規会員登録を完了してください。`,
          image: '23.png',
        },
      ],
    },
  ],
  en: [
    {
      id: 1000,
      title: 'What Is the &well App?',
      content: [
        {
          id: 1001,
          color: '#004098',
          h1: 'What Is the &well App?',
          h2: '',
          text: `&well is an app that provides a variety of information about health, events, and programs to help you change your health awareness, put it into action, and make health activities a habit. <br>
          <br>
          <a href="https://go.andwell.jp/library/ddd8f65c-72a0-11ec-9ce8-0af363eb5062">> How to Operate the App (Introductory video) </a>
          Note: Please log in with your own password to view it.<br>
          Note: The app screens in the introductory video might not be the latest version.
          `,
          image: 'movie1.png',
        },
        {
          id: 1018,
          color: 'transparent',
          h1: 'What is &well Walk Event?',
          h2: '',
          text: `&well Walk events are fun, popular challenges! <br>
          <br>
          You naturally build a habit of walking while having fun in Walk Team Competitions and Walk Individual Challenges. People who try their hardest at walking can win prizes, too!<br>
          <br>
          <a href="https://go.andwell.jp/library/c49a4bc5-baf6-11ec-b342-06d158a16ffc">> &well Walk Events (Introductory video)</a><br>
          Note: Please log in with your own password to view it. <br>
          Note: The app screens in the introductory video might not be the latest version.
          `,
          image: '1018.png',
        },
        {
          id: 1002,
          color: 'transparent',
          h1: '&well Walk: What are Team Competitions?',
          h2: '',
          text: `Walking programs everyone will get hooked on <br>
          <br>
          Programs to get people interested in health through walking. <br>
  <ul>
  <li>Employees at participating companies form teams of seven or more members, then compete with other teams to walk the most steps on average. </li>
  <li>Aim for at least 8,000 steps to get healthier. </li>
  <li>Colleagues encourage each other while chatting together, also helping boost communication in the company.</li>
  <li>The members of the top-ranking teams get luxury prizes. </li>
  <li>Held: Three times a year. </li>
  </ul>
  <a href="https://go.andwell.jp/library/a27faf13-72b0-11ec-9ce8-0af363eb5062">> "Team Competitions: How to Organize a Team" (Introductory video) </a>
  Note: Please log in with your own password to view it. <br>
  Note: The app screens in the introductory video might not be the latest version.`,
          image: 'movie2.png',
        },
        {
          id: 1003,
          color: '#004098',
          h1: '&well Walk: What are Individual Challenges?',
          h2: '',
          text: `Keep motivated, and keep up your healthy activities <br>
          <br>
          Programs to make walking change your daily behavior. <br>
  <ul>
  <li>Events to get as many people as possible to walk 8,000 steps a day—an amount that's said to be good for preventing lifestyle diseases. </li>
  <li>Individuals whose average steps is above a certain number during the event period will be entered in a luxury prize draw. </li>
  <li>Held: Three times a year.</li>
  </ul>`,
          image: 'walkthrough_illust22@2x.png',
        },
        {
          id: 1004,
          color: 'transparent',
          h1: '&What Are &well Festas?',
          h2: '',
          text: `Large scale events to kick-start a healthier lifestyle <br>
          <br>
          Programs to try things out, learn new things about health, and understand your own body better through measurement meetings. <br>
  <ul>
  <li>Feature-packed health events you can invite all your colleagues to and all have fun together.</li>
  <li>Held online via the app, and using special websites, etc., too. </li>
  <li>Held: Three times a year.</li>
  </ul>
  <a target="_blank" href="http://andwell-festa.jp/">http://andwell-festa.jp/</a>`,
          image: '26.jpg',
        },
        {
          id: 1005,
          color: 'transparent',
          h1: 'What Is Well-zhi?',
          h2: '',
          text: `Change your behavior by having fun learning about health <br>
          <br>
          An easy-to-do health literacy quiz that asks one question a day. <br>
  <ul>
  <li>Questions are taken from five genres: diet, exercise, sleep, mental health, and health management.</li>
  <li>Raise your health literacy through fun questions with surprises and insights that'll be the talk of your workplace. </li>
  <li>Held: Three times a year. (About 25 questions each) </li>
  </ul>
  <a href="https://go.andwell.jp/library/68e8cee5-e0a7-11eb-a0b5-06d158a16ffc">>About Well-zhi (Introductory video) </a>
  Note: Please log in with your own password to view it. <br>
  Note: The app screens in the introductory video might not be the latest version.`,
          image: 'movie5.png',
          /*
          movie:
            'https://movie.andwell.jp/224e4b32-9e51-4f56-a173-996234ad7f6c/movie.m3u8',
          thumbnailImageUrl:
            'https://movie.andwell.jp/224e4b32-9e51-4f56-a173-996234ad7f6c/thumbnail-00001.png',
          */
        },
        {
          id: 1006,
          color: 'transparent',
          h1: 'What Are Weekly Programs?',
          h2: '',
          text: `Learn about health regularly, and become a health expert <br>
          <br>
          Programs to casually improve your health literacy by watching a video once a week. <br>
  <ul>
  <li>Original &well programs by experts on diet, exercise, sleep, mental health, health management, and a wide variety of other fields. </li>
  <li>An online program of easy-to-watch five-minute videos. You can watch them during breaks.</li>
  <li>Held: Streamed every Tuesday and Wednesday  Note: the days, etc. may change. </li>
  </ul>
  <a href="https://go.andwell.jp/library/5971636f-cdc0-11eb-9b97-06d158a16ffc">> About Weekly Programs (Introductory video) </a>
  Note: Please log in with your own password to view it. <br>
  Note: The app screens in the introductory video might not be the latest version.`,
          image: 'movie6.png',
          /*
          movie:
            'https://movie.andwell.jp/115d08b8-09e8-41ca-a8d1-42431ad2d07c/movie.m3u8',
          thumbnailImageUrl:
            'https://movie.andwell.jp/115d08b8-09e8-41ca-a8d1-42431ad2d07c/thumbnail-00001.png',
          */
        },
        {
          id: 1009,
          color: '#AFD1D8',
          h1: 'What Are Self-Log and Step Log?',
          h2: '',
          text: `Manage your daily lifestyle by understanding it visually <br>
          <br>
          Tools to help you work on your health yourself, and make it a habit.<br>
  <br>
  ・Self-Log <br>
  Encourages you to lead a healthy lifestyle by recording and graphically showing you your walking, eating, sleeping, and mood patterns and your weight and body fat percentage, every day.<br>
  <br>
  ・Step Log <br>
  Lets you record and manage your daily step data and calories burned.`,
          image: 'walkthrough_illust25@2x.png',
        },
        {
          id: 1010,
          color: '#AFD1D8',
          h1: 'What Are Missions?',
          h2: '',
          text: `Have fun keeping up healthy activities <br>
          <br>
          You can earn points by completing the designated missions. <br>
  <ul>
  <li>Missions are set every month, and for each one you complete, you'll get points and an additional &well stamp. </li>
  <li>If you collect 10 &well stamps, you'll get additional points as an &well stamp completion bonus. </li>
  <li>Complete as many missions as you can and get points!</li>
  </ul>`,
          image: 'walkthrough_illust24@2x.png',
        },
        {
          id: 1011,
          color: 'transparent',
          h1: 'What Are &well Points? (How to Earn and Use Them)',
          h2: '',
          text: `Earn points and win luxury prizes! <br>
          <br>
          A system to make working on your health through &well even more fun, so you'll want to do it even more. <br>
  <br>
  ・Earning points：<br>
  Get points for keeping up your activities every day and completing a wide variety of missions! <br>
  <br>
  ・Two ways to use them! <br>
  1. You can exchange points anytime. <br>
  2. Point Exchange Campaigns <br>
    - Held: Three times a year. <br>
    - A chance to win a luxury prize! <br>
    <br>
    <a href="https://go.andwell.jp/library/32e170ac-c994-11eb-9b97-06d158a16ffc">>How to Earn and Use Points (Introductory video) </a>
    Note: Please log in with your own password to view it. <br>
    Note: The app screens in the introductory video might not be the latest version.`,
          image: 'movie11.png',
        },
        {
          id: 1019,
          color: 'transparent',
          h1: 'Let’s look back our lifestyle habit',
          h2: '',
          text: `Review your own lifestyle and get healthier!<br>
          <br>
          We give you tools to reflect on your lifestyle, like Lifestyle Reports and Log Report Emails calculated from your lifestyle record and steps.<br>
          Give them a try to help manage your health.<br>
          <br>
          <a href="https://go.andwell.jp/library/079bc8dd-b943-11ec-b99b-0af363eb5062">> Reviewing Your Own Lifestyle! (Introductory video)</a><br>
          Note: Please log in with your own password to view it.<br>
          Note: The app screens in the introductory video might not be the latest version.`,
          image: '1019.png',
        },
        {
          id: 1012,
          color: '#AFD1D8',
          h1: 'What Is Disease Risk Prediction?',
          h2: '',
          text: `Predicting your risk of future disease based on your current health check data <br>
          <br>
          A tool to prompt you to change your health awareness and take action based on knowing about your own health. <br>
  <ul>
  <li>Predicts your risk (%) of developing three diseases (diabetes, high blood pressure, and dyslipidemia) in the next five years, based on a year's worth of current health check data.</li>
  <li>Predicts the risk as a probability of onset in the next five years. </li>
  <li>Gives advice about your target weight through improvement simulations. </li>
  <li>Recommends activities, etc. you need through lifestyle improvement advice.</li>
  </ul>`,
          image: 'walkthrough_illust31@2x.png',
        },
        {
          id: 1013,
          color: '#C8B38D',
          h1: 'What Is the Friends Feature?',
          h2: '',
          text: `Keep motivated and keep up your healthy habits by having friends <br>
          <br>
          You can use QR codes or "Connect Codes" to become friends in the app. <br>
  <br>
  ・There are three ways to become friends: <br>
  1. Scan the other person's QR code with your own QR code reader. <br>
  2. Scan your own QR code with the other person's QR code reader. <br>
  3. Send your "Connect Code" to someone via a social network or email so they can enter it in the user search field. <br>
  <ul>
  <li>If you tap the "Become Friends" button, it changes to "Requested." </li>
  <li>Requests will be delivered to your request menu, so tap the "Accept" button if you want to.</li>
  </ul>
  <ul>
  <li>Once you become friends, you can view each other's profile information (teams they belong to and nicknames), activity data, etc., and enjoy chats.</li>
  <li>Your daily activities will also be displayed on your friends' timelines. </li>
  </ul>
  ■ How to delete friends<br>
  1. Press and hold the list.<br>
  2. The "Delete?" dialog appears.<br>
  3. Tap "Yes" to delete the friends from your friends list.`,
          image: 'walkthrough_illust7@2x.png',
        },
        {
          id: 1014,
          color: '#AFD1D8',
          h1: 'What Is Goal Setting?',
          h2: '',
          text: `Reach your goal. Diet Challenge! <br>
          <br>
  If you enter your current weight and height, you'll be able to see your appropriate weight based on a BMI of 22. <br>
  <ul>
  <li>Use your appropriate weight as a guide, enter your target weight, and start the Diet Challenge! </li>
  <li>The target weight you entered is displayed on a graph so you can check it visually.</li>
  <li>If your BMI is below 18.5 based on your current weight and height, you'll be considered underweight and won't be able to take the Diet Challenge. This means you won't be able to enter a target weight. </li>
  <li>An ideal target weight is one that's not more than 2 kg (per month) below your current weight. </li>
  </ul>
  Set a reasonable target weight, and lose weight in a healthy way!`,
          image: 'walkthrough_illust29@2x.png',
        },
        {
          id: 1015,
          color: 'transparent',
          h1: 'About Wearable Devices',
          h2: '',
          text: `You can link the &well app with wearable devices, etc. <br>
          <br>
          <a href="https://go.andwell.jp/library/25d824d8-eeb7-11eb-a3b7-0af363eb5062">> About Linking with Wearable Devices and Bluetooth Weight (Introductory video) </a>
          Note: Please log in with your own password to view it. <br>
          Note: The app screens in the introductory video might not be the latest version.`,
          image: 'movie15.png',
          /*
          movie:
            'https://movie.andwell.jp/43fc2171-b71e-4e27-bcdd-9c941c08675e/movie.m3u8',
          thumbnailImageUrl:
            'https://movie.andwell.jp/43fc2171-b71e-4e27-bcdd-9c941c08675e/thumbnail-00001.png',
          */
        },
        {
          id: 1016,
          color: '#838383',
          h1: 'About InBody',
          h2: '',
          text: `You can link the &well app with InBody.<br>
          <ul>
  <li>InBody is a high-performance body composition monitor that can display muscle and fat levels by location and analyze your body's internal balance. </li>
  <li>It can be used in special spaces (= &well Pits) in your office building. </li>
  <li>Linking it with the &well app enables your weight, body fat percentage, BMI, and muscle mass to be acquired automatically. <br>
  The acquired data will be displayed as data in your Self-Log. <br>
  You can tap to select data you want to display as a graph. <br>
  </li>
  </ul>
  &lt;How to link data with an InBody470&gt;<br>
  <ul>
  <li>When measuring for the first time, enter your ID on the InBody screen. </li>
  <li>You can enter your ID by manually entering your 9-digit "Connect Code" or by scanning the QR code with the QR code reader.</li>
  <li>For first time users only: Please enter your attribute information manually on the next screen. (Mobile phone number, height, age, gender.) </li>
  <li>You don't need to enter your attribute information from the second time onward. Just scan the QR code with the InBody QR code reader to start measurement. </li>
  <li>After measurement, the measurement data can be linked with the &well app by tapping the "Acquire Data" button.</li>
  </ul>`,
          image: '22.png',
        },
        {
          id: 1017,
          color: '#838383',
          h1: 'About Asken',
          h2: '',
          text: `&well can be linked with the Asken dietary record/improvement app. <br>
          <ul>
  <li>Linking it with the &well app will enable you to use the following data: <br>
  Weight, body fat percentage, energy intake, calories burned. <br>
  Note: The data registered the previous day will be reflected the next day. </li>
  <li>The acquired data will be displayed as data in your Self-Log. </li>
  <li>You can tap to select data you want to display as a graph. </li>
  </ul>
  Note: To link with Asken, you need to have it installed on your mobile device and have completed the new member registration. <br>
  <br>
  ・Have an Asken account? <br>
  From the Settings screen, go to the "Link data with Asken" screen and tap the "Link Data" button. The login screen for Asken will appear. Log in to your account, and the screen for allowing data access will be displayed. Put ticks in the checkboxes for the data you want &well to acquire, and tap the "Allow" button. <br>
  <br>
  ・Don't have an Asken account? <br>
  Please install Asken from the store and complete the new member registration.`,
          image: '23.png',
        },
      ],
    },
  ],
}

export default howToUseData
