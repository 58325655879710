<i18n>
{
  "ja":{
    "生活習慣スコア": "生活習慣スコア",
    "今週": "今週",
    "先週": "先週",
    "体重": "体重",
    "体脂肪率": "体脂肪率",
    "BMI": "BMI",
    "生活習慣レポートを見る":"生活習慣レポートを見る"
  },
  "en":{
    "生活習慣スコア": "Lifestyle Score",
    "今週": "This Week",
    "先週": "Last Week",
    "体重": "Weight",
    "体脂肪率": "Body Fat",
    "BMI": "BMI",
    "生活習慣レポートを見る":"Your Lifestyle Report"
  }
}
</i18n>
<template lang="pug">
  .HealthChart
    .inner
      .dateRange
        div {{ $d(fromDate, "short") }} ～ {{ $d(toDate, "short") }}
      .prevWeek(@click="prevWeek")
      .nextWeek(@click="nextWeek")
      .chartInfo
        .chartLabel
          ._title {{$t("生活習慣スコア")}}
          ul
            li {{$t("今週")}}
            li {{$t("先週")}}
        .selectChartType
          .selectBox(@click="selectChartTypeIsOpen=!selectChartTypeIsOpen" :class="[ selectChartTypeIsOpen ? 'open' : '' ]")
            span(v-if="chartType=='weight'") {{$t("体重")}}
            span(v-if="chartType=='bodyFatPercentage'") {{$t("体脂肪率")}}
            span(v-if="chartType=='BMI'") {{$t("BMI")}}
          transition(name="accodion")
            .selectBoxOptions(v-show="selectChartTypeIsOpen")
              div(@click="changeChartType('weight')") {{$t("体重")}}
              div(@click="changeChartType('bodyFatPercentage')") {{$t("体脂肪率")}}
              div(@click="changeChartType('BMI')") {{$t("BMI")}}
        .chart-health-log-input
          HealthLogInputStatus(:healthLogInputStatus="healthLogInputStatus")
      .chart
        //- 目盛り
        .scale
          div(v-for="num in chartScale")
            span(v-if="1*num>0") {{num}}
          div
            span {{scaleUnit}}
          .targetWeight(v-if="targetWeight && chartType=='weight'" :style="{top:27 * targetWeightHeight + 'px'}")
            span {{targetWeight}}
        //- 生活習慣スコアの棒グラフ
        .bars
          div(v-for="bar in bars")
            div(v-for="height in bar" :style="{height:height +'%'}" class="bar")
        LineGraph(:healthData="weeklyHealthData", :type="chartType", :scale="chartScale")
        .days
          div {{$t("月")}}
          div {{$t("火")}}
          div {{$t("水")}}
          div {{$t("木")}}
          div {{$t("金")}}
          div {{$t("土")}}
          div {{$t("日")}}
      HealthTable(:stepsData="weeklyStepsData" :healthData="weeklyHealthData")
      .footer-health-input.sp
        HealthLogInputStatus(:healthLogInputStatus="healthLogInputStatus")
      //-
        .link
          router-link(:to="{name:'healthStats'}") {{$t("生活習慣レポートを見る")}}


</template>
<script>
import moment from 'moment-timezone'
import HealthTable from '@/components/Home/HealthTable'
import LineGraph from '@/components/Home/LineGraph'
import HealthLogInputStatus from '@/components/Home/HealthLogInputStatus'

export default {
  name: 'HealthChart',
  components: {
    HealthTable,
    LineGraph,
    HealthLogInputStatus,
  },
  data() {
    return {
      fromDate: '',
      toDate: '',
      chartType: 'weight', // 体重(weight)、体脂肪率(bodyFatPercentage)、BMI(BMI)
      targetWeight: 0, // 目標体重
      targetWeightHeight: 0, // 目標体重目盛のy座標
      chartScale: [], // 表の目盛り
      scaleUnit: 'kg',
      weeklyHealthData: [],
      weeklyStepsData: [],
      bars: [],
      loading: false,
      selectChartTypeIsOpen: false,
      healthLogInputStatus: {},
    }
  },
  created() {
    // 今週の月曜と日曜
    this.fromDate = moment()
      .day(1)
      .startOf('day') // 1=monday
    this.toDate = moment()
      .day(7)
      .startOf('day') // 7=sunday
    this.draw()

    this.getHealthLogInputStatus()
  },
  methods: {
    prevWeek() {
      if (!this.loading) {
        // 7日前
        this.fromDate = this.fromDate.subtract(7, 'days').startOf('day')
        this.toDate = this.toDate.subtract(7, 'days').startOf('day')
        this.draw()
      }
    },
    nextWeek() {
      const monday = moment()
        .day(1)
        .startOf('day') // 1=monday
      const isThisWeekOrFuture = this.fromDate.isSameOrAfter(monday, 'day')

      if (!this.loading && !isThisWeekOrFuture) {
        // 7日後
        this.fromDate = this.fromDate.add(7, 'days').startOf('day')
        this.toDate = this.toDate.add(7, 'days').startOf('day')
        this.draw()
      }
    },
    changeChartType(type) {
      this.selectChartTypeIsOpen = !this.selectChartTypeIsOpen
      this.chartType = type
      console.log('chartType:', this.chartType)
      this.scaleUnit = { weight: 'kg', BMI: 'kg/㎡', bodyFatPercentage: '%' }[
        this.chartType
      ]
      this.draw()
    },
    draw() {
      const args = {
        fromDateTime: this.fromDate.unix() * 1000, // 00:00:00:00
        toDateTime: this.toDate.unix() * 1000, // 00:00:00:00
        needsWeeklyAverage: true,
        needsStepStatistics: true,
        needsDailyScore: true,
        needsInterview: true,
        needsWeight: true,
        needsBMI: true,
        needsBodyFatPercentage: true,
      }
      this.loading = true
      this.api('getHealthDataByRange', args, rv => {
        this.drawChart(rv)
        console.log('getHealthDataByRange', rv)
        this.loading = false
      })
    },
    parseWeeklyData(data) {
      let weeklyData = []
      // 月曜から日曜までのグラフ用データに整形する
      // 水曜がないなど歯抜けデータに対応
      for (let day = 1; day <= 7; day++) {
        const dailyData = data.find(v => {
          // 曜日が一致するデータを抽出
          return day % 7 === moment(v.dateTime).day()
        })
        weeklyData.push(dailyData)
      }
      return weeklyData
    },
    arrayMapIfExists(arr, key) {
      // [{ a: 1, b: 2 }, { a: 2 }, { a: 3, b: 6 }].map(v => v.b)
      // mapだとこうなってしまう[2,undefined,6]
      let rv = []
      arr.forEach(item => {
        const val = item[key]
        if (val !== undefined) {
          rv.push(val)
        }
      })
      return rv
    },
    roundDecimal(n) {
      return Math.round(n * 10) / 10
    },
    calcWeightScale(weeklyHealthData, targetWeight) {
      // 体重グラフの目盛りを算出する
      // 計算ロジックは「三井APP_画面設計書_Ph10.pptx」p.178参照
      const weightData = this.arrayMapIfExists(weeklyHealthData, 'weight')
      if (weightData.length === 0 && targetWeight === 0) {
        return [0, 0, 0, 0, 0]
      }
      if (weightData.length === 0 && targetWeight > 0) {
        weightData.push(targetWeight)
      }
      const latestWeight = weightData[weightData.length - 1]
      const plusWeight = latestWeight + 2
      const minusWeight = latestWeight - 2

      const allWeights = weightData.concat(plusWeight, minusWeight)
      if (targetWeight) {
        allWeights.push(targetWeight)
      }
      const min = Math.min(...allWeights)
      const max = Math.max(...allWeights)
      const sub = (max - min) / 4

      const scale = [max, min + sub * 3, min + sub * 2, min + sub * 1, min]
      return scale.map(this.roundDecimal)
    },
    calcScale(weeklyHealthData, chartType) {
      // BMI,体脂肪率グラフの目盛りを算出する
      // 計算ロジックは「三井APP_画面設計書_Ph10.pptx」p.178参照
      const list = this.arrayMapIfExists(weeklyHealthData, chartType) // chartType = "BMI" or "bodyFatPercentage"
      const latest = list[list.length - 1]
      const scale = [latest + 2, latest + 1, latest, latest - 1, latest - 2]
      console.log('calcScale', list)
      return scale.map(this.roundDecimal)
    },
    calcTargetWeightHeight(targetWeight, scale) {
      // scaleの最大値とtargetWeightがイコールなら0、最小値なら1
      const min = Math.min(...scale)
      const max = Math.max(...scale)
      const unitCount = 5 //5目盛
      const unitHeight = (max - min) / (unitCount - 1)
      const height = (targetWeight - min) / unitHeight
      console.log(...scale, height)
      return unitCount - 1 - height
    },
    drawChart(data) {
      this.weeklyHealthData = this.parseWeeklyData(data.healthData)
      this.weeklyStepsData = this.parseWeeklyData(data.stepsData)

      if (this.chartType == 'weight') {
        let weight = data.targetWeight ? data.targetWeight.weight : 0
        this.chartScale = this.calcWeightScale(data.healthData, weight)
        this.targetWeight = weight
        this.targetWeightHeight = this.calcTargetWeightHeight(
          this.targetWeight,
          this.chartScale
        )
      } else {
        this.chartScale = this.calcScale(data.healthData, this.chartType)
      }

      this.bars = []
      // 月曜から日曜までのグラフ用データに整形する
      // 水曜がないなど歯抜けデータに対応
      for (let day = 1; day <= 7; day++) {
        let scoreLastWeek = 0
        let scoreThisWeek = 0
        const item = data.healthData.find(v => {
          // stepsから曜日が一致するデータを抽出
          return day % 7 === moment(v.dateTime).day()
        })
        if (item) {
          scoreLastWeek = item.comparisonScore || 0
          scoreThisWeek = item.score || 0
        }
        this.bars.push([scoreLastWeek, scoreThisWeek])
      }
      console.log('bars', this.bars)
    },
    getHealthLogInputStatus() {
      this.api('getHealthLogInputStatus', {}, rv => {
        this.healthLogInputStatus = rv
      })
    },
  },
}
</script>
<style scoped lang="scss">
.HealthChart {
  background: #fff;
  margin: 20px auto;
  padding: 40px 115px;
  @include sp-layout {
    padding: 25px 15px;
  }
}
.inner {
  position: relative;
}
.dateRange {
  text-align: center;
  font-weight: bold;
}
.prevWeek,
.nextWeek {
  position: absolute;
  top: 0;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: $base-bg-gray-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include sp-layout {
    width: 28px;
    height: 28px;
  }
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border: #111 2px solid;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
  }
}
.prevWeek {
  left: 0;
  &:before {
    transform: rotate(-135deg);
  }
}
.nextWeek {
  right: 0;
}

.chartInfo {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 36px 0 30px;
  .chartLabel {
    @include sp-layout {
      display: none;
    }
    position: absolute;
    left: 70px;
    top: 0;

    ._title {
      font-size: 13px;
      margin-bottom: 6px;
    }
    ul {
      display: flex;
    }
    li {
      margin-right: 12px;
      font-size: 12px;
    }
    li::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 6px;
      border-radius: 2px;
      background: $tags-color-gray;
    }
    li:first-child::before {
      background: $tags-color-blue;
    }
  }
}

.chart-health-log-input {
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  @include sp-layout {
    display: none;
  }
}
.selectChartType {
  position: relative;
  font-size: 13px;
  @include sp-layout {
    font-size: 12px;
  }
  color: $graph-color-yellow;
  .selectBox {
    position: relative;
    width: 140px;
    height: 40px;
    border-radius: 40px;
    border: $graph-color-yellow 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      position: absolute;
      right: 12px;
      top: 17px;
      content: '';
      display: block;
      width: 10px;
      height: 6px;
      background: url('../../assets/images/selectBoxArrow.svg') no-repeat 0 0;
      background-size: contain;
    }
  }
  .selectBoxOptions {
    position: absolute;
    width: 140px;
    left: 50%;
    top: 40px;
    transform: translate(-50%, 0);
    z-index: 5;
    background: #fff;
    border: $graph-color-yellow 0px solid;
    border-width: 1px 1px 0;

    div {
      padding: 4px 8px;
      text-align: center;
      border-bottom: $graph-color-yellow 1px solid;
      cursor: pointer;
    }
    div:hover {
      background: #f2f2f2;
    }
  }
}
.chart {
  position: relative;
  .scale {
    position: absolute;
    left: 60px;
    top: 0;
    width: calc(100% - 60px);
    @include sp-layout {
      left: 40px;
      width: calc(100% - 40px);
    }
    > div {
      position: relative;
      height: 27px;
      border-bottom: #e2e2e2 1px solid;
    }
    span {
      position: absolute;
      left: -60px;
      bottom: -9px;
      width: 60px;
      padding-right: 8px;
      font-size: 14px;
      color: #999;
      text-align: right;
      @include sp-layout {
        left: -40px;
        width: 40px;
        font-size: 11px;
      }
    }
    .targetWeight {
      border-bottom: none;
      position: absolute;
      z-index: 3;
      width: 100%;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 4px;
        margin: auto;
        background-image: linear-gradient(
          to right,
          $graph-color-pink,
          $graph-color-pink 14px,
          transparent 14px,
          transparent 22px
        );
        background-size: 20px 3px;
        background-repeat: repeat-x;
        @include sp-layout {
          background-image: linear-gradient(
            to right,
            $graph-color-pink,
            $graph-color-pink 8px,
            transparent 8px,
            transparent 12px
          );
          background-size: 12px 2px;
        }
      }
      span {
        left: auto;
        right: 100%;
        width: auto;
        padding: 1px 10px;
        border-radius: 3px;
        background-color: $graph-color-pink;
        color: #fff;
        text-align: center;
      }
    }
  }
  .bars,
  .days {
    position: relative;
    display: flex;
    margin-left: 94px;
    justify-content: left; // centerだとデータがないときにずれる
    > div {
      width: 72px;
      margin: 0 14px;
    }
    @include sp-layout {
      margin-left: 40px;
      > div {
        width: 10.5%; // 100%/7 = 14.28を[width:margin = 3:1]で配分
        margin-left: 3.5%;
        margin-right: 0;
      }
    }
  }
  .bars {
    height: 162px;
    align-items: flex-end;
    > div {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .bar {
      width: 24px;
      border-radius: 4px 4px 0 0;
      background: $tags-color-blue;
      margin: 0 2px;
      &:first-child {
        width: 16px;
        background-color: $tags-color-gray;
      }
      @include sp-layout {
        border-radius: 3px 3px 0 0;
      }
    }
  }
  .days {
    font-size: 14px;
    @include sp-layout {
      font-size: 11px;
    }
    > div {
      margin-top: 6px;
      text-align: center;
    }
  }

  .LineGraph {
    position: absolute;
    left: 94px;
    top: 0;
    width: 700px;
    height: 162px;
    @include sp-layout {
      width: calc(100% - 40px);
      left: 40px;
    }
  }
}

.HealthTable {
  margin-top: 40px;
  width: 100%;
}

.link {
  padding: 32px 0 0;
  text-align: center;

  a {
    color: $tags-color-blue;
    text-decoration: underline;
    font-size: 14px;
  }
}

.footer-health-input {
  margin-top: 16px;
}
</style>
