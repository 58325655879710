import Repository from './Repository'

export default {
  get(offset, perPage, scheduleType, onlyFree, onlyOnline, onlyReservation) {
    return Repository('getEvents', {
      offset,
      perPage,
      scheduleType,
      onlyFree,
      onlyOnline,
      onlyReservation,
    })
  },
  getReservingEvents(offset, perPage) {
    return Repository('getReservingEvents', {
      offset,
      perPage,
    })
  },
}
