<template lang="pug">
  dialog.questions(ref="questions")
    ._header
      h3 予約前アンケート
      ._close(@click="close")
    ._message
      ._text アンケートにお答えください。
      ._note <span class="_pink">※</span>がついている項目は必須です。

    form(@submit.prevent="")
      ul
        li(v-for="item in questions" :class="`type-${item.type}`") 
          div.body
            div.question-line
              span.head(v-if="item.questionnaireIndex != null") {{item.questionnaireIndex + 1 }}
              span.question(v-if="item.question" v-html="item.question")
              span.required._pink(v-if="item.required") ※
            //-
              div.alert(v-if="hasAlart(item.questionnaireIndex)" class="alert") {{ requireMessage }}
            TextInput(v-if="item.type === 'text'" @getData='setData($event)' :item='item')
            Checkbox(v-if="item.type === 'checkbox'" @getData='setData($event)' :item='item')
            Select(v-if="item.type === 'select'" @getData='setData($event)' :item='item')
            SlideImage(v-if="item.type === 'images'" :item="item")
      .errorMessage(v-if="errorMessage") {{$t(errorMessage)}}
      .submitArea
        button.middle-button-style.blue-button(type='submit' @click="submit") {{$t("回答")}}
</template>
<script>
import TextInput from '@/components/Input/Text'
import Checkbox from '@/components/Input/Checkbox'
import Select from '@/components/Input/Select'
import SlideImage from '@/components/Input/SlideImage'

export default {
  components: {
    TextInput,
    Select,
    Checkbox,
    SlideImage,
  },
  props: ['questions'],
  data() {
    return {
      isMounted: false,
      errorMessage: '',
      sessionID: '',
      formData: [],
    }
  },
  mounted(){
    this.isMounted = true
  },
  methods: {
    // modalを開く
    open(sessionID) {
      console.log(this.questions)
      this.sessionID = sessionID
      const $elem = this.$refs.questions
      setTimeout(() => {
        $elem.scrollTo({ top: 0 })
      }, 0)
      $elem.showModal()
    },
    // modalを閉じる
    close() {
      this.$refs.questions.close()
    },
    // フォーム入力時にコールされる
    setData(data) {
      this.$set(this.formData, data.index, data.value)
    },
    validate() {
      console.log(this.formData)
      const found = this.questions.find((q) => {
        if (q.required) {
          const value = this.formData[q.questionnaireIndex]
          if (value === undefined || value === null) {
            return true
          }
        }
        return false
      })
      this.errorMessage = found ? '入力必須の項目が入力されていません' : ''
      const isValid = found ? false : true
      return isValid
    },
    submit() {
      if (this.validate()) {
        this.close()
        this.$emit('reserve', this.sessionID, this.formData)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
dialog {
  overscroll-behavior: none;
  max-width: 540px;
  margin: auto;
  padding: 0;
  border: none;
  @include sp-layout {
    margin-top: 0;
    height: 100vh;
    height: 100dvh;
  }
}
dialog::backdrop {
  background: #000;
  opacity: 0.3;
}
.questions {
  .swiper-container {
    max-width: 100%;
  }
  .swiper-slide {
    box-sizing: border-box;
    padding: 0 10px;
    img {
      max-width: 100%;
    }
  }

  ._header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    padding-left: 1em;
    display: flex;
    align-items: center;
    font-weight: bold;
    background: #fff;
    z-index: 10;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04);

    h3 {
      margin: 0;
    }
  }
  ._close {
    display: block;
    width: 54px;
    height: 54px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    background: url('../../assets/images/icon_batsu.svg') no-repeat center;
    background-size: 45%;
    @include sp-layout {
      width: 45px;
      height: 45px;
    }
  }
  ._message {
    padding: 14px 20px;
    background: #f6f6f8;
  }
  ._note {
    margin: 10px 0;
    font-size: 11px;
  }
  ._pink {
    color: #ec357e;
  }
  form {
    padding: 20px 0 40px;
  }
  li {
    padding: 1.6em 1.6em;
    @include sp-layout {
      padding: 1.1em 1.1em;
    }
  }
  .head {
    font-weight: bold;
    background: #e60012;
    color: #fff;
    width: 30px;
    display: inline-block;
    text-align: center;
    height: 28px;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 3px;
    line-height: 27px;
    flex-shrink: 0;
  }
  .question-line {
    display: flex;
    align-items: center;
  }
  .required {
    display: inline-block;
    margin-left: 8px;
  }
  .alert {
    color: $alert-color-red;
    font-weight: bold;
    font-size: 0.85em;
    margin: 0.4em 0 0 0;
  }
  .submitArea {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .blue-button {
    display: block;
    margin: 0 auto;
    &:before {
      content: none;
    }
  }
  .errorMessage {
    padding: 1em;
    color: $alert-color-red;
  }
}

</style>
