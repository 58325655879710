import router from '@/router'
import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const announcementRepository = RepositoryFactory.get('announcement')

const defaultState = () => ({
  items: [],
  length: 0,
  paged: 0,
})

const state = defaultState()

const getters = {}

const mutations = {
  incrementPage(state) {
    state.paged++
  },
  setItems(state, payload) {
    state.length = payload.length
    state.items = state.items.concat(payload)
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems({ state, commit }, payload) {
    try {
      const { succeed, announcements } = await announcementRepository.get(
        state.paged * payload,
        payload
      )
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', announcements)
      }
    } catch (error) {
      //
    }
  },
  resetItems({ commit }) {
    commit('resetItems')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
