<i18n>
{
  "ja": {
    "自分ログフィードバック": "自分ログフィードバック",
    "総合評価":"総合評価",
    "周りと比べてみよう":"周りと比べてみよう",
    "自分ログの入力率":"自分ログの入力率",
    "分類別評価":"分類別評価",
    "歩数スコア":"歩数スコア",
    "運動":"運動",
    "食事":"食事",
    "飲酒":"飲酒",
    "睡眠":"睡眠",
    "ストレス":"ストレス",
    "exercise":"運動",
    "meal":"食事",
    "drinking":"飲酒",
    "sleep":"睡眠",
    "stress":"ストレス",
    "あなたにおすすめのコンテンツ": "あなたにおすすめのコンテンツ"
  },
  "en": {
    "自分ログフィードバック": "Health Log Feedback",
    "総合評価":"Summary",
    "周りと比べてみよう":"Let's compare with others",
    "自分ログの入力率":"Health Log input rate",
    "分類別評価":"Evaluation by category",
    "歩数スコア":"Steps Score",
    "運動":"Exercise",
    "食事":"Meal",
    "飲酒":"Drinking",
    "睡眠":"Sleep",
    "ストレス":"Stress",
    "exercise":"Exercise",
    "meal":"Meal",
    "drinking":"Drinking",
    "sleep":"Sleep",
    "stress":"Stress",
    "あなたにおすすめのコンテンツ": "Recommended content for you"
  }
}
</i18n>
<template lang="pug">
//- 自分ログフィードバック
.HealthStats
  DatePicker(@picked="onDateChange")

  section
    HeadingBlock(level="1" :text="$t('総合評価')")
    section.HealthStatsLargeItem(v-if="pickedMoment")
      .intro
        p(v-if="this.$i18n.locale === 'en'") Lifestyle Score of {{pickedMoment.format('MMMM YYYY')}}
        p(v-else) {{pickedMoment.format('YYYY年M月')}}の生活習慣スコア
        div
          em {{latestScore}}
          span %
      .chart
        ScoreChart(v-if="loaded" :scores="scoreChartData.score" :weights="scoreChartData.weight" :labels="scoreChartLabels" :isLarge="true")
      .comment(v-html="comments.score")
    .HealthStatsItems
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('周りと比べてみよう')")
        .HealthStatsItemInner
          .chart
            Histogram(v-if="loaded" :values="histogramData" :score="latestScore")
          .comment(v-if="this.$i18n.locale === 'en'") Among your peers, you are in the top <b>{{histogramPosition.from}}</b> to <b>{{histogramPosition.to}}</b> percent.
          .comment(v-else) 同年代の生活習慣の中であなたは上位<b>{{histogramPosition.from}}</b>～<b>{{histogramPosition.to}}</b>％に位置しています。
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('自分ログの入力率')")
        .HealthStatsItemInner
          .chart
            Doughnut(v-if="loaded" :value="inputRate")
          .inputRate
            em {{inputRate}}
            | %
          HealthLogInputStatus(:healthLogInputStatus="healthLogInputStatus")
          .comment(v-if="this.$i18n.locale === 'en'") Your input rate is <b>{{inputRate}}</b>%.<br>Keeping a record is a good idea because you can analyze yourself from a third-person perspective.
          .comment(v-else) あなたの入力率は <b>{{inputRate}}</b>％です。<br>記録の良いところは、第三者目線で自分を見つめ直すことができることです。

  section
    HeadingBlock(level="1" :text="$t('分類別評価')")
    section.HealthStatsLargeItem
      .chart
        RadarChart(v-if="loaded" :values="radarChartData.scores" :average="radarChartData.average")
      .rating
        div(v-for="rate in radarChartRating")
          .title {{$t(rate.key)}}
          img(:src="require(`@/assets/images/emoticon/${rate.icon}.svg`)")
          p(:class="'icon'+rate.icon") {{rate.score}}%

    .HealthStatsItems
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('歩数スコア')")
        .HealthStatsItemInner
          .chart
            ScoreChart(v-if="loaded" :scores="scoreChartData.steps" :weights="scoreChartData.weight" :labels="scoreChartLabels")
          .comment(v-html="comments.steps")
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('運動')")
        .HealthStatsItemInner
          .chart
            ScoreChart(v-if="loaded" :scores="scoreChartData.exercise" :weights="scoreChartData.weight" :labels="scoreChartLabels")
          .comment(v-html="comments.exercise")
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('食事')")
        .HealthStatsItemInner
          .chart
            ScoreChart(v-if="loaded" :scores="scoreChartData.meal" :weights="scoreChartData.weight" :labels="scoreChartLabels")
          .comment(v-html="comments.meal")
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('飲酒')")
        .HealthStatsItemInner
          .chart
            ScoreChart(v-if="loaded" :scores="scoreChartData.drinking" :weights="scoreChartData.weight" :labels="scoreChartLabels")
          .comment(v-html="comments.drinking")
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('睡眠')")
        .HealthStatsItemInner
          .chart
            ScoreChart(v-if="loaded" :scores="scoreChartData.sleep" :weights="scoreChartData.weight" :labels="scoreChartLabels")
          .comment(v-html="comments.sleep")
      section.HealthStatsItem
        HeadingBlock(level="2" :text="$t('ストレス')")
        .HealthStatsItemInner
          .chart
            ScoreChart(v-if="loaded" :scores="scoreChartData.stress" :weights="scoreChartData.weight" :labels="scoreChartLabels")
          .comment(v-html="comments.stress")

  section
    HeadingBlock(level="1" :text="$t('あなたにおすすめのコンテンツ')")
    .container.articles(v-if="articles")
      ul.columns-block-list.row.row-cols-1.row-cols-sm-1.row-cols-md-2
        li.col(v-for="item,index in articles") 
          router-link(:to="{name: 'library', params: {id: item.id}}")
            .column-header-block(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
            .column-footer-block
              ul.common-tags-lists
              .title {{ item.title }}
      
</template>

<script>
import moment from 'moment-timezone'
import HeadingBlock from '@/components/HeadingBlock'
import ScoreChart from '@/components/HealthStatsCharts/ScoreChart'
import Histogram from '@/components/HealthStatsCharts/Histogram'
import RadarChart from '@/components/HealthStatsCharts/RadarChart'
import Doughnut from '@/components/HealthStatsCharts/Doughnut'
import DatePicker from '@/components/Account/DatePicker'
import HealthLogInputStatus from '@/components/Home/HealthLogInputStatus'

export default {
  components: {
    HeadingBlock,
    ScoreChart,
    Histogram,
    RadarChart,
    Doughnut,
    DatePicker,
    HealthLogInputStatus,
  },
  data() {
    return {
      pickedMoment: null,
      loaded: false,
      latestScore: 0,
      comments: {},
      inputRate: 0,
      scoreChartLabels: [],
      scoreChartData: {
        weight: [],
        score: [],
        steps: [],
        exercise: [],
        meal: [],
        drinking: [],
        sleep: [],
        stress: [],
      },
      radarChartData: {
        scores: [],
        average: [],
      },
      radarChartRating: [],
      histogramData: [],
      histogramPosition: { from: 0, to: 0 },
      articles: null,
      healthLogInputStatus: {},
    }
  },
  methods: {
    fetch() {
      const fromDateTime = this.pickedMoment.valueOf()
      this.api('getHealthLogStats', { fromDateTime }, rv => {
        console.log(
          'getHealthLogStats: fromDateTime:' + fromDateTime,
          rv,
          JSON.stringify(rv)
        )
        this.parse(rv)
      })

      /*
      // getHealthLogStats
      const rv = {
        succeed: true,
        scoreData: [
          {
            dateTime: 1625097600 * 1000, // 2021/7/1
            score: Math.random() * 100,
            weight: Math.random() * 2 + 59,
            steps: 55.5555,
            exercise: 44.4444,
            meal: 33.3333,
            drinking: 77.7777,
            sleep: 100,
            stress: 0,
          },
          {
            dateTime: 1627776000 * 1000, // 8/1
            score: Math.random() * 100,
            weight: Math.random() * 2 + 59,
            steps: 44.4444,
            exercise: 55.5555,
            meal: 44.4444,
            drinking: 88.8888,
            sleep: 88.8888,
            stress: 11.1111,
          },
          {
            dateTime: 1630454400 * 1000, // 9/1
            score: Math.random() * 100,
            weight: Math.random() * 2 + 59, // 非必須なのでない場合がある
            steps: 66.6666,
            exercise: 66.6666,
            meal: 66.6666,
            drinking: 77.7777,
            sleep: 77.7777,
            stress: 44.4444,
          },
          {
            dateTime: 1633014000 * 1000, // 10/1
            score: Math.random() * 100,
            weight: Math.random() * 2 + 59,
            steps: Math.random() * 100,
            exercise: Math.random() * 100,
            meal: Math.random() * 100,
            drinking: Math.random() * 100,
            sleep: Math.random() * 100,
            stress: Math.random() * 100,
          },
        ],
        histogram: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
        average: {
          exercise: 33.3333,
          meal: 66.6666,
          drinking: 77.7777,
          sleep: 55.5555,
          stress: Math.random() * 100,
        },
        inputRate: Math.random() * 100,
      }
      this.parse(rv)
      */
    },
    round(n) {
      return Math.round(n * 10) / 10
    },
    arrayTotal(arr) {
      return arr.reduce(function(sum, val) {
        return sum + val
      }, 0)
    },
    onDateChange(year, month) {
      moment.locale(this.$i18n.locale)
      this.pickedMoment = moment({ year, month: month - 1 })
      console.log(year, month)
      this.fetch()
    },
    calcScoreChartData(servercodeValue) {
      for (const [key, arr] of Object.entries(this.scoreChartData)) {
        this.scoreChartData[key] = []
        servercodeValue.scoreData.forEach(data => {
          let score = key in data ? data[key] : null
          score = this.round(score)
          this.scoreChartData[key].push(score)
        })
      }
      // 体重=0なら表にプロットしない。nullにしておく
      this.scoreChartData.weight = this.scoreChartData.weight.map(v => {
        return v === 0 ? null : v
      })
    },
    // 分類別評価の下の表
    calcRadarChartRating(scores) {
      const rating = scores.map(item => {
        // bad <<<  0-25:3, 25-50:2, 50-75:1, 75-100:0  >>> good
        const score = this.round(item.score)
        const icon = score === 0 ? 3 : Math.floor((100 - score) / 25)
        return { key: item.key, score, icon }
      })
      return rating
    },
    // あなたにおすすめの記事用のタグを選ぶ
    chooseArticleTags(scores) {
      let tags = []
      const conf = {
        exercise: 'genre4', // 運動
        meal: 'genre2', // 食事
        sleep: 'genre3', // 休息
        stress: 'genre5', // メンタルヘルス
        drinking: ['master9', 'master10'], // 飲酒の頻度,飲酒量
      }
      scores.sort((a, b) => a.score - b.score) // 昇順

      // 下位2つのkeyが候補
      const key1 = scores[0].key
      const key2 = scores[1].key
      const tag1 = conf[key1]
      const tag2 = conf[key2]
      const diff = scores[1].score - scores[0].score

      if (key1 === 'drinking') {
        // 飲酒だけ対応タグが2個あるのでそれを返す
        tags = ['master9', 'master10']
      } else if (diff > 20) {
        // key1だけが極端に悪い場合はtag1だけを返す
        tags = [tag1]
      } else {
        // 通常は下位2つのkeyに対応するtagを返す
        tags = [tag1, tag2]
      }
      console.log(tags)
      return tags
    },
    calcRadarChartData(servercodeValue) {
      let latestScores = []
      this.radarChartData.scores = []
      this.radarChartData.average = []
      const keys = ['exercise', 'meal', 'drinking', 'sleep', 'stress']
      const latestScoreData =
        servercodeValue.scoreData[servercodeValue.scoreData.length - 1]
      keys.forEach(key => {
        const score = this.round(latestScoreData[key])
        const average = this.round(servercodeValue.average[key])
        latestScores.push({ key, score })
        this.radarChartData.scores.push(score)
        this.radarChartData.average.push(average)
      })

      // 評価の表
      this.radarChartRating = this.calcRadarChartRating(latestScores)

      // 関連コンテンツ
      const tags = this.chooseArticleTags(latestScores)
      this.showArticles(tags)
    },
    calcHistogramPosition(histogramData) {
      // 上位from% - to%に位置しています
      const score = this.latestScore
      const total = this.arrayTotal(histogramData)

      let from = 0
      let to = 0
      for (var i = 0; i < 10; i++) {
        const max = (10 - i) * 10
        const min = max - 10
        if (i > 0) {
          from += histogramData[i - 1]
        }
        to += histogramData[i]

        if ((min == score && score == 0) || (min < score && score <= max)) {
          break
        }
      }
      this.histogramPosition.from = Math.round((from / total) * 100)
      this.histogramPosition.to = Math.round((to / total) * 100)
    },
    calcHistogramData(servercodeValue) {
      this.histogramData = servercodeValue.histogram.reverse() // データは昇順、グラフは降順
      this.calcHistogramPosition(this.histogramData)
    },
    calcChartLabels() {
      moment.locale(this.$i18n.locale)
      let labels = []
      const momentClone = moment(this.pickedMoment) // subtractは元のmomentを変更するのでcloneしておく
      for (var i = 0; i < 4; i++) {
        const month = i === 0 ? momentClone : momentClone.subtract(1, 'months')
        labels.unshift(month.format('MMM')) // 4月 | April
      }
      this.scoreChartLabels = labels
    },
    showArticles(tags) {
      if (!tags || !tags.length) return
      this.articles = []

      const perPage = 6 / tags.length
      tags.forEach(tag => {
        this.api(
          'getReportArticlesByTag',
          {
            tagID: tag,
            sortBy: 'newer',
            perPage: perPage,
          },
          rv => {
            if (rv.reportArticles && rv.reportArticles.length > 0)
              this.articles.push(...rv.reportArticles)
          }
        )
      })
    },
    chooseComments(scores) {
      const year = this.pickedMoment.year()
      const month = this.pickedMoment.month() + 1

      import(`/src/const/healthStatsComments/${year}/${month}.js`).then(
        module => {
          const comments = module.comments[this.$i18n.locale]
          this.formatComments(comments, scores)
        }
      )
    },
    formatComments(comments, scores) {
      const len = scores.length
      Object.keys(comments).map(key => {
        let comment = ''
        const scoreThisMonth = scores[len - 1][key] || 0 // データの無い月はスコア0とする
        const scoreLastMonth = scores[len - 2][key] || 0
        const diff = this.round(scoreThisMonth - scoreLastMonth) // 先月とのスコア差

        if (diff > 0) {
          comment = comments[key].up.replace(
            '%d',
            '<span class="up">' + diff + '</span>'
          )
        } else if (diff < 0) {
          comment = comments[key].down.replace(
            '%d',
            '<span class="down">' + -1 * diff + '</span>'
          )
        } else if (scoreThisMonth === 100 && comments[key].equal100) {
          // 2カ月連続満点の場合
          comment = comments[key].equal100
            .replace('%満点%', '<span class="good">満点</span>')
            .replace('%maximum%', '<span class="good">maximum</span>')
        } else {
          comment = comments[key].equal
        }
        //this.comments[key] = comment
        this.$set(this.comments, key, comment)
      })
    },
    parse(servercodeValue) {
      // 今月の総合スコア
      const scores = servercodeValue.scoreData
      const len = scores.length
      this.latestScore = this.round(scores[len - 1].score)

      // コメント選択
      this.chooseComments(scores)

      // 各グラフのデータ
      this.inputRate = this.round(servercodeValue.inputRate)
      this.calcScoreChartData(servercodeValue)
      this.calcRadarChartData(servercodeValue)
      this.calcHistogramData(servercodeValue)
      this.calcChartLabels()

      console.log('scoreChartData', this.scoreChartData)
      console.log('radarChartData', this.radarChartData)
      console.log('histogramData', this.histogramData)

      this.loaded = true
    },
    getHealthLogInputStatus() {
      this.api('getHealthLogInputStatus', {}, rv => {
        this.healthLogInputStatus = rv
      })
    },
  },

  mounted() {
    moment.locale(this.$i18n.locale)
    const lastMonth = moment().subtract(1, 'months')
    const year = lastMonth.year()
    const month = lastMonth.month() + 1
    this.onDateChange(year, month)
    this.getHealthLogInputStatus()
  },
}
</script>

<style lang="scss" scoped>
.HealthStats {
  .HealthStatsItems {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .HealthStatsItem {
    position: relative;
    margin-top: 28px;
    background: #fff;
    padding: 15px 0;
    width: 48%;
    @include sp-layout {
      width: 100%;
    }

    .ScoreChart {
      width: 360px;
      height: 280px;
      @include sp-layout {
        width: 100%;
        height: 60vw;
      }
    }
    .Histogram {
      width: 360px;
      height: 320px;
      @include sp-layout {
        width: calc(100vw - 66px);
        height: calc(100vw - 66px);
      }
    }
    .Doughnut {
      margin: 60px 0;
      width: 200px;
      height: 200px;
      @include sp-layout {
        margin: 20px 0;
        width: 40vw;
        height: 40vw;
      }
    }
    .inputRate {
      position: absolute;
      left: 0;
      top: 207px;
      width: 100%;
      text-align: center;
      em {
        font-size: 30px;
      }
      @include sp-layout {
        left: 4px;
        top: 140px;
      }
    }
  }
  .HealthStatsLargeItem {
    padding-bottom: 28px;
    border-bottom: $tags-color-gray 1px solid;
    margin-bottom: 28px;

    .comment {
      background-color: #fff;
    }
    .ScoreChart {
      width: 100%;
      height: 360px;
      @include sp-layout {
        width: 100%;
        height: 66vw;
      }
    }
    .RadarChart {
      width: 500px;
      height: 500px;
      @include sp-layout {
        width: 100%;
        height: 90vw;
      }
    }
  }
  .HealthStatsItemInner {
    padding: 0 15px;
  }
  h1 {
    background: #fff;
    border: $tags-color-blue 1px solid;
    border-radius: 4px;
    color: $tags-color-blue;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: normal;
    margin-top: 64px;
    margin-bottom: 24px;
    @include sp-layout {
      padding: 10px 12px;
      font-size: 20px;
    }
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 22px;
    font-size: 18px;
  }
  em {
    font-family: 'GothamBold', arial, sans-serif;
    font-size: 48px;
    margin-right: 5px;
    @include sp-layout {
      font-size: 32px;
    }
  }
  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .comment {
    border: $tags-color-gray 1px solid;
    background: $base-bg-gray-color;
    padding: 16px 20px;
    margin: 20px 0;
    font-size: 13px;
    line-height: 1.8;
    /deep/ .up {
      margin: 0 2px;
      font-weight: bold;
      color: #fd6299;
    }
    /deep/ .down {
      margin: 0 2px;
      font-weight: bold;
      color: #71c1f5;
    }
    /deep/ .good {
      font-weight: bold;
      color: #fd6299;
    }
    /deep/ a {
      text-decoration: underline;
    }
    /deep/ b {
      font-weight: bold;
    }
  }
  .intro {
    text-align: center;
    margin: 40px 0;
    font-size: 14px;
    p {
      margin-bottom: 5px;
    }
  }
  .rating {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 20px 0;
    border: $tags-color-gray 1px solid;
    > div {
      text-align: center;
      width: 20%;
      border-left: $tags-color-gray 1px solid;
      &:first-child {
        border-left: none;
      }
    }
    .title {
      margin-bottom: 10px;
      font-size: 13px;
    }
    p {
      font-size: 12px;
    }
    .icon0,
    .icon1 {
      color: #fd6299;
    }
    .icon2,
    .icon3 {
      color: #71c1f5;
    }
  }

  .articles {
    margin-top: 64px;
    margin-bottom: 30px;
    .col {
      margin-bottom: 40px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        @include link-hover();
      }
    }
    .column-thumbnail {
      width: 100%;
      display: block;
    }
    .column-header-block {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #f6f6f8;
      width: 100%;
      height: auto;
      padding-top: 56.25%;
    }
    .column-footer-block {
      padding-top: 14px;
    }
    .title {
      margin-top: 9px;
      font-weight: bold;
    }
  }
}
</style>
