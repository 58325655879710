<i18n>
{
  "ja":{
    "同意する": "同意する"
  },
  "en":{
    "同意する": "Accept"
  }
}
</i18n>
<template lang="pug">
  .terms
    .common-h1-area
      HeadingBlock(level="1" text="Terms of Use")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") {{$t("利用規約")}}
    .sub-page-padding
      .container
        article.article-content
          TermsContent
      PrivacyPolicy

      .buttons.center.m-t-50
        button.middle-button-style.blue-button(@click="termsAccepted") {{$t("同意する")}}

</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import TermsContent from '@/components/TermsContent'
import PrivacyPolicy from '@/components/PrivacyPolicy'

export default {
  components: {
    HeadingBlock,
    TermsContent,
    PrivacyPolicy,
  },
  methods: {
    termsAccepted() {
      this.$store.dispatch('updateTermsVersion')
      this.$router.push({ name: 'signin' })
    },
  },
}
</script>

<style lang="scss" scoped>
.article-content {
  max-width: 800px;
  margin: 0 auto;
}
</style>
