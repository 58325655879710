import { registerables } from 'chart.js'

export default {
  color: {
    bar: 'rgb(0,64,152)',
    barActive: 'rgb(244,134,178)',
    line: '#CAB77D',
    fill: 'rgba(0,64,152,0.4)',
    fillActive: 'rgba(244,134,178,0.5)',
    base: '#f2f2f7',
  },
}
