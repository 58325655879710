<!--
「1-初回ログイン」と「2-個人メール登録」 完了画面
-->
<template lang="pug">
  .signin
    .common-h1-area
      HeadingBlock(level="1" text="Login")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") ログイン
    .sub-page-padding
      .container
        section
          .text(v-if="this.$i18n.locale === 'en'") We've sent you an email.<br>We've sent you a URL for setting a password. Please check the details and set one.
          .text(v-else) メールを送信しました。<br>パスワード設定のためのURLをお送りしましたので、内容をご確認のうえ、パスワードの設定をお願いします。
          .text(v-if="this.$i18n.locale === 'en'") Please log in from the login screen using the account you entered the first time and the password you set.
          .text(v-else) ログイン画面より初回に入力したアカウントと設定したパスワードでログインしてください。
          router-link.large-button-style.blue-button(:to="{ name: 'signin' }") {{$t("ログイン")}}


</template>
<script>
import HeadingBlock from '@/components/HeadingBlock'
export default {
  components: {
    HeadingBlock,
  },
}
</script>
