import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const libraryRepository = RepositoryFactory.get('library')

const defaultState = () => ({
  items: [],
})

const state = defaultState()

const getters = {}

const mutations = {
  setItems(state, payload) {
    state.items = payload
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems({ state, commit }) {
    try {
      const { succeed, reportArticles } = await libraryRepository.getRanking()
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', reportArticles)
      }
    } catch (error) {
      //
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
