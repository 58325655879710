<template lang="pug">
.faq
  .common-h1-area
    HeadingBlock(level="1" text="FAQ")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") {{$t("よくある質問")}}
  .sub-page-padding
    .container
      article.article-content
        ul.toc
          li(v-for="category in faqData[locale]" :key="category.id")
            a(href="#" v-scroll-to="'#a' + category.id") {{category.title}}
      article.article-content(v-for="category in faqData[locale]" :key="category.id")
        div.h2(:id="'a'+category.id")
          h2 {{category.title}}
        AccordionMenu(v-show="isOpen(item)" v-for="item in category.content" :key="item.id" :id="'a'+item.id")
          template(slot="title") {{item.title}}
          template(slot="content")
            div(v-html="item.html")
</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import AccordionMenu from '@/components/AccordionMenu'
import moment from 'moment-timezone'
import faqData from '@/const/faq'

export default {
  name: 'faq',
  components: {
    HeadingBlock,
    AccordionMenu,
  },
  data() {
    return { faqData: faqData }
  },
  computed: {
    locale() {
      return this.$i18n.locale === 'en' ? 'en' : 'ja'
    },
  },
  methods: {
    // 掲載期間のある記事の掲載可否を判定する
    isOpen(item) {
      let open = true
      if (item.date) {
        const now = moment()

        if (item.date.from) {
          const from = moment(item.date.from + ' 00:00:00')
          // 掲載開始日の00:00:00より前ならfalse
          if (now.isBefore(from)) {
            open = false
          }
        }
        if (item.date.to) {
          const to = moment(item.date.to + ' 00:00:00').add(1, 'days')
          // 掲載終了日の翌日00:00:00以後ならfalse
          if (now.isSameOrAfter(to)) {
            open = false
          }
        }
      }
      return open
    },
    scrollToHash() {
      const hash = this.$route.hash
      if (hash && hash.match(/^#.+$/)) {
        this.$scrollTo(hash)
      }
    },
  },
  /*
  mounted() {
    //this.scrollToHash()
  },
  updated() {
    this.scrollToHash()
  },
  */
}
</script>

<style lang="scss">
.faq {
  .article-content:first-child {
    margin-bottom: 40px;
  }
  .article-content {
    max-width: 800px;
    margin: 0 auto 80px;
    font-size: 14px;
    .toc {
      border: $tags-color-gray 1px solid;
      padding: 20px;
      list-style-type: none;
      columns: 2;
      @include sp-layout {
        columns: 1;
      }

      li {
        line-height: 2;
        &::before {
          content: '\25BC';
          margin-right: 6px;
          color: $base-brand-color;
        }
      }
    }
    a {
      display: inline;
    }
    h2 {
      font-size: 16px;
      margin-bottom: 30px;
    }
    // アンカーリンク対策。pc/spのヘッダー高さに応じてpadding等を調整
    .h2::before,
    .accordion::before {
      display: block;
      height: 150px;
      margin-top: -150px;
      content: '';
      @include sp-layout {
        height: 60px;
        margin-top: -60px;
      }
    }

    h3 {
      font-size: 16px;
    }
    h4 {
      margin-top: 16px;
      font-weight: bold;
    }
    ul {
      list-style-type: disc;
      padding-left: 1em;
    }
    ol {
      list-style-type: decimal;
      padding-left: 1em;
      margin: 10px 0;
    }
    .col2 {
      display: flex;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-30 {
      margin-top: 30px;
    }
    .mb-20 {
      margin-bottom: 20px;
    }
    .mb-30 {
      margin-bottom: 30px;
    }
  }
}
</style>
