<i18n>
{
  "ja": {
    "体重": "体重",
    "スコア":"スコア"
  },
  "en": {
    "体重": "Weight",
    "スコア":"Score"
  }
}
</i18n>
<template lang="pug">
  .ScoreChart
    canvas(ref="chart")
</template>
<script>
import Chart from 'chart.js/auto'
import style from './style.js'

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        drawTicks: false,
        drawOnChartArea: false,
      },
      ticks: {
        padding: 8,
      },
    },
    y1: {
      display: true,
      type: 'linear',
      min: 50,
      max: 80,
      position: 'right',
      ticks: {
        padding: 8,
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
        drawOnChartArea: false,
      },
    },
    y2: {
      beginAtZero: true,
      min: 0,
      max: 100,
      type: 'linear',
      display: true,
      ticks: {
        padding: 8,
        stepSize: 20,
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
        drawOnChartArea: true,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        pointStyle: 'rect',
        font: {
          size: 12,
        },
      },
    },
  },
}
export default {
  name: 'ScoreChart',
  props: ['weights', 'scores', 'labels', 'isLarge'],
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    propsToWatch() {
      return [this.weights, this.scores, this.labels]
    },
  },
  watch: {
    propsToWatch: function(val) {
      this.setWeightScale()
      this.chart.data = this.getData(...val)
      this.chart.update()
    },
  },
  methods: {
    getData(weights, scores, labels, hasPadding) {
      // 左右にダミーの棒グラフを追加する場合に色がずれないようにする
      const bgColor = hasPadding
        ? [
            style.color.bar,
            style.color.bar,
            style.color.bar,
            style.color.bar,
            style.color.barActive,
            style.color.bar,
          ]
        : [
            style.color.bar,
            style.color.bar,
            style.color.bar,
            style.color.barActive,
          ]

      return {
        datasets: [
          {
            type: 'line',
            label: this.$i18n.t('体重'),
            data: weights,
            backgroundColor: style.color.line,
            borderColor: style.color.line,
            borderWidth: 3,
            pointRadius: function() {
              return window.innerWidth > 1000 ? 5 : 4
            },
            pointBorderColor: '#fff',
            pointBorderWidth: 1,
            yAxisID: 'y1',
          },
          {
            type: 'bar',
            label: this.$i18n.t('スコア'),
            data: scores,
            backgroundColor: bgColor,
            barPercentage: 0.5, // バーの太さ
            //categoryPercentage: 0.5,
            borderRadius: 4,
            yAxisID: 'y2',
          },
        ],
        labels: labels,
      }
    },
    setWeightScale() {
      // weightはnullがありうる
      const availableWeights = this.weights.filter(v => {
        return v != null
      })
      if (availableWeights.length === 0) {
        // 全部nullの場合、目盛りごと消す
        options.scales.y1.display = false
      } else {
        // 最小値と最大値
        options.scales.y1.min = Math.min(...availableWeights) - 1
        options.scales.y1.max = Math.max(...availableWeights) + 1
      }
    },
    // グラフの左右に余白を作るためにデータとラベルの前後にダミーデータを入れる
    addDataPadding() {
      // deep copy
      const labels = JSON.parse(JSON.stringify(this.labels))
      const weights = JSON.parse(JSON.stringify(this.weights))
      const scores = JSON.parse(JSON.stringify(this.scores))

      // 前後にダミーデータを追加
      labels.unshift('')
      weights.unshift(null)
      scores.unshift(null)
      labels.push('')
      weights.push(null)
      scores.push(null)
      return [weights, scores, labels]
    },
  },

  mounted() {
    this.setWeightScale()
    let params = [this.weights, this.scores, this.labels]
    const hasPadding = this.isLarge && window.innerWidth > 768
    if (hasPadding) {
      params = this.addDataPadding()
    }
    const data = this.getData(...params, hasPadding)
    const ctx = this.$refs.chart
    this.chart = new Chart(ctx, {
      data,
      options,
    })
  },
}
</script>
