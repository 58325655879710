import announcementRepository from './announcementRepository'
import eventRepository from './eventRepository'
import libraryRepository from './libraryRepository'
import surveyRepository from './surveyRepository'
import userRepository from './userRepository'

const repositories = {
  announcement: announcementRepository,
  event: eventRepository,
  library: libraryRepository,
  survey: surveyRepository,
  user: userRepository,
}

export const RepositoryFactory = {
  get: name => repositories[name],
}
