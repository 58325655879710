<template lang="pug">
  div
    textarea(v-model="input" @change="sendData")
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      input: [],
    }
  },
  methods: {
    sendData() {
      this.$emit('getData', {
        index: this.item.questionnaireIndex,
        value: this.input,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  margin: 0.8em 0 0 0;
}

textarea {
  width: 100%;
  height: 8em;
  border-color: $base-bg-gray-color;
  background: $base-bg-gray-color;
  border-radius: 3px;
}
</style>
