<template lang="pug">
  ul.events-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
    li.col(v-for="event in parsedData")
      .favButton(@click.stop="toggleFavorite(event)" :class="event.isFavoritedCopy ? 'favButtonOn':'favButtonOff'")
      router-link(:to="{name: 'event', params: {id: event.id}}")
        .event-header-block(:style="{backgroundImage: 'url(' + event.thumbnailUrl + ')'}")
        .event-footer-block
          //- 残席わずか/締切間近/Pick Up!
          ul.common-tags-lists(v-if="event.advertising")
            li.common-tags-list.common-caution-tags {{$t(event.advertising)}}
          h2.title {{ event.title }}
          .date {{ event.dateStr }}
          .place
            span {{ event.place }}
            span(v-if="event.providingType == 3") &nbsp;/&nbsp;{{$t("オンライン")}}
          //- 予約開始前/予約受付中/予約終了/満席
          .common-tags-lists.reserveStatus(v-if="event.reserveStatus")
            .common-tags-list(:class="event.reserveStatus.status.name") {{$t(event.reserveStatus.status.text)}}
            .reserveStatusReserved(v-if="event.isReserved") {{$t('予約済')}}
            .reserveDate(v-else) {{event.reserveStatus.date}}

</template>
<script>
import moment from 'moment-timezone'
import mixinEvent from '@/mixins/event.js'

export default {
  name: 'Events',
  props: ['events'],
  mixins: [mixinEvent],
  data() {
    return {
      parsedData: [],
    }
  },
  methods: {
    init() {
      this.events = this.$props.events
      this.parse()
    },
    parse() {
      const now = moment().valueOf()
      this.parsedData = this.events.map(event =>
        this.parseEventData(event, now)
      )
    },
  },
  watch: {
    events() {
      this.init()
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_events.scss';
</style>
