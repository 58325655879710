import Repository from './Repository'

export default {
  get() {
    return Repository('getQuestionnaires')
  },
  getPost(questionnaireID) {
    return Repository('getQuestionnaireById', {
      questionnaireID: questionnaireID,
    })
  },
  post(questionnaireID, isGeneral, body) {
    return Repository('answerQuestionnaire', {
      questionnaireID: questionnaireID,
      isGeneral: isGeneral,
      body: body,
    })
  },
}
