<template lang="pug">
  .container
    section
      .text(v-if="this.$i18n.locale === 'en'") Please confirm the registration information.
      .text(v-else) アカウント作成の登録情報を確認してください。
      form(v-on:submit.prevent="signup")
        ._item(v-for="(item, key) in formSettings")
          label(:class="{'required': item.required}") {{$t(item.label)}}
          div {{accountModel[key]}}
        .error(v-if="formError") {{formError}}
        .confirm-message
          .text(v-if="this.$i18n.locale === 'en'") An email will be sent to you, please set your password.<br>After setting your password, please enter your email address and password from the login screen to log in.
          .text(v-else) メールが送信されますのでパスワードの設定をお願いします。<br>パスワード設定完了後、ログイン画面からメールアドレス・パスワードを入力してログインしてください。
        ._action
          a.large-button-style.button-cancel(@click="$router.back()") {{$t('修正する')}}
          button.large-button-style(type="submit") {{$t('送信する')}}

</template>

<script>
import formSettings from './formSettings'

export default {
  name: 'SignupConfirm',
  props: {
    accountModel: {},
  },
  data() {
    return {
      formSettings: {},
      formError: '',
    }
  },
  methods: {
    signup() {
      // https://kii.backlog.com/git/MF/BucketDesignDoc/blob/phase-16/ServerCode/createUserByInvitationCode.md
      const args = this.accountModel
      this.api('createUserByInvitationCode', args, rv => {
        console.log('createUserByInvitationCode', rv)
        if (rv.errorCode === 'validationError') {
          this.formError = rv.validationErrorMessage[0]
        } else {
          // 完了画面を開く
          this.$router.push({ name: 'signup-complete' })
        }
      })
      // this.$router.push({ name: 'signup-complete' })
    },
  },
  mounted() {
    this.formSettings = formSettings
  },
}
</script>

<style scoped lang="scss">
._action {
  display: flex;
  align-items: center;
  gap: 2em;
}
.button-cancel {
  background: #666;
}
.confirm-message {
  margin: 4em 0;
}
</style>
