<template lang="pug">
  .Doughnut
    canvas(ref="chart")
</template>
<script>
import Chart from 'chart.js/auto'
import style from './style.js'

const getData = value => {
  return {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [style.color.barActive, style.color.base],
        hoverBackgroundColor: [style.color.barActive, style.color.base],
      },
    ],
  }
}
const options = {
  responsive: true,
  cutout: '80%',
  elements: {
    arc: {
      borderColor: style.color.base,
      borderWidth: 0.5,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
}
export default {
  name: 'Doughnut',
  props: ['value'],
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    value: function(value) {
      this.chart.data = getData(value)
      this.chart.update()
    },
  },
  mounted() {
    const ctx = this.$refs.chart
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: getData(this.value),
      options: options,
    })
  },
}
</script>
