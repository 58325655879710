<template lang="pug">
//- 「学ぶ」新着一覧
.libraries
  .tag-h1-area
    .container
      h1._mainttl {{$t("新着")}}
  .sub-page-padding
    .container
      ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
        //- Servercodeの仕様で10件固定
        li.col(v-for="item,index in libraries" v-if="index < 9") 
          router-link(:to="{name: 'library', params: {id: item.id}}")
            .column-header-block(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
            .column-footer-block
              ul.common-tags-lists
                //- li.common-tags-list.isRead(v-if="item.isRead") {{$t("既読")}}
                li.common-tags-list.common-tags-blue.common-tags-color-bg(v-if="item.relType!='ReportArticles'") {{$t(item.relType)}}
              h2.title {{ item.title }}
</template>

<script>
import { mapState } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'
export default {
  name: 'Libraries',
  components: {
    HeadingBlock,
  },
  data() {
    return {
      perPage: 9,
    }
  },
  watch: {
    locale() {
      this.$store.dispatch('libraryNew/getItems', {
        perPage: this.perPage,
      })
    },
  },
  computed: {
    ...mapState('libraryNew', { libraries: 'items' }),
    ...mapState(['locale']),
  },
  created() {
    if (!this.libraries.length) {
      this.$store.dispatch('libraryNew/getItems', {
        perPage: this.perPage,
      })
    }
  },
}
</script>

<style scoped lang="scss">
.columns-block-list {
  margin-top: 32px;
  margin-bottom: 30px;
  > li {
    margin-bottom: 40px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include link-hover();
    }
  }
  .column-thumbnail {
    width: 100%;
    display: block;
  }
  .column-header-block {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f8;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }
  .column-footer-block {
    padding-top: 14px;
  }
  .title {
    margin-top: 9px;
    font-weight: bold;
  }
}
</style>
