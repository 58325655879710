<template lang="pug">
.announcement
  .sub-page-padding
    .container
      article.article-content
        .announcement-head-block
          span.important-tag(v-if="announcement.isImportant") {{$t("重要")}}
          br
          span.time
            time {{announcement.fromDateTime | moment}}
        h1 {{announcement.title}}
        
        ImageViewerWithThumbnail(:images="announcement.images" :uid="announcement.id")

        template(v-if="!$store.state.user.authenticated")
          LoginRequired

        template(v-else)
          SectionContents(:sections="announcement.sections")

          ShareButton(:fullPath="$route.fullPath", :title="announcement.title")

</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import LoginRequired from '@/components/LoginRequired'
import ImageViewerWithThumbnail from '@/components/ImageViewerWithThumbnail'
import SectionContents from '@/components/SectionContents'
import ShareButton from '@/components/ShareButton'
import mixinMetaTag from '@/mixins/metaTag.js'

export default {
  components: {
    HeadingBlock,
    LoginRequired,
    ImageViewerWithThumbnail,
    SectionContents,
    ShareButton,
  },
  mixins: [mixinMetaTag],
  data() {
    return {
      announcement: {},
    }
  },
  methods: {
    display(id) {
      this.api('getAnnouncementById', { id }, rv => {
        if (!rv || !rv.succeed) {
          this.$router.push({
            path: '/notFound',
          })
        } else {
          this.announcement = rv.announcement
          this.setArticleTitleAsMeta(this.announcement.title)
          this.setArticleDescriptionAsMeta(this.announcement.sections)
        }
      })
    },
  },
  created() {
    // 既読フラグを更新したいので一覧のキャッシュを削除
    this.$store.dispatch('announcement/resetItems')
    this.display(this.$route.params.id)
  },
  beforeRouteUpdate(to, from, next) {
    // URL の id が変わったときは再描画する
    this.display(to.params.id)
    next()
  },
}
</script>

<style lang="scss">
.announcement {
  .article-content {
    max-width: 800px;
    margin: 0 auto;
  }
  .important-tag {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(236, 53, 126);
    border-radius: 4px;
    padding: 4px 7px;
    margin-right: 12px;
    margin-bottom: 10px;
    @include sp-layout {
      font-size: 12px;
      padding: 3px 6px;
    }
  }
}
.announcement-head-block {
  margin-bottom: 15px;
}
</style>
