<template lang="pug">
  .click-thumbnail-lists(v-if="images && images[0]")
    div.display-thumbnail.row
      img(:src="selectedImageUrl") 
    ul.display-thumbnail-lists.row.row-cols-4.row-cols-md-5(v-if="images.length>1")
      li(v-for="(image, imageIndex) in images" :key="image.id")
        input(type="radio" :name="'click-thumbnail' + uid" :id="image.id" :value="image.url" v-model="selectedImageUrl")
        label.link-hover(:for="image.id")
          img(:src="image.url")

</template>
<script>
export default {
  props: ['images', 'uid'],
  data() {
    return {
      selectedImageUrl: '',
    }
  },
  methods: {
    init() {
      if (this.$props.images && this.$props.images[0]) {
        console.log(this.$props.uid, this.$props.images)
        this.selectedImageUrl = this.$props.images[0].url
      }
    },
  },
  watch: {
    images() {
      this.init()
    },
  },
  created() {
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.click-thumbnail-lists {
  margin-bottom: 35px;
}
</style>
