<i18n>
{
  "ja": {
    "メールアドレスまたはアカウント": "メールアドレスまたはアカウント",
    "パスワードをお忘れの方": "パスワードをお忘れの方"
  },
  "en": {
    "メールアドレスまたはアカウント": "Email address or account",
    "パスワードをお忘れの方": "Forgot your password?"
  }
}
</i18n>
<template lang="pug">
  .signin
    .common-h1-area
      HeadingBlock(level="1" text="Login")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") ログイン
    .sub-page-padding
      .container
        section
          .text(v-if="this.$i18n.locale === 'en'") If you've already signed up, log in here.<br>Log in here even if you don't have an email address. (a string of characters assigned by your company)
          .text(v-else) 登録済みの方はこちらからログインしてください。<br>メールアドレスがない方（会社で割り振られた文字列）もこちらからログインしてください。
          form.login-form-block(v-on:submit.prevent="signinByIdPass")
            div
              input(id="userid" type="text" ref="userid" :placeholder="$t('メールアドレスまたはアカウント')" v-model="userModel.id")
            div
              input(id="password" type="password" :placeholder="$t('パスワード')" v-model="userModel.password")
            .error(v-if="loginErrorText") {{loginErrorText}}
            p.forgot-password
              router-link(:to="{ name: 'resetPassword' }") {{$t('パスワードをお忘れの方')}}
            button.large-button-style.blue-button(type="submit") {{$t('ログイン')}}
        // - 1-初回ログイン
        section.requestResetPassword
          .text(v-if="this.$i18n.locale === 'en'") If you're logging in for the first time, enter your company email address here.<br>We'll email you a URL for setting a password.
          .text(v-else) 初めてログインする方はこちらに、企業で登録しているメールアドレスを入力してください。<br>メールにてパスワード設定のためのURLをお送りします。
          ResetPassword(next="registerMailAddressRequested")
        
        section.requestResetPassword
          .text 外部サービスのアカウントをお持ちの方で、初めてログインする場合は、こちらからお進みください
          router-link.large-button-style.blue-button(:to="{name: 'signup'}") アカウント作成

        section.manual
          .login-manual(v-if="this.$i18n.locale === 'en'")
            ._ttl
              span &well App Login Manual
            ul
              li PDF： 
                a.link(href="/files/2024/download_and_login.pdf" target="_blank") 日本語
                |  ｜ 
                a.link(href="/files/2024/download_and_login_en.pdf" target="_blank") ENGLISH
              li MOVIE： 
                a.link(href="https://vimeo.com/600026456" target="_blank") iPhone (iOS)
                |  ｜ 
                a.link(href="https://vimeo.com/600026438" target="_blank") Android
          .login-manual(v-else)
            ._ttl
              span &wellアプリログインマニュアル
            ul
              li PDF：
                a.link(href="/files/2024/download_and_login.pdf" target="_blank") 日本語
                |  ｜ 
                a.link(href="/files/2024/download_and_login_en.pdf" target="_blank") ENGLISH
              li 動画： 
                a.link(href="https://vimeo.com/600026456" target="_blank") iPhone用 (iOS)
                |  ｜ 
                a.link(href="https://vimeo.com/600026438" target="_blank") Android用

</template>
<script>
import mixinUser from '@/mixins/user.js'
import HeadingBlock from '@/components/HeadingBlock'
import ResetPassword from '@/components/ResetPassword'

export default {
  name: 'Signin',
  mixins: [mixinUser],
  components: {
    HeadingBlock,
    ResetPassword,
  },
  data() {
    return {
      userModel: {
        id: '',
        password: '',
      },
      mailaddressModel: '',
      loginErrorText: '',
      mailErrorText: '',
    }
  },
  methods: {
    signinByIdPass() {
      this.loginErrorText = ''
      const id = this.userModel.id
      const password = this.userModel.password
      if (!id) {
        this.loginErrorText = this.$i18n.t('メールアドレスが入力されていません')
        return
      }
      if (!password) {
        this.loginErrorText = this.$i18n.t('パスワードが入力されていません')
        return
      }

      this.$store.dispatch('signin', { id, password }).then((user) => {
        if (user) {
          // メールアドレスが登録済：ログイン成功、元居たページへ遷移
          // メールアドレスが未登録：メールアドレス登録へ
          const mailAddress = user ? user.getEmailAddress() : ''
          console.log(user, mailAddress)
          if (mailAddress) {
            this.signinSucceeded()
          } else {
            this.requireMailAddress()
          }
        } else {
          this.loginErrorText = this.$i18n.t(
            'メールアドレスまたはパスワードが違います'
          )
          return
        }
      })
    },
    signinSucceeded() {
      // ログイン成功、元居たページへ遷移
      let fullPath = this.loadRouteBeforeSignin()

      // 元居たページがパスワード忘れなど不適切なページの場合はトップへ
      const ngList = [
        'signin',
        'terms',
        'resetPassword',
        'registerMailAddress',
        'resetPasswordRequested',
        'registerMailAddressRequested',
        'signup',
      ]
      let matched = ngList.find((v) => fullPath.startsWith('/' + v))
      if (matched) {
        fullPath = '/'
      }
      this.$router.push({ path: fullPath })
    },
    requireMailAddress() {
      // メールアドレス登録画面へ
      this.$router.push({ name: 'registerMailAddress' })
    },
  },
  mounted() {
    this.$refs.userid.focus()
    console.log(this.$route.fullPath)
  },
}
</script>
<style scoped lang="scss">
.signin {
  .container {
    input {
      &#userid {
        border-radius: 4px;
        margin-bottom: 8px;
      }
      &#password {
        border-radius: 4px;
      }
    }
  }
  .forgot-password {
    font-size: 13px;
    color: $gray-color;
    text-decoration: underline;
    text-align: center;
    margin-top: 30px;
  }
  .requestResetPassword {
    margin-top: 4em;
    border: #eee 1px solid;
    padding: 40px;
  }
}
.large-button-style {
  font-size: 13.3px;
}
.manual {
  margin-top: 70px;
}
.login-manual {
  position: relative;
  border: 3px solid #eee;
  padding: 30px 75px 25px;
  @include sp-layout {
    padding: 30px 40px 25px;
  }
  ._ttl {
    font-weight: bold;
    position: absolute;
    top: -1em;
    left: 0;
    right: 0;
    display: inline-flex;
    justify-content: center;
    span {
      background-color: #fff;
      padding: 0 25px;
      letter-spacing: 0.7px;
    }
  }
  .link {
    text-decoration: underline;
  }
}
</style>
