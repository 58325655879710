<i18n>
{
  "ja":{
    "現在実施中のアンケートはありません": "現在実施中のアンケートはありません"
  },
  "en":{
    "現在実施中のアンケートはありません": "There are no surveys currently being conducted."
  }
}
</i18n>
<template lang="pug">
  div
    .common-h1-area
      HeadingBlock(level="1" text="Surveys")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") アンケート
      p.heading-block-note {{$t("法人契約でご利用の方以外は閲覧できないコンテンツがあります")}}
    .sub-page-padding
      .container
        ul.list(v-if="surveys && surveys.length")
          li(v-for="item in surveys")
            router-link.survey-link(:to="{name: 'survey', params: {id: item.id}}" :class="{ answeredLink: item.answered }")
              .title-line
                p.title {{ item.title }}
                span.isPosted(v-if="item.answered") {{$t("回答済")}}
              .date
                //- span.period {{$t("回答期限")}} アプリ版に当該箇所や翻訳も存在しない為コメントアウト
                p
                  span {{ item.fromDateTime | moment('ll') }}~{{ item.toDateTime | moment('ll') }} 
                  span {{$t("まで")}}
        .empty(v-else) {{$t("現在実施中のアンケートはありません")}}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'
export default {
  data() {
    return {
      perPage: 50,
    }
  },
  components: {
    HeadingBlock,
  },
  watch: {
    locale() {
      this.$store.dispatch('survey/getItems', { perPage: this.perPage })
    },
  },
  computed: {
    more() {
      return this.length === this.perPage
    },
    ...mapState('survey', { surveys: 'items' }),
    ...mapState('survey', { length: 'length' }),
    ...mapState(['locale']),
  },
  methods: {
    readMore() {
      this.$store.commit('announcement/incrementPage')
      this.$store.dispatch('announcement/getItems', this.perPage)
    },
  },
  created() {
    if (!this.surveys.length) {
      this.$store.dispatch('survey/getItems', { perPage: this.perPage })
    }
  },
}
</script>

<style scoped lang="scss">
.list {
  padding: 0 140px;
  margin-bottom: 55px;
  @include sp-layout {
    padding: 0;
  }

  > li {
    border-bottom: 1px solid #e6e6e6;

    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      line-height: 1em;
      height: 1.1em;
      overflow: hidden;

      &:hover .title {
        text-decoration: underline;
      }
    }

    a.survey-link {
      display: flex;
      flex-direction: column;
      padding: 25px 0 30px;
      @include sp-layout {
        padding: 21px 0 18px;
      }
      &:hover {
        text-decoration: none;
        .title {
          text-decoration: underline;
        }
        .date {
          text-decoration: underline;
        }
      }
      &.answeredLink {
        pointer-events: none;
      }
    }
  }

  > li:last-child {
    border-bottom: none;
  }

  .date {
    margin-right: 60px;
    font-size: 13px;
  }

  .title-line {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    margin-bottom: 16px;
    @include sp-layout {
      margin-bottom: 12px;
    }
  }

  .isPosted {
    margin-left: 25px;
    white-space: nowrap;
    color: #fff;
    background-color: #e60012;
    border-radius: 3px;
    padding: 3px 17px 4px;
    font-size: 12px;
  }
  .period {
    background-color: #f6f6f8;
    font-size: 13px;
    padding: 6px 11px;
    margin-right: 10px;
    @include sp-layout {
      padding: 3px 9px;
    }
  }
}

.empty {
  margin: 40px 20px;
  text-align: center;
}

@media (max-width: 767px) {
  .list {
    > li {
      a {
        flex-direction: column;

        .date {
          margin-right: 0;
          margin-bottom: 7px;
          font-size: 13px;
        }

        .title-line {
          font-size: 14px;
        }
      }
    }
  }
  .isPosted.sp {
    display: inline !important;
    margin-left: 15px;
    font-size: 12px;
  }
}
</style>
