<template lang="pug">
//- イベント詳細ページ
.event
  Loading(v-if="isLoading")
  .sub-page-padding
    .container
      article.event-block.article-content(v-if="event")
        .left-col
          .event-head-block
            //- 残席わずか/締切間近/Pick Up!
            ul.common-tags-lists
              li.common-tags-list(v-if="event.advertising" class="common-caution-tags") {{$t(event.advertising)}}
              //- 予約開始前/予約受付中/予約終了/満席
              template(v-if="event.reserveStatus")
                li.common-tags-list(:class="event.reserveStatus.status.name") {{$t(event.reserveStatus.status.text)}}
                li.reserveStatusReserved(v-if="event.isReserved") {{$t('予約済')}}
            .favButton(@click="toggleFavorite(event)" :class="event.isFavoritedCopy ? 'favButtonOn':'favButtonOff'")

          h1 {{event.title}}

          ImageViewerWithThumbnail(:images="event.images" :uid="event.id")

          template(v-if="!$store.state.user.authenticated")
            LoginRequired

          template(v-else)
            .sp
              a._button(v-if="event.reservableFlag" href="#sessions") {{$t('参加する')}}

            SectionContents(:sections="event.sections")

            HeadingBlock(level="2" text="イベント概要")
            table.article-table
              tbody
                tr
                  template(v-if="(event.endDateTime - event.startDateTime) <= 86400000")
                    //- 1日開催の場合
                    th {{$t("開催日")}}
                    td
                      //- | {{ event.startDateTime | moment('YYYY.MM.DD(ddd) HH:mm') }}〜{{ event.endDateTime | moment('HH:mm') }}
                      | {{ event.startDateTime | moment('YYYY.MM.DD(ddd)') }}
                      br
                      | {{ event.dateRemarks }}
                  template(v-else)
                    //- 期間開催の場合
                    //- ※ event.endDateTimeは当日24:00:00(つまり翌日)を含むので-1して23:59:59.99..にしている 
                    th {{$t("開催期間")}}
                    td
                      | {{ event.startDateTime | moment('YYYY.MM.DD(ddd)') }}〜{{ event.endDateTime-1 | moment('YYYY.MM.DD(ddd)') }}
                      br
                      | {{ event.dateRemarks }}
                tr
                  th {{$t("会場")}}
                  td
                    span(v-if="event.providingType == 1") {{ event.place }}
                    span(v-if="event.providingType == 2") {{$t("オンライン")}}
                    span(v-if="event.providingType == 3") {{ event.place }} / {{$t("オンライン")}}
                tr
                  th {{$t("定員")}}
                  td(v-if="event.sessions && event.sessions[0] && event.sessions[0].capacity") {{event.sessions[0].capacity + $t("名様")}}
                  td(v-else) {{$t("制限なし")}} 
                tr
                  th {{$t("参加費")}}
                  td(v-if="event.price === 0") {{$t("無料")}}
                  td(v-else)
                    div {{ event.price.toLocaleString() }} {{$t("円")}}
                    //- div(v-if="!event.place") 
                    div(v-if="this.$i18n.locale === 'en'") Note: Please pay at the venue
                    div(v-else) ※ お支払いは会場でお願いします
                tr(v-if="event.extraText")
                  th {{ event.extraLabel }}
                  td {{ event.extraText }}
              
              //- ライブラリーへのリンク(ライブラリー側から当記事が関連イベントに指定されている場合リンクを表示)
            .reltype-link(v-if="event.reportArticleID")
              router-link(:to="{name: 'library', params: {id: event.reportArticleID}}")
                button.middle-button-style.blue-button.w-100 {{$t("ライブラリー")}}

        .right-col(id="sessions" v-if="event.sessions")
          Sessions(:event="event" @init="init" @showLoading="isLoading=true" @hideLoading="isLoading=false")

          .event-share-block
            ShareButton(:fullPath="$route.fullPath", :title="event.title")

</template>

<script>
import moment from 'moment-timezone'
import mixinMetaTag from '@/mixins/metaTag.js'
import mixinEvent from '@/mixins/event.js'

import HeadingBlock from '@/components/HeadingBlock'
import LoginRequired from '@/components/LoginRequired'
import ImageViewerWithThumbnail from '@/components/ImageViewerWithThumbnail'
import SectionContents from '@/components/SectionContents'
import ShareButton from '@/components/ShareButton'
import Sessions from '@/components/Event/Sessions'
import Loading from '@/components/Loading'

export default {
  components: {
    HeadingBlock,
    LoginRequired,
    ImageViewerWithThumbnail,
    SectionContents,
    ShareButton,
    Sessions,
    Loading,
  },
  mixins: [mixinMetaTag, mixinEvent],
  data() {
    return {
      event: null,
      eventId: this.$route.params.id,
      now: 0,
      isLoading: false,
    }
  },
  computed: {},
  methods: {
    init() {
      this.isLoading = true
      this.now = moment().valueOf()
      this.api('getEventByID', { eventID: this.eventId }, (rv) => {
        console.log(rv)
        if (!rv || !rv.succeed) {
          this.$router.push({
            path: '/notFound',
          })
        } else {
          this.event = this.parseEventData(rv.event, this.now)
          this.setArticleTitleAsMeta(this.event.title)
          this.setArticleDescriptionAsMeta(this.event.sections)
          // this.__addQuestionarieToResponse()
          this.isLoading = false
        }
      })
    },
    __addQuestionarieToResponse() {
      // TODO PH15: レスポンスにアンケートを追加
      this.event.hasQuestions = true
      this.event.questions = [
        {
          type: 'comment',
          question: '生活習慣について',
        },
        {
          type: 'images',
          images: [
            {
              id: 'XXXXXXXX1',
              url: 'https://placehold.jp/600x400.png?text=Image01',
            },
            {
              id: 'XXXXXXXX2',
              url: 'https://placehold.jp/600x400.png?text=Image02',
            },
          ],
        },
        {
          questionnaireIndex: 0,
          required: true,
          type: 'select',
          question: '運動はしていますか？',
          choices: ['定期的にしている', '時々している', 'していない'],
          choiceImages: [
            null,
            [
              {
                id: 'XXXXXXXX3',
                url: 'https://placehold.jp/600x400.png?text=Image03',
              },
            ],
            null,
          ],
        },
        {
          questionnaireIndex: 1,
          required: false,
          type: 'checkbox',
          question: '当てはまるものを全て選択して下さい',
          choices: ['飲酒する', '喫煙する', '寝不足である', ''],
        },
      ]
    },
  },
  created() {
    this.init()
    //this.test_parseSessions()

    //- イベント予約用関数
    //- this.recordParticipation('b00ec20d-e929-11eb-ac47-0af363eb5062', '1800')
  },
  beforeRouteUpdate(to, from, next) {
    // URL の id が変わったときは再描画する
    this.eventId = to.params.id
    this.init()
    next()
  },
}
</script>

<style lang="scss" scoped>
.event-block {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  @include sp-layout {
    flex-direction: column;
  }
  .event-head-block {
    position: relative;
    margin-bottom: 18px;
    @include sp-layout {
      margin-bottom: 15px;
    }
    .time {
      margin-right: 10px;
    }
  }
  .left-col {
    width: 720px;
    @include sp-layout {
      width: 100%;
    }
  }
  .right-col {
    width: 290px;
    @include sp-layout {
      width: 100%;
      padding-top: 56px;
    }
  }
  .single-article-table {
    margin-bottom: 40px;
    @include sp-layout {
      margin-bottom: 32px;
    }
  }
  .favButtonOn {
    background-image: url('../../assets/images/event/favorite_on.svg');
    background-size: 18px 24px;
  }
  .favButtonOff {
    background-image: url('../../assets/images/event/favorite_off.svg');
    background-size: 18px 24px;
  }

  .article-table {
    margin-bottom: 64px;
  }

  ._button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    width: 100%;
    background-color: $tags-color-blue;
    color: #fff;
    padding: 0 28px;
    border-radius: 24px;
    text-decoration: none;
    @include link-hover();
  }
}
</style>
