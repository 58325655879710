<template lang="pug">
  .announcement
    .sub-page-padding
      .container
        article.article-content
          //- pre {{ post }}
          h1 {{ post.title }}
          template(v-if="!$store.state.user.authenticated")
            LoginRequired.login-margin
          div.m-t-50.text-center(v-else-if="post.answered")
            //- 回答済みで且つ直接回答済みのアンケートURLを叩いて来た方に対して一覧に戻ってもらう為のリンク
            h3 {{$t("回答済")}}
            router-link.m-t-50(:to="{ name: 'surveys' }") {{$t("一覧に戻る")}}
          template(v-else-if="$store.state.user.authenticated")
            router-view(:post = 'post')
</template>

<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import LoginRequired from '@/components/LoginRequired'

const SurveyRepository = RepositoryFactory.get('survey')
export default {
  name: 'Survey',
  components: {
    LoginRequired,
  },
  data() {
    return {
      post: {},
    }
  },
  async created() {
    try {
      const data = await SurveyRepository.getPost(this.$route.params.id)
      if (!data.succeed) {
        this.$router.push({
          path: '/notFound',
        })
      } else {
        this.post = data
        this.lf2Br()
      }
    } catch (error) {
      //
    }
  },
  methods: {
    lf2Br() {
      this.post.body.forEach(({ question }, index) => {
        this.post.body[index].question = question.replace(/\n/g, '<br/>')
      })
    },
  },
}
</script>

<style lang="scss">
.login-required.login-margin {
  margin-top: 90px;
}
</style>
