<template lang="pug">
swiper(:options="computedSwiperOptions")
  swiper-slide.events-block-list(v-for="(img, index) in item.images" :key="img.id")
    img(:src="img.url")
  .swiper-pagination(slot="pagination")
</template>

<script>
export default {
  name: 'SlideImage',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      baseSwiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        spaceBetween: 20,
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: 5,
        breakpoints: {
          768: {
            spaceBetween: 32,
          },
        },
      },
    }
  },
  computed: {
    computedSwiperOptions() {
      // 画像が1枚のみの場合はスライドさせない
      if (this.item.images && this.item.images.length === 1) {
        return {
          ...this.baseSwiperOption,
          loop: false,
          allowSlideNext: false,
          allowSlidePrev: false,
          pagination: false,
        }
      }
      return this.baseSwiperOption
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0.8em 0 0 0;
}
img {
  width: 100%;
}
</style>
