<i18n>
{
  "ja": {
      "ホームへ": "ホームへ"
  },
  "en": {
      "ホームへ": "Home"
  }
}
</i18n>

<template lang="pug">
  .NotFound
    .common-h1-area
      HeadingBlock(level="1" text="Not Found")
    .sub-page-padding
      .container
        section
          .text(v-if="this.$i18n.locale === 'en'") Page Not Found
          .text(v-else) お探しのページは見つかりませんでした。
          div
            router-link.large-button-style.blue-button(to="/") {{$t("ホームへ")}}
</template>
<script>
import HeadingBlock from '@/components/HeadingBlock'
export default {
  components: {
    HeadingBlock,
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 540px;
  margin: 0 auto;
  @include sp-layout {
    width: 100%;
  }
  .text {
    text-align: center;
    margin-bottom: 5em;
    font-size: 13px;
  }
}
</style>
