<template lang="pug">
.search-word
  .click-area(@click="openModal")
    font-awesome-icon.loupe-icon(:icon="['fas', 'search']")
    .input-wrap
      input(v-model="placeholder" readonly)
  transition(name="fade")
    .modal(v-if="isModalOpen" :class="{ 'is-open': isModalOpen }")
      .overlay(@click.stop="closeModal")
      .content
        .close-button(@click.stop="closeModal")
        .content-wrap
          h4.ttl 人気ワード
          ul.tag-list
            li(v-for="tag in popularTags.all" :key="`all-${tag.id}`" @click="clickLink($event)")
              router-link(:to="{name: 'libraryTag', params: {id: tag.id}}") {{ tag.name }}

          ul.genre-list.row.row-cols-1.row-cols-sm-2
            li.genre-list__child.genre-list__1.col
              h4.ttl(@click="clickLink($event)")
                router-link(:to="{name: 'libraryTag', params: {id: 'genre1'}}")
                  img(src="@/assets/images/icon_tag_health.svg" alt="健康管理")
                  | {{$t("健康管理")}}
              ul.tag-list
                li(v-for="tag in popularTags.genre1" :key="`genre1-${tag.id}`" @click="clickLink($event)")
                  router-link(:to="{name: 'libraryTag', params: {id: tag.id}, query: {genre: 'genre1'} }") {{ tag.name }}
            li.genre-list__child.genre-list__2.col
              h4.ttl(@click="clickLink($event)")
                router-link(:to="{name: 'libraryTag', params: {id: 'genre2'}}")
                  img(src="@/assets/images/icon_tag_meals.svg" alt="食事")
                  | {{$t("食事")}}
              ul.tag-list
                li(v-for="tag in popularTags.genre2" :key="`genre2-${tag.id}`" @click="clickLink($event)")
                  router-link(:to="{name: 'libraryTag', params: {id: tag.id}, query: {genre: 'genre2'} }") {{ tag.name }}
            li.genre-list__child.genre-list__3.col
              h4.ttl(@click="clickLink($event)")
                router-link(:to="{name: 'libraryTag', params: {id: 'genre3'}}")
                  img(src="@/assets/images/icon_tag_relax.svg" alt="休息")
                  | {{$t("休息")}}
              ul.tag-list
                li(v-for="tag in popularTags.genre3" :key="`genre3-${tag.id}`" @click="clickLink($event)")
                  router-link(:to="{name: 'libraryTag', params: {id: tag.id}, query: {genre: 'genre3'} }") {{ tag.name }}
            li.genre-list__child.genre-list__4.col
              h4.ttl(@click="clickLink($event)")
                router-link(:to="{name: 'libraryTag', params: {id: 'genre4'}}")
                  img(src="@/assets/images/icon_tag_walklog.svg" alt="運動")
                  | {{$t("運動")}}
              ul.tag-list
                li(v-for="tag in popularTags.genre4" :key="`genre4-${tag.id}`" @click="clickLink($event)")
                  router-link(:to="{name: 'libraryTag', params: {id: tag.id}, query: {genre: 'genre4'} }") {{ tag.name }}
            li.genre-list__child.genre-list__5.col
              h4.ttl(@click="clickLink($event)")
                router-link(:to="{name: 'libraryTag', params: {id: 'genre5'}}")
                  img(src="@/assets/images/icon_tag_mentalhealth.png" alt="メンタルヘルス")
                  | {{$t("メンタルヘルス")}}
              ul.tag-list
                li(v-for="tag in popularTags.genre5" :key="`genre5-${tag.id}`" @click="clickLink($event)")
                  router-link(:to="{name: 'libraryTag', params: {id: tag.id}, query: {genre: 'genre5'} }") {{ tag.name }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SearchWord',
  props: {},
  data() {
    return {
      isModalOpen: false,
      placeholder: '',
      genre: {
        genre1: this.$t('健康管理'),
        genre2: this.$t('食事'),
        genre3: this.$t('休息'),
        genre4: this.$t('運動'),
        genre5: this.$t('メンタルヘルス'),
      },
    }
  },
  watch: {
    locale() {
      this.$store.dispatch('libraryPopularTags/getItems')
    },
    'tag.name': {
      handler(newVal) {
        if (newVal) {
          this.placeholder = this.getPlaceholder
        }
      },
    },
  },
  computed: {
    getPlaceholder() {
      if (this.$route.path === '/library') return '検索ワード'

      const genre = this.$route.query.genre
        ? this.genre[this.$route.query.genre]
        : ''
      return `${genre} ${this.tag.name || ''}` || '検索ワード'
    },
    ...mapState('libraryPopularTags', { popularTags: 'items' }),
    ...mapState('libraryTag', { tag: 'tag' }),
    ...mapState(['locale']),
  },
  methods: {
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
    clickLink($event) {
      const currentUrl = window.location.href
      const clickedHref = $event.target.closest('a').getAttribute('href')
      if (!currentUrl.endsWith(clickedHref)) {
        this.$emit('clickLink')
      }
      this.closeModal()
    },
  },
  created() {
    this.placeholder = this.getPlaceholder

    if (!this.popularTags) {
      this.$store.dispatch('libraryPopularTags/getItems')
    }
  },
}
</script>

<style lang="scss" scoped>
.search-word {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 16px;
  margin-bottom: -16px;
}
.click-area {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.input-wrap {
  position: relative;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #999999 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  input {
    display: block;
    width: 250px;
    padding: 6px 21px 6px 10px;
    border-radius: 6px;
    border: 1px solid #999999;
    font-size: 14px;
    cursor: pointer;
  }
}
.loupe-icon {
  font-size: 20px;
  margin-right: 6px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .ttl {
    font-weight: bold;
    display: inline-block;
    a {
      display: flex;
      align-items: center;
      padding: 6px 14px;
      border-radius: 6px;
      transition: background-color 0.2s;
      &:hover {
        background-color: #f2f2f7;
      }
    }
    img {
      width: 30px;
      margin-right: 8px;
      @include sp-layout {
        width: 23px;
      }
    }
  }
  &.is-open .overlay {
    pointer-events: auto;
    cursor: default;
  }
  .close-button {
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background: url('../../assets/images/icon_batsu.svg') no-repeat center;
    background-size: 45%;
    @include sp-layout {
      width: 45px;
      height: 45px;
    }
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1001;
    pointer-events: none;
  }
  .content {
    background-color: #fff;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 34px;
    z-index: 1002;
    width: 100%;
    max-width: 1080px;
    border-radius: 10px;
    cursor: default;
    max-height: 95%;
    overflow-y: scroll;
    @include sp-layout {
      max-width: 85%;
      padding: 28px;
    }
  }
  .genre-list {
    margin-top: 20px;
    @include sp-layout {
      margin-top: 8px;
    }
    .genre-list__child {
      margin-top: 40px;
      @include sp-layout {
        margin-top: 20px;
      }
    }
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 10px;
      margin-top: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      display: block;
      padding: 4px 24px;
      border-radius: 6px;
      background-color: #f2f2f7;
      @include sp-layout {
        font-size: 13px;
        padding: 4px 20px;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
