const { META } = require('@/assets/constants.json')

export default {
  methods: {
    setArticleTitleAsMeta(title) {
      const routeInstance = this.$route
      if (title && routeInstance.meta.title) {
        const setTitle =
          title + ' | ' + routeInstance.meta.title + ' | ' + META.TITLE
        document.title = setTitle
        document
          .querySelector("meta[property='og:title']")
          .setAttribute('content', setTitle)
        document
          .querySelector("meta[property='twitter:title']")
          .setAttribute('content', setTitle)
      }
    },
    setArticleDescriptionAsMeta(sections) {
      let desc = this._getDescriptionFromSectionContents(sections)
      if (!desc) return
      desc = desc.replace(/\n/g, '').substring(0, 150)
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', desc)
      document
        .querySelector("meta[property='og:description']")
        .setAttribute('content', desc)
      document
        .querySelector("meta[property='twitter:description']")
        .setAttribute('content', desc)
    },
    _getDescriptionFromSectionContents(sections) {
      let desc = ''
      const freeCommentSection = sections.find(
        section => section.sectionType === 2 // 0:サブタイトル 1: 2:フリーコメント
      )
      if (freeCommentSection) {
        desc = freeCommentSection.comment
      }
      return desc
    },
  },
}
