<template lang="pug">
.account-friends
  section.content-section.friend-list-section
    HeadingBlock(level="2" text="友達リスト")
    .content-section-inner
      ul.account-thumbnail-list(v-if="friends")
        li.include-image(v-for="friend in friends" :key="friend.id")
          router-link(:to="{name: 'friendProfile', params: {id: friend.user.userID} }")
            ._image(:style="[friend.user.imageUrl ? { backgroundImage: 'url(' + friend.user.imageUrl + ')' } : {'background-image': 'url(' + defaultThumbnailImage + ')'} ]")
            p {{ friend.user.displayName }}
      ul.account-thumbnail-list(v-else)
        li
          a(style="pointer-events:none;") {{$t("まだ友だちが登録されていません")}}
          div.space
      .events-readmore-block.center(v-if="friends" :class="{ 'hide' : nomore }")
        button.default-button-style.blue-button(@click="readMore") {{$t("もっと見る")}}
</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'

export default {
  name: 'AccountFriends',
  components: {
    HeadingBlock,
  },
  data() {
    return {
      defaultThumbnailImage: require('@/assets/images/icon_user.svg'),
      friends: [],
      offset: 0,
      perPage: 9,
      nomore: false,
    }
  },
  methods: {
    getOwnFriends() {
      this.api(
        'getOwnFriends',
        {
          offset: this.offset,
          perPage: this.perPage,
        },
        rv => {
          console.log(rv)
          this.friends = this.friends.concat(rv.friends)
          if (rv.friends && rv.friends.length < this.perPage) {
            this.nomore = true
          }
        }
      )
    },
    readMore() {
      this.offset += this.perPage
      this.getOwnFriends()
    },
  },
  created() {
    this.friends = []
    this.getOwnFriends()
  },
}
</script>

<style lang="scss" scoped>
.account-thumbnail-list {
  margin-top: 30px;
  li {
    position: relative;
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 28px 15px 28px 93px;
      @include link-hover();
    }
    & + li {
      border-top: 1px solid $tags-color-gray;
    }
    ._image {
      content: '';
      pointer-events: none;
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $base-bg-color;
      border: 1px solid #eee;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}
</style>
