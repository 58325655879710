<template lang="pug">
//- 「学ぶ」タグ一覧
.libraries
  .tag-h1-area
    .container
      h1._mainttl(v-if="genreTagID") {{$t("検索結果")}}
      h1._mainttl(v-else)= "#{{ this.tag.name }}"
  .container(v-if="genreTagID")
    SearchWord(@clickLink="clickSearchWordLink")
  .sub-page-padding
    .container
      nav.tag-nav-bar
        ul.tag-nav-bar-list(v-if="shouldDisplayNavBar")
          li(:class="{'current': $route.params.id == 'genre1'}"): router-link(:to="{ name: 'libraryTag', params: { id: 'genre1'} }" @click.prevent="checkLink('genre1')") {{$t("健康管理")}}
          li(:class="{'current': $route.params.id == 'genre2'}"): router-link(:to="{ name: 'libraryTag', params: { id: 'genre2'} }" @click.prevent="checkLink('genre2')") {{$t("食事")}}
          li(:class="{'current': $route.params.id == 'genre3'}"): router-link(:to="{ name: 'libraryTag', params: { id: 'genre3'} }" @click.prevent="checkLink('genre3')") {{$t("休息")}}
          li(:class="{'current': $route.params.id == 'genre4'}"): router-link(:to="{ name: 'libraryTag', params: { id: 'genre4'} }" @click.prevent="checkLink('genre4')") {{$t("運動")}}
          li(:class="{'current': $route.params.id == 'genre5'}"): router-link(:to="{ name: 'libraryTag', params: { id: 'genre5'} }" @click.prevent="checkLink('genre5')") {{$t("メンタルヘルス")}}
      .filter-sortby-wrapper
        .filter-select-block(@click="toggleSortbySelect()" :class="[ selectSortbyIsOpen ? 'open' : '' ]")
          span(v-if="this.sortBy == 'newer'") {{$t("公開日の新しい順")}}
          span(v-if="this.sortBy == 'older'") {{$t("公開日の古い順")}}
        .selectBoxOptions(v-show="selectSortbyIsOpen")
          div(@click="changeSortbyStatus('newer'); toggleSortbySelect()") {{$t("公開日の新しい順")}}
          div(@click="changeSortbyStatus('older'); toggleSortbySelect()") {{$t("公開日の古い順")}}
      ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
        li.col(v-for="item,index in libraries") 
          router-link(:to="{name: 'library', params: {id: item.id}}")
            .column-header-block(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
            .column-footer-block
              ul.common-tags-lists
                //- li.common-tags-list.isRead(v-if="item.isRead") {{$t("既読")}}
                //- li.common-tags-list.common-tags-blue.common-tags-color-bg(v-if="item.relType!='ReportArticles'") {{$t(item.relType)}}
              h2.title {{ item.title }}
      .events-readmore-block.center(:class="{ 'hide' : !more }")
        button.middle-button-style.blue-button(@click="readMore") {{$t("もっと見る")}}
</template>

<script>
import { mapState } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'
import mixinMetaTag from '@/mixins/metaTag.js'
import SearchWord from '@/components/Library/SearchWord'

export default {
  name: 'libraryTag',
  components: {
    HeadingBlock,
    SearchWord,
  },
  mixins: [mixinMetaTag],
  data() {
    return {
      perPage: 9,
      selectSortbyIsOpen: false,
      sortBy: 'newer',
    }
  },
  watch: {
    locale() {
      this.load()
    },
    $route: {
      handler() {
        this.reset()
      },
      deep: true,
    },
  },
  computed: {
    genreTagID() {
      return this.$route.query.genre || undefined
    },
    more() {
      return this.length === this.perPage
    },
    shouldDisplayNavBar() {
      const routeName = this.$route.name
      const routeParam = this.$route.params.id

      return (
        routeName === 'libraryTag' &&
        ['genre1', 'genre2', 'genre3', 'genre4', 'genre5'].includes(routeParam)
      )
    },
    ...mapState('libraryTag', { libraries: 'items' }),
    ...mapState('libraryTag', { length: 'length' }),
    ...mapState('libraryTag', { tag: 'tag' }),
    ...mapState(['locale']),
  },
  methods: {
    readMore() {
      this.$store.commit('libraryTag/incrementPage')
      this.load()
    },
    load() {
      this.$store.dispatch('libraryTag/getItems', {
        tagID: this.$route.params.id,
        genreTagID: this.genreTagID,
        sortBy: this.sortBy,
        perPage: this.perPage,
      })
      this.setArticleTitleAsMeta(this.tag.name)
    },
    reset() {
      this.$store.commit('libraryTag/resetItems')
      this.load()
    },
    clickSearchWordLink() {
      this.$store.commit('libraryTag/resetItems')
    },
    checkLink(genreId) {
      if (
        this.$route.name === 'libraryTag' &&
        this.$route.params.id === genreId
      ) {
        return
      } else {
        this.$router.push({ name: 'libraryTag', params: { id: genreId } })
      }
    },
    toggleSortbySelect() {
      this.selectSortbyIsOpen = !this.selectSortbyIsOpen
    },
    changeSortbyStatus(sortBy) {
      this.sortBy = sortBy
      this.$store.commit('libraryTag/resetItems')
      this.load()
    },
  },
  created() {
    if (!this.libraries.length) {
      console.log('created libraryTag')
      this.load()
    } else if (this.tag.id != this.$route.params.id) {
      this.$store.commit('libraryTag/resetItems')
      this.load()
    }
  },
  mounted() {
    this.setArticleTitleAsMeta(this.tag.name)
  },
}
</script>

<style scoped lang="scss">
.sub-page-padding {
  overflow: hidden;
}
.columns-block-list {
  margin-top: 32px;
  margin-bottom: 30px;
  > li {
    margin-bottom: 40px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include link-hover();
    }
  }
  .column-thumbnail {
    width: 100%;
    display: block;
  }
  .column-header-block {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f8;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }
  .column-footer-block {
    padding-top: 14px;
  }
  .title {
    margin-top: 9px;
    font-weight: bold;
  }
}

.filter-sortby-wrapper {
  width: 190px;
  height: 46px;
  position: relative;
  margin: 0 auto;
  @include sp-layout {
    width: 158px;
    height: 36px;
  }
  .filter-select-block {
    border: none;
    position: relative;
    cursor: pointer;
    span {
      margin-left: -6px;
    }
    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      right: 13px;
      top: 1px;
      bottom: 0;
      margin: auto;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5.5px 0 5.5px;
      border-color: #ffffff transparent transparent transparent;
      @include sp-layout {
        right: 11px;
      }
    }
  }
  .filter-select-block:focus {
    outline: none;
  }
  .filter-select-block,
  .filter-select-block option {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 28px;
    background-color: #004098;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    @include sp-layout {
      font-size: 13px;
    }
  }
  .selectBoxOptions {
    position: absolute;
    width: 156px;
    left: 50%;
    top: 40px;
    transform: translate(-50%, 0);
    z-index: 5;
    background: #fff;
    border: #004098 0px solid;
    border-width: 2px 2px 0;
    color: #004098;
    @include sp-layout {
      width: 138px;
      top: 32px;
    }

    div {
      padding: 4px 8px;
      text-align: center;
      border-bottom: #004098 2px solid;
      cursor: pointer;
    }
    div:hover {
      background: #004098;
      color: #fff;
    }
  }
}

.tag-nav-bar {
  margin-left: -15px;
  margin-right: -15px;
  .tag-nav-bar-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: center;
    @include sp-layout {
      justify-content: flex-start;
      padding-left: 18px;
      padding-right: 18px;
    }
    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px;
      background-color: rgb(246, 246, 248);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      @include sp-layout {
        font-size: 13px;
      }
      a {
        padding: 11px 20px;
        display: block;
        @include link-hover();
        @include sp-layout {
          padding: 9px 17px;
        }
      }
      &.current {
        background-color: #004098;
        pointer-events: none;
        a {
          color: #fff;
        }
      }
    }
    > li + li {
      margin-left: 12px;
      @include sp-layout {
        margin-left: 8px;
      }
    }
  }
}
</style>
