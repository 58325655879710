<template lang="pug">
  .HealthTable
    ._title
      ._cell: img(src="@/assets/images/selflog/steps.svg")
      ._cell: img(src="@/assets/images/selflog/exercise.svg")
      ._cell: img(src="@/assets/images/selflog/breakfast.svg")
      ._cell: img(src="@/assets/images/selflog/dinner.svg")
      ._cell: img(src="@/assets/images/selflog/eat.svg")
      ._cell: img(src="@/assets/images/selflog/drink.svg")
      ._cell: img(src="@/assets/images/selflog/sleep.svg")
      ._cell: img(src="@/assets/images/selflog/feeling.svg")
    ._tbody
      ._row
        ._col(v-for="steps in stepsData")
          div
            ._cell(v-if="steps" :class="'emotion emotion' + getEmotioconIdBySteps(steps.steps)")
            ._cell(v-else)
      ._row
        ._col(v-for="health in healthData")
          div
            ._cell(v-if="health" :class="'emotion emotion' + health.exercise")
            ._cell(v-else)
            ._cell(v-if="health" :class="'emotion emotion' + health.breakfast")
            ._cell(v-else)
            ._cell(v-if="health" :class="'emotion emotion' + health.nightMeal")
            ._cell(v-else)
            ._cell(v-if="health" :class="'emotion emotion' + health.overeating")
            ._cell(v-else)
            ._cell(v-if="health" :class="'emotion emotion' + health.overdrinking")
            ._cell(v-else)
            ._cell(v-if="health" :class="'emotion emotion' + health.sleepness")
            ._cell(v-else)
            ._cell(v-if="health" :class="'emotion emotion' + health.feeling")
            ._cell(v-else)
    ._borders
      ._cell
      ._cell
      ._cell
      ._cell
      ._cell
      ._cell
      ._cell

</template>
<script>
export default {
  name: 'HealthTable',
  props: {
    healthData: Array,
    stepsData: Array,
  },
  methods: {
    getEmotioconIdBySteps(steps) {
      let id = 0
      if (steps >= 12000) {
        id = 0 // 😆
      } else if (steps >= 8000) {
        id = 1 // 🙂
      } else if (steps >= 4000) {
        id = 2 // 😟
      } else {
        id = 3 // 😫
      }
      return id
    },
  },
}
</script>
<style scoped lang="scss">
.HealthTable {
  display: flex;

  ._cell {
    height: 40px;
    border-bottom: #e2e2e2 1px solid;
    border-right: #e2e2e2 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ._title {
    width: 11.75%; // (100%-6%)/8
    border-top: #e2e2e2 1px solid;
    @include sp-layout {
      width: 16%;
    }
    img {
      width: 34px;
      height: 34px;
    }
    div {
      background: $base-bg-gray-color;
    }
  }
  ._tbody {
    width: 82.25%; // (100%-6%)*7/8
    border-top: #e2e2e2 1px solid;
    @include sp-layout {
      width: 84%;
    }
    ._col {
      width: calc(100% / 7);
    }
  }
  ._row {
    display: flex;
  }
  ._borders {
    @include sp-layout {
      display: none;
    }
    width: 6%;
    border-top: #e2e2e2 1px solid;
    ._cell {
      border-right: none;
    }
  }
}

.emotion::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  @include sp-layout {
    width: 20px;
    height: 20px;
  }
}
.emotion0::after {
  // 😆
  background-image: url('../../assets/images/emoticon/0.svg');
}
.emotion1::after {
  // 🙂
  background-image: url('../../assets/images/emoticon/1.svg');
}
.emotion2::after {
  // 😟
  background-image: url('../../assets/images/emoticon/2.svg');
}
.emotion3::after {
  // 😫
  background-image: url('../../assets/images/emoticon/3.svg');
}
</style>
