<template lang="pug">
//- お知らせ一覧ページ
.announcements
  .common-h1-area
    HeadingBlock(level="1" text="Notifications")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") お知らせ
    p.heading-block-note {{$t("法人契約でご利用の方以外は閲覧できないコンテンツがあります")}}
  .sub-page-padding
    .container
      AnnouncementBlock(:perPage="10")
</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import AnnouncementBlock from '@/components/AnnouncementBlock'

export default {
  components: {
    HeadingBlock,
    AnnouncementBlock,
  },
  data() {
    return {
      announcements: [],
    }
  },
  methods: {},
  created() {},
}
</script>

<style lang="scss" scoped></style>
