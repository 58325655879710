import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/'

import Home from './views/Home'
import Announcement from './views/Announcement'
import Announcements from './views/Announcements'
import Library from './views/Library/Id'
import LibraryIndex from './views/Library/Index'
import LibraryNew from './views/Library/New'
import LibraryTag from './views/Library/Tag'
import LibraryRanking from './views/Library/Ranking'
import Event from './views/Event/Id'
//import Events from './views/Events'
import Events from './views/Event/Index'
import EventsReserved from './views/Event/Reserved'
import Terms from './views/Terms'
import Signin from './views/Signin'
import Signup from './views/Signup/Index'
import SignupForm from './views/Signup/Form'
import SignupConfirm from './views/Signup/Confirm'
import SignupComplete from './views/Signup/Complete'
import Surveys from './views/Survey/Index'
import Survey from './views/Survey/Id'
import SurveyForm from './views/Survey/Form'
import SurveyThanks from './views/Survey/Thanks'
import Account from './views/Account'
import AccountProfile from './components/Account/Profile'
import AccountFriends from './components/Account/Friends'
import AccountHealthStats from './components/Account/HealthStats'
import AccountFriendProfile from './views/AccountFriendProfile'
import NotFound from './views/NotFound'
import PrivacyPolicy from './views/PrivacyPolicy'
import Faq from './views/Faq'
import TermsOfService from './views/TermsOfService'
import WebsiteTermsOfUse from './views/WebsiteTermsOfUse'
import HowToUse from './views/HowToUse'
import ResetPassword from './views/ResetPassword'
import ResetPasswordRequested from './views/ResetPasswordRequested'
import RegisterMailAddress from './views/RegisterMailAddress'
import RegisterMailAddressRequested from './views/RegisterMailAddressRequested'
import Config from './views/Config'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'signin',
    component: Signin,
    meta: {
      title: 'ログイン',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のログインページです。',
    },
  },
  {
    path: '/signup',
    name: 'signup',
    redirect: '/signup/form',
    component: Signup,
    meta: {
      title: 'アカウント作成',
      desc: '| アカウント作成',
    },
    children: [
      {
        path: 'form',
        name: 'signup-form',
        component: SignupForm,
      },
      {
        path: 'confirm',
        name: 'signup-confirm',
        component: SignupConfirm,
        beforeEnter: (to, from, next) => {
          // 正常ルート以外のアクセスを禁止
          if (from.name !== 'signup-form') next({ name: 'signup-form' })
          else next()
        },
      },
      {
        path: 'complete',
        name: 'signup-complete',
        component: SignupComplete,
        beforeEnter: (to, from, next) => {
          // 正常ルート以外のアクセスを禁止
          if (from.name !== 'signup-confirm') next({ name: 'signup-form' })
          else next()
        },
      },
    ]
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      title: '利用規約',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の利用規約です。',
    },
  },
  {
    path: '/notifications',
    name: 'announcements',
    component: Announcements,
    meta: {
      title: 'お知らせ',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のお知らせ一覧です',
    },
  },
  {
    path: '/notifications/:id',
    name: 'announcement',
    component: Announcement,
    meta: {
      title: 'お知らせ',
      //desc: ' | お知らせ',
    },
  },
  {
    path: '/library',
    name: 'libraries',
    component: LibraryIndex,
    meta: {
      title: '学ぶ',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の「学ぶ」ページです',
    },
  },
  {
    path: '/library/new',
    name: 'libraryNew',
    component: LibraryNew,
    meta: {
      title: '新着 | 学ぶ',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の「学ぶ」の新着記事一覧です',
    },
  },
  {
    path: '/library/ranking',
    name: 'libraryRanking',
    component: LibraryRanking,
    meta: {
      title: 'ランキング | 学ぶ',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の「学ぶ」の記事ランキングです',
    },
  },
  {
    path: '/library/tag/:id',
    name: 'libraryTag',
    component: LibraryTag,
    meta: {
      title: '学ぶ',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の「学ぶ」のタグ一覧です',
    },
  },
  {
    path: '/library/:id',
    name: 'library',
    component: Library,
    meta: {
      title: 'ライブラリー',
      //desc: ' | ライブラリー',
    },
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      title: 'イベント',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のイベント一覧です。',
    },
  },
  {
    path: '/events/reserved',
    name: 'eventsReserved',
    component: EventsReserved,
    meta: {
      title: '予約済み/お気に入り',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の予約済み/お気に入りイベント一覧です。',
    },
  },
  {
    path: '/events/:id',
    name: 'event',
    component: Event,
    meta: {
      title: 'イベント',
      //desc: '| イベント',
    },
  },
  {
    path: '/surveys',
    name: 'surveys',
    component: Surveys,
    meta: {
      title: 'アンケート',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のアンケート一覧です。',
    },
  },
  {
    path: '/surveys/:id',
    component: Survey,
    children: [
      { path: '', name: 'survey', component: SurveyForm },
      { path: '/thanks', name: 'survey-thanks', component: SurveyThanks },
    ],
    meta: {
      title: 'アンケート',
      desc: '| アンケート',
    },
  },
  {
    path: '/account',
    name: 'account',
    redirect: '/account/profile',
    component: Account,
    meta: {
      requiresAuth: true,
      title: 'マイページ',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のマイページです。',
    },
    children: [
      {
        //  /account/profile がマッチした時に
        //  Account の <router-view> 内部で AccountProfile を描画
        path: 'profile',
        name: 'profile',
        component: AccountProfile,
        meta: {
          requiresAuth: true,
          title: 'マイページ',
          desc:
            '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のマイページです。',
        },
      },
      {
        //  /account/friends がマッチした時に
        //  Account の <router-view> 内部で AccountFriends は描画
        path: 'friends',
        name: 'friends',
        component: AccountFriends,
        meta: {
          requiresAuth: true,
          title: 'マイページ',
          desc:
            '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のマイページです。',
        },
      },
      {
        path: 'healthStats',
        name: 'healthStats',
        component: AccountHealthStats,
        meta: {
          requiresAuth: true,
          title: '生活習慣レポート',
          desc:
            '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の自分ログフィードバックページです。',
        },
      },
    ],
  },
  {
    path: '/account/friends/:id',
    name: 'friendProfile',
    component: AccountFriendProfile,
    meta: {
      requiresAuth: true,
      title: '友だち',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の友だちページです。',
    },
  },

  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'プライバシーポリシー（&well）',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のプライバシーポリシー（&well）です。',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      title: 'よくある質問',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のよくある質問です。',
    },
  },
  {
    path: '/terms-of-use',
    name: 'termsOfService',
    component: TermsOfService,
    meta: {
      title: '利用規約',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」の利用規約です。',
    },
  },
  {
    path: '/website-terms-of-use',
    name: 'websiteTermsOfUse',
    component: WebsiteTermsOfUse,
    meta: {
      title: 'サイトのご利用上の注意',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」のサイトのご利用上の注意ページです。',
    },
  },
  {
    path: '/about-andwell',
    name: 'howToUse',
    component: HowToUse,
    meta: {
      title: '&wellについて',
      desc:
        '三井不動産が提供するソリューションサービス「＆well（アンドウェル）」についての説明ページです。',
    },
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      title: 'パスワードリセット',
    },
  },
  {
    path: '/resetPasswordRequested',
    name: 'resetPasswordRequested',
    component: ResetPasswordRequested,
    meta: {
      title: 'パスワードリセット',
    },
  },
  {
    path: '/registerMailAddress',
    name: 'registerMailAddress',
    component: RegisterMailAddress,
    meta: {
      title: 'パスワードリセット',
    },
  },
  {
    path: '/registerMailAddressRequested',
    name: 'registerMailAddressRequested',
    component: RegisterMailAddressRequested,
    meta: {
      title: 'パスワードリセット',
    },
  },
  {
    path: '/ConfigF1643C022E4941F388EB2059D3C726AA',
    name: 'Config',
    component: Config,
    meta: {
      title: 'Config',
    },
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound,
    meta: { title: 'お探しのページは見つかりませんでした' },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          })
        }, 500)
      })
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('signinWithToken').then(next)
})

export default router
