<i18n>
{
  "ja": {
    "人数": "人数"
  },
  "en": {
    "人数": "number of people"
  }
}
</i18n>
<template lang="pug">
  .Histogram
    canvas(ref="chart")
</template>
<script>
import Chart from 'chart.js/auto'
import style from './style.js'

const options = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      grid: {
        drawTicks: false,
        drawBorder: false,
      },
    },
    y: {
      grid: {
        drawBorder: false,
        drawTicks: false,
        drawOnChartArea: false,
      },
    },
  },
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        pointStyle: 'rect',
        font: {
          size: 12,
        },
      },
    },
  },
}
export default {
  name: 'Histogram',
  props: ['values', 'score'],
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    propsToWatch() {
      return [this.values, this.score]
    },
  },
  watch: {
    propsToWatch: function(val) {
      this.chart.data = this.propsToChartData(...val)
      this.chart.update()
    },
  },
  methods: {
    propsToChartData(values, score) {
      let labels = []
      let backgroundColor = []
      for (var i = 10; i > 0; i--) {
        const max = i * 10
        const min = max - 10

        labels.push(min + '-' + max + '%')

        let color = style.color.bar
        if ((min == score && score == 0) || (min < score && score <= max)) {
          color = style.color.barActive
        }
        backgroundColor.push(color)
      }
      return {
        datasets: [
          {
            label: this.$i18n.t('人数'),
            data: values,
            barPercentage: 0.7, // バーの太さ
            borderRadius: 4,
            backgroundColor: backgroundColor,
          },
        ],
        labels,
      }
    },
  },
  mounted() {
    const ctx = this.$refs.chart

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: this.propsToChartData(this.values, this.score),
      options: options,
    })
  },
}
</script>
