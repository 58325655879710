import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const eventRepository = RepositoryFactory.get('event')

const defaultState = () => ({
  items: [],
  paged: 0,
  length: 0,
})

const state = defaultState()

const mutations = {
  incrementPage(state) {
    state.paged++
  },
  setItems(state, payload) {
    state.length = payload.length
    state.items = state.items.concat(payload)
  },
  init(state) {
    Object.assign(state, defaultState())
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems({ state, commit }, { perPage }) {
    try {
      const { succeed, events } = await eventRepository.getReservingEvents(
        state.paged * perPage,
        perPage
      )
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', events)
      }
    } catch (error) {
      //
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
