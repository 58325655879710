<i18n>
{
  "ja":{
    "Event": "Event",
    "News": "News",
    "&wellからのお知らせ": "&wellからのお知らせ",
    "イベント概要": "イベント概要",
    "アカウント": "アカウント",
    "Signin":"Signin",
    "Login":"Login",
    "ログイン":"ログイン",
    "Surveys":"Surveys",
    "友達リスト":"友達リスト",
    "設定":"設定",
    "基本情報":"基本情報",
    "参加しているチーム対抗戦":"参加しているチーム対抗戦",
    "予約済み/お気に入り":"予約済み/お気に入り",
    "開催済": "開催済",
    "開催予定": "開催予定"
  },
  "en":{
    "Event": "Events",
    "News": "Notifications",
    "&wellからのお知らせ": "Notifications",
    "イベント概要": "Event Overview",
    "アカウント": "Account",
    "Signin":"Signin",
    "Login":"Login",
    "ログイン":"Login",
    "Surveys":"Surveys",
    "友達リスト":"Friends List",
    "設定":"Settings",
    "基本情報":"Basic Info",
    "参加しているチーム対抗戦":"Team competition in which we participate",
    "予約済み/お気に入り":"Booked/Favorited",
    "開催済": "Already Held",
    "開催予定": "Before Start"
  }
}
</i18n>

<template lang="pug">
  component(:is="tagLevel") {{ $t(text) }}
</template>

<script>
export default {
  name: 'HeadingBlock',
  props: {
    level: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    tagLevel(level) {
      return `h${this.level}`
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 40px;
  text-align: center;
  font-family: $font-gotham-bold;
  @include sp-layout {
    font-size: 24px;
  }
}
h2 {
  font-size: 28px;
  border-left: 4px solid #e60012;
  padding-left: 14px;
  line-height: 1.05;
  font-weight: bold;
  /* 仮の空白 */
  margin: 20px 0;
  @include sp-layout {
  }
}
h3 {
  font-size: 28px;
  @include sp-layout {
  }
}
h4 {
  font-size: 28px;
  @include sp-layout {
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 20px;
  }
}
</style>
