<template lang="pug">
.privacy-policy
  .common-h1-area
    HeadingBlock(level="1" text="Privacy Policy (&well)")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") {{$t("プライバシーポリシー（&well）")}}
  .sub-page-padding
    PrivacyPolicy

</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import PrivacyPolicy from '@/components/PrivacyPolicy'

export default {
  name: 'privacyPolicy',
  components: {
    HeadingBlock,
    PrivacyPolicy,
  },
}
</script>
