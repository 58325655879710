<template lang="pug">
  header#header
    .pc
      .container.head-container
        div(:class="[isScroll ? 'is-scroll' : '', 'header-main-block']")
          .header-login-block
            ul
              li.header-profile-image(v-if="isAuthenticated")
                router-link(class="_a" :to="{name: 'profile'}")
                  ._image(v-if="!!profileImage" :style="{ backgroundImage: 'url(' + profileImage + ')' }")
                  ._image._default(v-else alt="プロフィール")
              li(v-else)
                button.default-button-style(@click="verifyBeforeSignin") {{$t("ログイン")}}
              li.header-mf-logo
                a.link(href="https://www.mitsuifudosan.co.jp/" target="_blank")
                  img(class="_image" src="@/assets/images/mflogo.svg" alt="三井不動産")

        .header-main-logo
          router-link(:to="{ name: 'home' }")
            div(v-if="this.$route.path === '/'")
              h1
                img(class="_image" src="@/assets/images/main_logo.svg" alt="&amp;well")
            div(v-else)
              p
                img(class="_image" src="@/assets/images/main_logo.svg" alt="&amp;well")

        div(:class="[isScroll ? 'is-scroll' : '', 'header-main-menu']")
          nav
            ul
              li(class="header__home header__nav")
                router-link(:to="{ name: 'home' }") {{$t("ホーム")}}
              li(class="header__announcement header__nav")
                router-link(:to="{ name: 'announcements' }") {{$t("お知らせ")}}
              li(class="header__event header__nav")
                router-link(:to="{ name: 'events' }") {{$t("参加する")}}
              li(class="header__library header__nav")
                router-link(:to="{ name: 'libraries' }") {{$t("学ぶ")}}
              li(class="header__survey header__nav")
                router-link(:to="{ name: 'surveys' }") {{$t("アンケート")}}

    .sp
      .sp-header-content
        .sp-header-main-logo
          router-link(:to="{ name: 'home' }")
            div(v-if="this.$route.path === '/'")
              h1
                img(class="_image" src="@/assets/images/main_logo.svg" alt="&amp;well")
            div(v-else)
              p
                img(class="_image" src="@/assets/images/main_logo.svg" alt="&amp;well")
        button.hamburger-menu(@click="toggleSwitch" :class="{ _active : isHamburgerActive }")
          ._inner
            ._1
            ._2
            ._3
        .header-mf-logo
          a.link(href="https://www.mitsuifudosan.co.jp/" target="_blank")
            img(class="_image" src="@/assets/images/mflogo.svg" alt="三井不動産")

      transition(name="fade")
        .menu-background(v-if="isHamburgerActive")
          .sp-header-main-menu
            ul.sp-menu-login-block
              li(v-if="$store.state.user.authenticated")
                router-link(:to="{name: 'profile'}" @click.native="closeMenu")
                  button.wide-button {{$t("プロフィール")}}
              li(v-else)
                button.wide-button(@click="loginCloseMenu") {{$t("ログイン")}}
            nav
              ul.sp-menu-nav-block
                li(class="header__home header__nav")
                  router-link(:to="{ name: 'home' }" @click.native="closeMenu") {{$t("ホーム")}}
                li(class="header__announcement header__nav")
                  router-link(:to="{ name: 'announcements' }" @click.native="closeMenu") {{$t("お知らせ")}}
                li(class="header__event header__nav")
                  router-link(:to="{ name: 'events' }" @click.native="closeMenu") {{$t("参加する")}}
                li(class="header__library header__nav")
                  router-link(:to="{ name: 'libraries' }" @click.native="closeMenu") {{$t("学ぶ")}}
                li(class="header__survey header__nav")
                  router-link(:to="{ name: 'surveys' }" @click.native="closeMenu") {{$t("アンケート")}}
      transition(name="fade")
        .overlay(v-if="isHamburgerActive" @click="closeMenu")
</template>

<script>
import mixinUser from '@/mixins/user.js'
import { mapState } from 'vuex'

export default {
  name: 'CommonHeader',
  mixins: [mixinUser],
  data() {
    return {
      scrollY: 0,
      isScroll: false,
      isHamburgerActive: false,
    }
  },
  computed: {
    ...mapState({
      profileImage: (state) => state.user.profileImage,
    }),
    isAuthenticated() {
      return this.$store.state.user.authenticated
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll)
  },
  watch: {
    isAuthenticated(val, old) {
      return val
    },
  },
  methods: {
    checkScroll() {
      this.scrollY = window.scrollY
      this.isScroll = this.scrollY > 149
    },
    toggleSwitch() {
      this.isHamburgerActive = !this.isHamburgerActive
    },
    closeMenu() {
      this.isHamburgerActive = false
    },
    loginCloseMenu() {
      this.closeMenu()
      this.verifyBeforeSignin()
    },
  },
}
</script>

<style scoped lang="scss">
#header {
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  z-index: 100;

  .pc {
    nav {
      > ul {
        display: flex;
        justify-content: space-around;
        > li {
          height: 40px;
          width: 140px;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            text-decoration: none;
            line-height: 38px;
            font-weight: bold;
            &:after {
              position: absolute;
              bottom: -4px;
              left: 0;
              content: '';
              width: 100%;
              height: 2px;
              background: #e60012;
              opacity: 0;
              visibility: hidden;
              transition: 0.3s;
            }
            &.router-link-exact-active {
              color: $base-brand-color;
            }
            &.router-link-exact-active:after {
              position: absolute;
              left: 0;
              content: '';
              width: 100%;
              height: 2px;
              background: #e60012;
              transition: 0.3s;
              bottom: 0px;
              opacity: 1;
              visibility: visible;
            }
            &:hover::after {
              bottom: 0px;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .header-main-menu {
    margin-top: 5px;
    transition: ease-out 0.3s;
    // &.is-scroll {
    //   margin-top: 20px;
    // }

    .header__home {
      background: image-url('icon_home.svg') no-repeat 25px 10px;
      > a {
        text-indent: 54px;
      }
    }
    .header__announcement {
      background: image-url('icon_bell.svg') no-repeat 25px 10px;
      > a {
        text-indent: 53px;
      }
    }
    .header__event {
      background: image-url('icon_calendar.svg') no-repeat 23px 10px;
      > a {
        text-indent: 53px;
      }
    }
    .header__library {
      background: image-url('icon_book.svg') no-repeat 34px 11px;
      > a {
        text-indent: 64px;
      }
    }
    .header__survey {
      background: image-url('icon_survey.svg') no-repeat 13px 10px;
      background-size: 18px;
      > a {
        text-indent: 43px;
      }
    }
  }
  .header-main-block {
    display: flex;
    position: relative;

    justify-content: flex-end;
    padding-top: 34px;
    transition: ease-out 0.3s;
    // &.is-scroll {
    //   padding-top: 30px;
    // }
  }
  .header-main-logo {
    position: absolute;
    left: 23px;
    right: auto;
    top: 26px;
    display: inline-block;
    margin: auto;
    height: 48px;

    img{
      height: 48px;
    }
  }
  .header-login-block {
    width: 100%;
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      li {
        height: 52px;
      }
      li + li {
        margin-left: 15px;
      }
    }
  }
  .header-mf-logo {
    margin-top: -11px;
    ._image {
      width: 145px;
    }
  }

  .sp-header-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 56px;
    z-index: 3;
    .sp-header-main-logo {
      position: absolute;
      height: 32px;
      left: 15px;
      right: auto;
      top: -2px;
      bottom: 0;
      margin: auto;
      > a {
        display: block;
      }
      img {
        height: 32px;
      }
    }
    .hamburger-menu {
      display: flex;
      width: 56px;
      height: 56px;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      ._inner {
        > div {
          background-color: $base-color;
          transition: all ease-in-out 0.2s;
        }
        div + div {
          margin-top: 4px;
        }
        ._1 {
          width: 18px;
          height: 2px;
        }
        ._2 {
          width: 18px;
          height: 2px;
        }
        ._3 {
          width: 18px;
          height: 2px;
        }
      }
      &._active {
        div + div {
          margin-top: 0;
        }
        ._1 {
          width: 19px;
          transform: rotate(-135deg) translate(0px, -1px);
        }
        ._2 {
          display: none;
        }
        ._3 {
          width: 19px;
          transform: rotate(135deg) translate(0px, 1px);
        }
      }
    }
    .header-mf-logo {
      margin-top: 0;
      margin-right: 5px;
      ._image {
        width: 100px;
        padding-top: 5px;
      }
    }
  }
  .sp-header-main-menu {
    position: fixed;
    z-index: 3;
    display: block;
    top: 56px;
    width: 100%;
    background-color: $base-bg-color;
    overflow-y: scroll;
    height: calc(100% - 56px);
    .sp-menu-login-block {
      padding: 20px 48px 25px;
      border-bottom: 1px solid $tags-color-gray;
    }
    nav {
      border-bottom: 1px solid $tags-color-gray;
      .sp-menu-nav-block {
        padding: 20px 48px;
        > li {
          a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 19px 0;
            font-weight: bold;
            &.router-link-exact-active {
              color: $base-brand-color;
            }
          }
        }
        .header__home {
          background: image-url('icon_home.svg') no-repeat 0px center;
          > a {
            text-indent: 28px;
          }
        }
        .header__announcement {
          background: image-url('icon_bell.svg') no-repeat 2px center;
          > a {
            text-indent: 28px;
          }
        }
        .header__event {
          background: image-url('icon_calendar.svg') no-repeat 0px center;
          > a {
            text-indent: 28px;
          }
        }
        .header__library {
          background: image-url('icon_book.svg') no-repeat 1px center;
          > a {
            text-indent: 28px;
          }
        }
        .header__survey {
          background: image-url('icon_survey.svg') no-repeat 0px center;
          background-size: 18px;
          > a {
            text-indent: 28px;
          }
        }
      }
    }
  }
}
.overlay {
  position: absolute;
  z-index: 1;
  background-color: #000;
  width: 100%;
  opacity: 0.4;
  height: calc(100vh - 56px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.menu-background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.header-profile-image {
  ._a {
    margin-top: -8px;
    display: inline-block;
  }
  ._image {
    display: block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: $base-bg-color;
    background-size: cover;
    background-position: center;
    border: 2px solid $tags-color-gray;
    @include link-hover();
    overflow: hidden;
    &._default {
      background: image-url('icon_user.svg') no-repeat 0 8px;
      background-size: auto;
    }
  }
}
.head-container {
  position: relative;
}

/* 英語用ヘッダー調整  */
.en {
  #header {
    .pc {
      nav {
        > ul {
          > li {
            a {
              line-height: 40px;
            }
          }
        }
      }
    }
    .header-main-menu {
      font-size: 14px;
      .header__announcement {
        background: image-url('icon_bell.svg') no-repeat 18px 10px;
        > a {
          text-indent: 42px;
        }
      }
      .header__library {
        background: image-url('icon_book.svg') no-repeat 24px 10px;
        > a {
          text-indent: 50px;
        }
      }
    }
  }
}
</style>
