<i18n>
{
  "ja": {
    "自分ログフィードバック": "自分ログフィードバック",
    "総合評価":"総合評価",
    "周りと比べてみよう":"周りと比べてみよう",
    "自分ログの入力率":"自分ログの入力率",
    "分類別評価":"分類別評価",
    "運動":"運動",
    "食事":"食事",
    "飲酒":"飲酒",
    "睡眠":"睡眠",
    "ストレス":"ストレス"
  },
  "en": {
    "自分ログフィードバック": "Health Log Feedback",
    "総合評価":"Summary",
    "周りと比べてみよう":"Let's compare with others",
    "自分ログの入力率":"Health Log input rate",
    "分類別評価":"Evaluation by category",
    "運動":"Exercise",
    "食事":"Meal",
    "飲酒":"Drinking",
    "睡眠":"Sleep",
    "ストレス":"Stress"
  }
}
</i18n>
<template lang="pug">
  .datePicker
    ol.years
      li.year(v-for="item in availbleYearMonth")
        .num.available(v-on:click="pickYear(item.year)" :class="{'active': year === item.year}") {{item.year}}
    ol.months(v-for="item in availbleYearMonth" v-if="year === item.year")
      li.month(v-for="m in item.months")
        .num.available(v-if="m.available" v-on:click="pickMonth(m.month)" :class="{'active': month === m.month}") {{m.month}}
        .num(v-else) {{m.month}}

</template>

<script>
import moment from 'moment-timezone'

export default {
  data() {
    return {
      year: 0,
      month: 0,
      availbleYearMonth: [],
    }
  },
  methods: {
    init() {
      moment.locale(this.$i18n.locale)
      const startDate = moment('2021-12-01') // 自分ログ取得開始月(固定値)
      const endDate = moment().subtract(1, 'months') // 先月まで
      const startYear = startDate.year()
      const endYear = endDate.year()
      const startMonth = startDate.month() + 1
      const endMonth = endDate.month() + 1

      for (var y = startYear; y <= endYear; y++) {
        let item = { year: y, months: [] }
        for (var m = 1; m <= 12; m++) {
          let available = true
          if (y === startYear && m < startMonth) {
            // 開始年月より前
            available = false
          }
          if (y === endYear && endMonth < m) {
            // 終了年月より後
            available = false
          }
          item.months.push({ month: m, available: available })
        }
        this.availbleYearMonth.push(item)
      }

      // 先月がデフォルト
      this.year = endDate.year()
      this.month = endDate.month() + 1
    },
    pickYear(year) {
      this.year = year

      // 年を切り替えた時、選択されている月が期間内に存在しない可能性がある
      // その場合は強制的に月を選ぶ
      const months = this.availbleYearMonth.find(x => x.year === year).months
      if (!months.find(x => x.month === this.month && x.available)) {
        const reversed = [...months].reverse() // monthsを破壊せずにreverseする
        // availableな最後の月を選択
        this.month = reversed.find(x => x.available).month
      }
      this.onChange()
    },
    pickMonth(month) {
      this.month = month
      this.onChange()
    },
    onChange() {
      this.$emit('picked', this.year, this.month)
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.datePicker {
  padding-bottom: 28px;
  border-bottom: $tags-color-gray 1px solid;
  margin-bottom: 40px;
  ol {
    display: flex;
  }
  .years {
    @include sp-layout {
      flex-wrap: wrap;
    }
  }
  .months {
    justify-content: space-between;
    @include sp-layout {
      flex-wrap: wrap;
    }
  }
  .num {
    color: $base-color;
    border-radius: 4px;
    font-size: 14px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp-layout {
      height: 32px;
    }
  }
  .year {
    margin: 0 6px 12px 0;
    width: 122px;
    @include sp-layout {
      width: 80px;
    }
  }
  .month {
    margin-bottom: 12px;
    width: 58px;
    @include sp-layout {
      width: 15%;
    }
  }
  .num.available {
    background: #fff;
    cursor: pointer;
  }
  .num.active {
    background: $tags-color-blue;
    color: #fff;
  }
}
</style>
