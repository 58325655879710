<i18n>
{
  "ja":{
    "順位": "順位",
    "平均歩数": "平均歩数",
    "歩": "歩",
    "位":"位"
  },
  "en":{
    "順位": "Ranking",
    "平均歩数": "Average steps",
    "歩": "steps",
    "位":" "
  }
}
</i18n>
<template lang="pug">
  .StepsChart
    .inner
      .dateRange
        div {{ $d(fromDate, "short") }} ～ {{ $d(toDate, "short") }}
      .status
        .item
          .title {{$t("順位")}}
          .value 
            span.num.large {{rank | number}}
            span.num.small / {{rankTotal | number}}
            span.unit {{$t("位")}}
        .item
          .title {{$t("平均歩数")}}
          .value
            span.num.large {{average | number}}
            span.unit {{$t("歩")}}
      .prevWeek(@click="prevWeek")
      .nextWeek(@click="nextWeek")
      .chart
        .scale
          div.y10000: span 10,000
          div.y8000: span 8,000
          div.y5000: span 5,000
          div.y0: span 0
        .bars
          div(v-for="bar in bars" :style="{height:bar.height +'%'}" :class="{achieved:bar.achieved}")
        .days
          div {{$t("月")}}
          div {{$t("火")}}
          div {{$t("水")}}
          div {{$t("木")}}
          div {{$t("金")}}
          div {{$t("土")}}
          div {{$t("日")}}
</template>
<script>
const STEPS_MAX = 15000
const STEPS_GOAL = 8000
import moment from 'moment-timezone'
export default {
  name: 'StepsChart',
  data() {
    return {
      fromDate: '',
      toDate: '',
      rank: 0,
      rankTotal: 0,
      average: 0,
      bars: [],
      loading: false,
    }
  },
  created() {
    // 今週の月曜と日曜
    this.fromDate = moment()
      .day(1)
      .startOf('day') // 1=monday
    this.toDate = moment()
      .day(7)
      .startOf('day') // 7=sunday
    this.draw()
    console.log(this.fromDate)
  },
  methods: {
    prevWeek() {
      if (!this.loading) {
        // 7日前
        this.fromDate = this.fromDate.subtract(7, 'days').startOf('day')
        this.toDate = this.toDate.subtract(7, 'days').startOf('day')
        this.draw()
      }
    },
    nextWeek() {
      const monday = moment()
        .day(1)
        .startOf('day') // 1=monday
      const isThisWeekOrFuture = this.fromDate.isSameOrAfter(monday, 'day')

      if (!this.loading && !isThisWeekOrFuture) {
        // 7日後
        this.fromDate = this.fromDate.add(7, 'days').startOf('day')
        this.toDate = this.toDate.add(7, 'days').startOf('day')
        this.draw()
      }
    },
    draw() {
      const args = {
        fromDateTime: this.fromDate.unix() * 1000, // 00:00:00:00
        toDateTime: this.toDate.unix() * 1000, // 00:00:00:00
        needsWeeklyAverage: true,
        needsStepStatistics: true,
      }
      this.loading = true
      this.api('getHealthDataByRange', args, rv => {
        const stepsData = rv.stepsData
        this.drawChart(stepsData)
        this.loading = false
        console.log('health', rv.healthData)
        console.log('steps', rv.stepsData)
        console.log('weeklyStepsData', rv.weeklyStepsData)

        // 平均歩数とランキング
        if (rv.weeklyStepsData && rv.weeklyStepsData.length > 0) {
          this.average = rv.weeklyStepsData[0].steps
          const stat = rv.weeklyStepsData[0].stepStatistics
          this.rank = stat.rank
          this.rankTotal = stat.maxRank
        } else {
          this.average = 0
          this.rank = 0
          this.rankTotal = 0
        }
      })
    },
    drawChart(steps) {
      this.bars = []
      // 月曜から日曜までのグラフ用データに整形する
      // 水曜がないなど歯抜けデータに対応
      for (let day = 1; day <= 7; day++) {
        let step = 0
        let achieved = false
        const item = steps.find(v => {
          // stepsから曜日が一致するデータを抽出
          return day % 7 === moment(v.dateTime).day()
        })
        if (item) {
          step = Math.min(STEPS_MAX, item.steps)
          achieved = step >= STEPS_GOAL // 目標を達成したか
        }
        this.bars.push({
          height: (step / STEPS_MAX) * 100, // 最大値基準のパーセンテージ
          achieved,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.StepsChart {
  background: #fff;
  margin: 28px auto 20px;
  padding: 40px 115px;
  @include sp-layout {
    padding: 25px 15px;
  }
}
.inner {
  position: relative;
}
.dateRange {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  @include sp-layout {
    font-size: 12px;
  }
}
.prevWeek,
.nextWeek {
  position: absolute;
  top: 0;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: $base-bg-gray-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include sp-layout {
    width: 28px;
    height: 28px;
  }

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border: #111 2px solid;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
  }
}
.prevWeek {
  left: 0;
  &:before {
    transform: rotate(-135deg);
  }
}
.nextWeek {
  right: 0;
}

.status {
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  @include sp-layout {
    margin: 40px 0 12px;
  }
  .item {
    text-align: center;
    margin: 0 40px;
  }
  @include sp-layout {
    .item {
      margin: 0;
    }
    .item:first-child {
      margin-right: 40px;
    }
  }
  .title {
    height: 14px;
    text-align: left;
    border-left: $base-color-red 2px solid;
    padding-left: 8px;
    font-size: 14px;
    line-height: 14px;
    @include sp-layout {
      height: 13px;
      font-size: 13px;
      line-height: 13px;
    }
  }
  .value {
    margin-top: 14px;
    @include sp-layout {
      margin-top: 8px;
    }
  }
  .num {
    font-family: $font-gotham-bold;
  }
  .large {
    font-size: 48px;
    @include sp-layout {
      font-size: 28px;
    }
  }
  .small {
    margin-left: 4px;
    font-size: 14px;
    @include sp-layout {
      font-size: 12px;
    }
  }
  .unit {
    margin-left: 4px;
    font-size: 14px;
  }
}

.chart {
  position: relative;
  .scale {
    position: absolute;
    left: 60px;
    top: 0;
    width: calc(100% - 60px);
    @include sp-layout {
      left: 40px;
      width: calc(100% - 40px);
    }
    > div {
      position: relative;
      height: 55px;
      border-bottom: #e2e2e2 1px solid;
    }
    .y8000 {
      border-bottom: none;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 4px;
        margin: auto;
        background-image: linear-gradient(
          to right,
          $graph-color-pink,
          $graph-color-pink 12px,
          transparent 12px,
          transparent 20px
        );
        background-size: 20px 4px;
        background-repeat: repeat-x;
        @include sp-layout {
          background-image: linear-gradient(
            to right,
            $graph-color-pink,
            $graph-color-pink 8px,
            transparent 8px,
            transparent 12px
          );
          background-size: 12px 2px;
        }
      }
    }
    .y8000 {
      height: 22px;
    }
    .y5000 {
      height: 33px;
    }
    span {
      position: absolute;
      left: -60px;
      bottom: -8px;
      width: 60px;
      padding-right: 8px;
      font-size: 14px;
      color: #999;
      text-align: right;
      @include sp-layout {
        left: -40px;
        width: 40px;
        font-size: 11px;
      }
    }
  }
  .bars,
  .days {
    position: relative;
    display: flex;
    margin-left: 124px;
    justify-content: left; // centerだとデータがないときにずれる
    > div {
      width: 68px;
      margin: 0 14px;
    }
    @include sp-layout {
      margin-left: 40px;
      > div {
        width: 10.5%; // 100%/7 = 14.28を[width:margin = 3:1]で配分
        margin-left: 3.5%;
        margin-right: 0;
      }
    }
  }
  .bars {
    height: 165px;
    align-items: flex-end;
    > div {
      border-radius: 8px 8px 0 0;
      background: $base-brand-color;
      @include sp-layout {
        border-radius: 4px 4px 0 0;
      }
    }
    > .achieved {
      background: $base-brand-color url('../../assets/images/icon_check.svg')
        no-repeat center 12px;
      background-size: 20px auto;
      @include sp-layout {
        background-size: 12px auto;
      }
    }
  }
  .days {
    font-size: 14px;
    @include sp-layout {
      font-size: 11px;
    }
    > div {
      margin-top: 6px;
      text-align: center;
    }
  }
}
</style>
