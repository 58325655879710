export default {
  methods: {
    api(name, args, callback) {
      if (!args) args = {}
      args.locale = this.$store.state.locale
      const entry = Kii.serverCodeEntry(name)
      entry.execute(args).then(params => {
        const execResult = params[2]
        const rv = execResult.getReturnedValue()
        if (callback) callback(rv.returnedValue)
      })
    },
  },
}
