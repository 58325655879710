import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const eventRepository = RepositoryFactory.get('event')

const defaultState = () => ({
  items: [],
  paged: 0,
  length: 0,

  homeItems: [],

  filterStatus: {
    scheduleType: 1, // 1:開催前, 2:開催後, 3:本日開催
    onlyFree: false,
    onlyOnline: false,
    onlyReservation: false,
  },
})

const state = defaultState()

const mutations = {
  incrementPage(state) {
    state.paged++
  },
  setItems(state, payload) {
    state.length = payload.length
    state.items = state.items.concat(payload)
  },
  setHomeItems(state, payload) {
    state.homeItems = state.homeItems.concat(payload)
  },
  setFilterStatus(state, { key, value }) {
    state.filterStatus[key] = value
  },
  init(state) {
    Object.assign(state, defaultState())
  },
  resetItemsOnly(state) {
    state.items = []
    state.paged = 0
    state.length = 0
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems(
    { state, commit },
    { perPage, scheduleType, onlyFree, onlyOnline, onlyReservation }
  ) {
    try {
      const { succeed, events } = await eventRepository.get(
        state.paged * perPage,
        perPage,
        scheduleType,
        onlyFree,
        onlyOnline,
        onlyReservation
      )
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', events)
      }
    } catch (error) {
      //
    }
  },
  async getHomeItems({ state, commit }, { perPage, scheduleType }) {
    try {
      const { succeed, events } = await eventRepository.get(
        0,
        perPage,
        scheduleType
      )
      if (!succeed) {
        throw new Error()
      } else {
        commit('setHomeItems', events)
      }
    } catch (error) {
      //
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
