<template lang="pug">
footer#footer
  .container
    .footer-main-block
      .footer-logo
        router-link(:to="{ name: 'home' }")
          img._image(src="@/assets/images/main_logo_footer.png" alt="&well")
      .footer-menu
        ul
          li
            router-link(:to="{ name: 'home' }") {{$t("ホーム")}}
          li
            router-link(:to="{ name: 'announcements' }") {{$t("お知らせ")}}
          li
            router-link(:to="{ name: 'events' }") {{$t("参加する")}}
          li
            router-link(:to="{ name: 'libraries' }") {{$t("学ぶ")}}
          li
            router-link(:to="{ name: 'surveys' }") {{$t("アンケート")}}
      .footer-menu
        ul
          li
            router-link(:to="{ name: 'howToUse' }") {{$t("&wellについて")}}
          li
            router-link(:to="{ name: 'faq' }") {{$t("よくある質問")}}
          li
            router-link(:to="{ name: 'termsOfService' }") {{$t("利用規約")}}
          //- li
          //-   router-link(:to="{ name: 'privacyPolicy' }") {{$t("プライバシーポリシー")}}
      .footer-menu.mini
        ul
          li
            a.link(href="https://www.mitsuifudosan.co.jp/privacy_policy/" target="_blank") {{$t("個人情報保護方針")}}
          li
            a.link(href="https://www.mitsuifudosan.co.jp/privacy_policy/toriatsukai/" target="_blank") {{$t("個人情報の取り扱いについて")}}
          li
            router-link(:to="{ name: 'privacyPolicy' }") {{$t("プライバシーポリシー（&well）")}}
          li
            router-link(:to="{ name: 'websiteTermsOfUse' }") {{$t("サイトのご利用上の注意")}}
          li
            a.link(href="https://www.mitsuifudosan.co.jp/privacy_policy/cookie_accesslog/" target="_blank") {{$t("Cookieおよびアクセスログについて")}}
      .copyright
        small &copy; Mitsui Fudosan Co., Ltd. All Rights Reserved.

</template>

<script>
export default {
  name: 'CommonFooter',
  components: {},
  data() {
    return {
      CommonFooter: [],
    }
  },
}
</script>

<style lang="scss" scoped>
#footer {
  background-color: $base-bg-dark-color;
  .footer-main-block {
    padding-bottom: 40px;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 10px;

    a {
      display: block;
      img {
        display: block;
        width: 120px;
      }
    }
  }
  .footer-menu {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    ul {
      display: flex;
      li {
        position: relative;
        + li {
          margin-left: 35px;
        }
        a {
          color: #fff;
          text-decoration: none;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
    &.mini {
      ul {
        li {
          + li {
            margin-left: 16px;
          }
          + li::after {
            content: '';
            position: absolute;
            left: -9px;
            top: 9px;
            width: 1px;
            height: 12px;
            background-color: #fff;
          }
          a {
            font-size: 11px;
          }
        }
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    small {
      color: #666;
      font-size: 10px;
    }
  }
  .LocaleSelect {
    color: #fff;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  #footer {
    .footer-menu {
      margin-top: 49px;
      ul {
        flex-direction: column;
        text-align: center;
        li {
          + li {
            margin-left: 0;
            margin-top: 18px;
          }
        }
      }
    }
    .footer-menu.mini {
      margin-top: 70px;
      ul {
        li {
          + li {
            margin-left: 0;
            margin-top: 10px;
          }
          + li::after {
            display: none;
          }
          a {
            font-size: 10px;
          }
        }
      }
    }
    .copyright {
      margin-top: 40px;
    }
  }
}
</style>
