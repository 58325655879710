<template lang="pug">
  .LineGraph
    canvas(ref="canvas")
</template>
<script>
const PC_MIN_WIDTH = 768
const GRAPH_LINE_COLOR = '#CAB77D'
export default {
  name: 'LineGraph',
  props: {
    healthData: Array,
    type: String, // 体重(weight)、体脂肪率(bodyFatPercentage)、BMI(BMI)
    scale: Array, // 目盛(大きい順)
  },
  data() {
    return {
      graphData: this.$props.healthData,
      graphType: this.$props.type,
      graphScale: this.$props.scale,
      ctx: null,
      pointSize: 6, // 点の半径
      width: 0,
      height: 0,
    }
  },

  watch: {
    healthData(newData) {
      this.graphData = newData
      this.draw()
    },
    type(newType) {
      this.graphType = newType
      this.draw()
    },
    scale(newScale) {
      this.graphScale = newScale
      this.draw()
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    isPC() {
      return window.innerWidth >= PC_MIN_WIDTH
    },
    initCanvas() {
      /*
        画面サイズによっていろいろ変わる
        ・canvasのサイズ
        ・各x座標
        ・点の大きさ
        ・線の太さ
      */
      const canvas = this.$refs.canvas
      if (this.isPC()) {
        this.width = 672
        this.height = 162
      } else {
        this.width = document.body.clientWidth - 100 // スクロールバー分除く
        this.height = 162
      }
      canvas.width = this.width
      canvas.height = this.height
      this.ctx = canvas.getContext('2d')
      this.ctx.fillStyle = GRAPH_LINE_COLOR
      this.ctx.strokeStyle = GRAPH_LINE_COLOR

      if (this.isPC()) {
        this.pointSize = 6
        this.ctx.lineWidth = 2
      } else {
        this.pointSize = 4
        this.ctx.lineWidth = 1
      }
    },
    handleResize() {
      this.initCanvas()
      this.draw()
    },
    drawPoint(x, y) {
      const r = this.pointSize

      // 白丸
      this.ctx.fillStyle = '#FFFFFF'
      this.ctx.beginPath()
      this.ctx.arc(x, y, r, 0, Math.PI * 2)
      this.ctx.fill()
      this.ctx.closePath()

      // 中の丸
      this.ctx.fillStyle = GRAPH_LINE_COLOR
      this.ctx.beginPath()
      this.ctx.arc(x, y, r - 1, 0, Math.PI * 2)
      this.ctx.fill()
      this.ctx.closePath()
    },
    drawLine(x1, y1, x2, y2) {
      this.ctx.beginPath()
      this.ctx.moveTo(x1, y1)
      this.ctx.lineTo(x2, y2)
      this.ctx.stroke()
      this.ctx.closePath()
    },
    calcHeight(val, scale) {
      // 点のy座標を求める
      const min = Math.min(...scale) - 1
      const max = Math.max(...scale) + 1
      const heightRatio = (val - min) / (max - min) // 上から何%か(0～1)
      return (1 - heightRatio) * this.height
    },
    draw() {
      // canvasをクリア
      this.ctx.clearRect(0, 0, this.width, this.height)

      console.log('graph', this.graphType, this.graphData)

      // 体重or体脂肪率orBMIを抽出
      let graphTypeData = this.graphData.map(v => {
        let rv = undefined
        if (v !== undefined) {
          rv = v[this.graphType]
        }
        return rv
      })
      console.log(graphTypeData)

      // 各データのy座標を計算
      let graphValues = graphTypeData.map(v => {
        let rv = undefined
        if (v !== undefined) {
          rv = this.calcHeight(v, this.graphScale)
        }
        return rv
      })
      console.log(graphValues)

      const dx = this.width / 7 // x座標の1目盛分
      let x = dx / 2
      let lastPoint = { x: undefined, y: undefined }

      graphValues.forEach((v, i) => {
        if (v !== undefined) {
          // 点を描く
          this.drawPoint(x, v)
          if (i > 0 && lastPoint.x !== undefined) {
            // 線を引く
            // データがない日は飛ばして線をつなぐ
            this.drawLine(lastPoint.x, lastPoint.y, x, v)
          }
          lastPoint = { x: x, y: v }
        }
        x += dx
      })
    },
  },
}
</script>
<style scoped lang="scss">
.LineGraph {
  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
