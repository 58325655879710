<template lang="pug">
  div.login-required
    p.undisclosed.login-required__title {{$t("続きを読むにはログインが必要です")}}
    br
    button.large-button-style.blue-button(type="button" @click="verifyBeforeSignin") {{$t("ログインする")}}
</template>
<script>
import mixinUser from '@/mixins/user.js'

export default {
  name: 'loginRequired',
  mixins: [mixinUser],
  data() {
    return {}
  },
  methods: {},
  mounted() {},
}
</script>
<style scoped lang="scss">
.login-required {
  max-width: 400px;
  margin: 0 auto 40px;
  text-align: center;
  .login-required__title {
    font-size: 16px;
    margin-bottom: 35px;
    @include sp-layout {
      font-size: 14px;
    }
  }
}
</style>
