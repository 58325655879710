<template lang="pug">
div.Home.overflow-hidden
  .gray-bg-area(v-if="$store.state.user.authenticated")
    .container
      section.top-page-chart
        .tabs
          .tab(v-on:click="activeTab = 'stepsChart'" :class="{'active': activeTab === 'stepsChart'}") {{$t("歩数ログ")}}
          .tab(v-on:click="activeTab = 'healthChart'" :class="{'active': activeTab === 'healthChart'}") {{$t("自分ログ")}}
        StepsChart(v-if="activeTab === 'stepsChart'")
        HealthChart(v-if="activeTab === 'healthChart'")

  .container
    section.top-page-events
      HeadingBlock(level="2" text="参加する")
      .heading-read-more
        router-link(:to="{name: 'events'}") {{$t("もっと見る")}}

      //- 非ログイン状態のトップページ用イベント
      .events-block(v-if="!$store.state.user.authenticated")
        swiper(:options="swiperOption")
          swiper-slide.events-block-list(v-for="(event, index) in parsedEvents" v-if="index < 5" :key="event.id")
            router-link(:to="{name: 'event', params: {id: event.id}}")
              .event-header-block(:style="{backgroundImage: 'url(' + event.thumbnailUrl + ')'}")
              .event-footer-block
                ul.common-tags-lists(v-if="event.advertising")
                  li.common-tags-list.common-caution-tags {{$t(event.advertising)}}
                  li.common-tags-list(v-if="event.reserveStatus" :class="event.reserveStatus.status.name") {{$t(event.reserveStatus.status.text)}}
                h2.title {{ event.title }}


      //- ログイン状態のトップページ用イベント
      .events-block(v-if="$store.state.user.authenticated")
        ul.events-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
          li.col(v-for="(event, index) in parsedEvents" v-if="index < 3" :key="event.id")
            router-link(:to="{name: 'event', params: {id: event.id}}")
              .event-header-block(:style="{backgroundImage: 'url(' + event.thumbnailUrl + ')'}")
              .event-footer-block
                ul.common-tags-lists(v-if="event.advertising")
                  li.common-tags-list.common-caution-tags {{$t(event.advertising)}}
                  li.common-tags-list(v-if="event.reserveStatus" :class="event.reserveStatus.status.name") {{$t(event.reserveStatus.status.text)}}
                h2.title {{ event.title }}

  .gray-bg-area
    .container
      section.top-page-announcements
        HeadingBlock(level="2" text="&wellからのお知らせ")
        .pc
          .heading-read-more
            router-link(:to="{name: 'announcements'}") {{$t("もっと見る")}}
        AnnouncementBlock(:perPage="5")
        .sp
          .center-heading-read-more
            router-link(:to="{name: 'announcements'}") {{$t("もっと見る")}}

  .container
    section.top-page-columns
      HeadingBlock(level="2" text="学ぶ")
      ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
        li.col(v-for="item,index in libraries" v-if="index < 6") 
          router-link(:to="{name: 'library', params: {id: item.id}}")
            .column-header-block(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
            .column-footer-block
              h2.title {{ item.title }}
      .middle-link-button-style.blue-button
        router-link(:to="{name: 'libraries'}") {{$t("もっと見る")}}
</template>

<script>
import AnnouncementBlock from '@/components/AnnouncementBlock'
import StepsChart from '@/components/Home/StepsChart'
import HealthChart from '@/components/Home/HealthChart'
import HeadingBlock from '@/components/HeadingBlock'
import mixinEvent from '@/mixins/event.js'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

export default {
  components: {
    AnnouncementBlock,
    StepsChart,
    HealthChart,
    HeadingBlock,
  },
  mixins: [mixinEvent],
  data() {
    return {
      parsedEvents: [],
      announcements: [],
      activeTab: '',
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
        spaceBetween: 20,
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: 5,
        breakpoints: {
          768: {
            spaceBetween: 32,
          },
        },
      },
    }
  },
  computed: {
    ...mapState('event', { events: 'homeItems' }),
    ...mapState('libraryNew', { libraries: 'items' }),
  },

  methods: {
    parseEvents() {
      const now = moment().valueOf()
      this.parsedEvents = this.events.map(event =>
        this.parseEventData(event, now)
      )
    },
  },
  created() {
    this.activeTab = 'stepsChart'

    if (!this.events.length) {
      this.$store
        .dispatch('event/getHomeItems', {
          perPage: 5, // ログイン時：3件、未ログイン：5件
          scheduleType: 1,
        })
        .then(() => {
          this.parseEvents()
        })
    } else {
      this.parseEvents()
    }

    if (!this.libraries.length) {
      this.$store.dispatch('libraryNew/getItems', {
        perPage: 9, // 学ぶ新着とstoreを共有しているためperPageを合わせる
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_events.scss';
.events-block {
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    line-height: 1.1em;
    height: 1.1em;
    overflow: hidden;
    // margin-left: 10px;
    font-weight: bold;
  }
  .event-footer-block {
    display: flex;
    align-items: center;
  }
  .common-tags-lists {
    flex-shrink: 0;
    margin-right: 10px;
  }
}
.tabs {
  display: flex;
  justify-content: center;
  .tab {
    height: 56px;
    width: 160px;
    border-radius: 28px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include sp-layout {
      font-size: 13px;
      width: 120px;
      height: 44px;
    }
  }
  .tab.active {
    background: $base-brand-color;
    color: #fff;
  }
}
.tenantID {
  font-family: $font-gotham-bold;
}
.top-page-chart {
  padding: 48px 0 60px;
  @include sp-layout {
    padding: 40px 0 30px;
  }
}
.top-page-announcements {
  h2 {
    margin-bottom: 35px;
  }
  padding: 35px 0 55px;
  @include sp-layout {
    padding: 35px 0 48px;
  }
}
.top-page-events {
  padding: 36px 0 55px;
  @include sp-layout {
    padding: 20px 0 40px;
  }
}
.top-page-columns {
  padding: 36px 0 85px;
}
.columns-block-list {
  margin-top: 32px;
  margin-bottom: 30px;
  > li {
    margin-bottom: 40px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include link-hover();
    }
  }
  .column-thumbnail {
    width: 100%;
    display: block;
  }
  .column-header-block {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f8;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }
  .column-footer-block {
    padding-top: 14px;
  }
  .title {
    margin-top: 5px;
    font-weight: bold;
  }
}

.swiper-container {
  overflow: visible;
}
.swiper-slide {
  width: 720px;
  @include sp-layout {
    width: 300px;
  }
  .events-header-block {
    height: auto;
    padding-top: 56.25%;
    @include sp-layout {
    }
  }
}
@media (max-width: 374px) {
  .swiper-slide {
    width: 270px;
  }
}
</style>
