<!--
3-パスワード忘れ 完了
-->
<template lang="pug">
  .signin
    .common-h1-area
      HeadingBlock(level="1" text="Forgot Your Password?")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") パスワードをお忘れの場合
    .sub-page-padding
      .container
        section
          .text(v-if="this.$i18n.locale === 'en'") We've sent a password reset link to your registered email address.<br>Please access the password reset link in the email and reset your password.
          .text(v-else) ご登録のメールアドレス宛てにパスワードリセット用リンクを送信しました。<br>メールに記載されているパスワードリセットのリンクへアクセスして、パスワードリセットを行って下さい。
          router-link.large-button-style.blue-button(:to="{ name: 'signin' }") {{$t("ログイン")}}


</template>
<script>
import HeadingBlock from '@/components/HeadingBlock'
export default {
  components: {
    HeadingBlock,
  },
}
</script>
