import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const SurveyRepository = RepositoryFactory.get('survey')

const defaultState = () => ({
  items: [],
  length: 0,
})

const state = defaultState()

const mutations = {
  setItems(state, payload) {
    state.length = payload.length
    state.items = state.items.concat(payload)
  },
  init(state) {
    Object.assign(state, defaultState())
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems({ commit }) {
    try {
      const { succeed, questionnaires } = await SurveyRepository.get()
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', questionnaires)
      }
    } catch (error) {
      //
    }
  },
  resetItems({ commit }) {
    commit('resetItems')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
