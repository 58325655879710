import Vue from 'vue'
import Vuex from 'vuex'
import announcement from './modules/announcement'
import event from './modules/event'
import reservingEvent from './modules/reservingEvent'
import libraryIndex from './modules/libraryIndex'
import libraryNew from './modules/libraryNew'
import libraryTag from './modules/libraryTag'
import libraryRanking from './modules/libraryRanking'
import libraryPopularTags from './modules/libraryPopularTags'
import survey from './modules/survey'
import { RepositoryFactory } from '../repositories/RepositoryFactory'
const userRepository = RepositoryFactory.get('user')

const LocalStorage = require('localstorage-ttl')

Vue.use(Vuex)
const LOCAL_STORAGE_KEY_USER = 'user'
const LOCAL_STORAGE_KEY_LOCALE = 'locale'
const LOCAL_STORAGE_KEY_TERMS_VERSION = 'TERMS_VERSION'

const store = new Vuex.Store({
  state: {
    user: {
      token: '',
      authenticated: false,
      profileImage: '',
    },
    locale: 'ja',
  },
  mutations: {
    mLoadUser(state) {
      const user = LocalStorage.get(LOCAL_STORAGE_KEY_USER)
      if (user) {
        state.user.token = user.token
        state.user.profileImage = user.profileImage
        state.user.contract = user.contract
      }
    },
    mSaveUser(state, user) {
      state.user.token = user.token
      state.user.profileImage = user.profileImage
      state.user.contract = user.contract
      state.user.authenticated = true

      // ユーザ情報はLocalStorageに保持する
      LocalStorage.set(
        LOCAL_STORAGE_KEY_USER,
        user,
        31 * 24 * 60 * 60 * 1000 // 有効期限(ms)
      )
    },
    mClearUser(state) {
      state.user.token = ''
      state.user.profileImage = ''
      state.user.authenticated = false
      LocalStorage.set(LOCAL_STORAGE_KEY_USER, null)
    },
    mLoadLocale(state) {
      const locale = LocalStorage.get(LOCAL_STORAGE_KEY_LOCALE)
      state.locale = locale
    },
    mSaveLocale(state, locale) {
      state.locale = locale
      LocalStorage.set(LOCAL_STORAGE_KEY_LOCALE, locale)
    },
  },
  actions: {
    async getSavedToken({ commit, state }) {
      commit('mLoadUser')
      return state.user.token // LocalStorageからtokenをロード
    },
    async getUserContract({ commit, state }) {
      commit('mLoadUser')
      return state.user.contract
    },
    signedIn({ commit, dispatch }, user) {
      commit('mSaveUser', user)
      //dispatch('resetListItems')
    },
    signedOut({ commit, dispatch }) {
      commit('mClearUser')
      dispatch('resetListItems')
    },
    loadLocale(context) {
      context.commit('mLoadLocale')
    },
    saveLocale({ commit }, locale) {
      commit('mSaveLocale', locale)
      // 言語の変更があった際にStoreされた記事をリセット
      store.dispatch('resetListItems')
    },
    resetListItems({ commit }) {
      commit('announcement/resetItems')
      commit('event/resetItems')
      commit('reservingEvent/resetItems')
      commit('libraryIndex/resetItems')
      commit('libraryNew/resetItems')
      commit('libraryTag/resetItems')
      commit('libraryRanking/resetItems')
      commit('survey/resetItems')
    },
    async verifyTermsVersion() {
      const { termsVersion } = await userRepository.getConfig()
      const localTermsVersion =
        LocalStorage.get(LOCAL_STORAGE_KEY_TERMS_VERSION) || 0
      const serverTermsVersion = termsVersion || 0
      console.log(
        'TermsVersion',
        localTermsVersion,
        serverTermsVersion,
        localTermsVersion >= serverTermsVersion
      )
      return parseInt(localTermsVersion) >= parseInt(serverTermsVersion)
    },
    async updateTermsVersion() {
      const { termsVersion } = await userRepository.getConfig()
      LocalStorage.set(LOCAL_STORAGE_KEY_TERMS_VERSION, termsVersion)
    },
    // LocalSorageに保存したtokenでログインする
    async signinWithToken({ dispatch }) {
      if (store.state.user.authenticated) {
        console.log('authenticateWithToken: already auth')
        return
      }
      /*
      // tokenによるログイン時も利用規約の更新を確認する
      dispatch('verifyTermsVersion').then(valid => {
        if (!valid) {
          dispatch('signout')
        }
      })
      */

      // トークンによる認証
      try {
        const token = await dispatch('getSavedToken') // LocalStorageからtokenをロード
        const user = await KiiUser.authenticateWithToken(token)
        console.log('authenticateWithToken done.')
        if (user) {
          dispatch('notifyLoggedIn', user)
        }
      } catch (err) {
        // ログイン失敗
        console.log('authenticateWithToken: fail')
        dispatch('signedOut')
      }
      return
    },
    // idとpassで明示的にログインする
    async signin({ dispatch }, { id, password }) {
      let user = null
      try {
        user = await KiiUser.authenticate(id, password)
        if (user) {
          await user.refresh()
          dispatch('notifyLoggedIn', user)
        }
      } catch (err) {
        // ログイン失敗
        user = null
        console.log('login error', err.code, err.message)
        dispatch('signedOut')
      }
      return user
    },
    // notifyLoggedInでユーザー種別を取得
    notifyLoggedIn({ dispatch }, user) {
      // notifyLoggedInでユーザー種別を取得
      const args = { locale: this.state.locale }
      const entry = Kii.serverCodeEntry('notifyLoggedIn')
      entry.execute(args).then(params => {
        const execResult = params[2]
        const rv = execResult.getReturnedValue()
        const contract = rv.returnedValue.tenantContract
        console.log('notifyLoggedIn', rv.returnedValue)

        dispatch('signedIn', {
          token: user.getAccessToken(),
          profileImage: user.get('imageUrl'),
          contract,
        })
      })
    },
    signout() {
      KiiUser.logOut()
      store.dispatch('signedOut')
    },
  },
  modules: {
    libraryIndex,
    libraryNew,
    libraryTag,
    libraryRanking,
    libraryPopularTags,
    announcement,
    event,
    reservingEvent,
    survey,
  },
})

export default store
