// Event一覧と詳細で共通のメソッド群
//import moment from 'moment-timezone'
const moment = require('moment-timezone')
export default {
  data() {
    return {
      RESERVE_STATUS: {
        NONE: null,
        BEFORE: { text: '予約開始前', name: 'reserveStatusBefore' },
        AVAILABLE: { text: '予約受付中', name: 'reserveStatusAvailable' },
        ENDED: { text: '予約終了', name: 'reserveStatusEnded' },
        FULL: { text: '満席', name: 'reserveStatusFull' },
      },
    }
  },
  methods: {
    toggleFavorite(event) {
      this.api(
        'favoriteContent',
        {
          relType: 'Events',
          relObjectID: event.id,
          favorite: !event.isFavoritedCopy, // 今の反対をリクエスト
        },
        rv => {
          console.log(rv)
          if (!rv || !rv.succeed) {
            //
          } else {
            event.isFavoritedCopy = !event.isFavoritedCopy // 連打用にCopyを使用
          }
        }
      )
    },
    getNewestReservationStartDateTime(event) {
      const min = event.sessions.reduce((a, b) =>
        a.reservationStartDateTime < b.reservationStartDateTime ? a : b
      )
      return min.reservationStartDateTime
    },
    getOldestReservationEndDateTime(event) {
      const max = event.sessions.reduce((a, b) =>
        a.reservationEndDateTime > b.reservationEndDateTime ? a : b
      )
      return max.reservationEndDateTime
    },
    // 要予約イベントか
    isReservationRequired(event) {
      return event.reservableFlag
    },
    // 予約開始前か
    isBeforeReservation(event, now) {
      const reservationStarted = event.sessions.find(session => {
        return session.reservationStartDateTime < now
      })
      return !reservationStarted // 予約開始がひとつでもある = 予約開始前ではない
    },
    // 予約終了か
    isReservationEnded(event, now) {
      const reservationNotEnded = event.sessions.find(session => {
        return session.reservationEndDateTime > now
      })
      return !reservationNotEnded // 予約終了前がひとつでもある = 予約終了ではない
    },
    // 予約受付中か
    isReservable(event, now) {
      const hasReservableSession = event.sessions.find(session => {
        return (
          session.reservationStartDateTime < now &&
          now < session.reservationEndDateTime &&
          session.capacity > session.reservedCount
        )
      })
      return hasReservableSession
    },
    // 予約済みか
    isReserved(event) {
      return event.sessions.find(session => session.participant) ? true : false
    },
    // 満席か
    isFull(event, now) {
      const hasNotFullSession = event.sessions.find(session => {
        return (
          session.reservationStartDateTime < now &&
          now < session.reservationEndDateTime &&
          session.capacity > session.reservedCount
        )
      })
      return !hasNotFullSession // 満席でないsessionがひとつでもある = 満席でない
    },
    getReserveStatus(event, now) {
      /*
      status:予約済、開始前、終了、受付中、満席
      date:
        非表示：予約済or予約終了
        ～xx：受付中or満席
        xx～：予約開始前
      */
      let date = null
      let status = {
        status: this.RESERVE_STATUS.NONE,
        date: '',
      }
      if (!this.isReservationRequired(event)) {
        // 予約不要イベント
        return null
      }
      if (this.isBeforeReservation(event, now)) {
        // 予約開始前
        date = this.getNewestReservationStartDateTime(event)
        status.status = this.RESERVE_STATUS.BEFORE
        status.date = moment(date).format('YYYY.MM.DD(ddd)') + '～' // 'xx～'
        return status
      }
      if (this.isReservationEnded(event, now)) {
        // 予約終了
        status.status = this.RESERVE_STATUS.ENDED
        return status
      }
      if (this.isFull(event, now)) {
        // ※順番に注意。予約開始前と予約終了後の判定の後にする
        // 予約期間中かつ満席
        date = this.getOldestReservationEndDateTime(event)
        status.status = this.RESERVE_STATUS.FULL
        status.date = '～' + moment(date).format('YYYY.MM.DD(ddd)') // '～xx'
        return status
      }
      if (this.isReservable(event, now)) {
        // 予約期間中
        date = this.getOldestReservationEndDateTime(event)
        status.status = this.RESERVE_STATUS.AVAILABLE
        status.date = '～' + moment(date).format('YYYY.MM.DD(ddd)') // '～xx'
        return status
      }
      return status
    },
    isOneDayEvent(event) {
      return event.endDateTime - event.startDateTime <= 24 * 60 * 60 * 1000
    },
    getDateStr(event) {
      let str = ''
      moment.locale(this.$i18n.locale)

      if (this.isOneDayEvent(event)) {
        // 1日イベント
        str = moment(event.startDateTime).format('YYYY.MM.DD(ddd)')
      } else {
        // 期間開催
        str =
          moment(event.startDateTime).format('YYYY.MM.DD(ddd)') +
          '～' +
          moment(event.endDateTime - 1).format('MM.DD(ddd)') // endDateTimeは00:00:00を含むので-1する
      }
      return str
    },
    parseEventData(event, now) {
      let obj = {
        dateStr: this.getDateStr(event),
        thumbnailUrl: event.thumbnailImages[0].url,
        isReserved: this.isReserved(event),
        reserveStatus: this.getReserveStatus(event, now),
        isFavoritedCopy: event.isFavorited,
      }
      return Object.assign(obj, event)
    },
  },
}
