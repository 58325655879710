<template lang="pug">
  ul
    li(v-for="(choice, index) in item.choices")
      input(type="checkbox" :value="index" :id="`item${item.questionnaireIndex}-${index}`" v-model="input" @change="sendData")
      label(:for="`item${item.questionnaireIndex}-${index}`")
        | {{ choice }}
      ul.images(v-if="item.choiceImages && item.choiceImages[index]")
        li(v-for="img in item.choiceImages[index]" :key="img.id")
          img(:src="img.url")
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      input: [],
    }
  },
  methods: {
    sendData() {
      this.$emit('getData', {
        index: this.item.questionnaireIndex,
        value: this.input,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  margin: 17px 0 0 0;
  gap: 32px 16px;
  align-items: center;

  li {
    display: inline-block;

    input[type='checkbox'] {
      display: none;
    }
    label {
      margin-bottom: 8px;
      padding-left: 45px;
      position: relative;
      display: inline-block;
      line-height: 35px;
    }
    input[type='checkbox'] + label::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      width: 35px;
      height: 35px;
      background-color: $base-bg-gray-color;
      border-radius: 3px;
    }
    input[type='checkbox']:checked + label::after {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 10px;
      bottom: 0;
      margin: auto 0;
      width: 15px;
      height: 21px;
      border-right: 4px solid $base-brand-color;
      border-bottom: 4px solid $base-brand-color;
      transform: rotate(45deg);
    }
    .images {
      margin-top: 12px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
