<template lang="pug">
.locale-select-area
  .btn-close(@click="hideLocaleSelectModal")
  .locale-select-inner
    h2.title 言語設定 Language
    template(v-if="updated")
      div.alert {{ warningText[[$i18n.locale]] }}
    ul(class="LocaleSelect")
      li
        input(type="radio" name="localeselect" id="LocaleSelect1" v-model="$i18n.locale" value="ja" @change="changeLocale")
        label(for="LocaleSelect1") {{$t("日本語")}}
      li
        input(type="radio" name="localeselect" id="LocaleSelect2" v-model="$i18n.locale" value="en" @change="changeLocale")
        label(for="LocaleSelect2") {{$t("英語")}}
    p.note {{ $t("※このアプリは日本語をベースにして作成されています。英語への切り替えは自動翻訳によって行われますので固有名詞などの単語は、正しく翻訳されない場合がございます。ご了承ください。")}}
    //- button#enter.large-button-style.blue-button(type="button" @click="hideLocaleSelectModal") OK
</template>
<script>
import mixinUser from '@/mixins/user.js'

export default {
  name: 'LocaleSelect',
  mixins: [mixinUser],
  data() {
    return {
      warningText: {
        ja: '言語設定を日本語に変更しました',
        en: 'Changed language setting to English.',
      },
      updated: false,
    }
  },
  methods: {
    changeLocale(e) {
      const locale = e.target.value
      this.updated = false
      this.api('updateLocale', { locale }, rv => {
        if (!rv || !rv.succeed) {
          console.log('updateLocale', rv)
        } else {
          this.updated = true
          //console.log('updateLocale', rv)
        }
      }) // 言語設定更新をサーバーに通知する
      this.$store.dispatch('saveLocale', locale) // ローカルストレージに保存
    },
  },
}
</script>

<style scoped lang="scss">
.locale-select-area {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .locale-select-inner {
    width: 400px;
    height: 320px;
    max-width: 90%;
  }
  .btn-close {
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background: image-url('icon_batsu.svg') no-repeat center;
    background-size: 45%;
    @include sp-layout {
      width: 45px;
      height: 45px;
    }
  }
  .title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    @include sp-layout {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .note {
    font-size: 13px;
    margin-bottom: 20px;
    @include sp-layout {
      font-size: 12px;
    }
  }
  .LocaleSelect {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    > li {
      display: flex;
      align-items: center;
      padding: 0;
    }
    > li + li {
      border-top: 1px solid $tags-color-gray;
    }
    label {
      padding: 8px;
      flex-grow: 1;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1.7s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
