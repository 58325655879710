import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import moment from 'moment-timezone'
import lib from '@/lib'
import VueI18n from 'vue-i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLine,
  faFacebook,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
import VueScrollTo from 'vue-scrollto'
import VueClipboard from 'vue-clipboard2'
import VueGtag from 'vue-gtag'

require('@/assets/scss/global.scss')

process.env.NODE_ENV !== 'development' && (console.log = () => {})

Vue.config.productionTip = false

// IE判定 (this.$isIEでグローバルに使用可)
Vue.prototype.$isIE = /MSIE|Trident/.test(window.navigator.userAgent)

// Font-Awesome
library.add(faLine, faFacebook, faTwitter, faCopy, faSearch, faTimes)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueClipboard)
Vue.mixin(lib)
Vue.use(VueI18n)
Vue.use(VueScrollTo)
Vue.use(VueGtag, {
  config: { id: 'G-0NQTBM53RG' },
})

// Awesome Vue Swiper
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

const i18n = new VueI18n({
  locale: 'ja',
  fallbackLocale: 'en',
  messages: require('@/lang.json'),
  dateTimeFormats: require('@/dateTimeFormats.json'),
  silentTranslationWarn: true,
  silentFallbackWarn: true,
})

// 日付フォーマット用フィルタ
// https://momentjs.com/
Vue.filter('moment', (value, format) => {
  moment.tz.setDefault('Asia/Tokyo')
  moment.locale(i18n.locale)
  if (!format) format = 'YYYY.MM.DD'
  return moment(value).format(format)
})

// 1000 -> 1,000
Vue.filter('number', value => {
  const number = new Number(value)
  return number.toLocaleString()
})

// 150文字制限処理 & 改行処理
Vue.filter('truncate', function(value) {
  let newVal = ''
  const length = 150
  const ommision = '...'
  if (value && value.length <= length) {
    newVal = value.replace(/\n/g, '<br/>')
    return newVal
  }
  if (value && value.length > length) {
    newVal = value.replace(/\n/g, '<br/>')
    newVal = newVal.substring(0, length) + ommision
    return newVal
  }
})

// テキスト処理(改行とリンク)
Vue.filter('text2html', value => {
  if (!value) return ''
  value = value
    .replace(/(https?:\/\/[^\s\n]*)/g, '<a target="_blank" href="$1">$1</a>') // URLをリンクに
    .replace(/\n/g, '<br/>') // 改行を<br>に

  return value
})

// Kii
Kii.initializeWithSite(
  process.env.VUE_APP_APP_ID,
  process.env.VUE_APP_APP_KEY,
  process.env.VUE_APP_APP_LOCATION
)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
