const LocalStorage = require('localstorage-ttl')

export default {
  methods: {
    // ログイン前に利用規約のバージョンを確認
    verifyBeforeSignin() {
      this.saveRouteBeforeSignin() // ログイン前のURLを記録
      this.$store.dispatch('verifyTermsVersion').then(valid => {
        if (valid) {
          this.$router.push({ name: 'signin' })
        } else {
          this.$router.push({ name: 'terms' })
        }
      })
    },
    showMailMagazineModal() {
      this.$modal.show('mailmagazine-select')
    },
    hideMailMagazineModal() {
      this.$modal.hide('mailmagazine-select')
    },
    showLocaleSelectModal() {
      this.$modal.show('locale-select')
    },
    hideLocaleSelectModal() {
      this.$modal.hide('locale-select')
    },
    loadRouteBeforeSignin() {
      const fullPath = LocalStorage.get('ROUTE_BEFORE_SIGNIN') || '/'
      return fullPath
    },
    saveRouteBeforeSignin() {
      const fullPath = this.$route.fullPath
      console.log('saveRouteBeforeSignin', fullPath)
      LocalStorage.set('ROUTE_BEFORE_SIGNIN', fullPath)
    },
  },
}
