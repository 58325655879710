import Repository from './Repository'

export default {
  /*
  get(offset, perPage, onlyOwn) {
    return Repository('getReportArticles', {
      offset: offset,
      perPage: perPage,
      onlyOwn: onlyOwn,
    })
  },
  */
  getIndex() {
    return Repository('getReportArticleTop')
  },
  getNew(offset, perPage) {
    // Servercodeの仕様で10件固定
    return Repository('getNewArrivalReportArticles', {
      offset: offset,
      perPage: perPage,
    })
  },
  getTag(tagID, genreTagID, sortBy, offset, perPage) {
    return Repository('getReportArticlesByTag', {
      tagID: tagID,
      genreTagID: genreTagID,
      sortBy: sortBy,
      offset: offset,
      perPage: perPage,
    })
  },
  getRanking() {
    return Repository('getReportArticleRanking')
  },
  getPopularTags() {
    return Repository('getReportArticlePopularTags')
  },
}
