export const messages = {
  invalid: {
    required: {
      text: {
        ja: '必須項目の入力をご確認ください。',
        en: 'Please fill in all the required fields.',
      },
    },
    required_short: {
      text: {
        ja: '必須項目の入力をご確認ください。',
        en: 'Please fill in all the required fields.',
      },
    },
  },
  error: {
    unknown: {
      text: {
        ja: '不明なエラーが発生しました。時間を空けてまたお試しください。',
        en:
          'An unknown error has occurred. Please give it some time and try again.',
      },
    },
  },
}

export const labels = {
  form: {
    send: {
      text: {
        ja: '送信する',
        en: 'Send',
      },
    },
  },
}
