<i18n>
{
  "ja":{
    "健康管理": "健康管理",
    "食事":"食事",
    "休息":"休息",
    "運動":"運動",
    "メンタルヘルス":"メンタルヘルス"
  },
  "en":{
    "健康管理": "Health management",
    "食事":"Meals",
    "休息":"Rest",
    "運動":"Exercise",
    "メンタルヘルス":"Mental health"
  }
}
</i18n>
<template lang="pug">
  .Library(v-if="indexData")
    .common-h1-area
      HeadingBlock(level="1" text="Library")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") 学ぶ
      p.heading-block-note {{$t("法人契約でご利用の方以外は閲覧できないコンテンツがあります")}}
    .container
      SearchWord
    .sub-page-padding-kai
      section.pickup(v-if="indexData.pickups && indexData.pickups.length > 0")
        .container-cover
          .container
            .ttl-block
              h2.sub-ttl Pickup
            template(v-if="indexData.pickups.length < 3")
              ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
                li.col(v-for="item,index in indexData.pickups" :key="item.id" v-if="index < 3")
                  router-link(:to="{name: 'library', params: {id: item.id}}")
                    .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
                    .pickupText(v-html="item.description")
            template(v-else)
              .heading-read-more
                button.prev-button(@click.prevent="slidePrev")
                button.next-button(@click.prevent="slideNext")
              hooper(ref="carousel" @slide="updateCarousel" :settings="hooperSettings")
                slide(v-for="item in indexData.pickups" :key="item.id")
                  router-link(:to="{name: 'library', params: {id: item.id}}")
                    .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }") 
                    .pickupText(v-html="item.description")
      .container
        section.articles(v-if="indexData.newArrivals")
          .ttl-block
            h2.sub-ttl {{$t("新着")}}
            .heading-read-more
              router-link.more(:to="{path: 'library/new'}") {{$t("もっと見る")}}
          ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
            li.col(v-for="item,index in indexData.newArrivals" :key="item.id" v-if="index < 3")
              router-link(:to="{name: 'library', params: {id: item.id}}")
                .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
                .column-footer-block
                  h3._title {{item.title}}
    .gray-bg-area.tag-index-search
      .container
        section.tags
          .ttl-block
            h2.sub-ttl {{$t("目的から探す")}}
          ul.tag-master-lists
            li(v-for="item in indexData.tags" :key="item.id")
              router-link(:to="{name: 'libraryTag', params: {id: item.id}}")= "#{{item.name}}"
    .sub-page-padding
      .container
        section.articles(v-if="rankingData && rankingData.length")
          .ttl-block
            h2.sub-ttl {{$t("ランキング")}}
            .heading-read-more
              router-link.more(:to="{path: 'library/ranking'}") {{$t("もっと見る")}}
          ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3.ranking-header-icon
            li.col(v-for="item,index in rankingData" :key="item.id" v-if="index < 3" :data-ranking="index + 1")
              router-link(:to="{name: 'library', params: {id: item.id}}")
                .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
                .column-footer-block
                  h3._title {{item.title}}

      .container(v-for="taged in indexData.tagedLists")
        section.articles(v-if="taged.reportArticles.length")
          .ttl-block
            h2.sub-ttl {{'#'+taged.tag.name}}
            .heading-read-more
              router-link.more(:to="{name: 'libraryTag', params: {id: taged.tag.id}}") {{$t("もっと見る")}}
          ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
            li.col(v-for="item,index in taged.reportArticles" :key="item.id" v-if="index < 3")
              router-link(:to="{name: 'library', params: {id: item.id}}")
                .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
                .column-footer-block
                  h3._title {{item.title}}
</template>

<script>
/*
master1	喫煙
master2	運動
master3	日常の歩行・身体活動
master4	歩行速度
master5	遅い夕食
master6	夜食・間食
master7	食べる速度
master8	朝食ぬき
master9	飲酒の頻度
master10	飲酒量
master11	睡眠・休養
genre1	健康管理 Health management
genre2	食事 Meals
genre3	休息 Rest
genre4	運動 Exercise
genre5	メンタルヘルス Mental health
*/
import { mapState } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'
import { Hooper, Slide } from 'hooper'
import SearchWord from '@/components/Library/SearchWord'

export default {
  components: {
    HeadingBlock,
    Hooper,
    Slide,
    SearchWord,
  },
  data() {
    return {
      carouselData: 0,
      hooperSettings: {
        itemsToShow: 1,
        wheelControl: false,
        infiniteScroll: true,
        breakpoints: {
          426: {
            itemsToShow: 2,
          },
          768: {
            itemsToShow: 3,
          },
        },
      },
    }
  },
  watch: {
    locale() {
      this.$store.dispatch('libraryIndex/getItems')
      this.$store.dispatch('libraryRanking/getItems')
    },
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData)
    },
  },
  computed: {
    ...mapState('libraryIndex', { indexData: 'items' }),
    ...mapState('libraryRanking', { rankingData: 'items' }),
    ...mapState(['locale']),
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev()
    },
    slideNext() {
      this.$refs.carousel.slideNext()
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide
    },
  },
  created() {
    if (!this.indexData) {
      this.$store.dispatch('libraryIndex/getItems')
    }
    if (!this.rankingData.length) {
      this.$store.dispatch('libraryRanking/getItems')
    }
  },
}
</script>
<style>
.pickupText b {
  font-weight: 700;
}
</style>
<style scoped lang="scss">
.Library {
  .pickup {
    margin-bottom: 60px;

    .thumbnail__image {
      padding-top: 41.666666%;
    }
    .pickupText {
      margin-top: 16px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .articles {
    margin-bottom: 15px;
  }
  .pickup,
  .articles {
    img {
      max-width: 100%;
      display: block;
    }
  }
  .tags {
    margin-bottom: 10px;
  }
  .genres {
    .common-tags-lists {
      display: flex;
    }
    .genre-list__child {
      height: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 48px;
        border-radius: 6px;
        text-align: center;
        font-weight: bold;
        padding-top: 90px;
      }
    }
    .genre-list__1 a {
      background-image: url('../../assets/images/icon_tag_health.svg');
      background-position: 50% calc(50% - 15px);
    }
    .genre-list__2 a {
      background-image: url('../../assets/images/icon_tag_meals.svg');
      background-position: 50% calc(50% - 15px);
    }
    .genre-list__3 a {
      background-image: url('../../assets/images/icon_tag_relax.svg');
      background-position: 50% calc(50% - 15px);
    }
    .genre-list__4 a {
      background-image: url('../../assets/images/icon_tag_walklog.svg');
      background-position: 50% calc(50% - 15px);
    }
    .genre-list__5 a {
      background-image: url('../../assets/images/icon_tag_mentalhealth.png');

      background-position: 50% calc(50% - 15px);
    }
  }
  .sub-ttl {
    font-size: 28px;
    border-left: 4px solid #e60012;
    padding-left: 14px;
    line-height: 1.05;
    font-weight: bold;
    margin: 20px 0 20px;
    @include sp-layout {
      font-size: 20px;
    }
  }
  .tag-index-search {
    padding: 50px 0 55px;
  }
  .ttl-block {
    margin-bottom: 35px;
  }
  .column-footer-block {
    padding-top: 14px;
    ._title {
      margin-top: 5px;
      font-weight: bold;
    }
  }
  .carousel-lists {
    display: flex;
    overflow-x: visible;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    // padding-right: 25px;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    // margin-bottom: 20px;
    > li {
      scroll-snap-align: start;
      white-space: normal;
    }
  }
  .container-cover {
    max-width: 100%;
    overflow: hidden;
  }

  .prev-button {
    margin-top: -5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #f6f6f8;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 4px;
      margin: auto;
      border-top: 2px solid #111;
      border-right: 2px solid #111;
      transform: rotate(-135deg);
    }
  }
  .next-button {
    margin-top: -5px;
    margin-left: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #f6f6f8;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      top: 0;
      right: 3px;
      bottom: 0;
      left: 0;
      margin: auto;
      border-top: 2px solid #111;
      border-right: 2px solid #111;
      transform: rotate(45deg);
    }
  }
  .sub-page-padding-kai {
    padding: 55px 0 20px;
  }
  .columns-block-list > li {
    margin-bottom: 40px;
  }
  .thumbnail__image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f8;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }
}
</style>

<style lang="scss">
.hooper-track .hooper-slide {
  border-right: 15px solid transparent;
  //margin-right: 30px;
}
</style>
