const formSettings = {
  invitationCode: {
    required: true,
    label: '専用コード',
  },
  userName: {
    required: true,
    label: '氏名',
  },
  userPhonetic: {
    required: true,
    label: '氏名(フリガナ)',
  },
  userNickname: {
    required: true,
    label: 'ニックネーム',
  },
  externalAccountId: {
    required: false,
    label: '外部サービス会員ID',
    value: null,
  },
  userEmailAddress: {
    required: true,
    label: 'メールアドレス',
  },
  userEmailAddress2: {
    required: true,
    label: 'メールアドレス確認用',
  },
}

export default formSettings
