<template lang="pug">
//- 自分のプロフィールページ
.account
  .account-bg-image-area(:style="[userData.profile.profile && userData.profile.profile.backgroundImageUrl ? { backgroundImage: 'url(' + userData.profile.profile.backgroundImageUrl + ')'} : {'background-image': 'none'} ]")
  .account-header-data-area
    .container
      h1.hidden {{$t("プロフィール")}}
      .account-image(:style="[userData.profile.profile && userData.profile.profile.profileImageUrl ? {'background-image': 'url(' + userData.profile.profile.profileImageUrl + ')'} : {'background-image': 'url(' + defaultThumbnailImage + ')'} ]")
      .account-header-data
        .account-name-wrapper
          .account-name(v-if="userData.profile.profile && userData.profile.profile.nickname") {{ userData.profile.profile.nickname }}
          .account-attribution-list
            ul
              li.attribution.attribution1(v-if="userData.profile.profile && userData.profile.profile.attribution1") {{ userData.profile.profile.attribution1 }}
        .account-comment(v-if="userData.profile.profile && userData.profile.profile.comment") {{ userData.profile.profile.comment }}
      .account-sp-menu.sp
        ul
          li
            router-link(:to="{ name: 'profile' }") {{$t("プロフィール")}}
          li 
            router-link(:to="{ name: 'friends' }") {{$t("友達")}}
          li
            router-link(:to="{ name: 'healthStats' }") {{$t("生活習慣レポート")}}

  .sub-page-padding.account-main-content-bg
    .container
      .account-main-content
        .left-col.pc
          ul.account-left-col-lists
            li
              router-link(:to="{ name: 'profile' }") {{$t("プロフィール")}}
            li
              router-link(:to="{ name: 'friends' }") {{$t("友達")}}
            li
              router-link(:to="{ name: 'healthStats' }") {{$t("生活習慣レポート")}}
        .right-col
          router-view(:userData="userData")

</template>

<script>
import moment from 'moment-timezone'
import HeadingBlock from '@/components/HeadingBlock'

export default {
  components: {
    HeadingBlock,
  },
  data() {
    return {
      defaultThumbnailImage: require('@/assets/images/icon_user.svg'),
      userData: {
        userInfo: {},
        profile: [],
        healthData: [],
        stepsData: [],
        individualMatches: [],
        fromDate: '',
        toDate: '',
        bestWeight: 0,
        targetWeight: 0,
        rank: 0,
        rankTotal: 0,
        average: 0,
      },
    }
  },
  methods: {
    getUserInfo() {
      this.userData.userInfo = {
        name: this.userData.profile.profile.nickname,
        comment: this.userData.profile.profile.comment,
        birthYear: this.userData.profile.personalInformation.birthYear,
        imageUrl: this.userData.profile.profile.profileImageUrl,
        height: this.userData.profile.personalInformation.height,
        gender: this.userData.profile.personalInformation.gender,
        attribution1: this.userData.profile.profile.attribution1,
      }
    },
    getStepsData() {
      // 今週の月曜と日曜
      const fromDateTime = moment()
        .day(1)
        .startOf('day')
        .valueOf() // 1=monday
      const toDateTime = moment()
        .day(7)
        .startOf('day')
        .valueOf() // 7=sunday
      const args = {
        fromDateTime,
        toDateTime,
        needsWeeklyAverage: true,
        needsStepStatistics: true,
        needsInterview: true,
        needsWeight: true,
        needsBMI: true,
        needsBodyFatPercentage: true,
      }

      this.api('getHealthDataByRange', args, rv => {
        console.log(rv)
        this.userData.stepsData = rv.stepsData
        if (rv.targetWeight) {
          this.userData.targetWeight = rv.targetWeight.weight
        }

        // 平均歩数
        let totalSteps = 0
        if (this.userData.stepsData) {
          this.userData.stepsData.forEach(v => {
            totalSteps += v.steps
          })
          this.userData.average = Math.round(
            totalSteps / this.userData.stepsData.length
          )
        }

        // ランキング
        if (rv.weeklyStepsData && rv.weeklyStepsData.length > 0) {
          const stat = rv.weeklyStepsData[0].stepStatistics
          this.userData.rank = stat.rank
          this.userData.rankTotal = stat.maxRank
        }
      })
    },

    //後で消すメソッド
    // makeFriendship(inviteeID) {
    //   this.api('makeFriendship', { inviteeID }, rv => {
    //     console.log(rv)
    //   })
    // },

    calcBestWeight() {
      const h = this.userData.userInfo.height
      const bmi = 22 * ((h / 100) * (h / 100))
      this.userData.bestWeight = this.orgRound(bmi, 10)
    },
    orgRound(value, base) {
      return Math.round(value * base) / base
    },
    display() {
      this.getUserInfo()
      this.getStepsData()

      if (this.userData.userInfo.height) {
        this.calcBestWeight()
      }
    },
  },
  mounted() {
    this.api('getMyProfile', {}, rv => {
      this.userData.profile = rv
      this.display()
    })
  },
}
</script>

<style lang="scss">
.account {
  .account-bg-image-area {
    width: 100%;
    height: 240px;
    background-size: cover;
    background-position: center;
    background-color: $base-bg-gray-color;
    @include sp-layout {
      height: 104px;
    }
  }
  .account-header-data {
    padding: 34px 0 34px 200px;
    @include sp-layout {
      padding: 0;
    }
  }
  .account-sp-menu {
    > ul {
      display: flex;
      > li {
        min-width: 55px;
        a {
          display: block;
          padding: 12px 0;
          text-align: center;
          &.router-link-exact-active {
            color: $base-brand-color;
            border-bottom: 2px solid $base-brand-color;
            pointer-events: none;
          }
        }
      }
      > li + li {
        margin-left: 15px;
      }
    }
  }
  .account-image {
    position: absolute;
    margin-top: -30px;
    display: inline-block;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    background-color: $base-bg-color;
    background-position: center;
    background-repeat: no-repeat;
    border: 10px solid $base-bg-color;
    overflow: hidden;
    background-size: cover;
    @include sp-layout {
      position: static;
      height: 64px;
      width: 64px;
      border: 2px solid $base-bg-color;
    }
    &._default {
      background: image-url('main_logo.svg') no-repeat center
        $base-bg-gray-color;
      background-size: 100px;
    }
  }
  .account-name {
    font-size: 24px;
    font-weight: bold;
    @include sp-layout {
      font-size: 16px;
    }
  }
  .account-name-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    @include sp-layout {
      display: block;
    }
  }
  .account-attribution-list {
    margin-left: 25px;
    @include sp-layout {
      margin-left: 0;
    }
    > ul {
      display: flex;
      font-size: 13px;
      color: $gray-color;
    }
    .attribution {
      position: relative;
    }
    .attribution + .attribution {
      margin-left: 16px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -11px;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 5px;
        height: 5px;
        border-top: 1px solid $gray-color;
        border-left: 1px solid $gray-color;
        transform: rotate(135deg);
      }
    }
  }
  .account-comment {
    margin-bottom: 15px;
  }
  .account-main-content-bg {
    background-color: $base-bg-gray-color;
  }
  .account-main-content {
    display: flex;
    justify-content: space-between;
    .left-col {
      width: 320px;
    }
    .right-col {
      width: 760px;
      @include sp-layout {
        width: 100%;
      }
    }
    .content-section {
      background-color: $base-bg-color;
      padding-top: 20px;
      padding-bottom: 40px;
      @include sp-layout {
        padding-top: 11px;
        padding-bottom: 20px;
        font-size: 13px;
      }
      h2 {
        @include sp-layout {
          font-size: 16px;
        }
      }
    }
    .content-section + .content-section {
      margin-top: 32px;
    }
    .data-list {
      > div {
        display: flex;
        justify-content: space-between;
        padding: 18px 0;
        flex-wrap: wrap;
        > dt {
          width: 50%;
          text-align: left;
        }
        > dd {
          width: 50%;
          text-align: right;
        }
        @include sp-layout {
          padding: 16px 0;
        }
      }
      > div + div {
        border-top: 1px solid $tags-color-gray;
      }
    }
    .content-section-inner {
      padding: 0 40px;
      @include sp-layout {
        padding: 0 18px;
      }
    }
  }
  .average-weight-area {
    display: flex;
    margin-top: 17px;
    ._left,
    ._right {
      width: 48%;
      background-color: $base-bg-gray-color;
      border-radius: 6px;
      padding: 32px;
      @include sp-layout {
        padding: 14px;
      }
    }
    ._right {
      margin-left: 4%;
      ._num {
        color: $base-brand-color;
      }
    }
    ._ttl {
      margin-bottom: 6px;
    }
    ._num {
      font-size: 28px;
      font-family: $font-gotham-bold;
      @include sp-layout {
        font-size: 20px;
      }
    }
  }
  .private-setting-info {
    display: flex;
    justify-content: flex-end;
    margin-top: -36px;
    margin-bottom: 25px;
  }
  .account-left-col-lists {
    margin-top: 35px;
    padding-left: 25px;
    li {
      a {
        color: $gray-color;
        cursor: pointer;
        &.router-link-exact-active {
          color: $base-brand-color;
          position: relative;
          pointer-events: none;
          &::after {
            content: '';
            position: absolute;
            left: -16px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $base-brand-color;
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li + li {
      margin-top: 18px;
    }
  }
  .account-setting-list {
    > li {
      position: relative;
      a {
        display: block;
        padding: 18px 0;
        @include link-hover();
      }
      button {
        display: block;
        padding: 18px 0;
        @include link-hover();
        font-size: 100%;
        width: 100%;
        height: 100%;
        text-align: left;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
        width: 10px;
        height: 10px;
        border-top: 2px solid $gray-color;
        border-left: 2px solid $gray-color;
        transform: rotate(135deg);
      }
    }
    > li + li {
      border-top: 1px solid $tags-color-gray;
    }
  }
}
.space {
  height: 100px;
  width: 100%;
}
</style>
