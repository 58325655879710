<template lang="pug">
//- イベント予約お気に入りページ
.eventsRese
  .tag-h1-area
    .container
      h1._mainttl {{$t("予約済み/お気に入り")}}
  .sub-page-padding
    .container
      .events-block
        template(v-if="reservingEvents.length")
          Events(:events="reservingEvents")

        .events-readmore-block.center(:class="{ 'hide' : !more }")
          button.middle-button-style.blue-button(@click="readMore") {{$t("もっと見る")}}

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import HeadingBlock from '@/components/HeadingBlock'
import Events from '@/components/Events'
import LoginRequired from '@/components/LoginRequired'

export default {
  components: {
    HeadingBlock,
    Events,
    LoginRequired,
  },
  data() {
    return {
      perPage: 9,
    }
  },
  computed: {
    more() {
      return this.reservingLength === this.perPage
    },
    ...mapState('reservingEvent', { reservingEvents: 'items' }),
    ...mapState('reservingEvent', { reservingLength: 'length' }),
    ...mapState(['locale']),
  },
  methods: {
    readMore() {
      this.$store.commit('reservingEvent/incrementPage')
      this.$store.dispatch('reservingEvent/getItems', {
        perPage: this.perPage,
      })
    },
  },

  created() {
    if (!this.reservingEvents.length) {
      this.$store.dispatch('reservingEvent/getItems', this.perPage)
    } else if (this.reservingEvents.length < this.perPage) {
      this.$store.commit('reservingEvent/resetItems')
      this.$store.dispatch('reservingEvent/getItems', this.perPage)
    }
  },
}
</script>

<style lang="scss" scoped></style>
