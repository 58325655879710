<i18n>
{
  "ja":{
    "ReportArticles": "",
    "Events": "イベント",
    "IndividualMatches": "歩数戦",
    "TeamMatches":"歩数戦",
    "関連コンテンツ":"関連コンテンツ",
    "こちらもおすすめ":"こちらもおすすめ"
  },
  "en":{
    "ReportArticles": "",
    "Events": "Events",
    "IndividualMatches": "Step competitions",
    "TeamMatches":"Step competitions",
    "関連コンテンツ":"Related Contents",
    "こちらもおすすめ":"Recommended Contents"
  }
}
</i18n>
<template lang="pug">
.library
  .sub-page-padding
    .container
      article.article-content
        h1.title {{library.title}}
        //- .date {{library.startDateTime | moment}}

        ImageViewerWithThumbnail(:images="library.images" :uid="library.id")

        template(v-if="!$store.state.user.authenticated")
          LoginRequired

        template(v-else)
          section.intro(v-if="library.relatedEvent")
            table.article-table
              tbody
                tr
                  //- イベントの場合は開催日、会場、参加費、要予約 を表示
                  template(v-if="(library.relatedEvent.endDateTime - library.relatedEvent.startDateTime) <= 24*60*60*1000")
                    //- 1日開催
                    th {{$t("開催日")}}
                    td {{library.relatedEvent.startDateTime | moment('LL')}}
                  template(v-else)
                    //- 期間開催
                    th {{$t("開催期間")}}
                    td {{library.relatedEvent.startDateTime | moment('LL')}} ～ {{library.relatedEvent.endDateTime | moment('LL')}}
                tr
                  th {{$t("会場")}}
                  td {{library.relatedEvent.place}}
                tr
                  th {{$t("参加費")}}
                  td(v-if="library.relatedEvent.price") {{library.relatedEvent.price}} {{$t("円")}}
                  td(v-else) {{$t("無料")}}
                tr(v-if="library.relatedEvent.extraText")
                  th {{library.relatedEvent.extraLabel || $t("注意事項")}}
                  td {{library.relatedEvent.extraText}}
            .reltype-link
              router-link(:to="{name: 'event', params: {id: library.relatedEvent.id} }")
                button.middle-button-style.blue-button.w-100 {{$t("イベント")}}

          section.intro(v-if="library.relatedIndividualMatch")
            //- 開催期間を表示
            table.article-table
              tbody
                tr
                  th {{$t("開催期間")}}
                  td {{library.relatedIndividualMatch.startDateTime | moment('LL')}} ～ {{library.relatedIndividualMatch.endDateTime | moment('LL')}}

          section.intro(v-if="library.relatedTeamMatch")
            //- 開催期間を表示
            table.article-table
              tbody
                tr
                  th {{$t("開催期間")}}
                  td {{library.relatedTeamMatch.startDateTime | moment}} ～ {{library.relatedTeamMatch.endDateTime | moment}}

          SectionContents(:sections="library.sections" :libraryId="library.id")

          ShareButton(:fullPath="$route.fullPath", :title="library.title")

  .gray-bg-area(v-if="$store.state.user.authenticated")
    .container
      section.tags.content-800
        ul.tag-master-lists(v-if="library.tags")
          li(v-for="item in library.tags" :key="item.id")
            router-link(:to="{name: 'libraryTag', params: {id: item.id}}") {{item.name}}
  .sub-page-padding(v-if="$store.state.user.authenticated")
    .container
      section.content-800(v-if="library.relatedReportArticles && library.relatedReportArticles.length")
        .ttl-block
          h2.sub-ttl {{$t("関連コンテンツ")}}
        ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
          li.col(v-for="item,index in library.relatedReportArticles" :key="item.id" v-if="index < 3")
            router-link(:to="{name: 'library', params: {id: item.id}}")
              .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
              .column-footer-block
                h3._title {{item.title}}

      section.content-800(v-if="library.recommendedReportArticles && library.recommendedReportArticles.length")
        .ttl-block
          h2.sub-ttl {{$t("こちらもおすすめ")}}
        ul.columns-block-list.row.row-cols-1.row-cols-sm-2.row-cols-md-3
          li.col(v-for="item,index in library.recommendedReportArticles" :key="item.id" v-if="index < 3")
            router-link(:to="{name: 'library', params: {id: item.id}}")
              .thumbnail__image(:style="{ backgroundImage: 'url(' + item.imageUrl + ')' }")
              .column-footer-block
                h3._title {{item.title}}


</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import mixinMetaTag from '@/mixins/metaTag.js'
import LoginRequired from '@/components/LoginRequired'
import ImageViewerWithThumbnail from '@/components/ImageViewerWithThumbnail'
import SectionContents from '@/components/SectionContents'
import ShareButton from '@/components/ShareButton'
export default {
  components: {
    HeadingBlock,
    LoginRequired,
    ImageViewerWithThumbnail,
    SectionContents,
    ShareButton,
  },
  mixins: [mixinMetaTag],
  data() {
    return {
      library: {},
    }
  },
  methods: {
    draw(id) {
      if (!id) id = this.$route.params.id
      this.api('getReportArticleById', { id }, rv => {
        if (!rv || !rv.succeed) {
          this.$router.push({
            path: '/notFound',
          })
        } else {
          console.log(rv)
          this.library = rv
          this.setArticleTitleAsMeta(this.library.title)
          this.setArticleDescriptionAsMeta(this.library.sections)
        }
      })
    },
  },
  created() {
    this.draw()
  },
  beforeRouteUpdate(to, from, next) {
    // URL の id が変わったときは再描画する
    this.draw(to.params.id)
    next()
  },
}
</script>

<style scoped lang="scss">
.library {
  section {
    padding: 1px 0;
  }
  .title {
    padding: 5px 0 12px;
    margin-bottom: 40px;
    border-bottom: 1px solid $tags-color-gray;
  }
  .article-content {
    max-width: 800px;
    margin: 0 auto;
  }
  .intro {
    margin-bottom: 56px;
  }
  .reltype-link {
    margin-top: 30px;
  }
  img {
    max-width: 100%;
    display: block;
  }
  .main-image {
    margin-bottom: 35px;
  }
  .content-800 {
    max-width: 800px;
    margin: 0 auto;
  }
  section.tags {
    padding: 40px 0 25px;
  }
  .sub-ttl {
    font-size: 28px;
    border-left: 4px solid #e60012;
    padding-left: 14px;
    line-height: 1.05;
    font-weight: bold;
    margin: 20px 0 20px;
    @include sp-layout {
      font-size: 20px;
    }
  }
  .tag-index-search {
    padding: 50px 0 55px;
  }
  .ttl-block {
    margin-bottom: 35px;
  }
  .column-footer-block {
    padding-top: 9px;
    ._title {
      margin-top: 5px;
      font-weight: bold;
    }
  }
  .columns-block-list {
    > li {
      margin-bottom: 20px;
    }
  }
  .thumbnail__image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f6f6f8;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  }
}
</style>
