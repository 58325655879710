<template lang="pug">
//-  アコーディオン コンポーネント
//-  [設置例]
//-  AccordionMenu
//-    template(slot="title") ここに質問タイトル
//-    template(slot="content") ここに質問の中身ですここに質問の中身ですここに質問の中身です

.accordion(:class="{ open : isOpen }")
  .accordion-ttl(@click="toggle()")
    slot(name="title")
    transition(name="arrow")
      .arrow
  transition(name="toggle")
    .accordion-content(v-show="isOpen")
      slot(name="content")
</template>

<script>
export default {
  name: 'AccordionMenu',
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion.open {
  .arrow {
    transform: rotate(45deg) translate(25%, 25%);
  }
}
.accordion-ttl {
  position: relative;
  background-color: $base-bg-gray-color;
  cursor: pointer;
  padding: 15px 40px 15px 20px;
  font-weight: bold;
  .arrow {
    content: '';
    position: absolute;
    transition: 0.2s linear;
    top: 0;
    bottom: 0;
    right: 25px;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $gray-color;
    border-left: 2px solid $gray-color;
    transform: rotate(225deg);
  }
}
.accordion-content {
  padding: 15px 20px;
}
.toggle-enter-active {
  animation: toggle 0.2s;
}
.toggle-leave-active {
  animation: toggle 0.2s linear reverse;
}
.accordion + .accordion {
  margin-top: 25px;
}
@keyframes toggle {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
