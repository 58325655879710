import Repository from './Repository'

export default {
  get(offset, perPage) {
    return Repository('getAnnouncements', {
      offset: offset,
      perPage: perPage,
    })
  },
}
