<template lang="pug">
.terms-of-service
  .text(v-if="this.$i18n.locale === 'en'").
    <h2>&well Service Terms of Use</h2>
    The &well Service Terms of Use (hereinafter referred to as "the Terms") provided for the necessary matters regarding the provision of services by Mitsui Fudosan Co., Ltd. (hereinafter referred to as “the Company") to users via "&well" (hereinafter referred to as "the Service") and the use of same by all users. All users are asked to accept the matters set forth in these terms before using the Service.
    Furthermore, the conditions for using the Service are subject to change without notice, so please always check the latest version.<br>
    <h3>1. Definitions</h3>
    The definitions of the terms listed below of the Terms shall be as described in the corresponding item.<br>
      •	User Corporation: A corporation that has entered into an "&well Service Use Contract" with the Company, or a corporation that has submitted an "&well Service Use Application"<br>
      •	Member: An employer or employee at a User Corporation who has registered as a member in advance.<br>
      •	Guest: A user of the Service who is not a Member.<br>
      •	User: Members and Guests shall be collectively referred to as Users.<br>
    <h3>2. Licensing</h3>
    The Company shall license the Users to download and use a smartphone application designated by the Company (hereinafter referred to as "the App") on a mobile terminal that supports the App, such as the Users' smartphones (hereinafter referred to as "Mobile Terminals"), under the conditions set forth under the Terms. The App may be used for Users' own personal use.<br>
    <h3>3. &well Service Use Conditions</h3>
    Users may use the Service on condition that they agree to the Terms. If Users use the Service, they shall be deemed to have agreed to the Terms and accompanying terms and conditions.<br>
    <h3>4. Users' Duties and Responsibilities</h3>
      •	Users shall subscribe to Internet services and enter into contracts for using communication equipment, software, and telephones and contracts for using Mobile Terminals for using the Service at User Corporations' or their own cost and responsibility.<br>
      •	Users must strictly manage at their own responsibility any and all information regarding themselves registered when using the Service (hereinafter referred to as "User-Registered Information") and user IDs and passwords created in order to use the Service (including using automatic input of user IDs, passwords, etc.). The Company shall bear no responsibility for damage to Users caused by use by a third party of User-Registered Information, user IDs, or passwords, regardless of whether there was intention or negligence on the part of the Users concerned. The Company shall assume that use of the Service by means of User-Registered Information, user IDs, or passwords was performed by the Users concerned.<br>
      •	To use the Service, Users must perform setup to link step data in a dedicated application service in their own smartphones or wearable devices (hereinafter referred to as "the Wearable Device Service"). By agreeing to the Terms, Users are deemed to have agreed that step data in their smartphones or wearable devices synchronized with the Wearable Device Service linked to the App and the Service.<br>
      •	The information provided via the App is not guaranteed to be accurate, effective, etc., and is not a diagnosis regarding state of health or the presence or absence of any disease. Any simulation information provided via the App does not guarantee future results. Users shall use the Service and the App upon agreeing to these matters.<br>
    <h3>5. Provisions Regarding Privileges</h3>
    Matters regarding privileges such as &well points earned by using the App and incentives exchanged for them (hereinafter referred to as "the Privileges") shall be provided for under the Terms and "&well Points Service Terms of Use," and notification of such matters shall also be given in the App.<br>
    <h3>6. Use of the Privileges</h3>
      •	The Privileges may only be used by Members of the App.<br>
      •	Applying to use the Privileges shall be done by Members in a manner prescribed by the Company.<br>
      •	If similar privileges are received via other systems or services, it may not be possible to receive some of the Privileges according to laws and regulations or otherwise stipulated by the Company.<br>
      •	Members may not transfer to third parties’ rights to use the Privileges provided, or trade such rights with third parties in any form whatsoever.<br>
      •	If Members use the Privileges provided by companies affiliated with the Company (hereinafter referred to as "Providers of the Service"), they shall comply with the terms and conditions, etc. set by the Providers of the Service in addition to the Terms.<br>
      •	The Company shall bear no responsibility for disputes that may arise with Providers of the Service or for damage to Members or third parties that may arise in connection with using the Privileges provided by Providers of the Service.<br>
    <h3>7. Copyrights, Etc.</h3>
      •	All copyrights and other intellectual property rights related to the content the Company provides to Users in the Service shall vest in the Company or Providers of the Service that have licensed the Company to use their rights. If Users infringe the intellectual property rights of Providers of the Service or if legal action or other disputes should arise between Users and Providers of the Service as a result thereof, the Users shall resolve the issues at their own expense and responsibility, and shall hold the Company harmless from any damages.<br>
      •	Although trademarks, logos, service marks, etc. (hereinafter referred to as "the Trademarks") may sometimes be displayed in the App, they do not mean that the Company transfers any rights pertaining to the Trademarks to Users or other third parties or licenses Users or other third parties to use the Trademarks under the Terms.<br>
    <h3>8. Disclaimer</h3>
    The Company makes no guarantees regarding matters such as operation in Mobile Terminal devices, compatibility with terminals, safety (including occurrence of errors, etc., repairs if a problem occurs, and computer viruses and other harmful things getting into the App and servers) when Users use the Service and the App. The Service and the App shall be used at the Users' own responsibility, and the Company shall bear no responsibility for paying compensation regarding the following kinds of damage:<br>
      •	damage arising from using or not being able to use content in the Service and the App;<br>
      •	damage arising from accidents caused by Users' carelessness, such as operating the App or looking at the screen while driving or walking;<br>
      •	Damage to Users or third parties arising from the Terms and handling in accordance with the Terms regarding matters such as stoppage, suspension, changes, or termination to/of the Service, or from using or not being able to use the Service;<br>
      •	Damage arising from using information obtained from external services introduced via the Service and from other websites linked to in the App;<br>
      •	Damage arising from accidents due to software or hardware or from contamination, loss of data, harm, etc. due to computer viruses when using the App; or<br>
      •	Damage arising from matters such as trouble between Users of the Service and the App or between Users and other third parties.<br>
    <h3>9. Prohibited Matters</h3>
    When using the Service, Users shall not engage in any conduct that falls or is likely to fall under any of the following:<br>
      •	Using the Service in any manner other than as approved by the Company;<br>
      •	Using the Service in a manner that goes beyond the scope of personal use, for example for business or commercial purposes;<br>
      •	Engaging in conduct that will, for example, hinder the operation of the Service (or servers and networks connected to the App);<br>
      •	Illegally collecting, disclosing, or providing personal information regarding third parties;<br>
      •	Infringing or engaging in conduct that is likely to infringe the rights of the Company or third parties, such as their copyrights, trademark rights, intellectual property rights, privacy rights, portrait rights, or honor;<br>
      •	Violating laws and regulations, public order and decency, or the Terms, etc.;<br>
      •	Offering profits to or cooperating with anti-social groups; or<br>
      •	Engaging in any other conduct that the Company deems inappropriate.<br>
    <h3>10. Suspension of use, and Compensation for Damages</h3>
      •	The Company shall suspend Users' use of the Service without giving them prior notification if they fall under any of the following:<br>
      •	If the User Companies to which the Members belong ask for their use of the Service to be suspended.<br>
      •	If the Users ask for their use of the Service to be suspended, doing so in a manner prescribed by the Company.<br>
      •	If the Company is unable to locate the Users for reasons attributable to them, such as failing to notify the Company that their email address has changed.<br>
      •	If the Company deems that there are appropriate grounds for suspending the Users' use of the Service, such as the Users’ violating the Terms; or<br>
      •	If the Users and relatives living with them fall under the definition of anti-social forces.<br>
      •	If use of the Service is suspended in accordance with the preceding paragraph, all &well points accumulated up to then shall become invalid. Furthermore, if Users cause damage to the Company or third parties as a result of conduct that violates the Terms or is unjust or illegal, the Company may claim compensation for damages from the Users.<br>
      •	In view of the fact that a Member uses the Service based on the "&well Service Use Contract" between the Company and a User Corporation, in the event that a Member has suffered damage due to the willful misconduct or negligence of the Company, the Member shall engage in all consultations, claims, etc. with the Company regarding such damage through the User Corporation.<br>
    <h3>11. Suspension of the Service</h3>
      •	The Company may suspend all or part of the Service without giving prior notification if any of the following conditions occurs:<br>
      •	If it performs maintenance or inspection of equipment, etc. necessary for providing the Service, or if the equipment, etc. breaks down;<br>
      •	If there are grounds for doing so that are not attributable to the Company, such as a disaster or calamity; or<br>
      •	If the Company otherwise deems that there are appropriate grounds for suspending the Service.<br>
      •	Even if all or part of the Service is suspended in accordance with the preceding paragraph, the validity period of &well points obtained through the Service will not be extended.<br>
    <h3>12. Changes to the Content, etc. of the Service</h3>
    The Company may change the content of the Service, such as the content of the Privileges and conditions related to use, without giving prior notification.<br>
    <h3>13. Termination of the Service</h3>
      •	If it becomes difficult to continue to provide the App, provision of the App may be terminated in a manner prescribed by the Company.<br>
      •	If the Service is terminated in accordance with the preceding paragraph, all &well points accumulated up to then shall become invalid.<br>
    <h3>14. Modification of the Terms</h3>
      •	The Company may modify the Terms at any time without obtaining consent from and giving prior notification to Users, and shall apply the revised Terms from the date of revision of the Terms onward. When changing the Terms, the Company shall notify to that effect, the detail of the changes, and the effective date of such changes on the App or on the website of the Services.<br>
      •	Matters not provided for under the Terms shall be governed by the rules, etc. separately provided for by the Company. If there is any inconsistency between the Terms and the rules, etc. separately provided for by the Company, the provisions of the Terms shall prevail unless otherwise provided for.<br>
    <h3>15. Validity of the Terms</h3>
    Even if some of the provisions of the Terms become invalid under the laws or regulations, the remaining provisions of the Terms shall remain valid. Even if some of the provisions in the Terms become invalid or are cancelled in relation to some Users, the Terms shall remain fully valid in relation to other Users.<br>
    <h3>16. Governing Laws and Jurisdiction</h3>
    The Terms shall be governed by and interpreted in accordance with the laws of Japan. Furthermore, if any dispute may arise between Users and the Company, the Tokyo District Court shall be the court of first instance having exclusive jurisdiction by agreement.<br>
    <br>
    <p class="text-right">
      End of the &well Service Terms of Use<br>
      <br>
      Established on: April 1, 2019<br>
      Revised on: April 1, 2020<br>
      September 30, 2020<br>
      July 26, 2021<br>
      Mitsui Fudosan Co., Ltd.<br>
      [Compatible OSs]<br>
      iOS 15 and higher<br>
      Android 9.0 and higher<br>
    </p>
    <br>
    <br>
    <h2>&well Points Service Terms of Use</h2>
    Mitsui Fudosan Co., Ltd. (hereinafter referred to as "the Company") shall stipulate the following conditions when providing incentives that can be exchanged for goods, services, rights, etc. under the conditions specified in advance (hereinafter referred to as "the Incentives") and the &well points stipulated in the Articles below (hereinafter referred to as "the Points") to employers or employees (hereinafter referred to as "Members") at corporations that have entered into a "&well Point Service Contract" or corporations that have submitted an "&well Service Use Application" in accordance with the "&well Service Terms of Use." The "&well Service Terms of Use" shall apply with regard to matters for which there are no stipulations in these terms regarding the Points.
    Furthermore, if the content of these terms differs from the content of the "&well Service Terms of Use," the content of these terms shall prevail.
    Furthermore, the conditions for using this service are subject to change without notice, so please always check the latest version.<br>
    <h3>1. Scope of the Points</h3>
    These terms shall apply to things for which "&well points" is displayed in the services stipulated in the "&well Service Terms of Use" that the Company provides.<br>
    <h3>2. Obtaining the Points</h3>
    Members can obtain the Points by meeting certain conditions in the programs in the App.<br>
    <h3>3. Using the Points</h3>
      •	Members must comply with the validity periods, use methods, precautions, etc. when they use the Points.<br>
      •	The Points provided by the Company may only be used in relation to the Company and providers of the Incentives for non-commercial and private purposes.<br>
      •	The Company does not guarantee that Members will be able to use the Points at specific dates and times that they wish to.<br>
      •	Members must not make forgeries of the Points.<br>
      •	Members may not make the Points the objects of transactions, such as transferring, lending, or mortgaging them to third parties.<br>
      •	The Points shall be used by the Members themselves, and may not be used by third parties who are not the Members concerned.<br>
    <h3>4. Validity Period of the Points</h3>
    Members must use the Points within the validity period (within three (3) years counted from the date the Points are awarded) in accordance with the stipulated methods of use. If Members do not use the Points within the validity period, the Points shall automatically become invalid even if the Members applied to use them during the validity period.<br>
    <h3>5. Cancellation or Disappearance of the Points</h3>
      •	If the Company deems that any of the following conditions applies, it may cancel all or some of the Points that Members possess without giving them prior notification:<br>
      •	If the "&well Service Use Contract" has been cancelled in accordance with Article 10 of the "&well Service Terms of Use";<br>
      •	If the Points service is terminated owing to termination of the service for providing various privileges stipulated by the Company in accordance with Article 13 of the "&well Service Terms of Use";<br>
      •	If the Members violate or are likely to violate the Terms;<br>
      •	If the Members commit fraud against the Company, providers of the Incentives, or other third parties, or the Company reasonably deems they are likely to have done so; or<br>
      •	If the Company otherwise deems it appropriate to cancel the Points provided to the Members.<br>
      •	If the Points have been canceled, the transactions for the Incentives that the Members conducted using the Points shall become invalid, and the Members must immediately restore the Incentives to their original state before the Points were used.<br>
      •	After Members have used the Points, the Company shall not return or reissue them to the Members even if the Members cancel or terminate the contracts related to the Incentives, for example by returning the goods.<br>
      •	The Company shall not pay compensation or bear any responsibility regarding content pertaining to the Points that have been cancelled.<br>
    <h3>6. Disclaimer</h3>
      •	While the Company strives to provide reliable services and information to Members, it makes no guarantees and shall bear no responsibility regarding whether the goods, services, information, etc. available through the Points service meet Members' expectations. When using the Points service, Members must decide for themselves about the usefulness, etc. of the goods, information, and services provided through it, and use it at their own responsibility. Furthermore, for inquiries regarding providers of the Incentives, Members shall contact the providers concerned directly, and the Company will not be involved.<br>
      •	The Company shall bear no responsibility for damage to Members arising in relation to use of the Points service or changes, termination, or suspension thereof in accordance with Article 7.<br>
    <h3>7. Changes, Termination, etc. of the Service</h3>
    The Company may change, terminate, or suspend the Points service and all or part of its content without giving prior notification to Members.<br>
    <br>
    <p class="text-right">
      End of the &well Points Service Terms of Use<br>
      <br>
      Established on: April 1, 2019<br>
      Revised on: April 1, 2020<br>
      September 30, 2020<br>
      July 26, 2021<br>
      Mitsui Fudosan Co., Ltd.<br>
    </p>

  .text(v-else).
    <h2>&well サービス利用規約</h2>
    &well サービス利用規約（以下「本規約」といいます。）は、三井不動産株式会社（以下「当社」といいます。）が「&well」 （以下「本サービス」といいます。） において利用者の皆様に対するサービス提供および利用にあたり必要な事項を定めたものです。利用者の皆様は、この規約に定める事項をご承諾の上ご利用ください。
    また、本サービスのご利用条件は予告なく変更することがありますので、常に最新の内容をご確認いただきますようお願いいたします。<br>
    <h3>１．定義</h3>
    本規約のおける次の各号に掲げる用語の定義は、当該各号に掲げるところによります。<br>
      ① 利用法人	当社と「&well サービス利用契約書」を締結した法人または、「&well サービス利申込書」を提出した法人をいいます。<br>
      ② 会員	利用法人の使用者または従業者のうち、あらかじめ会員登録した者をいいます。<br>
      ③ ゲスト	本サービスの利用者のうち会員でないものをいいます。<br>
      ④ 利用者	会員およびゲストを総称して利用者といいます。<br>
    <h3>２．使用権の許諾</h3>
    当社は、利用者に対し、本規約に規定された条件の下で、利用者のスマートフォン等、当社所定のスマートフォン用アプリケーション（以下「本アプリ」といいます。） に対応した携帯端末機器（以下「携帯端末」といいます。） に、本アプリをダウンロードして使用することを許諾するものとします。本アプリは、利用者が個人で使用する目的でのみ利用することができます。<br>
    <h3>３．&well サービス利用条件</h3>
    本サービスは、利用者が本規約に同意することを条件として利用できるものとします。利用者が本サービスを利用した場合、本規約および付随する規約・利用条件に同意したものとみなします。<br>
    <h3>４．利用者の義務および責任</h3>
    （１）利用者は、本サービスを利用するための通信機器やソフトウェア、電話の利用契約の締結、携帯端末の利用契約の締結、インターネットサービスプロバイダへの加入等を、利用法人または自己の費用および責任において実施するものとします。<br>
    （２）利用者は、本サービスの利用に際して登録した自己に関する情報（以下「利用者登録情報」といいます。） および本サービスの利用のために設定したユーザーIDおよびパスワードを自らの責任において厳重に管理しなければなりません（ユーザーIDおよびパスワード等の自動入力の利用を含みます）。当社は、利用者登録情報、ユーザーID およびパスワードが第三者に使用されたことによって当該利用者に生じた損害については、当該利用者の故意、過失の有無にかかわらず、一切責任を負いません。当社は、当該利用者登録情報、ユーザーID およびパスワードを用いて行われた本サービスの利用は、当該利用者によりなされたものとみなします。<br>
    （３）本サービスの利用にあたっては、お使いのスマートフォンまたはウェアラブル端末の専用アプリサービス（以下「ウェアラブル端末サービス」といいます。）の歩数データを連携させる設定を利用者自身で行う必要があります。本規約に同意することにより、ウェアラブル端末サービスに同期された利用者のスマートフォンまたはウェアラブル端末の歩数データが本アプリおよび本サービスに連携することにつき同意したものとみなします。<br>
    （４）本アプリにより提供されるサービスおよび情報等は、その正確性や効能効果等を保証するものではなく、疾病の有無や程度および健康状態を診断するものではありません。また、本アプリにより提供されるシミュレーション情報については、将来の結果を保証するものではありません。利用者はこれらに同意のうえ本サービスおよび本アプリを利用するものとします。<br>
    <h3>５．特典に関する定め</h3>
    本アプリを利用することによって得られる&wellポイントやその交換先であるインセンティブ等の特典（以下「特典」といいます。）に関する事項は、本規約および「&wellポイントサービス利用規約」で定めるほか、本アプリ等において告知します。<br>
    <h3>６．特典の利用</h3>
      ① 特典は、本アプリの会員に限り利用できるものとします。<br>
      ② 特典の利用の申込みは、当社所定の方法により会員が行なうものとします。<br>
      ③ 他の制度・サービスにより同種同等の特典を受ける場合、法令等の定めまたは当社が別途定めるところにより一部の特典が受けられないことがあります。<br>
      ④ 会員は、提供された特典を利用する権利をいかなる形であっても第三者へ譲渡または売買することはできません。<br>
      ⑤ 会員は、当社と提携する会社（以下「本サービス提供会社」といいます。）が提供する特典を利用する場合、本規約のほか、本サービス提供会社の規定等に従うものとします。<br>
      ⑥ 本サービス提供会社が提供する特典を利用するにあたって、本サービス提供会社との間において生じた紛争および会員または第三者に生じた損害について、当社は一切責任を負いません。<br>
    <h3>７．著作権等</h3>
    （１）本サービスにおいて、当社が利用者に提供するコンテンツに関する著作権その他一切の知的財産権は当社または当社に権利の使用を許諾した本サービス提供会社に帰属します。利用者が本サービス提供会社の知的財産権を侵害しまたはこれに起因して本サービス提供会社との間で訴訟その他の紛争が生じた場合、利用者は、自己の費用と責任において問題を解決するものとし、当社に何らの損害または損失も与えないものとします。<br>
    （２）本アプリ上には商標、ロゴおよびサービスマーク等（以下「商標等」といいます。）が表示される場合がありますが、当社は、本規約により利用者その他の第三者に対し何ら当該商標等に係る権利を譲渡し、または当該商標等の使用を許諾するものではありません。<br>
    <h3>８．免責事項</h3>
    当社は、利用者が本サービスおよび本アプリを利用するにあたり、携帯端末機器での動作、端末への適合性、安全性（エラー等の発生、問題が発生した場合の修正、本アプリおよびサーバへのコンピューターウィルス 、その他の有害物の侵入等）等について、保証するものではありません。本サービスおよび本アプリの利用は、利用者の責任において行われるものとし、当社は、当社の責めに帰さない以下の損害を賠償する責任を負いません。<br>
      ① 本サービスおよび本アプリのコンテンツを利用すること又は利用できないことよって生じる損害。<br>
      ② 車両運転中または歩行中に本アプリを操作し、もしくは画面注視するなど利用者の不注意により発生した事故の損害。<br>
      ③ 本サービスの停止・中断、本サービスの変更・終了等本規約および本規約に基づく取り扱い、または本サービスの利用または利用できなかったことにより利用者または第三者に生じた損害。<br>
      ④ 本サービスにおいて紹介する外部のサービスおよび本アプリにリンクが設定されている他のウェブサイトから取得された各種情報の利用によって生じる損害。<br>
      ⑤ 本アプリご利用の際の、ソフトウェア、ハードウェア上の事故、コンピューターウィルスによる汚染、データの滅失、毀損等によって生じる損害。<br>
      ⑥ 本サービスおよび本アプリの利用者間または利用者と他の第三者間において生じたトラブル等によって生じる損害。<br>
    <h3>９．禁止事項</h3>
    利用者は、本サービスの利用に際して、つぎのいずれかに該当するまたはそのおそれのある行為を行ってはなりません。<br>
      ① 当社の承認した以外の方法により本サービスを利用すること。<br>
      ② 本サービスを個人的な利用範囲を超えて営業・営利目的等において利用すること。<br>
      ③ 本サービス（ または本アプリに接続されているサーバおよびネットワーク） の運営を妨げる等の行為を行うこと。<br>
      ④ 第三者の個人情報を不正に収集、開示または提供する行為。<br>
      ⑤ 当社または第三者の著作権、商標権の知的財産権、プライバシー権、肖像権、名誉等の権利を侵害し、またはそのおそれのある行為を行うこと。<br>
      ⑥ 法令、公序良俗または本規約等に違反する行為を行うこと。<br>
      ⑦ 反社会的勢力への利益供与その他の協力行為。<br>
      ⑧ そのほか、当社が不適切と判断した行為を行うこと。<br>
    <h3>１０．利用停止、損害賠償</h3>
    （１）当社は、利用者がつぎのいずれかに該当する場合は、事前に通知することなく当該利用者について本サービスの利用を停止します。<br>
      ① 会員が所属する利用法人が、本サービスの利用停止を申し出た場合。<br>
      ② 利用者が当社所定の方法により、本サービスの利用停止を申し出た場合。<br>
      ③ メールアドレス変更の届出を怠る等、利用者の責めに帰すべき事由によって、当社において利用者の所在が不明 となった場合。<br>
      ④ その他、利用者が本規約に違反した場合等、当社が本サービスの利用を停止する相応の事由があると判断した場合。<br>
      ⑤ 利用者および利用者の同居の親族が反社会的勢力に該当する場合。<br>
    （２）前項により本サービスの利用を停止した場合、その時点までに蓄積された&wellポイントはすべて失効するものとします。また、本規約に反した行為、または不正もしくは違法な行為により当社または第三者に損害を生じさせた場合、当社は当該利用者に対し損害賠償を請求できるものとします。<br>
    （３）会員による本サービスの利用が当社と利用法人との&well サービス利用契約書に基づき行われていることに鑑み、当社の故意または過失によって会員に損害が生じた場合、会員は当該損害に関する当社との一切の協議・請求等を利用法人を通じて行うものとします。<br>
    <h3>１１．本サービスの中断</h3>
    （１）当社は、つぎのいずれかに該当する場合は、事前に通知することなく本サービスの一部または全部を中断することがあります。<br>
      ① 本サービスの提供に必要な設備等の保守・点検を行なう場合、または当該設備等に障害が発生した場合。<br>
      ② 災害・事変等当社の責めに帰すことのできない事由がある場合。<br>
      ③ その他、当社が本サービスを中断する相応の事由があると判断した場合。<br>
    （２）前項により本サービスの一部または全部を中断した場合であっても、本サービスによって得られた&well ポイントの有効期限は延長しません。<br>
    <h3>１２．サービスの内容等の変更</h3>
    当社は、特典の内容および利用に関する条件等、本サービスの内容を事前に通知することなく変更することがあります。<br>
    <h3>１３．本サービスの終了</h3>
      ① 本アプリの提供を継続することが困難となった場合、当社が定める方法により、本アプリの提供を終了することができるものとします。<br>
      ② 前項により本サービスを終了した場合、その時点までに蓄積された&well ポイントはすべて失効するものとします。<br>
    <h3>１４．規約変更</h3>
    （１）当社は、利用者の承諾および利用者への通知なしに、いつでも本規約の変更を行うことができるものとします。本規約を変更する場合は、本アプリまたは、本サービスのウェブサイトに本規約を変更する旨、その変更内容および当該変更の効力発生日等を表示いたします。<br>
    （２）本規約に特に定めのない事項については、当社が別に定める規定等に従うものとします。なお、本サービスについて、本規約と当社が別に定める規定等の内容が異なる場合には、別段の定めがある場合を除き、本規約が優先されるものとします。<br>
    <h3>１５. 本規約の有効性</h3>
    本規約の規定の一部が法令にもとづいて無効となる場合であっても、本規約のその他の規定は有効とします。また、本規約の規定の一部がある利用者との関係で無効とされ、または取り消された場合でも、本規約はその他の利用者との関係では有効とします。<br>
    <h3>１６．準拠法および裁判管轄</h3>
    本規約は日本法に準拠し、日本法に従って解釈されます。また、利用者と当社の間で紛争が生じた場合、 東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br>
    <br>
    <p class="text-right">
    以 上<br>
    <br> 
      制定日 2019 年4 月 1 日<br>
      改訂日 2020 年4 月 1 日<br>
      2020年９月３０日<br>
      2021年７月２６日<br>
      三井不動産株式会社<br>
          【動作対象OS】<br>
      iOS：15 以上<br>
      Android OS：9.0 以上<br>
    </p>
    <br>
    <br>
    <h2>&well ポイントサービス利用規約</h2>
    三井不動産株式会社（以下「当社」といいます。）は、「&well サービス利用規約」に基づき、「& well サービス利用契約」を締結した法人または、「&wellサービス利用申込書」の提出をした法人の使用者または従業者（以下「会員」といいます。）に対して、事前に指定された条件で商品・サービス・権利等と交換可能なインセンティブ（以下「インセンティブ」といいます。）、および次条以下に定める&well ポイント（以下「本ポイント」といいます。）を提供するにあたり、以下に定める条件を定めます。本ポイントに関し本規約に規定のない事項については、
    「&wellサービス利用規約」が適用されます。また、本規約と「& well サービス利用規約」との内容が異なる場合には、本規約の内容が優先されるものとします。
    また、本サービスのご利用条件は予告なく変更することがありますので、常に最新の内容をご確認いただきますようお願いいたします。<br>
    <h3>１．本ポイントの範囲</h3>
    当社の提供する「& well サービス利用規約」に定めるサービスにおいて「&well ポイント」と表示されるものを本規約の適用対象とします。<br>
    <h3>２．本ポイントの取得</h3>
    会員は、本アプリ内のプログラムにおいて一定条件を満たすことで本ポイントを取得することができます。<br>
    <h3>３．本ポイントの利用</h3>
      ①	会員は、本ポイントを利用する場合、有効期間・利用方法・注意事項等に従わなければなりません。<br>
      ②	当社が提供する本ポイントは、非商業的および私的目的のもと当社およびインセンティブ提供会社に対してのみ利用することができます。<br>
      ③	当社は、会員の希望する特定の日時に本ポイントを利用できることを保証するものではありません。<br>
      ④	会員による本ポイントの偽造は禁止します。<br>
      ⑤	本ポイントを第三者に対し譲渡、貸与または担保に供する等、会員が本ポイントを取引の対象とすることはできません。<br>
      ⑥	本ポイント利用は、会員本人が行うものとし、当該会員以外の第三者が行うことはできません。<br>
    <h3>４．本ポイントの有効期限</h3>
    会員が本ポイントを利用するには、有効期間内（本ポイント最終取得日から起算して 3 年以内）に利用方法に従って利用しなければなりません。会員が有効期間内に本ポイントの利用申込みをしたとしても、有効期間内に利用しなかった場合には、本ポイントは自動的に失効します。<br>
    <h3>５．本ポイントの取消・消滅</h3>
    （１）当社がつぎのいずれかに該当すると判断した場合、当社は会員に事前に通知することなく、会員が保有する本ポイントの全部または一部を取り消すことがあります。<br>
      ①	「&well サービス利用規約」第 1０条に基づき「& well サービス利用契約」が解除された場合。<br>
      ②	「&well サービス利用規約」第 1３条に基づき当社の定める各種特典を提供するサービスが終了したことに伴い、本ポイントサービスが終了した場合。<br>
      ③	会員が本規約に違反しまたは違反するおそれがある場合。<br>
      ④	会員が当社やインセンティブ提供会社その他の第三者に対して詐欺行為を行った場合またはその可能性があると当社が合理的に判断した場合。<br>
      ⑤	その他当社が会員に提供した本ポイントを取り消すことが適当と判断した場合。<br>
    （２）本ポイントが取り消された場合、会員が取り消された当該ポイントを通じて行ったインセンティブの取引は無効となり、会員は、ただちにインセンティブを当該ポイント利用前の状態に戻さなくてはなりません。<br>
    （３）会員が本ポイントを利用した後に、商品を返品する等、インセンティブにかかる契約を取り消しまたは解除した場合であっても、当社は、会員に対し、当該ポイントの返還または再発行を行いません。<br>
    （４）当社は、取り消した本ポイントの内容について何らの補償も行わず、一切責任を負わないものとします。<br>
    <h3>６．免責</h3>
    （１）当社は、信頼できるサービスや情報を会員へお届けすべく努力しますが、本ポイントサービスを通じて入手できる商品、サービス、情報等が会員の期待を満たすものであることについて、当社は何らの保証をするものではなく、一切責任を負いません。本ポイントサービスの利用に際しては、会員自身が、本ポイントサービスにおいて提供される商品情報やサービスの有用性等を判断し、自身の責任でご利用下さい。また、インセンティブ提供会社に関する問い合わせは会員が当該会社に対して直接行うものとし、当社は一切関与しないものとします。<br>
    （２）当社は、本ポイントサービスの利用、第７条による本ポイントサービスの変更、終了、停止に関して会員に生じた損害 について、一切責任を負わないものとします。<br>
    <h3>７．サービスの変更・終了等</h3>
    当社は会員に事前に通知することなく、本ポイントサービスおよび本ポイントサービスの内容の全部または一部を変更、終了または停止することがあります。<br>
    <br>
    <p class="text-right">
      以 上<br>
      <br>
      制定日 2019 年4 月 1 日<br>
      改訂日 2020 年4 月 1 日<br>
      2020年９月３０日<br>
      2021年７月２６日<br>
      2024年11月27日<br>
      三井不動産株式会社<br>
    </p>
</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'

export default {
  components: {
    HeadingBlock,
  },
}
</script>

<style lang="scss" scoped>
.article-content {
  max-width: 800px;
  margin: 0 auto;
}
</style>
