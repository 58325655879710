<template lang="pug">
  .signin
    .common-h1-area
      HeadingBlock(level="1" text="Signup")
      p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") アカウント作成
    .sub-page-padding
      router-view(:accountModel.sync="accountModel")

</template>
<script>
import HeadingBlock from '@/components/HeadingBlock'

export default {
  name: 'Signup',
  components: {
    HeadingBlock,
  },
  data() {
    return {
      accountModel: {},
    }
  },
}
</script>
<style lang="scss">
.signin {
  font-size: 14px;
  ._pink {
    color: #ec357e;
  }
  ._item {
    margin-bottom: 1.6em;
    label {
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: bold;
      &.required {
        &::after {
          content: '※';
          color: #ec357e;
          margin-left: 4px;
        }
      }
    }
    & > div {
      min-height: 1em;
      padding-left: 1em;
    }
  }
  ._action {
    margin-top: 3em;

    .large-button-style {
      margin-top: 0 !important;
      font-size: 14px;
    }
  }
}
</style>
