import { RepositoryFactory } from '../../repositories/RepositoryFactory'
const libraryRepository = RepositoryFactory.get('library')

const defaultState = () => ({
  items: null,
})

const state = defaultState()

const getters = {}

const mutations = {
  setItems(state, payload) {
    state.items = payload
  },
  resetItems(state) {
    Object.assign(state, defaultState())
  },
}

const actions = {
  async getItems({ commit }) {
    try {
      const { succeed, popularTags } = await libraryRepository.getPopularTags()
      if (!succeed) {
        throw new Error()
      } else {
        commit('setItems', popularTags)
      }
    } catch (error) {
      //
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
