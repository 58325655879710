<template lang="pug">
  div
    p {{$t("アンケートを回答しました。")}}
    br
    router-link(:to="{ name: 'surveys' }") {{$t("アンケートへ")}}
</template>

<script>
export default {
  name: 'Thanks',
}
</script>

<style scoped></style>
