<template lang="pug">
  ul
    //- select(v-model="input" @change="sendData")
      //- option(disabled value="") 選択
      //- option(v-for="(choice, index) in item.choices" :value="index") {{ choice }}
    li(v-for="(choice, index) in item.choices")
      input(type="radio" v-model="input" @change="sendData" :id="`item${item.questionnaireIndex}-${index}`" :value="index" class="radio-input")
      label(:for="`item${item.questionnaireIndex}-${index}`")
        | {{ choice }}
      ul.images(v-if="item.choiceImages && item.choiceImages[index]")
        li(v-for="img in item.choiceImages[index]" :key="img.id")
          img(:src="img.url")
</template>

<script>
export default {
  name: 'Select',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      input: '',
    }
  },
  methods: {
    sendData() {
      this.$emit('getData', {
        index: this.item.questionnaireIndex,
        value: this.input,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  margin: 0.8em 0 0 0;
}
ul {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  flex-wrap: wrap;
  > li {
    position: relative;
  }
}
.images {
  margin-bottom: 27px;
  margin-top: 12px;
  img {
    width: 100%;
  }
}
.radio-input {
  display: none;
}
.radio-input + label {
  padding-left: 45px;
  position: relative;
  margin-right: 35px;
  line-height: 40px;
  height: 40px;
  display: inline-block;
}
.radio-input + label::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 35px;
  height: 35px;
  background-color: $base-bg-gray-color;
  border-radius: 50%;
}
.radio-input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 6px;
  bottom: 0;
  margin: auto 0;
  width: 22px;
  height: 22px;
  background: $base-brand-color;
  border-radius: 50%;
}
.radio-input:checked + label {
  // color: #004098;
}

select {
  height: 2em;
  padding: 0 0.4em;
}
</style>
