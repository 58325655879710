<i18n>
{
  "ja": {
    "あなた": "あなた",
    "全体平均":"全体平均",
    "運動":"運動",
    "食事":"食事",
    "飲酒":"飲酒",
    "睡眠":"睡眠",
    "ストレス":"ストレス"
  },
  "en": {
    "あなた": "You",
    "全体平均":"Average",
    "運動":"Exercise",
    "食事":"Meal",
    "飲酒":"Drinking",
    "睡眠":"Sleep",
    "ストレス":"Stress"
  }
}
</i18n>
<template lang="pug">
  .RadarChart
    canvas(ref="chart")
</template>
<script>
import Chart from 'chart.js/auto'
import style from './style.js'

const options = {
  responsive: true,
  elements: {
    line: {
      borderWidth: 3,
    },
  },
  scales: {
    r: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
        backdropColor: '#f6f6f8',
      },
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        pointStyle: 'rect',
      },
    },
  },
}
export default {
  name: 'RadarChart',
  props: ['values', 'average'],
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    propsToWatch() {
      return [this.values, this.average]
    },
  },
  watch: {
    propsToWatch: function(val) {
      this.chart.data = this.getData(...val)
      this.chart.update()
    },
  },
  methods: {
    getData(values, average) {
      return {
        datasets: [
          {
            label: this.$i18n.t('あなた'),
            data: values,
            borderColor: style.color.barActive,
            backgroundColor: style.color.fillActive,
            pointBackgroundColor: style.color.barActive,
            pointRadius: function() {
              return window.innerWidth > 1000 ? 4 : 3
            },
            //pointBorderColor: '#fff',
          },
          {
            label: this.$i18n.t('全体平均'),
            data: average,
            borderColor: style.color.bar,
            backgroundColor: style.color.fill,
            pointBackgroundColor: style.color.bar,
            pointRadius: function() {
              return window.innerWidth > 1000 ? 4 : 3
            },
            //pointBorderColor: '#fff',
          },
        ],
        labels: [
          this.$i18n.t('運動'),
          this.$i18n.t('食事'),
          this.$i18n.t('飲酒'),
          this.$i18n.t('睡眠'),
          this.$i18n.t('ストレス'),
        ],
      }
    },
  },
  mounted() {
    const ctx = this.$refs.chart

    this.chart = new Chart(ctx, {
      type: 'radar',
      data: this.getData(this.values, this.average),
      options: options,
    })
  },
}
</script>
