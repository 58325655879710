const faqData = {
  ja: [
    {
      id: 1000,
      title: '初回ログイン',
      content: [
        {
          id: 1001,
          title: 'マニュアルはありますか？',
          html: `
          <h3>&wellアプリログインマニュアル</h3>
          <ul>
          <li>
            (PDF)
            <a href="/files/2024/download_and_login.pdf" target="_blank">日本語</a>
            │
            <a href="/files/2024/download_and_login_en.pdf" target="_blank">ENGLISH</a>
          </li>
          <li class="mt-20">
            (動画)
            <a href="https://vimeo.com/600026456" target="_blank">iPhone 用(iOS)</a>
            │
            <a href="https://vimeo.com/600026438" target="_blank">Android用</a>
          </li>
          </ul>`,
        },
        {
          id: 1002,
          title: '&wellアプリはどこからダウンロードできますか？',
          html: `
          <p>ご利用の端末から、以下のQRをQRリーダーで撮影するとストアへ遷移します。または、URLから遷移してください。</p>
          <div class="col2 mt-30">
            <div style="margin-right:30px;">
              <b>iPhone(iOS)をご利用の方</b>
              <p>App Storeからのインストール</p>
              <p><a href="https://apps.apple.com/jp/app/well/id1434515379" target="_blank">LINK</a></p>
              <img src="img/faq/ios.png" width="160" alt="iPhone">
            </div>
            <div>
              <b>Androidをご利用の方</b>
              <p>Google Playからのインストール</p>
              <p><a href="https://play.google.com/store/apps/details?id=jp.andwell" target="_blank">LINK</a></p>
              <img src="img/faq/android.png" width="160" alt="android">
            </div>
          </div>
          <br>
          <p>※会社貸与の端末において、セキュリティ上の理由で任意でアプリのインストールができない場合がございます。<br>
          QRコードが読み取れないなど発生した場合、企業または健康保険組合の担当窓口へお問い合わせください。</p>`,
        },
        {
          id: 1003,
          title:
            '初回会員登録でアカウント・パスワードがわかりません。どうすればよいですか？',
          html: `
          <h3>アカウントがわからない方</h3>
          <p>初回のログインアカウントは、所属企業または健康保険組合よりメール等で&wellの案内がありますのでご確認ください。不明な場合は、企業または健康保険組合の担当窓口へお問い合わせください。</p>

          <h3>パスワードがわからない方</h3>
          <p>
          アカウントがメールアドレスの方は、初期パスワードは不要です。<br>
          アカウントがメールアドレス以外の方は、企業または健康保険組合からの案内を確認してください。不明な場合は、企業または健康保険組合の担当窓口へお問い合わせください。
          </p>
          `,
        },
        {
          id: 1004,
          title:
            'ログインをしましたが、「&wellとは」の画面からホーム画面にいけません。どうすればよいですか？',
          html: `表示されている&well説明画面を何回か左にスワイプすると、最後に「あすけんと連携する」という画面が表示されます。
          その表示画面下の「次へ」のボタンを押下すると画面が遷移します。
          `,
        },
        {
          id: 1005,
          title:
            '&wellアプリ、&well会員サイトは、共通のアカウント・パスワードでしょうか？',
          html: `はい、共通のアカウント・パスワードです。
          `,
        },
        {
          id: 1006,
          title: '&wellアプリのみの利用は可能でしょうか？',
          html: `はい、可能です。
          `,
        },
        {
          id: 1007,
          title: '&well会員サイトのみの利用は可能でしょうか？',
          html: `
          はい、可能です。動画やLIVE配信、読み物系コンテンツを中心にお楽しみいただけます。<br>
          但し、Webサイトで歩数や自分ログの入力、ミッションの達成等はできません。また、アプリを立ち上げない場合は歩数の取得ができません。
          `,
        },
        {
          id: 1008,
          title:
            '&wellアプリの設定途中で、別のアプリ「ヘルスケア」（または、「Google fit」）のダウンロードを求められますが、必ずしないといけないのでしょうか？',
          html: `
          <h3>iPhone（iOS）をご利用の方</h3>
          <p>「ヘルスケア」アプリは、iPhone（iOS）に標準インストールされているアプリです。インストールの必要はありません。</p>

          <h3>Androidをご利用の方</h3>
          <p>「Google Fit」アプリのダウンロードが必要です。Google アカウントでログインする必要があります。</p>
          <p><img src="img/faq/googlefit.png" width="160" alt="Google fit"></p>
          `,
        },
        {
          id: 1009,
          title: '言語設定は何がありますか？変更方法を教えてください。',
          html: `
          <p>
          日本語と英語（自動翻訳）があります。<br>
          変更方法は、以下をご覧ください。
          </p>

          <h3>アプリで変更する場合</h3>
          <p>ホーム画面の右上の歯車マークを押下＞マイページの言語設定language ＞ 日本語/英語を選択 ＞ OKを押下</p>

          <h3>Webサイトで変更する場合</h3>
          <p>ログイン後トップページの右上のアイコンを押す＞マイページの言語設定language ＞ 日本語/英語を押下 ＞ ×部分を押下し閉じる</p>
          `,
        },
      ],
    },
    {
      id: 2000,
      title: 'アカウント・パスワード・登録アドレス',
      content: [
        {
          id: 2001,
          title: 'アカウント・パスワードを忘れてしまいました。',
          html: `
          <h3>アカウントを忘れた方</h3>
          <p>
          所属企業の人事部（総務部）、または所属の健康保険組合の担当窓口へお問い合わせください。<br>
          問い合わせ先がわからない場合、<a href="https://www3.mitsuifudosan.co.jp/enquete/regulation.php?enqueteurl=c3caa245d0f764c1e29085f7a2ecb307" target="_blank">&well事務局へお問い合わせ</a>ください。
          </p>

          <h3>パスワードを忘れた方</h3>
          <p><a href="https://go.andwell.jp/resetPassword">パスワードのリセット・変更はこちら</a></p>
          `,
        },
        {
          id: 2002,
          title: '登録したメールアドレスを変更したいです。',
          html: `
          <p>
          アカウントが会社のメールアドレスの場合、変更はできません。<br>
          アカウントが英数字文字列の場合は、登録アドレスの変更ができます。<br>
          </p>
          <p>
          アカウントがどちらのタイプかは、所属の企業、健康保険組合ごとに決められています。個別の選択はできません。
          </p>
          `,
        },
        {
          id: 2003,
          title: '&wellアプリ、&well会員サイトは、共通のパスワードですか？',
          html: `はい、共通のパスワードです。`,
        },
      ],
    },
    {
      id: 3000,
      title: '利用端末・ウェアラブル端末',
      content: [
        {
          id: 3001,
          title: '携帯端末2台で利用できますか？',
          html: `
          <p>はい、ご利用いただけます。<br>
          携帯端末を2台お持ちの方は、以下の点に注意してください。</p>
          <h3>前提</h3>
          <ul>
            <li>2台の携帯端末で、&wellアプリを同一のアカウントでログインし使用している</li>
          </ul>
          <h3>2台持ちの例</h3>
          <ul>
            <li>平日は会社の携帯（1台目）、休日は個人の携帯（2台目）を使用</li>
            <li>どちらの端末も、&wellアプリと連携している外部アプリは同じ</li>
          </ul>
          <h3>手順例</h3>
          <ul class="simple-list mb-30">
            <li>
              <p>月曜日~金曜日</p>
              <p>1台目で&wellアプリを起動し歩く</p>
              <p>※翌日にアプリを起動し、前日までの歩数をアップロードする</p>
            </li>
            <li>
              <p>土曜日、日曜日</p>
              <p>2台目で&wellアプリを起動し歩く</p>
              <p>※翌月曜日にアプリを起動し、土日の歩数をアップロードする</p>
            </li>
          </ul>
          <h3>結果</h3>
          <p>2つの端末で取得した歩数データを比べ、<strong>アプリ起動日14日前から多いほうの歩数が計上され、平均歩数が反映されます</strong></p>
          <p>※例えば、21日ぶりにアプリを起動した場合、直近14日間の歩数データを取得することはできますが、それ以前の7日間については歩数データを取得できません</p>`,
        },
        {
          id: 3002,
          title: 'iPad・パソコンで&wellアプリを利用できますか？',
          html: `iPad・パソコンではご利用いただけません。スマートフォン（iPhoneまたはAndroid）でご利用ください。`,
        },
        {
          id: 3003,
          title: '接続できるウェアラブル端末は何がありますか？',
          html: `
          <p>&wellアプリと連携できる代表的なウェアラブル端末は、以下となります。</p>
          <p>例）</p>
          <ul>
          <li>Apple Watch</li>
          <li>Fitbit</li>
          <li>ガーミン  など</li>
          </ul>
          <p class="mt-20">Fitbitは、Fitbit専用のアプリで直接連携できます。
          それ以外は「ヘルスケア」または「Google Fit」と連携できるウェアラブル端末であれば、&wellアプリと連携ができます。その際、別途コネクトアプリをダウンロードいただく必要がございます。
          コネクトアプリの詳細やウェラブル端末と「ヘルスケア」または「Google Fit」との連携方法に関しては、各メーカーのウェブサイト等をご確認いただくか、各メーカーまでお問い合わせください。
          </p>
          `,
        },
        {
          id: 3004,
          title:
            'ログインしていますが、歩数が連携できません。（iPhone：iOSにおけるヘルスケアとの連携方法）',
          html: `
          <p>以下の手順でご確認ください。</p>
          <p class="mt-20">＜手順＞</p>
          <ol>
          <li>&wellアプリの確認
          <li>iPhone端末の確認
          </ol>

          <p class="mt-20">＜手順詳細＞</p>
          <h3>1. &wellアプリの確認方法</h3>
          <p>ホーム画面の右上の歯車マークを押下＞マイページよりデータ連携を確認。連携している外部アプリが「ヘルスケア」と記載がされていることを確認してください。</p>
          <p>「ヘルスケア」の表記がない場合、連携している外部アプリを押下すると、選択画面が表示されますので、「ヘルスケア」を選択してください。</p>

          <h3>2. iPhone端末の確認方法</h3>
          <p>iPhone端末の設定＞プライバシー＞ヘルスケア＞&well＞「歩数」の右側のボタンがオン（緑色が表示）になっていることを確認。</p>
          <p>この処理を完了後、&wellアプリを起動すると歩数が取得できるようになります。</p>
          `,
        },
        {
          id: 3005,
          title:
            'ログインしていますが、歩数が連携できません。（AndroidにおけるGoogle Fitとの連携方法）',
          html: `
          <p>ホーム画面の右上の歯車マークを押下＞マイページよりデータ連携を確認。連携している外部アプリが「Google Fit」と記載がされていることを確認してください。</p>
          <p>「Google Fit」の表記がない場合、連携している外部アプリを押下すると、選択画面が表示されますので、「Google Fit」を選択してください。</p>
          <p>この処理を完了後、&wellアプリを起動すると歩数が取得できるようになります。</p>
          `,
        },
        {
          id: 3006,
          title:
            'ログインしていますが、歩数が連携できません。（Fit bitとの連携方法）',
          html: `
          <p>Fitbitとの連携については、<a href="#a1001">&wellアプリログインマニュアル</a>のP11以降をご確認ください。</p>`,
        },
        {
          id: 3007,
          title: 'ウェアラブル端末と&wellアプリの歩数ログが一致しません。',
          html: `歩数ログの集計のタイミングなどが異なる可能性があります。<br>
          詳細はわかりかねますので、各メーカーへお問い合わせください。`,
        },
      ],
    },
    {
      id: 4000,
      title: '設定・公開範囲',
      content: [
        {
          id: 4001,
          title: '友だち申請を取り消すことはできますか？',
          html: `
          <p>あなたが友だちの申請をして、相手がまだ「承認」または「却下」をしていない場合、この時点では申請のキャンセルができません。</p>
          <p>相手が「承認」したあとは、ホーム画面の左上「≡」メニューの「友だち」を押下＞「友だちリスト」より削除することが可能です。<br>
          <br></p>
          <p>＜取り消し方法＞</p>
          <h3>iPhone（iOS）の場合</h3>
          <p>削除したい友達を右から左にスワイプすると「削除」ボタンが出てきます。</p>
          
          <h3>Androidの場合</h3>
          <p>削除したい友達を長押しすると、削除のダイアログがでてきます。</p>`,
        },
        {
          id: 4002,
          title: '友だちには、自分のどんな情報が公開されますか？',
          html: `
          <p>いいね！を押したコンテンツや参加したプログラム、達成したミッションが公開されます。</p>
          <p class="mt-20">
          表示例）<br>
          〇さんが6,000歩歩こう！を達成しました。<br>
          〇さんが【4/1開催】モーニングストレッチを閲覧しよう！を達成しました。<br>
          </p>`,
        },
        {
          id: 4003,
          title:
            'つながった友だちに、自分のタイムラインを公開しない方法を教えてください。',
          html: `タイムラインを非公開にすることはできません。`,
        },
        {
          id: 4004,
          title:
            '登録アドレスにお知らせなどのメールが届きます。配信停止の方法を教えてください。',
          html: `<h3>アプリで配信停止の設定をする場合</h3>
          <p>ホーム画面の右上の歯車マークを押下＞マイページのメールマガジン設定＞受け取らないを押下＞OKを押下</p>
          
          <h3>会員サイトで配信停止の設定をする場合</h3>
          <p>ログイン後トップページの右上のアイコンを押下＞マイページの設定部分のメールガマガジン設定を押下＞受け取らないを押下＞×部分を押下し閉じる</p>
          
          <p class="mt-20">※配信停止後、しばらくメールが届く場合がありますのでご了承ください。</p>`,
        },
        {
          id: 4005,
          title: 'プロフィール写真の編集ができなくなりました。',
          html: `<h3>アプリで編集できます。</h3>
          <p>ホーム画面の右上の歯車マークを押下＞マイページのカメラアイコンまたは写真を押下して、変更することができます。<br>
          それでも変更できない場合は、以下の手順で試してください。
          </p>
          <ol>
          <li>ログアウト＞再ログイン</li>
          <li>スマートフォンの電源をOFF＞電源をON</li>
          <li>アプリをアンインストール（削除）＞再インストール後にログイン</li>
          </ol>`,
        },
        {
          id: 4006,
          title: 'ニックネームの変更方法を教えてください。',
          html: `<h3>アプリで変更できます。</h3>
          <p>ホーム画面の右上の歯車マークを押下＞マイページの下記を押下すると編集できます。</p>
          <ul>
          <li>ニックネーム（上部）</li>
          <li>基本情報（テキスト）</li>
          <li>基本情報内（性別・生年・身長）</li>
          </ul>`,
        },
      ],
    },
    {
      id: 5000,
      title: '機種変更',
      content: [
        {
          id: 5001,
          title:
            'スマートフォンを機種変更する予定です。データを引き継ぐにはどうすればよいですか？',
          html: `
          <p>機種変更後の端末に&wellアプリをダウンロードし、既存のアカウントでログインをしてください。<br>
          また、連携している外部アプリに応じて設定を行うことで連携が可能です。</p>
      <h3>iOSの場合</h3>
      <h3 class="h3">＜ヘルスケア＞</h3>
      <p>機種変更を行う場合、他のデータ同様、事前にバックアップをとる必要があります</p>
  
      <h3 class="h3 mt-20">1）iCloudでバックアップをとる</h3>
      <ul class="mt-20">
        <li>
          機種変更前の端末
          <ol>
            <li>「設定」＞ユーザー名＞「iCloud」の順に押下する。</li>
            <li>「iCloudバックアップ」を押下し「iCloudバックアップ」をオンにした状態で、「今すぐバックアップを作成」を押下しバックアップ作成。</li>
          </ol>
        </li>
        <li class="mt-20">
          機種変更後の端末
          <ol>
            <li>「新しいiPhoneとして設定」を押下して、初期設定をする。</li>
            <li>「iPhone バックアップ」という画面が表示されるので、「ほかのバックアップを選択、または復元しない」を押下する。</li>
            <li>すでにバックアップは保存済みなので「iCloudバックアップから復元」を押下する。</li>
            <li>Apple IDとパスワードを入力して「次へ」を押下。バックアップに使用するデータを選択する。復元が完了すると「復元しました」と表示される。</li>
          </ol>
        </li>
      </ul>
      <p class="mt-20">上記の操作を行う事で以前のヘルスケアデータが引き継がれます。</p>
  
      <h3 class="h3 mt-20">2）PCでバックアップをとる</h3>
      <ol class="mt-20">
        <li>iPhoneをPCにつなぎ、「信頼」を押下し、パスコードを入力する。</li>
        <li>iPhoneを接続すると、iTunesの画面左上にiPhoneのアイコンが表示される。</li>
        <li>
          「このコンピュータ」にチェックを付け、「今すぐバックアップ」をクリックする。
          <p>※このとき、<strong>ヘルスケアデータ</strong>もバックアップして引き継ぐには、「iPhoneのバックアップを暗号化」にチェックを付ける。</p>
        </li>
      </ol>
      <ul class="simple-list mt-20">
        <li>
          機種変更前の端末
          <ol>
            <li>「設定」＞ユーザー名＞「iCloud」＞「iPhoneを探す」の順に押下する。</li>
            <li>「iPhoneを探す」をオフにして、Apple IDのパスワードを入力。「オフにする」を押下する。</li>
            <li>PCの「iTunes」を起動し、画面左上の「iTunes」＞「環境設定」＞「デバイス」の順にクリックし、「iPod、iPhone、およびiPadを自動的に同期しない。」にチェックを付けて「OK」をクリックする。</li>
          </ol>
        </li>
        <li class="mt-20">
          機種変更後の端末
          <ol>
            <li>「新しいiPhoneとして設定」を押下して、初期設定をする。</li>
            <li>PCにiPhoneを接続したら、「このバックアップから復元」にチェックを付け、復元するデータを選択したら「続ける」を押下する。</li>
          </ol>
        </li>
      </ul>
      <p class="mt-20 mb-20">上記の操作を行う事で以前のヘルスケアデータが引き継がれます。</p>
  
      <h4>＜Fitbit＞</h4>
      <p>Fitbitはアカウントでの管理をしているので、機種変更後のアプリで外部アプリ（Fitbit）を選択し、同様のFitbitアカウントでログインすれば以前の歩数が表示されます。</p>
  
      <h3>Android</h3>
      <h4>＜Google Fit＞</h4>
      <p>Google Fitはアカウントでの管理をしているので、機種変後のアプリで外部アプリ（Google Fit）を選択し、同様のGoogle Fitアカウントでログインすれば以前の歩数が表示されます。</p>
  
      <h4>＜Fitbit＞</h4>
      <p>Fitbitはアカウントでの管理をしているので、機種変更後のアプリで外部アプリ（Fitbit）を選択し、同様のFitbitアカウントでログインすれば以前の歩数が表示されます。</p>`,
        },
        {
          id: 5002,
          title: '機種変更したら、ログインアカウントがわからなくなりました。',
          html: `企業または健康保険組合の担当窓口へお問い合わせください。`,
        },
      ],
    },
    {
      id: 6000,
      title: 'ウォーキングイベント・歩数',
      content: [
        {
          id: 6001,
          title:
            'ウォーキングイベント（チーム対抗戦）の参加方法などマニュアルはありますか？',
          html: `
          <p>ルールブック・各種マニュアルは以下よりご確認ください。</p>
          <ul class="mt-20">
          <li><a href="https://go.andwell.jp/notifications/1f0fbcb8-dcf0-11ed-b977-0ab1e6a5e015" target="_blank">ルールブック</a></li>
          <li><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">従業員マニュアル</a></li>
          </ul>
          `,
        },
        {
          id: 6002,
          title:
            '車いす（または、歩行が不安定）でもウォーキングイベントに参加できますか？',
          html: `<p>はい、参加できます。<br>
          歩数のカウント方法を、企業または健康保険組合の担当窓口へご相談ください。</p>`,
        },
        {
          id: 6003,
          title: '参加メンバーをキャンセルさせたいです。どうすればよいですか？',
          html: `<p>チーム対抗戦開始後は、メンバーの変更ができません。<br>
          <br>
          なお、アプリでチーム編成する場合、チームが成立すると開始前でもメンバーの変更ができません。<br>
          ただし、チーム成立前はメンバーの変更ができます。詳細は「従業員向けマニュアル」をご確認ください。
          </p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">従業員マニュアル</a></p>`,
        },
        {
          id: 6004,
          title: '他社のユーザーとチームを組むことはできますか？',
          html: `<p>企業をまたいでチームを組むことは出来ません。チームは企業内で編成してください。</p>`,
        },
        {
          id: 6005,
          title:
            'チームリーダーです。不明なユーザーからチームへの参加申請がありました。確認方法はありますか？',
          html: `<p>
          事務局では、個人の特定、対象者にアプリ内でメッセージを送ることもできません。<br>
          同時に2チーム以上の参加申請はできないため、周りの方々にお声がけいただき、心当たりがない場合は、参加申請を「却下」することをお勧めしています。<br>
          なお、不明なユーザーから申請がこないように、チーム作成時に公開範囲を「限定」にすることも可能です。<br>
          <br></p>
          <p>公開範囲設定方法は下記資料のP2をご確認ください。</p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">従業員マニュアル</a></p>
          `,
        },
        {
          id: 6006,
          title:
            '個人の歩数ログは記録が表示されますが、所属チームの記録には０歩と表示されます。',
          html: `<p>チーム対抗戦専用ページ（個人チャレンジ専用ページ）の平均歩数は、アプリ立ち上げから歩数反映まで一定時間がかかります。</p>
          `,
        },
        {
          id: 6007,
          title:
            'ウォーキングイベントに参加していますが、歩数が正しく反映されません。',
          html: `<p>歩数が反映されていない箇所により、下記1または2をご確認ください。</p>

          <h3>1.アプリホーム画面の「今日の歩数」が反映されない</h3>
          <p><a href="#a9006">「歩数が反映されなくなりました。」</a>をご確認ください。</p>

          <h3>2.「チーム対抗戦専用ページ（個人チャレンジ専用ページ）」の「前日の歩数」が反映されない</h3>
          <p>チーム対抗戦専用ページ（個人チャレンジ専用ページ）の平均歩数は、アプリ立ち上げから歩数反映まで一定時間がかかります。</p>
          `,
        },
        {
          id: 6008,
          title: 'チームへの参加申請方法が分かりません。',
          html: `<p>以下、ご確認ください。</p>
          <p class="mt-20">動画：<a href="https://go.andwell.jp/library/a800bce9-7cec-11ed-bff1-06d158a16ffc" target="_blank">チーム編成方法</a></p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">チーム編成マニュアル(p.5～)</a></p>
          `,
        },
        {
          id: 6009,
          title: 'チームの作成方法が分かりません。',
          html: `<p>以下、ご確認ください。</p>
          <p class="mt-20">動画：<a href="https://go.andwell.jp/library/a800bce9-7cec-11ed-bff1-06d158a16ffc" target="_blank">チーム編成方法</a></p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">チーム編成マニュアル(p.3～)</a></p>
          `,
        },
        {
          id: 6010,
          title: 'チーム名の変更はできますか？',
          html: `<p>≪参加登録期間中（8/23～9/12）≫の変更は可能です。変更方法はチーム編成方法によって異なります。</p>
          <h3>【企業&wellご担当者様にてチーム編成:csv登録】</h3>
          <p>チーム名を変更したcsvを再度ご登録ください。</p>
          <h3>【&wellアプリより従業員様にてチーム編成:アプリ登録】</h3>
          <p>従業員の方ご自身でチーム成立前のみアプリ上で変更可能です。</p>
          <p class="mt-20">なお、≪開催中≫はチーム編成方法に関係なく変更できません。</p>
          `,
        },
        {
          id: 6011,
          title:
            'ウォーキングイベント（個人チャレンジ）の参加方法などマニュアルはありますか',
          html: `<p>マニュアルは以下よりご確認ください。</p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/533a506d-34ec-11ed-8934-0af363eb5062" target="_blank">個人チャレンジ ルールブック</a></p>
          `,
        },
        /*
        {
          id: 6012,
          date: {
            from: '2022-02-08',
            to: '2022-04-04',
          },
          title: '参加をキャンセルをしたいです。どうすればよいですか？',
          html: `<p>個人チャレンジ開催中の参加キャンセルはできません。<br>
          個人チャレンジのエントリー期間中のキャンセルはアプリ上ではできません。事務局までお問い合わせください。</p>
          `,
        },
        */
        {
          id: 6013,
          title:
            '歩数ログとチーム対抗戦（ または個人チャレンジ）で表示される歩数が異なるのはなぜですか？',
          html: `<p>アプリホーム画面に表示される歩数ログとチーム対抗戦（または、個人チャレンジ）専用ページの平均歩数は集計期間が異なります。</p>

          <h3>アプリホーム画面</h3>
          <p>月曜日から1週間の平均歩数です。本日分を含んでいます。</p>
          
          <h3>チーム対抗戦（または、個人チャレンジ）専用ページ</h3>
          <p>期間中の前日分までの平均歩数です。本日分は含まれていません。</p>
          `,
        },
      ],
    },
    {
      id: 7000,
      title: 'ポイント交換（賞品発送）',
      content: [
        {
          id: 7001,
          title:
            'ポイント交換の際、同じメニューを複数口同時に手続きできますか？',
          html: `複数回の応募は可能です。<br>
          但し、複数口同時応募はできず、同時に複数応募する際は1口ずつのお申込みが必要です。`,
        },
        {
          id: 7002,
          date: {
            from: '2022-06-01',
            to: '2022-07-31',
          },
          title:
            'ポイント交換キャンペーンで、ポイントを使った賞品に応募というのは、応募をするだけでポイントを使うということでしょうか？',
          html: `抽選の賞品で応募いただいたポイントは、当落選に関わらず、応募した時点でポイントを利用した（＝ポイントを使った）ことになります。`,
        },
        {
          id: 7003,
          title:
            '賞品の発送先を入力したのですが確認できません。確認方法を教えてください。',
          html: `入力した送付先情報の内容は、アプリ上では確認することはできません。`,
        },
      ],
    },
    {
      id: 8000,
      title: '異動・退職時について',
      content: [
        {
          id: 8001,
          title:
            '異動で会社が変わります。そのまま&wellを利用することはできますか？',
          html: `企業（または健康保険組合）の担当窓口までご確認ください。`,
        },
        {
          id: 8002,
          title: '退職します。そのまま&wellを利用することはできますか？',
          html: `企業（または健康保険組合）の担当窓口までご確認ください。`,
        },
      ],
    },
    {
      id: 9000,
      title: '不具合・困ったら',
      content: [
        {
          id: 9001,
          title: 'ログインができません。',
          html: `<p>ログインできない場合、以下をお試しください。</p>
          <ol>
          <li>入力しているアカウント、パスワードが正しいかご確認ください。<br>
          ※iPhoneなど、スペルチェックをして自動変換してしまう機能がありますのでご注意ください。<br>
          ※全角と半角の区別があっているかご確認ください。
          <li>スマートフォンの電源を入れなおす。
          <li>起動中の&wellアプリを終了する。
          <li>キャッシュをクリアする。
          </ol>`,
        },
        {
          id: 9002,
          title: 'パスワードを再設定しても、再度ログインを要求されます。',
          html: `&wellアプリを一旦終了させ、再起動をしてから再度ログインをお試しください。`,
        },
        {
          id: 9003,
          title: 'アプリのホーム画面の一部が表示されません。',
          html: `<p>
          ログインしているアカウントに誤りがある可能性があります。<br>
          お手数ですが一度アプリよりログアウトし、アカウントをお確かめのうえ再ログインをお試しください。
          </p>
          <ol>
          <li>アプリ右上の「設定マーク」を押下
          <li>画面が遷移したら、下へスクロールし「ログアウト」を押下
          </ol>
          <p>
          ログイン画面に遷移したら。ログインアカウント名に誤りがないか確認し、再ログインをしてください。<br>
          ※iPhoneなど、スペルチェックをして自動変換してしまう機能がありますのでご注意ください。<br>
          ※全角と半角の区別があっているかご確認ください。<br>
          <br>
          </p>
          <p>再ログイン後も表示されない場合は、<br>アプリがフリーズしている可能性がございます。以下お試しください。</p>
          <p>
          ・アプリを終了する。<br>
          ※終了する方法は、検索サイトで「お使いの携帯電話（iPhone or Android）」と「アプリを終了させる方法」などと入力し検索すると、確認できます。<br>
          </p>
          <p class="mt-20">
          上記ご対応後も不具合が解消されない場合は、「アプリの再インストール」及び「スマートフォンの電源の入れ直し」をお試しください。
          </p>
          `,
        },
        {
          id: 9004,
          title:
            'アプリの入力フォームにメールアドレスを入力すると「形式が正しくありません」と表示されてしまいます。',
          html: `<p>
          メールアドレスに誤りはないですか？<br>
          多くの方がテキストの入力ミスであることが多く、下記2点を確認し、再入力をしてください。
          </p>
          <ol>
          <li>「@」も含め、全角と半角の区別があっているか
          <li>自動変換はされてないか。iPhoneなど、スペルチェックをして自動変換してしまう機能があるため一文字ずつご入力いただくなど、お試しください。
          </ol>
          `,
        },
        {
          id: 9005,
          title: 'アプリが起動できません。',
          html: `<p>アプリがフリーズしている可能性がございます。以下の方法を順にお試しください。</p>
          <ol>
          <li>アプリを終了する。<br>
          ※終了する方法は、検索サイトで「お使いの携帯電話（iPhone or Android）」と「アプリを終了させる方法」などと入力し検索すると、確認できます。
          <li>アプリをアンインストールし、再インスト―ルする。
          <li>スマートフォンの電源を入れなおす。
          </ol>
          `,
        },
        {
          id: 9006,
          title: '歩数が反映されません。',
          html: `<p>「外部アプリと連携ができていない」または「アプリがフリーズしている」可能性があります。<br>
          <p class="mt-20">外部アプリとの連携方法については使用端末に応じて以下をご確認下さい。</p>
          <ul class="mt-20">
          <li><a href="#a3003">iPhone(iOS)をご利用の場合</a></li>
          <li><a href="#a3004">Androidをご利用の場合</a></li>
          </ul>
          <p class="mt-20">
          アプリがフリーズしている場合は、以下方法を順にお試しください。</p>
          <ol>
          <li>アプリを終了する。<br>
          ※終了する方法は、検索サイトで「お使いの携帯電話（iPhone or Android）」と「アプリを終了させる方法」などと入力し検索すると、確認できます。
          <li>アプリからログアウトし、再ログインする。
          <li>アプリをアンインストールし、再インスト―ルする。
          <li>スマートフォンの電源を入れなおす。
          </ol>`,
        },
        {
          id: 9007,
          title: '＆wellアプリや事務局からのメールが届きません。',
          html: `<p>以下の確認をお願いします。</p>
          
          <h3>1.受信制限の設定をしていませんか？</h3>
          <p>
          ご登録しているメールアドレスがキャリアメールの場合、各キャリアの下記迷惑メール対策により、メールが正しく届かない場合があります。<br>
          「指定ドメインからのメールのみ受信許可している」または「キャリア提供の迷惑メール対策の強度が強い」場合は、ドメイン【 andwell.jp 】を受信できるよう設定をお願いします。<br>
          設定方法はご利用キャリアのウェブサイトにて確認してください。
          </p>
          <h3>2.登録されたメールアドレスに誤りはないですか？</h3>
          <h3>3.迷惑メールフォルダや「ゴミ箱」に振り分けられていませんか？</h3>
          <p>
          お使いのセキュリティソフト、メールソフトによって、スパムメール(迷惑メール)と判断されている場合があります。<br>
          「メールの自動振り分け機能などの設定」や「迷惑メールフォルダ」を確認してください。
          </p>
          <h3>4.受信メールボックスの容量が一杯になっていませんか？</h3>
          <p>メールの受信可能容量を確認し、調整ください。</p>`,
        },
        {
          id: 9008,
          title: '電話で問い合わせしたいです。',
          html: `<p>&wellに関するお問い合わせは専用フォームにてご対応しています。<br>
          お手数をおかけしますが、まずはお問い合わせ内容を専用フォームまでご連絡ください。<br>
          なお、お問い合わせ内容により、事務局よりお電話をする場合もあります。</p>`,
        },
      ],
    },
  ],
  en: [
    {
      id: 1000,
      title: 'First time login',
      content: [
        {
          id: 1001,
          title: 'Is there a manual available?',
          html: `
          <h3>&well App Login Manual</h3>
          <ul>
          <li>
            (PDF)
            <a href="/files/2024/download_and_login.pdf" target="_blank">日本語</a>
            │
            <a href="/files/2024/download_and_login_en.pdf" target="_blank">ENGLISH</a>
          </li>
          <li class="mt-20">
            (Movie)
            <a href="https://vimeo.com/600026456" target="_blank">iPhone(iOS)</a>
            │
            <a href="https://vimeo.com/600026438" target="_blank">Android</a>
          </li>
          </ul>`,
        },
        {
          id: 1002,
          title: 'Where can I download the &well app?',
          html: `
          <p>From your device, take a picture of the QR below with a QR reader to go to the store. Or, please go to the URL.</p>
          <div class="col2 mt-30">
            <div style="margin-right:30px;">
              <b>iPhone(iOS)</b>
              <p>App Store</p>
              <p><a href="https://apps.apple.com/jp/app/well/id1434515379" target="_blank">LINK</a></p>
              <img src="img/faq/ios.png" width="160" alt="iPhone">
            </div>
            <div>
              <b>Android</b>
              <p>Google Play</p>
              <p><a href="https://play.google.com/store/apps/details?id=jp.andwell" target="_blank">LINK</a></p>
              <img src="img/faq/android.png" width="160" alt="android">
            </div>
          </div>
          <br>
          <p>Note: In some cases, you may not be able to voluntarily install the application on company-loaned devices for security reasons.<br>
          If you are unable to read the QR code, please contact your company or health insurance association.</p>`,
        },
        {
          id: 1003,
          title:
            "I don't know my account and password when I register for the first time. What should I do?",
          html: `
          <h3>If you do not know your account</h3>
          <p>Please check your company or health insurance association for your first login account, which will be sent to you by email or other means. If you are unsure, please contact your company or health insurance association.</p>

          <h3>If you do not know your password</h3>
          <p>
          If your account is with an email address, you do not need an initial password.
If your account is not an e-mail address, please check the information provided by your company or health insurance association. If you are unsure, please contact your company or health insurance association.</p>
          `,
        },
        {
          id: 1004,
          title:
            'I\'ve logged in, but I can\'t get to the home page from the "about &well" page. What should I do?',
          html: `Swipe the displayed &well explanation screen to the left several times, and you will see the "Link with ASUKEN" screen at the end.
          Click on the "Next" button under that display screen to move to the next screen.`,
        },
        {
          id: 1005,
          title:
            'Is the &well app and the &well member site a common account and password?',
          html: `Yes, it is a common account and password.`,
        },
        {
          id: 1006,
          title: 'Can I use the &well app only?',
          html: `Yes, you can.`,
        },
        {
          id: 1007,
          title: 'Can I use the &well member site only?',
          html: `
          Yes, you can.<br>
You can enjoy mainly videos, LIVE streaming, and reading contents.<br>
However, it is not possible to enter the number of steps or your own log on the website. Also, if you do not launch the app, you will not be able to get the number of steps.
          `,
        },
        {
          id: 1008,
          title:
            'In the middle of setting up the &well app, it asks me to download another app "Healthcare" (or "Google fit"), do I have to do that?',
          html: `
          <h3>If you are using an iPhone (iOS)</h3>
          <p>The "Health Care" application is a standard application installed on the iPhone (iOS). There is no need to install it.
          </p>

          <h3>If you are using Android</h3>
          <p>You will need to download the "Google Fit" app and sign in with your Google account.</p>
          <p><img src="img/faq/googlefit.png" width="160" alt="Google fit"></p>
          `,
        },
        {
          id: 1009,
          title: 'What are the language settings? How can I change them?',
          html: `
          <p>
          Japanese and English (automatic translation) are available.<br>
          Please see below for how to change.
          </p>

          <h3>To change the language in the app</h3>
          <p>Click on the gear mark at the top right of the home screen > Select Language > Japanese/English in My Page > Click OK</p>

          <h3>To change the language on the website</h3>
          <p>After logging in, click the icon in the upper right corner of the top page > select My Page Language Settings Language > Japanese/English > click the X to close.</p>
          `,
        },
      ],
    },
    {
      id: 2000,
      title: 'Account, password, and registered address',
      content: [
        {
          id: 2001,
          title: 'I forgot my account/password.',
          html: `
          <h3>If you have forgotten your account</h3>
          <p>
          Please contact the Human Resources Department (General Affairs Department) of your company or your health insurance association.<br>
          If you do not know where to contact, <a href="https://www3.mitsuifudosan.co.jp/enquete/regulation.php?enqueteurl=36f461a6037d8beb226113ae7531199b" target="_blank">please contact the &well office.</a>
          </p>

          <h3>Forgot your password?</h3>
          <p><a href="https://go.andwell.jp/resetPassword">Click here to reset or change your password.</a></p>
          `,
        },
        {
          id: 2002,
          title: 'I want to change my registered e-mail address.',
          html: `
          <p>
          If your account is a company email address, you will not be able to change it.<br>
          If your account is an alphanumeric string, you can change your registered address.<br>
          </p>
          <p>
          Which type of account you have is determined by your company or health insurance association. Individual selection is not possible.
          </p>
          `,
        },
        {
          id: 2003,
          title:
            'Is there a common password for the &well app and the &well member site?',
          html: `Yes, it is a common password.`,
        },
      ],
    },
    {
      id: 3000,
      title: 'Available devices and wearable devices',
      content: [
        {
          id: 3001,
          title: 'Can I use the service with two mobile devices?',
          html: `
          <p>Yes, you can use this service.<br>
          If you have two mobile devices, please keep the following in mind.</p>
          <p class="paragraph mb-30">If you have two mobile devices, please note the following:</p>
          <h3>Assumption</h3>
          <ul>
            <li>The same account is used to log in and use the &well application on the two mobile devices.</li>
          </ul>
          <h3>Example of having two devices</h3>
          <ul>
            <li>Use your company's mobile phone (first device) on weekdays and your own mobile phone (second device) on weekends.</li>
            <li>Both devices have the same external app linked with the &well app.</li>
          </ul>
          <h3>Example procedure</h3>
          <ul class="simple-list mb-30">
            <li>
              Monday to Friday
              <p>Launch the &well app on the first device when you walk.</p>
              <p>Note: Launch the app the next day and upload your steps for the previous day.</p>
            </li>
            <li>
              Saturday and Sunday
              <p>Launch the &well app on the second device when you walk.</p>
              <p>Note: Launch the app the following Monday and upload your steps for Saturday and Sunday.</p>
            </li>
          </ul>
          <h3>Results</h3>
          <p>The data acquired by the two devices will be compared, the higher of the steps from 14 days before to the date the app was launched on will be logged, and the average steps will be reflected.</p>
          <p>Note: For example, if you launch the app after not using it for 21 days, you'll be able to acquire the step data for the last 14 days, but not for the 7 days before that.</p>`,
        },
        {
          id: 3002,
          title: 'Can I use the &well app on my iPad or PC?',
          html: `The &well app is not available for iPad and PC. Please use a smart phone (iPhone or Android).`,
        },
        {
          id: 3003,
          title: 'What wearable devices can be connected?',
          html: `
          <p>Typical wearable devices that can be connected to the &well app are as follows.</p>
          <p>Example:</p>
          <ul>
          <li>Apple Watch</li>
          <li>Fitbit</li>
          <li>Garmin, etc.</li>
          </ul>
          <p class="mt-20">Fitbit can be directly linked with the Fitbit dedicated app.
          Other than Fitbit, any wearable device that can be connected to "Healthcare" or "Google Fit" can be connected to the &well app. In this case, you will need to download the Connect app separately.
          For details on the Connect App and how to connect your wearable device to "Healthcare" or "Google Fit", please refer to the website of the manufacturer or contact the manufacturer.
          </p>
          `,
        },
        {
          id: 3004,
          title:
            "I'm logged in, but I can't link my steps. (iPhone: How to link with Health Care in iOS)",
          html: `
          <p>Please follow the steps below to confirm.</p>
          <p class="mt-20">&lt;Procedure&gt;</p>
          <ol>
          <li>Check the &well app.
          <li>Check your iPhone device.
          </ol>

          <p class="mt-20">&lt;Procedure Details&gt;</p>
          <h3>1. How to check the &well app</h3>
          <p>Click the gear symbol on the upper right corner of the home screen > Check the data linkage from My Page. Make sure that the external app you are linking to is listed as "Healthcare".</p>
          <p>If you don't see "Healthcare" on the screen, click on the external app you want to link to, and select "Healthcare".</p>

          <h3>2. How to check your iPhone device</h3>
          <p>Check that the button on the right side of Settings > Privacy > Healthcare > &well > "Steps" on your iPhone device is turned on (green color displayed). </p>
          <p>After completing this process, you will be able to get the number of steps by launching the &well app.</p>
          `,
        },
        {
          id: 3005,
          title:
            "I'm logged in, but I can't link my steps. (How to link with Google Fit in Android)",
          html: `
          <p>Home screen: Gear mark in the upper right corner > My Page: Data Linkage: The external application that is linked is listed as "Google Fit".</p>
          <p>If "Google Fit" is not listed, tap the external app you are linked to, and a selection screen will appear, select "Google Fit".</p>
          <p>After completing this process, you will be able to get the number of steps by launching the &well app.</p>
          `,
        },
        {
          id: 3006,
          title: `I'm logged in, but I can't link my steps.(How to link with Google Fit bit) `,
          html: `For information on linking with Fitbit, please refer to page 11 onward in <a href="#a1001">the &well App Download and Login Manual</a>. `,
        },
        {
          id: 3007,
          title:
            'The step count logs of the wearable device and the &well app do not match.',
          html: `The timing of the step log may be different.<br>
          We do not know the details, so please contact the manufacturer.`,
        },
      ],
    },
    {
      id: 4000,
      title: 'Settings and disclosure range',
      content: [
        {
          id: 4001,
          title: 'Can I cancel a friend request?',
          html: `
          <p>If you have sent a friend request and the other person has not yet "approved" or "rejected" it, you will not be able to cancel the request at this time.</p>
          <p>Once the other person has approved the request, you can delete the request by clicking "Friends" in the "≡" menu at the top left of the home screen > "Friends List".</p>

          <p class="mt-20">&lt;How to delete a friend&gt;</p>
          
          <h3>If you are using an iPhone (iOS)</h3>
          <p>Swipe the friend you want to remove from the list from right to left, and the "Delete" button will appear.</p>
          
          <h3>For Android</h3>
          <p>Press and hold the friend you want to delete to open the delete dialog box.</p>`,
        },
        {
          id: 4002,
          title:
            'What information about yourself will be available to your friends?',
          html: `
          <p>Like! The content you have liked, the programs you have participated in, and the missions you have accomplished will be published.</p>
          <p class="mt-20">
          Example of display:<br>
          X has achieved "Let's walk 6,000 steps!" has been achieved.<br>
          X has achieved "Let's browse Morning Stretch (April.1st)."  has been achieved.<br>
          </p>`,
        },
        {
          id: 4003,
          title:
            'How can I prevent my timeline from being shown to my connected friends?',
          html: `It is not possible to make the timeline private.`,
        },
        {
          id: 4004,
          title:
            'I receive e-mails such as notices at my registered address. How can I unsubscribe?',
          html: `<h3>Unsubscribe with the app</h3>
          <p>Click the gear mark in the upper right corner of the home screen > My Page Mail Magazine Settings > Don't Receive > OK</p>
          
          <h3>Unsubscribe at the member site</h3>
          <p>After logging in, click the icon in the upper right corner of the top page > Click the "Mail Magazine Settings" link in the "My Page" section > Click the "Do not accept" link > Click the "X" to close the page.</p>
          
          <p>Please note that you may receive some emails after unsubscribing.</p>`,
        },
        {
          id: 4005,
          title: "I can't edit my profile picture anymore.",
          html: `<h3>You can edit it in the app.</h3>
          <p>You can change it by pressing the gear symbol in the upper right corner of the home screen > camera icon or photo on My Page.<br>
          If you still can't change it, please try the following steps.
          </p>
          <ol>
          <li>logout -> log in again.</li>
          <li>Turn off your smartphone > Turn on the power</li>
          <li>Uninstall (delete) the application > Login after reinstalling.</li>
          </ol>`,
        },
        {
          id: 4006,
          title: 'How do I change my nickname?',
          html: `<h3>You can change it in the app.</h3>
          <p>Click on the gear mark in the upper right corner of the home screen > My Page and click on the following to edit.
          </p>
          <ul>
          <li>Nickname (top)</li>
          <li>Basic information (text)</li>
          <li>Basic information (gender, year of birth, height)</li>
          </ul>`,
        },
      ],
    },
    {
      id: 5000,
      title: 'Change of cell phone model',
      content: [
        {
          id: 5001,
          title:
            'I am planning to change my smartphone model. How can I transfer my data?',
          html: `
          <p>Download the &well app to your new device and login with your existing account.
          You can also connect to external apps by configuring the settings according to the external apps you are connecting to.
          </p>
          <h3>In the case of iPhone (iOS)</h3>
          <h3 class="h3">＜Health＞</h3>
          <p>If you change your device, you need to back up your Health data in advance, the same as for other data.</p>
      
          <h3 class="h3 mt-20">1）Back up on iCloud.</h3>
          <ul class="simple-list mt-20">
            <li>
              Device you're changing from
              <ol>
                <li>Tap "Settings" > User Name > "iCloud."</li>
                <li>Tap "iCloud Backup" and with "iCloud Backup" turned on, tap "Create a backup now" to create a backup.</li>
              </ol>
            </li>
            <li class="mt-20">
              Device you're changing to
              <ol>
                <li>Tap "Set as new iPhone" and do the initial setup.</li>
                <li>When the "iPhone Backup" screen appears, tap "Select another backup or do not restore."</li>
                <li>Tap "Restore from iCloud Backup," because a backup has already been saved.</li>
                <li>Enter your Apple ID and password and tap Next. Select the data you want to use for the backup. When the restore is complete, the message "Restored" will appear.</li>
              </ol>
            </li>
          </ul>
          <p class="mt-20">Performing the above operations will bring over your previous Health data.</p>
      
          <h3 class="h3 mt-20">2）Back up with a PC.</h3>
          <ol class="ordered-list mt-20">
            <li>Connect your iPhone to your PC, tap Trust, and enter the passcode.</li>
            <li>When you connect your iPhone, the iPhone icon appears in the upper left corner of the iTunes screen.</li>
            <li>
              Check "This computer" and click "Backup Now."
              <p>At this stage, if you also want to back up <strong>Health data</strong> and bring it over, check the "Encrypt iPhone backup" box.</p>
            </li>
          </ol>
          <ul class="simple-list mt-20">
            <li>
              Device you're changing from
              <ol>
                <li>Tap Settings > User Name > iCloud > Find My iPhone.</li>
                <li>Turn off "Find My iPhone" and enter your Apple ID password. Tap "Turn off."</li>
                <li>Launch "iTunes" on your PC, click "iTunes" in the upper left corner of the screen, then "Preferences" and "Devices," then "Don't automatically sync iPod, iPhone, and iPad," and click "OK."</li>
              </ol>
            </li>
            <li class="mt-20">
              Device you're changing to
              <ol>
                <li>Tap "Set as new iPhone" and do the initial setup.</li>
                <li>After connecting your iPhone to your PC, tick the "Restore from this Backup" checkbox, select the data you want to restore, then tap "Continue."</li>
              </ol>
            </li>
          </ul>
          <p class="mt-20">Performing the above operations will bring over your previous Health data.</p>
      
          <h3 class="h3 mt-20">＜Fitbit＞</h3>
          <p>Fitbit is managed via accounts, so if you select the external app (Fitbit) in the app after changing devices and log in with the same Fitbit account, you'll see your previous step count.</p>
      
          <h3>Android</h3>
          <h3 class="h3">＜Google Fit＞</h3>
          <p>Google Fit is managed via accounts, so if you select the external app (Google Fit) in the app after changing devices and log in with the same Google Fit account, you'll see your previous step count.</p>
      
          <h3 class="h3 mt-20">＜Fitbit＞</h3>
          <p>Fitbit is managed via accounts, so if you select the external app (Fitbit) in the app after changing devices and log in with the same Fitbit account, you'll see your previous step count.</p>`,
        },
        {
          id: 5002,
          title: "I changed my phone model and now I don't know my account.",
          html: `Please inquire at your company or health insurance association.`,
        },
      ],
    },
    {
      id: 6000,
      title: 'Walking events and steps',
      content: [
        {
          id: 6001,
          title:
            'Is there a manual on how to participate in walking events (team competitions)?',
          html: `
          <p>Please refer to the following links for the rulebook and various manuals.</p>
          <ul class="mt-20">
          <li><a href="https://go.andwell.jp/notifications/1f0fbcb8-dcf0-11ed-b977-0ab1e6a5e015" target="_blank">Rule Book</a></li>
          <li><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">Employee Manual</a></li>
          </ul>
          `,
        },
        {
          id: 6002,
          title:
            'Can I participate in a walking event if I am in a wheelchair (or unsteady walking)?',
          html: `<p>Yes, you can participate.<br>
          Please consult with your company or health insurance association about how to count your steps.</p>`,
        },
        {
          id: 6003,
          title: "I want to cancel a member's participation. What should I do?",
          html: `<p>Once the team competition starts, you cannot change the team members.<br><br>
          When organizing a team using the app, once the team is formed, you cannot change the members even before the team starts. However, you can change the team members before the team is formed. For details, please refer to the Employee Manual.
          </p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">Employee Manual</a></p>`,
        },
        {
          id: 6004,
          title: 'Can I team up with users from other companies?',
          html: `<p>It is not possible to form teams across companies.<br>
          Teams must be formed within a company.</p>`,
        },
        {
          id: 6005,
          title:
            "I'm the team leader. I received a request from an unknown user to join my team. Is there any way to check?",
          html: `<p>
          The secretariat will not be able to identify individuals or send messages to the target audience in the application. Since more than two teams cannot apply for participation at the same time, we recommend that you ask people around you, and if you do not know the person, "reject" the application. To prevent unknown users from applying for participation, you can set the scope of public access to "Limited" when you create a team.
          </p>
          <p class="mt-20">For details on how to set the disclosure range, please refer to p.2 of the following document.</p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">Employee Manual</a></p>
          `,
        },
        {
          id: 6006,
          title:
            "My personal step log shows the record, but my team's record shows 0 steps.",
          html: `<p>It will take a certain amount of time for the average number of steps in the team competition page (individual challenge page) to reflect the number of steps after the application is launched.</p>
          `,
        },
        {
          id: 6007,
          title: 'The number of steps is no longer reflected.',
          html: `<p>Please check the following (1) or (2) depending on where the number of steps is not reflected.</p>

          <h3>1."Today's step count" on the app home screen is not reflected.</h3>
          <p>Please check <a href="#a9006">"The number of steps is no longer reflected."</a></p>

          <h3>2.The "Number of steps taken the previous day" on the "Team competition page (individual challenge page)" is not reflected.</h3>
          <p>It takes a certain amount of time for the average number of steps on the Team competition page (Individual challenge page) to reflect the number of steps after the application is launched.</p>
          `,
        },
        {
          id: 6008,
          title: "I don't know how to apply to join a team.",
          html: `<p>Please check the following.</p>
          <p class="mt-20">Video: <a href="https://go.andwell.jp/library/a800bce9-7cec-11ed-bff1-06d158a16ffc" target="_blank">How to organize a team</a></p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">Team Organization Manual(p.5~)</a></p>
          `,
        },
        {
          id: 6009,
          title: "I don't know how to create a team.",
          html: `<p>Please check the following.</p>
          <p class="mt-20">Video: <a href="https://go.andwell.jp/library/a800bce9-7cec-11ed-bff1-06d158a16ffc" target="_blank">How to organize a team</a></p>
          <p class="mt-20"><a href="https://go.andwell.jp/notifications/0e53a5d7-dcf3-11ed-b85c-06528b823331" target="_blank">Team Organization Manual(p.3~)</a></p>
          `,
        },
        {
          id: 6010,
          title: 'Can I change my team name?',
          html: `<p>You can make changes ≪during the sign-up period (August 23 - September 12)≫. How you make changes varies depending on how your team is organized. </p>
          <h3>The Company's &well Representative Organizes Teams: CSV Registration</h3>
          <p>Please re-register your CSV with the team name changed. </p>
          <h3>Employees Organize Teams via the &well App: App Registration</h3>
          <p>Employees can only make changes in the app by themselves before the team is formed.</p>
          <p class="mt-20">You can't make changes ≪during the event≫ regardless of how your team is organized.</p>
          `,
        },
        {
          id: 6011,
          title:
            'Is there a manual available that explains how to join walking events (Individual Challenges) and other details about them?',
          html: `<p>The related manual can be found below. </p>
          <p class="mt-20">Link: <a href="https://go.andwell.jp/notifications/533a506d-34ec-11ed-8934-0af363eb5062" target="_blank">Individual Challenge Manual</a></p>
          `,
        },
        /*
        {
          id: 6012,
          date: {
            from: '2022-02-08',
            to: '2022-04-04',
          },
          title: 'I would like to cancel my participation. What should I do?',
          html: `<p>You cannot cancel your participation during the Individual Challenge.
          Cancellation during the entry period of the Individual Challenge is not possible on the application. Please contact the Secretariat.</p>
          `,
        },
        */
        {
          id: 6013,
          title:
            'Why is the number of steps shown in the step log different from the number of steps shown in the team competition (or individual challenge)?',
          html: `<p>The period of time when the average number of steps is counted differs between the step log displayed on the app home screen and the page dedicated to the team competition (or individual challenge).</p>

          <h3>App home screen</h3>
          <p>This is the average number of steps for one week from Monday. This includes today's steps.</p>
          
          <h3>Team competition (or individual challenge) page</h3>
          <p>This is the average number of steps taken up to the previous day during the period. It does not include today's steps.</p>
          `,
        },
      ],
    },
    {
      id: 7000,
      title: 'Redeeming points (shipping prize)',
      content: [
        {
          id: 7001,
          title:
            'Can I exchange multiple units of the same menu item at the same time?',
          html: `Multiple applications are allowed.<br>
          However, you cannot apply for multiple entries at the same time, and you must apply for one entry at a time.`,
        },
        {
          id: 7002,
          date: {
            from: '2022-06-01',
            to: '2022-07-31',
          },
          title:
            'Does applying for a prize using points in a point exchange campaign mean that I have to use points just to apply for the prize?',
          html: `Regardless of whether you win or lose, you will have used (i.e., spent) your points when you apply for the lottery prizes.`,
        },
        {
          id: 7003,
          title:
            'I have entered the shipping address for my prize, but I cannot confirm it. How can I check?',
          html: `The content of the shipping address information you entered cannot be confirmed on the application.`,
        },
      ],
    },
    {
      id: 8000,
      title: 'At the time of transfer or retirement',
      content: [
        {
          id: 8001,
          title: "I'm moving to a different company. Can I still use &well?",
          html: `Please check with the contact person at your company (or health insurance association).`,
        },
        {
          id: 8002,
          title: "I'm retiring. Can I continue to use &well?",
          html: `Please check with the contact person at your company (or health insurance association).`,
        },
      ],
    },
    {
      id: 9000,
      title: 'Problems and Troubleshooting',
      content: [
        {
          id: 9001,
          title: "I can't log in.",
          html: `<p>If you are unable to log in, please try the following: </p>
          <ol>
          <li>Please make sure that the account and password you are entering are correct.<br>
          Please note that some functions, such as spell check on iPhones, will automatically convert your password.<br>
          Please note that some functions, such as iPhone spell check, automatically convert your password.
          <li>Turn on your smartphone again.
          <li>Quit the running &well application.
          <li>Clear the cache.
          </ol>`,
        },
        {
          id: 9002,
          title:
            'Even if I reset my password, I will be asked to log in again.',
          html: `Please close the &well app, restart it, and then try logging in again.`,
        },
        {
          id: 9003,
          title: "Some parts of the app's home screen aren't showing up.",
          html: `
          <p>
          You may not be logged into the correct account. <br
          Please log out of the app, verify your account name, and try logging in again. <br>
          </p>
          <ol>
          <li>Press the "Settings mark" at the top right on the app 
          <li>When the screen changes, scroll down and press "Log out". Once you're back on the login screen, check your login account name for errors and try logging in again. 
          </ol>
          <p>
          Note: Be careful of the autocorrect function (found on iPhones and other smartphones) automatically changing your account name. <br>
          Note: Please check whether you are entering full- or half-width characters. 
          </p>
          <p class="mt-20">
          If it still doesn't show up correctly after you log in again, the app may be frozen. Follow the steps below to find out. <br>
          ・ Close the app. <br>
          Note: You can find out how to close the app by searching for "your mobile phone （iPhone or Android）" and "how to close apps," etc. in a search engine. 
          </p>
          <p class="mt-20">
          If the above actions don't solve the issue, try reinstalling the app or turning your smartphone off and on again.
          </p>

          `,
        },
        {
          id: 9004,
          title:
            'When I enter my email address in the input form of the application, it shows "Invalid format".',
          html: `<p>
          Is your e-mail address correct?<br>
          Please check the following two points and re-type your e-mail address.
          </p>
          <ol>
          <li>Make sure the "@" and other characters are correct.
          <li>Please try typing one letter at a time, as some iPhones have a function that checks spelling and automatically converts the text.
          </ol>
          `,
        },
        {
          id: 9005,
          title: "The app won't open.",
          html: `<p>If it still doesn't show up correctly after you log in again, the app may be frozen.Please follow the method below. </p>
          <ol>
          <li>Close the app. <br>
          Note: You can find out how to close the app by searching for "your mobile phone （iPhone or Android）" and "how to close apps," etc. in a search engine. 
          <li>Uninstall the app and re-install it. 
          <li>Turn your smartphone off and on again.
          </ol>
          `,
        },
        {
          id: 9006,
          title: `My steps aren't being reflected properly.`,
          html: `<p>Your app may not be correctly linked with an external app, or it may be frozen. <br>
          <br>
          To find out how to confirm if your app is linked to an external app, please see the following (based on your device). </p>
          <ul class="mt-20">
          <li><a href="#a3003">For iPhone (iOS) users</a></li>
          <li><a href="#a3004">For Android users</a></li>
          </ul>

          <p class="mt-20">If the app is frozen, please follow the method below.</p>
          <ol>
          <li>Close the app.<br>
          Note: You can find out how to close the app by searching for "your mobile phone (iPhone or Android)" and "how to close apps," etc. on a search engine. 
          <li>Log out of the app and log in again. 
          <li>Uninstall the app and re-install it. 
          <li>Turn your smartphone off and on again.
          </ol>`,
        },
        {
          id: 9007,
          title:
            'I have not received any emails from the &well app or the office.',
          html: `<p>Please check the following</p>

          <h3>1.Have you set any restrictions on receiving e-mails?</h3>
          <p>
          If your registered e-mail address is a carrier e-mail address,<br>
          you may not receive e-mails properly due to the following anti-spam measures of each carrier.<br>
          If you are only allowed to receive e-mails from the specified domain, or if the anti-spam measures provided by your carrier are too strong, please set your e-mail address to receive the domain 【andwell.jp】.<br>
          Please check your carrier's website for instructions.
          </p>
          <h3>2.Is the email address you registered correct?</h3>
          <h3>3.Please make sure that your email address is not being sent to your junk mail folder or "trash".</h3>
          <p>
          Your security software or e-mail software may be judging the e-mail as spam mail.<br>
          Please check the settings of the automatic mail sorting function and the junk mail folder.
          </p>
          <h3>4.Is the capacity of your incoming mailbox full?</h3>
          <p>Please check and adjust the capacity of your inbox.</p>
          `,
        },
        {
          id: 9008,
          title: 'I would like to inquire by phone.',
          html: `<p>If you have any questions about &well, please contact us using the dedicated form.<br>
          We apologize for the inconvenience, but please send your inquiry to the dedicated form first.<br>
          Depending on the nature of your inquiry, the secretariat may call you back.</p>`,
        },
      ],
    },
  ],
}

export default faqData
