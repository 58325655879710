<!--
「3-パスワード忘れ」とログイン画面下の「1-初回ログイン」に使用されるコンポーネント
「2-個人メール登録」は似ているが別処理
※番号は「ログイン仕様.pptx」より
-->
<template lang="pug">
  .ResetPassword
    form(v-on:submit.prevent="requestResetPassword")
      div
        input(id="mailaddress" type="text" :placeholder="$t('登録メールアドレス')" v-model="mailaddressModel")
      .error(v-if="mailErrorText") {{mailErrorText}}
      button.large-button-style.blue-button(type="submit") {{$t("送信")}}

</template>
<script>
export default {
  data() {
    return {
      mailaddressModel: '',
      mailErrorText: '',
    }
  },
  props: {
    next: {
      type: String,
    },
  },
  methods: {
    requestResetPassword() {
      this.mailErrorText = ''
      const mailAddress = this.mailaddressModel
      if (!mailAddress) {
        this.mailErrorText = this.$i18n.t('メールアドレスが入力されていません')
        return
      }
      KiiUser.resetPasswordWithNotificationMethod(mailAddress, 'EMAIL')
        .then(() => {
          this.mailErrorText = ''
          console.log('requestResetPassword done.')
          this.toNextPage()
        })
        .catch(error => {
          console.log(error.message)
          this.mailErrorText = this.$i18n.t(
            'テナントユーザーとして登録されていません'
          )
        })
    },
    toNextPage() {
      const nextPage = this.next || '/'
      this.$router.push({ name: nextPage })
    },
  },
}
</script>
