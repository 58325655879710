<template lang="pug">
.notice
  .common-h1-area
    HeadingBlock(level="1" text="Website Terms of Use")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") {{$t("サイトのご利用上の注意")}}
  .sub-page-padding
    .container
      article.article-content
        .text(v-if="this.$i18n.locale === 'en'").
          This website is operated and administered by Mitsui Fudosan Co., Ltd. (hereinafter referred to as "the Company"). Please read and agree with the following terms and conditions before using this website.<br>
          The <router-link class="inline-block" :to="{ name: 'termsOfService' }">&well Service Terms of Use</router-link> will apply to the use of service content on this website.<br>
          In addition, when service content has separate terms and conditions, please read and agree to those before use. By using this website, you agree to all the terms and conditions.<br>
          The terms and conditions for this website and service content may be changed as necessary, so please make sure you check the details each time you use them.<br>
          <br>

          <h2>Handling of Personal Information</h2>
          For more information on the privacy policy and the handling of personal information on this website, please visit the following pages:<br>
          <a href="https://www.mitsuifudosan.co.jp/privacy_policy/" target="_blank">Privacy Policy</a>
          <router-link class="inline-block" :to="{ name: 'privacyPolicy' }">Privacy Policy (&well)</router-link><br>
          <br>
          <h2>Copyright and Trademark Rights</h2>
          The Company or rights holders who have licensed the use to the Company hold the intellectual property rights such as copyrights, licenses, and other rights to the information, design, layout, logo marks, characters, trademarks, etc., on this website. You may use this website only to the extent that you do not infringe these rights. Please do not reproduce, redistribute, etc., without the permission of the rights holders.<br>
          <br>
          <h2>Information Provided</h2>
          We are making reasonable efforts so that you will not incur unexpected damage or disadvantage from the information provided on this website, and making sure we provide up-to-date and accurate information. However, this does not guarantee completeness, accuracy, usefulness, etc. of its content. Accordingly, the Company assumes no responsibility for any damage or disadvantage caused by actions you have taken based on such information.<br>
          <br>
          <h2>Accidents While Viewing</h2>
          The Company also assumes no responsibility for any damage or disadvantage, including, but not limited to, any problems with software and hardware, computer virus contamination, loss of or damage to data caused by use of this website, and any damage or disadvantage arising out of trouble and other issues between you and other users or any third parties.<br>
          <br>
          <h2>Contact from You</h2>
          The Company assumes no responsibility for e-mails or request for materials not delivered due to a malfunction or problems on the Internet.<br>
          <br>
          <h2>Information Sent to You</h2>
          The copyright to the e-mails the Company sent to you belongs to the Company. You may not redistribute them on other websites or as printed materials without our permission.<br>
          <br>
          <h2>Changes to Content</h2>
          The information, service content, address, etc., of this website may be added, changed, and discontinued without prior notice given to you. The Company assumes no responsibility for the content written on the message boards, etc., in this website. The Company may also delete all or part of the content written without prior notice if it determines that it contains content that is inappropriate.<br>
          <br>
          <h2>Linking to This Website</h2>
          If you wish to create a link to this website, please contact Mitsui Fudosan Co., Ltd. &well Administration Office via the inquiry form below. The Company may refuse the link if it deems it to be inappropriate, such as linking to websites containing content that is contrary to public order and morality, adult content, or content that is slandering or defamatory to the Company and others.<br>
          <br>
          <a href="https://www3.mitsuifudosan.co.jp/enquete/regulation.php?enqueteurl=36f461a6037d8beb226113ae7531199b" target="_blank">Inquiries</a>



        .text(v-else).
          本ウェブサイトは、三井不動産株式会社（以下「当社」といいます）が運営・管理しています。本ウェブサイトをご利用されるに際し、以下の利用条件をよくお読みいただき、ご同意のうえ本ウェブサイトをご利用下さるようお願い申しあげます。<br>
          本ウェブサイト内の各サービスコンテンツの利用にあたっては <router-link class="inline-block" :to="{ name: 'termsOfService' }">&wellサービス利用規約</router-link> が適用されるものとします。<br>
          また、各サービスコンテンツにおいて別途利用条件を定めている場合には、各利用条件についてもよくお読みいただき、ご同意のうえご利用いただくようお願い申しあげます。お客さまが本ウェブサイトをご利用いただいた場合には、すべての利用条件にご同意いただいたものとさせていただきますのでご了承願います。<br>
          なお、本ウェブサイトおよび各サービスコンテンツは、利用条件を必要に応じて変更することがありますので、ご利用の都度、内容をご確認いただきますようお願い申しあげます。<br>
          <br>
          <h2>個人情報の取り扱いについて</h2>
          本ウェブサイトでの、個人情報保護方針、個人情報の取り扱いについては下記のページにてご確認ください。<br>
          <a href="https://www.mitsuifudosan.co.jp/privacy_policy/" target="_blank">個人情報保護方針</a>
          <router-link class="inline-block" :to="{ name: 'privacyPolicy' }">プライバシーポリシー（&well）</router-link><br>
          <br>
          <h2>著作権・商標権について</h2>
          本ウェブサイト内に掲載されている情報、デザイン、レイアウト、ロゴマーク、キャラクター、商標などに関しては、当社または当社にその利用を認めた権利者が著作権などの知的財産権、使用権その他の権利を有しています。お客さまは、これらの権利を侵害しない範囲でのみ本ウェブサイトをご利用いただくことができます。権利者の許可なく複製、転用などされることのないようお願いいたします。<br>
          <br>
          <h2>提供している情報について</h2>
          本ウェブサイトで提供している各種情報につきましては、お客さまに不測の損害・不利益などが発生しないよう適切に努力し、最新かつ正確な情報を掲載するよう注意を払っておりますが、その内容の完全性、正確性、有用性などについて保証をするものではありません。したがいまして当社は、お客さまが当該情報に基づいて起こされた行動によって生じた損害・不利益などに対していかなる責任も負いません。<br>
          <br>
          <h2>閲覧中の事故について</h2>
          また当社は、本ウェブサイトのご利用に起因しソフトウエア、ハードウエア上に生じた事故、コンピュータウイルスによる汚染、データの滅失・毀損などの一切の損害・不利益など、およびご利用のお客さま間またはお客さまと第三者間において生じたトラブルなどによる損害・不利益などについていかなる責任も負いません。<br>
          <br>
          <h2>お客さまからのコンタクトについて</h2>
          お客さまからのメールや資料請求などが、インターネット上の不具合・事故などにより当社に着信しなかった場合、当社はそれに対するいかなる責任も負いません。<br>
          <br>
          <h2>お客さまへ発信した情報について</h2>
          当社がお客さまに発信したメールなどについての著作権は当社に帰属しており、当社に無断で他のウェブサイトや印刷物などへ転用することはできません。<br>
          <br>
          <h2>掲載内容の変更について</h2>
          本ウェブサイトは、お客さまに事前にお知らせすることなく、情報やサービス内容、アドレスなどの追加、変更、廃止をさせていただくことがございます。本ウェブサイト内に存在する掲示板などに書き込まれた内容について、当社はいかなる責任も負いません。また当社は、書き込まれた内容が不適当な内容を含むと判断した場合、事前に通知することなくその全部または一部を削除することがございます。<br>
          <br>
          <h2>本ウェブサイトへのリンクについて</h2>
          本ウェブサイトへリンクを希望される場合は、下記のお問い合わせフォームより三井不動産株式会社&well事務局宛にご連絡ください。ただし、公序良俗に反する内容、アダルトコンテンツ、当社などを誹謗中傷する内容を含むウェブサイトなど、当社がリンクを不適当と判断する場合には、リンクをお断りすることがございます。<br>
          <br>
          <a href="https://www3.mitsuifudosan.co.jp/enquete/regulation.php?enqueteurl=c3caa245d0f764c1e29085f7a2ecb307" target="_blank">&well事務局へのお問い合わせ</a>

</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'

export default {
  name: 'notice',
  components: {
    HeadingBlock,
  },
}
</script>

<style lang="scss" scoped>
.notice {
  .article-content {
    max-width: 800px;
    margin: 0 auto;
  }
}
</style>
