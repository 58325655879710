<template lang="pug">
  .share-button-block
    ul
      li
        button(v-clipboard:copy="nonEncodedShareUrl" v-clipboard:success="onCopy" v-clipboard:error="onError")
          font-awesome-icon(:icon="['far', 'copy']")
      li
        button(@click="moveLink(twitter)")
          font-awesome-icon(:icon="['fab', 'twitter']" aria-hidden='true')
      li
        button(@click="moveLink(facebook)")
          font-awesome-icon(:icon="['fab', 'facebook']")
      li
        button(@click="moveLink(line)")
          font-awesome-icon(:icon="['fab', 'line']")
</template>
<script>
export default {
  props: ['fullPath', 'title'],
  computed: {
    shareUrl() {
      return encodeURIComponent('https://go.andwell.jp' + this.$props.fullPath)
    },
    nonEncodedShareUrl() {
      return 'https://go.andwell.jp' + this.$props.fullPath
    },
    twitter() {
      return (
        `https://twitter.com/share?url=${this.shareUrl}&text=${
          this.$props.title
        }` +
        encodeURIComponent(
          'への紹介をお送りします。\n以下のURLから確認する事ができます。\n'
        )
      )
    },
    line() {
      return `https://social-plugins.line.me/lineit/share?url=${this.shareUrl}`
    },
    facebook() {
      return `http://www.facebook.com/share.php?u=${this.shareUrl}`
    },
  },
  methods: {
    moveLink(url) {
      window.open(url, '_blank')
    },
    onCopy(e) {
      const txt = this.$i18n.t('URLをコピーしました')
      alert(txt)
    },
    onError(e) {
      const txt = this.$i18n.t('URLのコピーに失敗しました')
      alert(txt)
    },
  },
}
</script>
<style scoped lang="scss">
.share-button-block {
  text-align: center;
  padding: 15px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      & + li {
        margin-left: 35px;
      }
      svg {
        font-size: 25px;
        height: 1.1em;
        display: inline-block;
      }
      .fa-copy {
        color: $gray-color;
      }
      .fa-twitter {
        color: #1da1f2;
      }
      .fa-facebook {
        color: #4267b2;
      }
      .fa-line {
        color: #06c755;
      }
    }
  }
}
</style>
