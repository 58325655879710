<template lang="pug">
.terms-of-service
  .common-h1-area
    HeadingBlock(level="1" text="Terms of Use")
    p.heading-block-sub(v-if="this.$i18n.locale === 'ja'") {{$t("利用規約")}}
  .sub-page-padding
    .container
      article.article-content
        TermsContent
</template>

<script>
import HeadingBlock from '@/components/HeadingBlock'
import TermsContent from '@/components/TermsContent'
export default {
  name: 'termsOfService',
  components: {
    HeadingBlock,
    TermsContent,
  },
}
</script>

<style lang="scss" scoped>
.article-content {
  max-width: 800px;
  margin: 0 auto;
}
</style>
